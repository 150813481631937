<template>
  <scroll-center-dialog
    v-model="opened"
    :title="title(edit_data.text)"
    :loading="loading"
    :applyDisable="!valid"
    @close="close"
    @apply="apply"
  >
    <v-container>
      <v-form v-model="valid">
        <v-text-field
          v-model="data.name"
          label="Название языка"
          :error="hasError('name')"
          :error-messages="getError('name')"
          :rules="[rules.required]"
          clearable
          outlined
        />
      </v-form>
    </v-container>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import {mapActions} from "vuex";

export default {
  name: "languagesDialog",
  props:{
    opened: {
      type: Boolean,
      required: true
    },
    edit_data: {
      type: Object,
      default: () => ({
        text: ""
      }),
      required: true
    }
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog},
  data() {
    return {
      title: v => v ? "Изменить язык" : "Добавить язык",
      loading: false,
      data: {
        name: this.edit_data.text
      },
      rules: {
        required: v => !!v || "Поле обязательно для заполнения."
      },
      valid: false
    }
  },
  methods: {
    ...mapActions({
      updateLanguages: "languages/updateLanguages",
      createLanguages: "languages/createLanguages"
    }),
    close() {
      this.$emit("close")
    },
    apply() {
      if (this.edit_data.text)
        this.save()
      else
        this.create()
    },
    save() {
      this.loading = true
      let url = undefined
      this.updateLanguages({
        url: url,
        id: this.edit_data.value,
        data: this.data,
        finalizer: () => {
          this.loading = false
          this.$emit("close")
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    },
    create() {
      this.loading = true
      let url = undefined
      this.createLanguages({
        url: url,
        data: this.data,
        finalizer: (data) => {
          this.loading = false
          this.$emit("close")
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
