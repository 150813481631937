<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @change="searchData"
        @click:clear="searchData"
        @click:append="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isUsersListLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[50]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="guid"
      show-expand
    >
      <template v-slot:item.is_delete="{ item }">
        <v-icon color="teal" v-if="item.is_delete">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.actualized="{ item }">
        <span>{{displayDate(item.actualized,true)}}</span>
      </template>
      <template v-slot:item.is_exclude="{ item }">
        <span v-if="item.is_exclude">{{item.is_exclude_reason}}</span>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <vue-json-pretty
              style="width: 100%"
              class="text-xs-left"
              :data="item"
              show-line
              show-double-quotes
              collapsed-on-click-brackets
              :deep="1"
            />
          </v-container>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import { mapActions, mapGetters } from "vuex"
import names from "@/modules/university_connector/routers/names"
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import VueJsonPretty from "vue-json-pretty";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import {displayDate} from "@/helper";

export default {
  name: "List",
  components: {
    ApplyDialog,
    VueJsonPretty,
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      page_title: "Факультеты",
      expanded: [],
      edit_data: null,
      search: {
        parameter: "search"
      },
      errors: {},
      pagination: {
        size_name: "size",
        size: 50
      },

      headers: [
        {
          text: "Группа",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Факультет",
          align: "left",
          sortable: false,
          value: "faculty.name",
        },
        {
          text: "Курс",
          align: "left",
          sortable: false,
          value: "course",
        },
        {
          text: "Актуализирован",
          align: "left",
          sortable: false,
          value: "actualized",
        },
        {
          text: "Исключен",
          align: "left",
          sortable: false,
          value: "is_exclude",
        },
        {
          text: "Удален",
          align: "left",
          sortable: false,
          value: "is_delete",
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      getUsersList: "group_un/getGroupList",
      getUsersCount: "group_un/getGroupsCount",
      isUsersListLoading: "group_un/isGroupsListLoading"
    }),
    names: () => names
  },
  methods: {
    ...mapActions({
      loadUserList: "group_un/loadGroupList",
    }),
    displayDate,
    loadData(url = undefined) {
      this.loadUserList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData(this.getCurrentLoadUrl)
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
