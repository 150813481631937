<template>
  <v-card
    class=" rounded-lg py-4 px-4"
    flat
  >
    <v-card
      class="rounded-lg mb-0"
      v-if="user"
    >
      <v-toolbar
        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6 style-color-main-gray text-left px-4 pr-1' : 'text style-color-main-gray text-left px-4 pr-1'"
        :dense="$vuetify.breakpoint.mdAndUp"
        flat
      >
        <span class="dark-primary"> Основная информация </span>
        <v-spacer/>
        <v-toolbar-items
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-btn
            @click="opened_form_avatar = true"
            class="dark-primary"
            text
          >
            <v-icon>
              aspect_ratio
            </v-icon>
            <span
              class="ml-3"
            >
              Сменить аватар
            </span>
          </v-btn>
          <v-btn
            @click="opened_form_pass = true"
            class="dark-primary"
            text
          >
            <v-icon>lock</v-icon>&nbsp;
            Сменить пароль
          </v-btn>&nbsp;
<!--          <v-btn-->
<!--            @click="opened_form_email = true"-->
<!--            class="dark-primary"-->
<!--            text-->
<!--          >-->
<!--            <v-icon>-->
<!--              mail_outline-->
<!--            </v-icon>&nbsp;-->
<!--            Сменить почту-->
<!--          </v-btn>&nbsp;-->
        </v-toolbar-items>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
            <v-switch
              hide-details
              style="max-width: 48px"
              inset
              color="green darken-1"
              v-model="edit"
              class="my-0"
            >
            </v-switch>
              </span>
          </template>
          <span>
            Режим редактирования
          </span>
        </v-tooltip>
        <v-menu
          v-if="$vuetify.breakpoint.smAndDown"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="ml-1 dark-primary"
            >
              <v-icon>
                more_vert
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                elevation="0"
                block
                @click="opened_form_avatar = true"
                color="white"
              >
                <v-icon>
                  aspect_ratio
                </v-icon>
                <span
                  class="ml-3"
                >
                  Сменить аватар
                </span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                elevation="0"
                block
                @click="opened_form_pass = true"
                color="white"
              >
                <v-icon>
                  lock
                </v-icon>&nbsp;
                Сменить пароль
              </v-btn>
            </v-list-item>
<!--            <v-list-item>-->
<!--              <v-btn-->
<!--                elevation="0"-->
<!--                block @click="opened_form_email = true"-->
<!--                color="white"-->
<!--              >-->
<!--                <v-icon>-->
<!--                  mail_outline-->
<!--                </v-icon>&nbsp;-->
<!--                Сменить почту-->
<!--              </v-btn>-->
<!--            </v-list-item>-->
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-card-text
        class="subheading text-left"
      >
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="3"
            class="pr-md-4"
          >
            <v-img
              :src="NotAvatar"
              :max-height="$vuetify.breakpoint.smAndUp ? 350 : 190"
              aspect-ratio="1"
              class="ma-5"
              v-if="avatar === null"
              contain
            />
            <v-img
              v-if="avatar !== null"
              :src="avatar"
              :max-height="$vuetify.breakpoint.smAndUp ? 350 : 190"
              :lazy-src="NotAvatar"
              aspect-ratio="1"
              class="ma-5"
            />
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-row
              :class="(col%2) ? 'pa-3 grey lighten-3 rounded-lg' : 'pa-3 rounded-lg'"
              v-for="col in Object.keys(fields)"
              :key="`row_${col}`"
              no-gutters
            >
              <v-col class="text-left">
                <span
                  class="font-weight-black text--primary"
                >
                  {{ fields[col].text }}:
                </span>
              </v-col>
              <v-col
                class="text-right text-sm-left text--primary"
              >
              <span
                v-if="fields[col].type"
              >
                {{
                  displayDate(user[fields[col].field], fields[col].type === 'datetime')
                }}
              </span>
                <span
                  v-else
                >
                  {{ user[fields[col].field] }}
                </span>
              </v-col>
            </v-row>
            <image-cropper-dialog
              v-if="opened_form_avatar"
              :opened="opened_form_avatar"
              :picture="avatar"
              :uid="getProfile.uid"
              @save="saveImg"
              @close="close()"
            ></image-cropper-dialog>
            <change-user-password-dialog
              v-if="opened_form_pass"
              :opened="opened_form_pass"
              :user_data="user"
              @close="close()"
            ></change-user-password-dialog>
          </v-col>
        </v-row>
      </v-card-text>
      <user-information
        @update_profile="loadData"
        :edit="edit"
        :user="user"
      >
      </user-information>
    </v-card>
    <template v-if="!loadingData">
      <section v-if="!loadingWorkerData">
        <user-worker v-if="Object.keys(userWorker).length"/>
      </section>
      <semipolar-spinner
        v-else
        class="mx-auto"
        :animation-duration="2000"
        :size="$vuetify.breakpoint.mdAndUp ? 160 : 60"
        color="#1976D2"
      />
      <section v-if="!loadingStudentData">
        <user-student v-if="userStudent.length"/>
      </section>
      <semipolar-spinner
        v-else
        class="mx-auto"
        :animation-duration="2000"
        :size="$vuetify.breakpoint.mdAndUp ? 160 : 60"
        color="#1976D2"
      />
    </template>
  </v-card>
</template>

<script>
import {displayDate} from "@/helper"
import NotAvatar from "@/assets/NotAvatar.png"
import TitledPageMixin from "@/mixins/TitledPageMixin"
import {mapActions, mapGetters} from "vuex"
import UserInformation from "@/modules/admins/users/components/userInformation/UserInformation.vue";
import ImageCropperDialog from "@/modules/admins/users/components/editDetail/ImageCropperDialog";
import ChangeUserPasswordDialog from "@/modules/admins/users/components/СhangeUserPasswordDialog";
import UserWorker from "@/modules/admins/users/components/userInformation/UserWorker.vue";
import UserStudent from "@/modules/admins/users/components/userInformation/UserStudent.vue";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "Detail",
  components: {
    UserStudent,
    UserWorker,
    ChangeUserPasswordDialog,
    ImageCropperDialog,
    UserInformation,
    SemipolarSpinner
  },
  mixins: [TitledPageMixin],
  data() {
    return {
      user: null,
      avatar: null,
      edit: false,
      NotAvatar: NotAvatar,
      opened_form_avatar: false,
      opened_form_pass: false,
      opened_form_email: false,
      loadingWorkerData: false,
      loadingStudentData: false,
      page_title: "",
      fields: [
        {
          text: "Имя пользователя",
          field: "username"
        },
        {
          text: "Зарегистрирован",
          field: "joined",
          type: "datetime"
        },
        {
          text: "Вы были последний раз",
          field: "last_login",
          type: "datetime"
        },
        {
          text: "Фамилия",
          field: "lastname"
        },
        {
          text: "Имя",
          field: "firstname"
        },
        {
          text: "Отчество",
          field: "midname"
        },
        {
          text: "Пол",
          field: "sex"
        },
        {
          text: "Дата рождения",
          field: "birth",
          type: "date"
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getProfile: "adminUsers/getProfile",
      userWorker: "adminUsers/getUserWorkerInfo",
      userStudent: "adminUsers/getUserStudentInfo",
      loadingData: "adminUsers/isAdmUsersLoading"
    })
  },
  methods: {
    ...mapActions({
      loadUserDetail: "adminUsers/loadUserDetail",
      loadUserWorker: "adminUsers/loadUserWorkerInfo",
      loadUserStudent: "adminUsers/loadUserStudentInfo"
    }),
    displayDate: displayDate,
    saveImg() {
      this.opened_form_avatar = false
      this.loadData()
    },
    loadData(url = undefined) {
      let id = this.$route.params.id
      this.loadUserDetail({
        url,
        id,
        finalizer: (data) => {
          this.user = data
          this.avatar = this.user.avatar
          this.page_title = this.user.lastname + " " + this.user.firstname + " " + this.user.midname
          this.loadWorkerData()
          this.loadStudentData()
        }
      })
    },
    loadWorkerData(url = null) {
      this.loadingWorkerData = true
      this.loadUserWorker({
        url,
        uid: this.getProfile.uid,
        finalizer: () => {
          this.loadingWorkerData = false
        },
        catcher: () => {
          this.loadingWorkerData = false
        }
      })
    },
    loadStudentData(url = null) {
      this.loadingStudentData = true
      this.loadUserStudent({
        url,
        uid: this.getProfile.uid,
        finalizer: () => {
          this.loadingStudentData = false
        },
        catcher: () => {
          this.loadingStudentData = false
        }
      })
    },
    close() {
      this.opened_form_avatar = false
      this.opened_form_pass = false
      this.opened_form_email = false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.page_title = "Администрирование"
    next()
  },
  created() {
    if (!this.getProfile || this.getProfile.id !== this.$route.params.id) {
      this.loadData()
    } else {
      this.user = this.getProfile
      this.avatar = this.user.avatar
      this.page_title = this.user.lastname + " " + this.user.firstname + " " + this.user.midname
    }
  }
}
</script>

<style scoped>

</style>
