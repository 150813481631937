<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        :disabled="isLoading"
        @change="searchData"
        @click:clear="clearSearchData"
        @click:append="searchData"
        @keydown.enter="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isLoading"
      :options.sync="options"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[50,100],disablePagination: isLoading}"
      class="elevation-2 rounded-b-lg rounded-t-0"
    >
      <template
        v-slot:item.lastname="props"
      >
        <span
          style="text-decoration: underline;
          color: #065aad; cursor: pointer"
          @click="routToDetail(props.item)"
          v-if="props.item.is_active"
        >
          {{props.item.lastname}} {{props.item.firstname}} {{props.item.midname}}
        </span>
        <span
          v-else
        >
          {{props.item.lastname}} {{props.item.firstname}} {{props.item.midname}}
        </span>
      </template>
      <template v-slot:item.username="props">
        <span v-if="props.item.student_identity !== null && props.item.username !== props.item.student_identity">
          {{props.item.username}} ({{props.item.student_identity}})
        </span>
        <span v-else>
          {{props.item.username}}
        </span>
      </template>
      <template v-slot:item.is_student="props">
        <span v-if="props.item.is_student"> Студент</span>
        <span v-if="props.item.is_graduate"> Выпускник</span>
        <span v-if="props.item.is_employee"> Работник</span>
      </template>
      <template v-slot:item.is_active="props">
        <v-icon color="teal" v-if="props.item.is_active">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.is_alive="props">
        <v-icon color="teal" v-if="props.item.password_data?.is_alive">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openEditProfile(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать почту пользователя</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.is_approve && item.is_active">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="openChangeUserPassword(item)"
              medium
            >
              <v-icon>
                lock_open
              </v-icon>
            </v-btn>
          </template>
          <span>Сменить пароль</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.is_active">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="routToDetail(item)"
              medium
            >
              <v-icon>
                account_box
              </v-icon>
            </v-btn>
          </template>
          <span>Просмотр профиля</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.is_active">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="openLoginAsUser(item)"
              medium
            >
              <v-icon>
                input
              </v-icon>
            </v-btn>
          </template>
          <span>Войти под</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.is_approve">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="openEditUserActive(item)"
              medium
            >
              <v-icon
                v-text="item.is_active ? 'indeterminate_check_box' : 'check_box'"
              >
              </v-icon>
            </v-btn>
          </template>
          <span>Сменить активность пользователя</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="openDeleteUser(item)"
              medium
            >
              <v-icon>
                delete_forever
              </v-icon>
            </v-btn>
          </template>
          <span>Удалить пользователя</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <edit-email-dialog
      v-if="opened_edit_profile"
      :opened="opened_edit_profile"
      :user_data="user_data"
      @close="closeEditProfile"
    />
    <edit-user-active-dialog
      v-if="opened_edit_user_active"
      :opened="opened_edit_user_active"
      :user_data="user_data"
      @close="closeEditUserActive"
    />
    <change-user-password-dialog
      v-if="opened_change_user_password"
      :opened="opened_change_user_password"
      :user_data="user_data"
      @close="closeChangeUserPassword"
    />
    <delete-user-dialog
      v-if="opened_delete_user"
      :opened="opened_delete_user"
      :user_data="user_data"
      @close="closeDeleteUser"
    />
    <login-as-user
      v-if="opened_login_as_user"
      :opened="opened_login_as_user"
      :user_data="user_data"
      @close="closeLoginAsUser"
    />
  </v-container>
</template>


<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/admins/routers/names";
import coreNames from '@/modules/core/routers/names';
import EditEmailDialog from '@/modules/admins/users/components/EditEmailDialog'
import EditUserActiveDialog from "@/modules/admins/users/components/EditUserActiveDialog";
import ChangeUserPasswordDialog from "@/modules/admins/users/components/СhangeUserPasswordDialog";
import DeleteUserDialog from '@/modules/admins/users/components/DeleteUserDialog'
import LoginAsUser from '@/modules/admins/users/components/LoginAsUserDialog'
import TitledPageMixin from '@/mixins/TitledPageMixin'
import admin_urls from "@/urls/admin";

export default {
  name: "List",
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  components: {
    LoginAsUser,
    DeleteUserDialog,
    ChangeUserPasswordDialog,
    EditUserActiveDialog,
    EditEmailDialog
  },
  data: function () {
    return {
      page_title: 'Администрирование',
      selected: {},
      opened_edit_profile: false,
      opened_edit_user_active: false,
      opened_change_user_password: false,
      opened_delete_user: false,
      opened_login_as_user: false,
      user_data: undefined,
      pagination: {
        page: 1,
        size: 50,
        filters: {},
      },
      options: {
        sortBy: [],
        sortDesc: [],
        multiSort:true
      },
      search: {
        parameter: 'information',
      },
      headers: [
        {
          text: 'Пользователь',
          sortable: false,
          value: 'lastname',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Логин',
          sortable: false,
          value: 'username',
          align: 'left',
        },
        {
          text: 'Смена пароля',
          sortable: false,
          value: 'password_data.last_set',
          align: 'left',
        },
        {
          text: 'Активность пароля',
          sortable: false,
          value: 'is_alive',
          align: 'center',
        },
        {
          text: 'Почта',
          sortable: false,
          value: 'email',
          align: 'left',
        },
        {
          text: 'Тип',
          sortable: false,
          value: 'is_student',
          align: 'center',
        },
        {
          text: 'Активность',
          align: 'center',
          sortable: false,
          value: 'is_active',
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'center',
          width: '20%'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUsersList: 'adminUsers/getUserList',
      getUsersCount: 'adminUsers/getUsersCount',
      isLoading: 'adminUsers/isAdmUsersLoading'
    }),
    names: () => names,
    coreNames: () => coreNames
  },
  methods: {
    ...mapActions({
      loadUserList: 'adminUsers/loadUserList',
    }),
    routToDetail(user) {
      this.$router.push({name: names.USERS.DETAIL, params: { id: user.id }})
    },
    loadData(url = undefined) {
      this.loadUserList({
        url: url,
        urlAppend: (url) => {
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    reloadData() {
      this.loadData()
    },
    refreshItem: function (item) {
      let idx = this.items.findIndex((val, idx, obj) => val.id === item.id);
      this.$set(this.items, idx, item);
      this.closeEditProfile()
    },
    openEditProfile(item) {
      this.opened_edit_profile = true
      this.user_data = item
    },
    closeEditProfile() {
      this.opened_edit_profile = false
      this.user_data = undefined
      this.loadData(this.getCurrentLoadUrl())
    },
    openEditUserActive(item) {
      this.opened_edit_user_active = true
      this.user_data = item
    },
    closeEditUserActive() {
      this.opened_edit_user_active = false
      this.user_data = undefined
      this.loadData(this.getCurrentLoadUrl())
    },
    openChangeUserPassword(item) {
      this.opened_change_user_password = true
      this.user_data = item
    },
    closeChangeUserPassword() {
      this.opened_change_user_password = false
      this.user_data = undefined
      this.loadData(this.getCurrentLoadUrl())
    },
    openDeleteUser(item) {
      this.opened_delete_user = true
      this.user_data = item
    },
    closeDeleteUser() {
      this.opened_delete_user = false
      this.user_data = undefined
      this.loadData(this.getCurrentLoadUrl())
    },
    openLoginAsUser(item) {
      this.opened_login_as_user = true
      this.user_data = item
    },
    closeLoginAsUser(reload) {
      this.opened_login_as_user = false
      this.user_data = undefined
      if (reload) {
        this.loadData(this.getCurrentLoadUrl())
      }
    }
  },
  created() {
    // if (this.getUsersCount === 0)
      this.loadData()
  },

}
</script>
<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0;
}
</style>
