<template>
  <div class="page-content" style="margin: 0px; width: 100%">
        <List v-if="$route.name === names.FILE.LIST"></List>
        <router-view v-else></router-view>
  </div>
</template>

<script>
import names from "@/modules/admins/routers/names";
import List from "@/modules/admins/reference/pages/file/pages/List.vue";

export default {
  name: "RolesBreadcrumbs",
  components: {List},

  data() {
    return {

    }
  },
  computed: {
    names: () => names,
  },
}
</script>

<style scoped>

</style>
