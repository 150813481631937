import {sendDeleteRequest, sendGetRequest, sendPostRequest} from "@/helper/requests";
import worker from "@/urls/worker";

const state= {
  tree:{
      list: [],
    },
};

const getters= {
  getTreeList: (state) => state.tree.list,
};

const actions= {
  loadTreeList: ({commit}, {url,organization_id,finalizer=()=>{}, catcher=()=>{}}) => {
    commit('SET_LIST', [])
    if (!url) {
      url = worker.WORKER.DEPARTMENT_ABOLISHED_LIST(organization_id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        let str = JSON.stringify(data).replaceAll('childs', 'children')
        commit('SET_LIST', JSON.parse(str))
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      },
    )
  },
  addCommentTree: ({commit}, {url,data,organization_id,id,finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = worker.WORKER.DEPARTMENT_ADD_COMMENT(organization_id,id)
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      },
    )
  },
  listCommentTree: ({commit}, {url,organization_id,id,finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = worker.WORKER.DEPARTMENT_LIST_COMMENT(organization_id,id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      },
    )
  },
  deleteCommentTree: ({commit}, {url,organization_id,id,finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = worker.WORKER.DEPARTMENT_DELETE_COMMENT(organization_id,id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      },
    )
  },
};

const mutations= {
  SET_LIST: (state, data) => {
    state.tree.list = data;
    }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
