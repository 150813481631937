<template>
  <v-container class="page-content px-5">
    <v-card>
      <v-toolbar elevation="0" color="#DADADA">
        <v-toolbar-title>
          Перечень всех активных сеансов на {{displayDate(new Date(),true)}}
        </v-toolbar-title>
        <v-spacer/>
        <v-menu
          v-if="$vuetify.breakpoint.xsOnly"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }" class="mr-3">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              color="black"
              class="mr-0"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-btn
              depressed
              color="transparent"
              width="100%"
              @click="reloadData"
            >
              Обновить
            </v-btn>
          </v-list>
        </v-menu>
        <div v-else>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                text
                class="pa-0 mr-3"
                v-bind="attrs"
                v-on="on"
                @click="reloadData"
              >
                <v-icon>
                  refresh
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить</span>
          </v-tooltip>
        </div>
      </v-toolbar>

        <v-text-field
          v-model="search.parameters.search"
          append-icon="search"
          label="Поиск по ФИО"
          placeholder="Для поиска нажмите Enter или кнопку поиск"
          dense
          outlined
          :class="!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3' : 'px-2 mx-2 my-3'"
          clearable
          hide-details
          @click:append="searchData"
          @keyup.enter="searchData"
          @click:clear="clearSearchData('search')"
        />
      <v-row no-gutters>
        <v-datetime-picker
          v-model="filtered.created__gte"
          label="Поиск по времени авторизации (от, включая)"
          placeholder="Для поиска нажмите Enter или кнопку поиск"
          time-format="HH:mm"
          date-format="MM.dd.yyyy"
          :text-field-props= "{
            'dense':true,
            'outlined':true,
            'hide-details':true,
             'class':!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3 my-0' : 'px-2 mx-2 my-3',
          }"
          okText = "Сохранить"
          clearText = "Очистить"
        />
        <v-datetime-picker
          v-model="filtered.created__lte"
          type="datetime-local"
          label="Поиск по времени авторизации (до, включая)"
          placeholder="Для поиска нажмите Enter или кнопку поиск"
          time-format="HH:mm"
          date-format="MM.dd.yyyy"
          :text-field-props= "{
            'dense':true,
            'outlined':true,
            'hide-details':true,
            'class':!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3 my-0' : 'px-2 mx-2 my-3',
          }"
          okText = "Сохранить"
          clearText = "Очистить"
        />
      </v-row >
      <v-row no-gutters>
        <v-datetime-picker
          v-model="filtered.ended__gte"
          type="datetime-local"
          label="Поиск по времени конца жизни сессии (от, включая)"
          placeholder="Для поиска нажмите Enter или кнопку поиск"
          time-format="HH:mm:ss"
          date-format="MM.dd.yyyy"
          okText = "Сохранить"
          clearText = "Очистить"
          :text-field-props= "{
            'dense':true,
            'outlined':true,
            'hide-details':true,
            'class':!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3 my-0' : 'px-2 mx-2 my-3',
          }"
        />
        <v-datetime-picker
          v-model="filtered.ended__lte"
          type="datetime-local"
          label="Поиск по времени конца жизни сессии (до, включая)"
          placeholder="Для поиска нажмите Enter или кнопку поиск"
          time-format="HH:mm:ss"
          date-format="MM.dd.yyyy"
          okText = "Сохранить"
          clearText = "Очистить"
          :text-field-props= "{
            'dense':true,
            'outlined':true,
            'hide-details':true,
            'class':!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3 my-0' : 'px-2 mx-2 my-3',
          }"
        />
      </v-row>
      <v-card flat tile>
        <v-data-table
          :headers="headers"
          :items="getList"
          :loading="getLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="getCount"
          :footer-props="{'items-per-page-options':[50,100]}"
        >
          <template v-slot:item.created="{item}">
              {{displayDate(item.created,true)}}
          </template>
          <template v-slot:item.ended="{item}">
            <span v-if="item.ended">{{displayDate(item.ended,true)}}</span>
            <span v-else>-</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {displayDate} from "@/helper";
import DeleteDialog from "@/modules/core/components/DeleteDialog";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import moment from "moment/moment";

export default {
  name: "SessionAdmin",
  mixins: [PaginatedDataMapperMixin, TitledPageMixin],
  components: {DeleteDialog},
  data() {
    return {
      page_title: 'Список активных сеансов администраторов',
      headers: [
        {
          text: 'Пользователь',
          align: 'left',
          sortable: false,
          value: 'user',
          width: '15%'
        },
        {
          text: 'Администратор',
          align: 'left',
          sortable: false,
          value: 'admin',
          width: '15%'
        },
        {
          text: 'Устройство',
          align: 'left',
          sortable: false,
          value: 'device',
          width: '30%'
        },
        {
          text: 'Время входа в аккаунт',
          align: 'left',
          sortable: false,
          value: 'created',
          width: '10%'
        },
        {
          text: 'Время выхода из аккаунта',
          align: 'left',
          sortable: false,
          value: 'ended',
          width: '10%'
        },
        {
          text: 'IP',
          align: 'left',
          sortable: false,
          value: 'ip',
          width: '20%'
        },
      ],
      filtered: {
        created__lte: null,
        created__gte: null,
        ended__gte: null,
        ended__lte: null,
      },
      pagination: {
        size: 50,
        size_name: 'size',
        page_name: 'page',
        filters: {
          created__lte: null,
          created__gte: null,
          ended__gte: null,
          ended__lte: null,
        },
      },
      search: {
        parameters: {
          search: null,
        },
      },
      selectors: {
        departments: []
      },
      selected: {},
      loading: false,
      delete_loading: false,
      opened_delete: false,
      error: null,
      opened_error: false,
    }
  },
  computed: {
    ...mapGetters({
      getList: 'sessionAdmin/getSessionList',
      getCount: 'sessionAdmin/getSessionListCount',
      getLoading: 'sessionAdmin/getSessionListLoading',

    })
  },
  watch: {
    'filtered': {
      deep: true,
      handler: function (nVal, oVal) {
        let update = null
        for (var key of Object.keys(nVal)) {
          if(nVal[key]){
            let isLte = true
            if (String(key).includes("__gte"))
              isLte = false
            let newDateObj = moment(nVal[key]).add(isLte ? 1 : -1, 'm').toDate();
            update = newDateObj.toISOString().slice(0,19)
          }
          this.pagination.filters[key] = update
        }
      }
    },
  },
  methods: {
    updateSearch(){
      let url = this.getCurrentLoadUrl()
      if (url)
        this.setCurrentLoadUrl(
          this.addSearchParameter(
            this.getCurrentLoadUrl()
          ),
          true
        );
    },
    displayDate,
    ...mapActions({
      loadRecipientsList: 'sessionAdmin/loadSessionList',
    }),
    loadData(url = undefined) {
      this.loadRecipientsList({
        url: url,
        urlAppend: (url) => {
          return this.addFiltersParameter(this.addSizeParameter(this.addPageParameter(url)));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: () => {
        }
      })
    },
    addSearchParameter(url) {
      return this.addGetParameters(
        url,
        this.search.parameters
      )
    },
    clearSearchData: function (param) {
      this.cleanPaginationPageData();
      switch (param) {
        case 'search' : {
          this.search.parameters.search = null;
          break
        }
        default: {
          this.search.parameters.search = null;
        }
      }
      this.setCurrentLoadUrl(
        this.addSearchParameter(
          this.getCurrentLoadUrl()
        ),
        true
      );
    },
    clearFiltersData() {
      this.cleanPaginationPageData();
      this.setCurrentLoadUrl(
        this.addSearchParameter(
          this.getCurrentLoadUrl()
        ),
        true
      );
    },
    reloadData() {
      this.clearSearchData()
      this.clearFiltersData()
      this.loadData()
    },
  },
  created() {
    if (this.getCount === 0) {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
