import university_urls from "@/urls/university";
import {sendGetRequest, sendPatchRequest, sendPostRequest} from "@/helper/requests"

const state = {
  university_faculty: {
    faculty_list: [],
    profile: null,
    loading: null,
    count: 0
  }
};

const getters = {
  getFacultyList: (state) => state.university_faculty.faculty_list,
  getFacultysCount: (state) => state.university_faculty.count,
  isFacultysListLoading: (state) => state.university_faculty.loading
};

const actions = {
  loadFacultyList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit("SET_LOADING")
    if (!url) {
      url = university_urls.UNIVERSITY.FACULTY_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit("SET_FACULTY_LIST", data["results"])
        commit("SET_FACULTYS_COUNT", data["count"])
        commit("SET_NOT_LOADING")
        finalizer(data, url)
      },
      (error) => {
        commit("SET_NOT_LOADING")
        catcher(error)
      }
    )
  },
  changeRestoreBooker: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.FACULTY_RESTORE_BOOKER(id)
    }
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  changeRestoreAD: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.FACULTY_RESTORE_AD(id)
    }
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadFacultyDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.FACULTY_DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations = {
  SET_FACULTY_LIST: (state, faculty_list) => {
    state.university_faculty.faculty_list = faculty_list
  },
  REMOVE_FACULTY_LIST: (state) => {
    state.university_faculty.faculty_list = []
  },
  SET_LOADING: (state) => {
    state.university_faculty.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.university_faculty.loading = false
  },
  SET_FACULTYS_COUNT: (state, count) => {
    state.university_faculty.count = count
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
