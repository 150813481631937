<template>
  <v-container>
    <v-row>
      <v-col cols="3" class="text-left">
        <strong>Работник: </strong>
        <span>{{ item.face.fio }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Должность: </strong>
        <span>{{ item.held.profession }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Подразделение: </strong>
        <span>{{ item.held.department }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Создано: </strong>
        <span>{{ displayDate(item.created) }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Обновлено: </strong>
        <span v-if="item.updated">{{ displayDate(item.updated) }}</span>
        <span v-else>-</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Выполнено: </strong>
        <v-icon v-if="item.is_execute" color="green">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Дата выполнения: </strong>
        <span>{{ displayDate(item.executed) }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Ставка: </strong>
        <span>{{ item.held.rate }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Вид занятости: </strong>
        <span>{{ item.held.kind }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Принят: </strong>
        <span>{{ displayDate(item.held.entered) }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Удалено: </strong>
        <v-icon v-if="item.is_delete" color="green">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Уволен: </strong>
        <span>{{ displayDate(item.fired) }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Полностью уволен: </strong>
        <v-icon v-if="item.full_fired" color="green">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Деактивирован: </strong>
        <v-icon v-if="item.deactivate" color="green">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Uid организации: </strong>
        <span>{{ item.organization.guid }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Название организации: </strong>
        <span>{{ item.organization.name }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Guid: </strong>
        <span>{{ item.guid }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {displayDate} from "@/helper";

export default {
  name: "DismissalExpand",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    displayDate
  }
}
</script>

<style scoped>

</style>
