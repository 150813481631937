<template>
  <v-dialog persistent v-model="opened" width="500">
    <v-card class="rounded-lg">
      <v-card-title class="super-grey medium-primary">Подтвердите действие</v-card-title>
      <v-divider></v-divider>
      <v-alert v-if="hasError('detail')" type="error">
        {{ getError('detail') }}
      </v-alert>
      <v-card-text class="mt-3">
        Вы уверены что хотите удалить "{{ delete_data.name }}"
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="apply" :loading="loading" color="red" text>
          Удалить
        </v-btn>
        <v-btn @click="close" text>
          Отмена
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "deleteDepartmentDialog",
  mixins: [FormErrorsMixin],
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
    delete_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      deleteCommon: 'commonReference/deleteCommon',
    }),
    close() {
      this.$emit('close')
    },
    apply() {
      this.loading = true
      let url = undefined
      this.deleteCommon({
        url: url,
        id: this.delete_data.id,
        finalizer: () => {
          this.loading = false
          this.$emit('close')
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
