import { render, staticRenderFns } from "./UserExpanded.vue?vue&type=template&id=561ae270&scoped=true"
import script from "./UserExpanded.vue?vue&type=script&lang=js"
export * from "./UserExpanded.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561ae270",
  null
  
)

export default component.exports