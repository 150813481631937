import {sendGetRequest} from "@/helper/requests";
import signer from "@/urls/signer";

const state= {
    logsList: {
      list: [],
      count: 0,
      loading: false,
    },
};

const getters= {
  getLogsList: (state) => state.logsList.list,
  getLogsListCount: (state) => state.logsList.count,
  getLogsListLoading: (state) => state.logsList.loading,
};

const actions= {
  loadLogsList: ({commit}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = signer.SIGNER.ADMIN.DISPATCHED_DOCUMENTS.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    commit('SET_LOADING')
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_NOT_LOADING')
        commit('SET_LIST', data['results'])
        commit('SET_COUNT', data['count'])
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      },
    )
  },
};

const mutations= {
  SET_LIST: (state, data) => {
    state.logsList.list =data;
  },
  SET_COUNT: (state, data) => {
    state.logsList.count = data;
  },
  SET_LOADING: (state) => {
    state.logsList.loading = true;
  },
  SET_NOT_LOADING: (state) => {
    state.logsList.loading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
