<template>
  <scroll-center-dialog
    v-model="opened"
    :loading="save_loading"
    @close="close"
    @apply="save"
    :title="form_title"
    :scrolled="false"
  >
    <div v-if="loading" class="px-3">
      <v-text-field
        class="mt-4 mb-0"
        v-model="common.name"
        :error="hasError('name')"
        :errorMessages=" (fixedError('name'))  "
        clearable
        outlined
      >
        <template v-slot:label>
          Наименование<span class="red--text">*</span>
        </template>
      </v-text-field>
      <v-autocomplete
        v-model="common.group"
        :items="groups"
        item-value="value"
        outlined
        :error="hasError('group')"
        :errorMessages="getError('group')"
      >
        <template v-slot:label>
          Группа<span class="red--text">*</span>
        </template>
      </v-autocomplete>
      <v-text-field
        label="Почта"
        v-model="common.email"
        :error="hasError('email')"
        :errorMessages="getError('email')"
        clearable
        outlined
      >
        <template v-slot:label>
          Почта
        </template>
      </v-text-field>
      <template
        v-for="(item, i) in common.phones"
      >
        <ReferencePhones
          :key="i+'phone'"
          :item="item"
          :index="i"
          :phones="common.phones"
          :special-error.sync="special_error"
        >
        </ReferencePhones>
      </template>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="addNewPhone"
          class="pr-0"
        >
          Добавить телефон
          <v-icon>add</v-icon>
        </v-btn>
      </v-row>
      <v-checkbox
        class="py-0 my-0"
        label="Скрытый"
        v-model="common.is_hidden"
        :error="hasError('is_hidden')"
        :errorMessages="getError('is_hidden')"
      ></v-checkbox>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import ReferencePhones from "@/modules/admins/reference/components/common/ReferencePhones.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "editCommonDialog",
  props:{
    opened: Boolean,
    edit: Boolean,
    commonId: Number,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, ReferencePhones, SemipolarSpinner },
  data() {
    return {
      loading: false,
      save_loading: false,
      common: {
        id: null,
        name: null,
        group: null,
        email: null,
        phones: [
          {phone:'',addition:[]}
        ],
        is_hidden: false,
      },
      special_error: [],
    }
  },
  computed: {
    form_title() {
      return !this.edit ? 'Добавить контакт' : 'Редактировать контакт'
    },
    ...mapGetters({
      groups: 'selectors/getGroups',
      item: 'commonReference/getCommonDetail'
    }),
  },
  methods: {
    ...mapActions({
      createCommon: 'commonReference/createCommon',
      updateCommon: 'commonReference/updateCommon',
      loadGroups : 'selectors/loadGroups',
      loadCommonDetail: 'commonReference/loadCommonDetail',
    }),
    close() {
      this.$emit('close', false)
    },
    fixedError(filed){
     let err = this.getError(filed)
      if(Array.isArray(err))
        err = String(err).replace('general contact','Общий контакт').replace('name','именем')
     return err
    },
    catchErrors(errors) {
      this.save_loading = false;
      if(!errors.phones){
        this.mapErrors(errors)
      } else {
        this.special_error = errors.phones
      }
    },
    save() {
      this.save_loading = true
      if (this.edit) {
        this.updateCommon({
          id: this.commonId,
          data: this.prepareData(),
          finalizer: () => {
            this.save_loading = false
            this.close()
          },
          catcher: this.catchErrors
        })
      }
      else {
        this.createCommon({
          data: this.prepareData(),
          finalizer: () => {
            this.save_loading = false
            this.close()
          },
          catcher: this.catchErrors
        })
      }
    },
    prepareData: function () {
      let data = {}
      for (let el in this.common) {
        if (el !== 'group'){
          if (this.common[el] !== null){
            data[el] = this.common[el]
          }
        } else if (this.common.group){
          if (this.common[el].value){
            data[el] = this.common[el].value
          } else {
            data[el] = this.common[el]
          }
        }
      }
      return data
    },
    // Добавление еще одного телефона
    addNewPhone() {
      this.common.phones.push({
        phone: null,
        addition: [],
      })
    },
    loadData() {
      if (this.commonId) {
        this.loadCommonDetail({
          id: this.commonId,
          finalizer: (data) => {
            this.common = data
            if(!this.common.phones)
              this.common.phones = []
            this.loading = true
          }
        })
      }
      else {
        this.loading = true;
      }
    },
    loadSelectors(){
      this.loadGroups({
        finalizer: (val) => {},
      })
    },
  },
  created() {
    if (this.groups.length === 0) {
      this.loadSelectors()
    }
    this.loadData()
  }
}
</script>

<style scoped>

</style>
