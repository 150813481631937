<template>
  <apply-change-readonly-boolean
    :opened="opened"
    :loading="loading"
    @close="close"
    @apply="apply"
  >
    <v-alert type="error" v-if="hasError('detail')">
      {{ getError('detail') }}
    </v-alert>
    Вы уверены что хотите
    <span
      v-if="user_data.is_active"
    >
      деактивировать
    </span>
    <span v-else>
      активировать
    </span>
    пользователя
    {{ user_data.lastname }} {{ user_data.firstname }} {{ user_data.midname }}?<br>
  </apply-change-readonly-boolean>
</template>

<script>
import ApplyChangeReadonlyBoolean from "@/modules/core/components/ApplyDialog";
import {mapActions} from "vuex";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "EditUserActiveDialog",
  mixins: [FormErrorsMixin],
  components: {ApplyChangeReadonlyBoolean},
  props: {
    opened: Boolean,
    user_data: Object,
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions({
      activateUser: 'adminUsers/activateUser',
      deactivateUser: 'adminUsers/deactivateUser'
    }),
    close() {
      this.$emit('close')
    },
    apply() {
      let url = undefined
      this.loading = true
      if (this.user_data.is_active) {
        this.deactivateUser({
          url: url,
          id: this.user_data.id,
          finalizer: (data) => {
            this.loading = false
            this.$emit('close')
          },
          catcher: (error) => {
            if (error.detail) {
              this.mapErrors(error)
              this.loading = false
            }
          }
        })
      } else {
        this.activateUser({
          url: url,
          id: this.user_data.id,
          finalizer: (data) => {
            this.loading = false
            this.$emit('close')
          },
          catcher: (error) => {
            if (error.detail) {
              this.mapErrors(error)
              this.loading = false
            }
          }
        })
      }
    }
  },
}
</script>

<style scoped>

</style>
