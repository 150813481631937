<template>
  <v-dialog persistent v-model="opened" width="500">
    <v-card>
      <v-card-title>Подтвердите действие</v-card-title>
      <v-divider></v-divider>
      <slot name="card_action">
        <v-card-text class="mt-3">
          Вы уверены что хотите удалить?
        </v-card-text>
      </slot>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="apply" :loading="loading" color="red" text>
          Удалить
        </v-btn>
        <v-btn @click="close" text>
          Отмена
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    opened: {
      required: true,
      type: Boolean
    },
    loading: {
      required: true,
      type: Boolean
    }
  },
  name: "DeleteDialog",
  methods: {
    apply() {
      this.$emit('close', true)
    },
    close() {
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped>

</style>
