<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="save"
    :title="form_title"
    :scrolled="false"
    :loading="loading"
  >
    <div v-if="!loading" class="px-3">
      <v-autocomplete
        class="pt-4"
        v-model="value.organization"
        :items="organizations"
        item-value="value"
        item-text="text"
        label="Организация"
        outlined
        :error="hasError('organization')"
        :errorMessages="getError('organization')"
      >
      </v-autocomplete>
      <v-text-field
        v-model.number="value.latitude"
        label="Широта"
        type="number"
        :error="hasError('latitude')"
        :error-messages="getError('latitude')"
        clearable
        outlined
      ></v-text-field>
      <v-text-field
        v-model.number="value.longitude"
        label="Долгота"
        type="number"
        :error="hasError('longitude')"
        :error-messages="getError('longitude')"
        clearable
        outlined
      ></v-text-field>
      <v-text-field
        v-model.number="value.radius"
        label="Радиус (м)"
        type="number"
        :error="hasError('radius')"
        :error-messages="getError('radius')"
        clearable
        outlined
      ></v-text-field>
      <v-expansion-panels
        v-model="panel"

      >
        <v-expansion-panel
          :key="1"
        >
          <v-expansion-panel-header>
            Посмотреть на карте
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-row>
        <v-col cols="2">
          <v-select
            :items="[2, 3, 4, 5, 6, 7 ,8,9,10,11,12,13,14,15,16,17]"
            hide-details
            v-model.lazy="zvalue"
          ></v-select>
        </v-col>
        <v-col>
          <v-img
            :aspect-ratio="16/9"
            :src="'https://static-maps.yandex.ru/v1?ll='
            +value.longitude+','+value.latitude+'&z='+zvalue
     +drawCirclePoints(20,value.radius/100000,value.longitude,value.latitude)
            +'&apikey=f525b9a9-28e2-4dd6-91da-64eb31d5749f'"
          ></v-img>
        </v-col>
      </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-alert outlined type="error" v-if="hasError('non_field_errors')">
        {{String(getError('non_field_errors'))}}
      </v-alert>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "editPersonalDialog",
  props:{
    opened: Boolean,
    edit_data: Object,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, SemipolarSpinner },
  data() {
    return {
      loading:false,
      panel:[],
      zvalue:15,
      value: {
        organization: null,
        latitude: 0,
        longitude: 0,
        radius: 0
      },
    }
  },

  watch:{

  },
  computed: {
    form_title() {
      if(this.edit_data)
        return 'Редактировать координаты'
      return 'Добавить координаты'
    },

    ...mapGetters({
      organizations: 'selectors/getOrganizations',
      item: 'personalReference/getPersonalDetail'
    }),
  },
  methods: {
        ...mapActions({
      createGeo: 'geo/createGeo',
      detailGeo: 'geo/loadGeoDetail',
      updateGeo: 'geo/updateGeo',
      loadOrganization : 'selectors/loadOrganization',
    }),
    drawCirclePoints( points,   radius, x,y)  {
      let slice = 2 * Math.PI / points
      let str = ''
      let strpoint = ''
      let fx = 0
      let fy = 0
      for ( let i = 0; i < points; i++) {
        let angle = slice * i
        let newX =  (x + 1.6 * radius * Math.cos(angle)).toFixed (7)
        let newY =  (y + radius * Math.sin(angle)).toFixed (7)
        if(i === 0){
          fx = newX
          fy = newY
        }
        strpoint =strpoint+ newX + ',' + newY
        if(i < points - 1)
          strpoint =strpoint+','
        else {
          strpoint = strpoint+','+fx+','+fy
        }
      }
      str = '&pl=c:ec473fFF,f:00FF00A0,w:10'+','+strpoint.slice(0, -1)
      return str
    },
    close() {
      this.$emit('close', false)
    },
    save() {
      if (this.edit_data) {
        this.updateGeo({
          id: this.edit_data.id,
          data: this.value,
          finalizer: () => {
            this.close()
          },
          catcher: (errors) => {
            this.mapErrors(errors)
          }
        })
      }
      else {
        this.createGeo({
          data: this.value,
          finalizer: () => {
            this.close()
          },
          catcher: (errors) => {
            this.mapErrors(errors)
          }
        })
      }
    },
  },
  created() {
    this.loadOrganization({
      finalizer: (data) => { }
    } )
    if(this.edit_data){
      this.loading = true
      this.detailGeo({
        id: this.edit_data.id,
        finalizer: (data) => {
          this.value.organization = data.organization?.id
          this.value.latitude = data.latitude
          this.value.longitude = data.longitude
          this.value.radius = data.radius
          this.loading = false
        }
      })
    }else {
      this.loading = false
    }

  }
}
</script>

<style scoped>
#yandex-map {
  width: 250px;
  height: 300px;
}

</style>
