import university_urls from "@/urls/university";
import {sendGetRequest, sendPatchRequest, sendPostRequest} from "@/helper/requests"

const state = {
  university_group: {
    group_list: [],
    profile: null,
    loading: null,
    count: 0
  }
};

const getters = {
  getGroupList: (state) => state.university_group.group_list,
  getGroupsCount: (state) => state.university_group.count,
  isGroupsListLoading: (state) => state.university_group.loading
};

const actions = {
  loadGroupList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit("SET_LOADING")
    if (!url) {
      url = university_urls.UNIVERSITY.GROUP_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit("SET_GROUP_LIST", data["results"])
        commit("SET_GROUPS_COUNT", data["count"])
        commit("SET_NOT_LOADING")
        finalizer(data, url)
      },
      (error) => {
        commit("SET_NOT_LOADING")
        catcher(error)
      }
    )
  },
  loadGroupDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.GROUP_DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations = {
  SET_GROUP_LIST: (state, group_list) => {
    state.university_group.group_list = group_list
  },
  REMOVE_GROUP_LIST: (state) => {
    state.university_group.group_list = []
  },
  SET_LOADING: (state) => {
    state.university_group.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.university_group.loading = false
  },
  SET_GROUPS_COUNT: (state, count) => {
    state.university_group.count = count
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
