<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @change="searchData"
        @click:clear="searchData"
        @click:append="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isUsersListLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[50]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="guid"
      show-expand
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom v-if="item.education_related">
          <template v-slot:activator="{ on }">
            <v-btn  icon v-on="on" @click="openUpdateGroupDialog( item )">
              <v-icon>sync_alt</v-icon>
            </v-btn>
          </template>
          Обновление группы в еиосе по исторической группе
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn  icon v-on="on" @click="openAddGroupDialog( item )">
              <v-icon>group_add</v-icon>
            </v-btn>
          </template>
          Привязка ист. группы к группе в еиос
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn  icon v-on="on" @click="openAddGroupDialogUni( item )">
              <v-icon>person_add</v-icon>
            </v-btn>
          </template>
          Привязка ист. группы к группе в университи
        </v-tooltip>
      </template>
      <template v-slot:item.is_archive="{ item }">
        <v-icon color="teal" v-if="item.is_archive">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <vue-json-pretty
              style="width: 100%"
              class="text-xs-left"
              :data="item"
              show-line
              show-double-quotes
              collapsed-on-click-brackets
              :deep="1"
            />
          </v-container>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <actualize-dialog
      v-if="change_actualize.open"
      :opened="change_actualize.open"
      :edit_data="edit_data"
      @close="closeAllDialog"
    ></actualize-dialog>
    <group-dialog
      v-if="dialog_group.open"
      :opened="dialog_group.open"
      :edit_data="edit_data"
      @close="closeAllDialog"
    ></group-dialog>
    <group-uni-dialog
      v-if="dialog_group_uni.open"
      :opened="dialog_group_uni.open"
      :edit_data="edit_data"
      @close="closeAllDialog"
    ></group-uni-dialog>
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import { mapActions, mapGetters } from "vuex"
import names from "@/modules/university_connector/routers/names"
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import VueJsonPretty from "vue-json-pretty";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import actualizeDialog from "@/modules/university_connector/historygroup/components/actualizeDialog.vue";
import groupDialog from "@/modules/university_connector/historygroup/components/groupDialog.vue";
import groupUniDialog from "@/modules/university_connector/historygroup/components/groupUniDialog.vue";

export default {
  name: "List",
  components: {
    actualizeDialog,
    groupDialog,
    ApplyDialog,
    VueJsonPretty,
    groupUniDialog
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      page_title: "Факультеты",
      expanded: [],
      edit_data: null,
      search: {
        parameter: "search"
      },
      errors: {},
      change_actualize: {
        open: false,
      },
      dialog_group_uni: {
        open: false,
      },
      dialog_group: {
        open: false,
      },
      pagination: {
        size_name: "size",
        size: 50
      },

      headers: [
        {
          text: "Год",
          align: "left",
          sortable: false,
          value: "year",
        },
        {
          text: "Группа",
          align: "left",
          sortable: false,
          value: "current_course.name",
        },
        {
          text: "Факультет",
          align: "left",
          sortable: false,
          value: "current_course.faculty.name",
        },
        {
          text: "Курс",
          align: "left",
          sortable: false,
          value: "current_course.course",
        },
        {
          text: "Архив",
          align: "left",
          sortable: false,
          value: "is_archive",
        },
        {
          text: "Действия",
          align: "right",
          sortable: false,
          value: "actions",
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      getUsersList: "history_group_un/getGroupList",
      getUsersCount: "history_group_un/getGroupsCount",
      isUsersListLoading: "history_group_un/isGroupsListLoading"
    }),
    names: () => names
  },
  methods: {
    ...mapActions({
      loadUserList: "history_group_un/loadGroupList",
    }),
    closeAllDialog(){
      this.edit_data=null
      this.change_actualize.open = false
      this.dialog_group.open = false
      this.dialog_group_uni.open = false
      this.reloadData()
    },
    openUpdateGroupDialog(props){
      this.edit_data=props
      this.change_actualize.open = true
    },
    openAddGroupDialog(props){
      this.edit_data=props
      this.dialog_group.open = true
    },
    openAddGroupDialogUni(props){
      this.edit_data=props
      this.dialog_group_uni.open = true
    },
    loadData(url = undefined) {
      this.loadUserList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData(this.getCurrentLoadUrl())
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
