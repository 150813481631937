<template>
  <v-list shaped single-line class="pa-0">
    <template v-for="(item, i) in items">
      <span :key="i" v-if="!item.base && !item.hide" class="current_menu__group">
        <CurrentLeftMenuAction
          :item="item"
          v-if="item.router"
        />
        <template v-if="item.subs !== undefined && !item.router && !item.base">
          <v-list-group
            :value="inSubs(item)"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title class="current_menu__title text-uppercase ms_text-h4">
                {{ item.title }}
              </v-list-item-title>
            </template>
            <template v-for="(subitem, j) in item.subs">
              <CurrentLeftMenuSubAction
                :key="j"
                :item="subitem"
              />
            </template>
          </v-list-group>
        </template>
      </span>
    </template>
  </v-list>
</template>

<script>
import CurrentLeftMenuAction from "@/modules/menus/left_menu/CurrentLeftMenuAction";
import CurrentLeftMenuSubAction from "@/modules/menus/left_menu/CurrentLeftMenuSubAction.vue";

export default {
  name: "CurrentLeftMenu",
  components: {
    CurrentLeftMenuSubAction,
    CurrentLeftMenuAction
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    inSubs: function (item) {
      if(item.subs !== undefined) {
        let idx = item.subs.findIndex((el) => {
          return el.router === this.activeRouter;
        });
        return idx !== -1;
      }else {
        return false;
      }
    }
  }
}
</script>

<style lang="scss">
.current_menu__group .v-list-group .v-list-group__header {
  padding: 16px 24px !important;
  height: 55px;
  border-bottom: 1px solid var(--blue-shades-focus);
  background: var(--background-white);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &:hover {
    background: var(--gray-shades-hover);
  }
}

.current_menu__group .v-list-group--active .v-list-group__header {
  padding: 16px 24px !important;
  height: 55px;
  border-bottom: 1px solid var(--blue-shades-focus);
  background: var(--blue-shades-focus);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.current_menu__title {
  text-align: left;
  color: var(--blue-shades-dark);
}
</style>
