import helper from '@/urls/helper'

let getReferenceUrlWithParameters = helper.getReferenceUrlWithParameters
let getReferenceUrlAllowed = helper.getReferenceUrlAllowed

export default {
  getReferenceUrlWithParameters,
  REFERENCE: {
    ALLOWED: () => getReferenceUrlAllowed(
      'admin|contacts|personal-list',
      'list'
    ),
    ADMIN:{
      FILE_UPLOAD: {
        LIST:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|requests-list',
            'list',
            {},
            gets
          ),
        DETAIL:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|requests-detail',
            "retrieve",
            {id: id},
            gets
          ),
        COMPLETED:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|requests-upload-complete',
            "upload_complete",
            {id: id},
            gets
          ),
        LOAD_FILE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|requests-download',
            "download",
            {id: id},
            gets
          ),
        DEPARTMENT_LOAD:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|department-need-unloading',
            "need_unloading",
            null,
            gets
          ),
        CONTACTS_LOAD:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|general-need-unloading',
            "need_unloading",
            null,
            gets
          ),
        PERSONAL_LOAD:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|personal-need-unloading',
            "need_unloading",
            null,
            gets
          ),
      },
      PHONE: {
        LIST:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|phones|number-list',
            'list',
            {},
            gets
          ),
        DETAIL:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|phones|number-detail',
            "retrieve",
            {id: id},
            gets
          ),
        CREATE:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|phones|number-list',
            "create",
            null,
            gets
          ),
        UPDATE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|phones|number-detail',
            "update",
            {id: id},
            gets
          ),
        DELETE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|phones|number-detail',
            "destroy",
            {id: id},
            gets
          ),
        DELETE_DEP_PHONE:
          (id, phone_id,gets = null) => getReferenceUrlWithParameters(
            'admin|phones|department-detail',
            "destroy",
            {id: id,phone_id: phone_id},
            gets
          ),
        DELETE_PERSONAL_PHONE:
          (id, phone_id,gets = null) => getReferenceUrlWithParameters(
            'admin|phones|personal-detail',
            "destroy",
            {id: id,phone_id: phone_id},
            gets
          ),
      },
      DEPARTMENT: {
        LIST:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|department-list',
            'list',
            {},
            gets
          ),
        DETAIL:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|department-detail',
            "retrieve",
            {id: id},
            gets
          ),
        CREATE:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|department-list',
            "create",
            null,
            gets
          ),
        UPDATE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|department-detail',
            "update",
            {id: id},
            gets
          ),
        DELETE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|department-detail',
            "destroy",
            {id: id},
            gets
          ),
      },
      COMMON: {
        LIST:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|general-list',
            'list',
            {},
            gets
          ),
        DETAIL:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|general-detail',
            "retrieve",
            {id: id},
            gets
          ),
        CREATE:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|general-list',
            "create",
            null,
            gets
          ),
        UPDATE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|general-detail',
            "update",
            {id: id},
            gets
          ),
        DELETE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|general-detail',
            "destroy",
            {id: id},
            gets
          ),
      },
      PERSONAL: {
        LIST:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|personal-list',
            'list',
            {},
            gets
          ),
        DETAIL:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|personal-detail',
            "retrieve",
            {id: id},
            gets
          ),
        CREATE:
          (gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|personal-list',
            "create",
            null,
            gets
          ),
        UPDATE:
          (id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|personal-detail',
            "update",
            {id: id},
            gets
          ),
      },
      COMMENT: {
        LIST:
          (contact_id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|comments-list',
            'list',
            {contact_id: contact_id},
            gets
          ),
        DELETE:
          (id,contact_id,  gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|comments-detail',
            "destroy",
            {contact_id: contact_id, id: id},
            gets
          ),
        DETAIL:
          (contact_id, id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|comments-detail',
            "retrieve",
            {contact_id: contact_id, id: id},
            gets
          ),
        CREATE:
          (contact_id, gets = null) => getReferenceUrlWithParameters(
            'admin|contacts|comments-list',
            "create",
            {contact_id: contact_id},
            gets
          ),
      },
      COMMENT_PHONE: {
        LIST:
          (phone_id, gets = null) => getReferenceUrlWithParameters(
            'admin|phones|comment-list',
            'list',
            {phone_id: phone_id},
            gets
          ),
        DELETE:
          (id,phone_id,  gets = null) => getReferenceUrlWithParameters(
            'admin|phones|comment-detail',
            "destroy",
            {phone_id: phone_id, id: id},
            gets
          ),
        DETAIL:
          (phone_id, id, gets = null) => getReferenceUrlWithParameters(
            'admin|phones|comment-detail',
            "retrieve",
            {phone_id: phone_id, id: id},
            gets
          ),
        CREATE:
          (phone_id, gets = null) => getReferenceUrlWithParameters(
            'admin|phones|comment-list',
            "create",
            {phone_id: phone_id},
            gets
          ),
      },
    }
  }
}
