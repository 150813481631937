<template>
  <v-dialog persistent scrollable v-model="open" max-width="1000" min-width="400">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">
          <slot name="title">
            {{title}}
          </slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="white" @click="$emit('refresh')">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn icon color="white" @click="$emit('exit')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <template v-if="can_process.loading">
        <v-row no-gutters justify="center" align="center" style="height: 30vh">
          <semipolar-spinner
            :animation-duration="2000"
            :size="$vuetify.breakpoint.mdAndUp ? 160 : 60"
            color="#1976D2"
          />
        </v-row>
      </template>
      <template v-else-if="!can_process.loading && can_process.can">
        <v-card-text class="text-xs-left subheading my-2" v-if="!process.executed" style="max-height: calc(100vh - 200px);">
          <v-expand-transition v-for="err in non_field_errors" :key="`err_${err}`">
            <v-alert v-if="err" dismissible outlined type="error">
              {{ err }}
            </v-alert>
          </v-expand-transition>
          <slot name="form">
          </slot>
        </v-card-text>
        <v-card-text v-if="process.executed">
          <v-alert  type="warning" outlined class="text-xs-left mt-3" v-if="!process.success">
            <slot name="error">
              <slot name="error-text">
                Во время процесса произошел сбой.
              </slot>
              <br>Лог операции:<br>
              <div style="max-height: calc(100vh - 300px); overflow-y: scroll;">
                <vue-json-pretty
                  style="width: 100%"
                  class="text-xs-left"
                  :data="process.log"
                  show-line
                  show-double-quotes
                  collapsed-on-click-brackets
                  :deep="2"
                ></vue-json-pretty>
              </div>
            </slot>
          </v-alert>
          <v-alert type="success" outlined class="text-xs-left mt-3" v-else>
            <slot name="success">
              Пароль успешно сгенерирован
              <br>Логин: {{ process.username }}
              <br>Пароль: {{ process.password }}
              <br>На почту {{ process.email }} выслано информационное письмо
              <br>Пользователю при первом входе на рабочей станции или в портале необходимо будет сменить пароль.
              <br>Время действия одноразового пароля не ограничено.
            </slot>
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="!process.executed">
          <v-flex>
            <slot name="action-button">
              <v-btn @click="$emit('action')" color="warning" class="mx-1" :loading="process.loading">
                <slot name="action-button-title">{{action_button_text}}</slot>
              </v-btn>
            </slot>
            <v-btn @click="$emit('exit')" class="mx-1" :loading="process.loading">
              Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </template>
      <v-card-text v-else-if="!error">
        <v-alert class="ma-3" outlined type="warning">
          <slot name="cant">
            К сожалению операция не разрешена для данного пользователя<br/>
            {{ can_process.detail }}
          </slot>
        </v-alert>
      </v-card-text>
      <v-card-text v-else>
        <v-alert class="ma-3" type="error" outlined>
          При получении данных возникла ошибка. Повторите попытку позднее.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "UserActionFormTemplate",
  components: {
    VueJsonPretty,
    SemipolarSpinner
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    can_process: {
      type: Object,
      required: true
    },
    process: {
      type: Object,
      required: true
    },
    action_button_text: {
      type: String,
      required: false
    },
    non_field_errors: {
      type: Array,
      required: false,
      default: () => []
    },
    error: {
      default: undefined
    },
  },
  data(){
    return {
      show_errors: {},
    }
  },
}
</script>

<style scoped>

</style>
