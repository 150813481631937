<template>
  <v-container class="page-content px-5">
    <v-card>
      <mail-list/>
      <logs-list/>
    </v-card>
  </v-container>
</template>

<script>
import MailList from "@/modules/admins/signer/logs/components/MailList";
import LogsList from "@/modules/admins/signer/logs/components/LogsList";
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "list",
  components: {LogsList, MailList},
  mixins: [TitledPageMixin],
  data() {
    return {
      page_title: 'Список логов ЭЦП'
    }
  }
}
</script>

<style scoped>

</style>
