import {sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest, sendPutRequest} from "@/helper/requests";
import reference from "@/urls/reference";

const state = {
  phone: {
    currentUrl: null,
    list: [],
    loading: null,
    count: 0,
    detail: [],
  }
};

const getters= {
  getPhoneList: (state) => state.phone.list,
  getPhoneDetail: state => state.phone.detail,
  getPhoneCurrentUrl: state => state.phone.currentUrl,
  getPhoneListCount: (state) => state.phone.count,
  isPhoneListLoading: (state) => state.phone.loading,
};

const actions= {
  loadPhoneList: ({commit}, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.PHONE.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isLoading || getters.getPhoneCurrentUrl !== url) {
      commit('SET_LOADING')
      sendGetRequest(
        url,
        (data, url) => {
          commit('SET_PHONE_LIST', data['results'])
          commit('SET_PHONE_COUNT', data['count'])
          commit('SET_PHONE_CURRENT_URL', url)
          commit('SET_NOT_LOADING')
          finalizer(data, url)
        },
        (error) => {
          commit('SET_NOT_LOADING')
          catcher(error)
        }
      )
    }
  },
  loadPhoneDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.PHONE.DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_PHONE_DETAIL', data);
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createPhone: ({commit}, {url, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.PHONE.CREATE()
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updatePhone: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.PHONE.UPDATE(id)
    }
    sendPutRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deletePhone: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.PHONE.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deletePhoneDepartment: ({commit}, {url, id, phone_id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.PHONE.DELETE_DEP_PHONE(id, phone_id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deletePhonePersonal: ({commit}, {url, id, phone_id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.PHONE.DELETE_PERSONAL_PHONE(id, phone_id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations= {
  SET_PHONE_LIST: (state, list) => {
    state.phone.list = list
  },
  SET_PHONE_DETAIL: (state, value) => {
    state.phone.detail = value
  },
  SET_PHONE_CURRENT_URL: (state, value) => {
    state.phone.currentUrl = value
  },
  SET_LOADING: (state) => {
    state.phone.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.phone.loading = false
  },
  SET_PHONE_COUNT: (state, count) => {
    state.phone.count = count
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
