<template>
  <scroll-center-dialog
    v-model="opened"
    :loading="save_loading"
    @close="close"
    @apply="save"
    :title="form_title"
    :scrolled="false"
  >
    <div v-if="loading" class="px-3">
      <v-row no-gutters class="text-left mb-7 mt-4" v-if="!edit_department">
        <v-col cols="11" class="text--secondary" style="border: rgb(0, 0, 0, 50%) 1px solid; border-radius: 5px; padding: 16px 12px">
          <strong>Подразделение: </strong>
          <span v-if="edit">{{ department.department.text }}</span>
        </v-col>
        <v-col cols="1" class="mt-2 mt-sm-2 pl-sm-2">
          <v-btn
            fab
            icon
            small
            color="primary"
            @click="() => {this.edit_department = true; this.department.department = ''}"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  close
                </v-icon>
              </template>
              <span>Изменить</span>
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
      <loading-autocomplete
        v-if="edit_department"
        class="mt-4"
        placeholder="Введите название и выберете из предложенных вариантов"
        label="Подразделение"
        outlined
        required
        v-model="department.department"
        search-parameter="search"
        :url="department_selector"
        :error="hasError('department') || hasError('detail')"
        :error-messages="getError('department') || getError('detail')"
        :item-text="getText"
        :load-fios="false"
        :menu-props="{ maxWidth: 575, maxHeight: 150 }"
      >
        <template v-slot:no-data>
          <div>
            Для поиска начните набирать название, <br> и подождите 1 секунду, либо нажмите Enter
          </div>
        </template>
      </loading-autocomplete>
      <v-text-field
        label="Почта"
        v-model="department.email"
        :error="hasError('email')"
        :errorMessages="getError('email')"
        clearable
        outlined
      >
        <template v-slot:label>
          Почта<span class="red--text">*</span>
        </template>
      </v-text-field>
      <template
        v-for="(item, i) in department.phones"
      >
        <ReferencePhones
          :key="i+'phone'"
          :item="item"
          :index="i"
          :phones="department.phones"
          :special-error.sync="special_error"
        >
        </ReferencePhones>
      </template>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="addNewPhone"
          class="pr-0"
        >
          Добавить телефон
          <v-icon>add</v-icon>
        </v-btn>
      </v-row>
      <v-checkbox
        class="py-0 my-0"
        label="Скрытый"
        v-model="department.is_hidden"
        :error="hasError('is_hidden')"
        :errorMessages="getError('is_hidden')"
      ></v-checkbox>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import loadingAutocomplete from "@/modules/core/components/LoadingAutocomplete.vue";
import selectors from "@/urls/selectors";
import ReferencePhones from "@/modules/admins/reference/components/ReferencePhones.vue";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "editDepartmentDialog",
  props:{
    opened: Boolean,
    edit: Boolean,
    departmentId: Number,
  },
  mixins: [FormErrorsMixin],
  components: {ReferencePhones, loadingAutocomplete, ScrollCenterDialog, SemipolarSpinner },
  data() {
    return {
      loading: false,
      save_loading: false,
      edit_department: true,
      department: {
        id: null,
        department: null,
        email: null,
        phones: [
          {phone:'',addition:[]}
        ],
        is_hidden: false,
      },
      selectors: {
        departments: [],
      },
      special_error: [],
    }
  },
  computed: {
    form_title() {
      return !this.edit ? 'Добавить контакт' : 'Редактировать контакт'
    },
    department_selector(){
      return selectors.SELECTORS.REFERENCE.DEPARTMENTS()
    },
    ...mapGetters({
      item: 'departmentsReference/getDepartmentDetail',
    }),
  },
  methods: {
    ...mapActions({
      createDepartment: 'departmentsReference/createDepartment',
      updateDepartment: 'departmentsReference/updateDepartment',
      loadDepartmentDetail: 'departmentsReference/loadDepartmentDetail',
      loadPhone: 'selectors/loadPhone',
    }),
    loadSelectors () {
      this.loadPhone({
        url: undefined,
        finalizer: (data) => {
        },
      })
    },
    close() {
      this.$emit('close', false)
    },
    catchErrors(errors) {
      this.save_loading = false;
      if(!errors.phones){
        this.mapErrors(errors)
      } else {
        this.special_error = errors.phones
      }
    },
    save() {
      this.save_loading = true
      if (this.edit) {
        this.updateDepartment({
          id: this.departmentId,
          data: this.prepareData(),
          finalizer: () => {
            this.save_loading = false
            this.close()
          },
          catcher: this.catchErrors
        })
      }
      else {
        this.createDepartment({
          data: this.prepareData(),
          finalizer: () => {
            this.save_loading = false
            this.close()
          },
          catcher: this.catchErrors
        })
      }
    },
    prepareData: function () {
      let data = {}
      for (let el in this.department) {
        if (el !== 'department'){
          if (this.department[el] !== null) {
            data[el] = this.department[el]
          }
        } else if (this.department.department){
          if (this.department[el].value){
            data[el] = this.department[el].value
          } else {
            data[el] = this.department[el]
          }
        }
      }
      // delete data.phones
      return data
    },
    // Добавление еще одного телефона
    addNewPhone() {
      this.department.phones.push({
        phone: null,
        addition: [],
      })
    },
    loadData() {
      if (this.departmentId) {
        this.loadDepartmentDetail({
          id: this.departmentId,
          finalizer: (data) => {
            this.department = structuredClone(data)
            this.department.phones = []
            for(let phone of data.phones){
              this.department.phones.push(
                {
                  "phone": phone.phone_id,
                  "addition": phone.addition
                }
              )
            }
            this.edit_department = false
            this.loading = true
          }
        })
      }
      else {
        this.loading = true;
      }
    },
    getText(value){
      if(value !== undefined)
        return `${value.text}`
      else
        return ''
    },
  },
  created() {
    this.loadData()
    this.loadSelectors()
  }
}
</script>

<style scoped>

</style>
