<template>
  <v-container >
    <v-card class="rounded-lg" v-if="detail">
      <v-toolbar
          class="pl-2 removeBtnPaddingRight"
          color="#DADADA"
          dense
          flat
      >
        <v-btn
            @click="returnParent()" class="dark-primary" icon small
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-subtitle>
        <v-row class="text-left text-h6 py-1" no-gutters>
          <v-col class="font-weight-bold" cols="5" md="4">ФИО:</v-col>
          <v-col class="text-right text-md-left" cols="7" md="4">{{ detail.user.text }}</v-col>
        </v-row>
      </v-card-subtitle>
      <v-divider class="elevation-2"></v-divider>
      <v-card-text>
        <v-row class="text-left subtitle-1 py-md-2" no-gutters>
          <v-col class="font-weight-bold" cols="5" md="4">Документ:</v-col>
          <v-col class="text-right text-md-left" cols="7" md="4"  v-if="completed">
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon target="_blank" :href="getLink()"
                  class="pa-0 ma-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">save_alt</v-icon>
                </v-btn>
              </template>
              <span>Скачать</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="text-left subtitle-1 py-md-2" no-gutters>
          <v-col class="font-weight-bold" cols="5" md="4">Заявка сформирована:</v-col>
          <v-col class="text-right text-md-left" cols="7" md="2"> {{ displayDate(detail.created, true) }}</v-col>
        </v-row>
        <v-row class="text-left subtitle-1 py-md-2" no-gutters>
          <v-col class="font-weight-bold" cols="5" md="4">Заявка изменена:</v-col>
          <v-col class="text-right text-md-left" cols="7" md="2"> {{ displayDate(detail.changed, true) }}</v-col>
        </v-row>
      </v-card-text>
      <v-col>
        <div v-if="!completed" class="action d-flex justify-center">
          <semipolar-spinner
              id="spinner"
              :animation-duration="2000"
              :size="115"
              color="#1935ff"
          />
        </div>
        <v-row v-if="!completed" class="body-2 justify-center">
          Ожидайте, идет сбор данных
        </v-row>
      </v-col>
    </v-card>
    <v-alert outlined type="info" v-if="!detail && !completed">
     <div v-for="obj in errors" >
       {{obj}}
     </div>
    </v-alert>
  </v-container>
</template>
<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {mapActions} from "vuex";
import {displayDate} from "@/helper";
import {SemipolarSpinner} from 'epic-spinners'
import names from "@/modules/admins/routers/names";
import admin from "@/urls/reference";


export default {
  name: "List",
  mixins: [TitledPageMixin,],
  components: {
    SemipolarSpinner,
  },
  data() {
    return {
      page_title: "Документ",
      detail: null,
      completed: false,
      errors:null
    }
  },
  computed: {
    names: () => names,
  },
  methods: {
    ...mapActions({
      detailFile: 'fileReference/detailFile',
      completeFile: 'fileReference/completeFile',

    }),
    returnParent(){
      this.$router.push({name: names.FILE.LIST })
    },
    displayDate: displayDate,
    getLink(){
      return admin.REFERENCE.ADMIN.FILE_UPLOAD.LOAD_FILE(this.$route.params.id)
    },
    loadData(url = undefined) {
      let id = this.$route.params.id
      this.detailFile({
        id: id,
        data: null,
        finalizer: (data, url) => {
          this.detail = data
          if (!data.unloading && !data.has_error) {
            let timerId = setInterval(() => {
              this.completeFile({
                id: id,
                data: null,
                finalizer: (data, url) => {
                  if (this.$route.name !== names.FILE.DETAIL)
                    clearInterval(timerId)
                  if (data.completed) {
                    clearInterval(timerId)
                    this.loadData()
                    this.completed = true
                  }
                }
              })
            }, 2000);
          } else {
            this.completed = true
          }
        },
        catcher: (data)=>{
          this.errors = data
        }
      })
    },


  },
  created() {
    this.loadData()
  }

}
</script>

<style scoped>

</style>
