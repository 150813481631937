<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <List
      v-show="$route.name === names.USERS.LIST"
    />
    <router-view v-if="$route.name !== names.USERS.LIST" />
  </div>
</template>

<script>
import names from '@/modules/admins/routers/names'
import List from '@/modules/admins/users/pages/List'

export default {
  name: 'UserBreadcrumbs',
  components: { List },
  computed: {
    names: () => names,
  },
}
</script>

<style scoped>
</style>
