<template>
  <v-app class="color--background-main">
    <TopMenu />
    <LeftMenu />
    <v-main
      id="base-content"
    >
      <div
        v-show="!maintoolbar.is_page_loading"
        class="overflow-y-auto"
        style="height: calc(100vh - 78px); height: calc(var(--doc-height) - 78px);"
      >
        <router-view />
      </div>
      <div v-show="maintoolbar.is_page_loading">
        <v-row no-gutters justify="center" align="center" style="height: 90vh">
          <semipolar-spinner
            :animation-duration="2000"
            :size="$vuetify.breakpoint.mdAndUp ? 160 : 60"
            color="#1976D2"
          />
        </v-row>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex"
import LeftMenu from "./modules/menus/LeftMenu"
import TopMenu from "./modules/menus/TopMenu"
import { SemipolarSpinner } from "epic-spinners"

export default {
  components: {
    TopMenu,
    LeftMenu,
    SemipolarSpinner
  },
  data () {
    return {
      surls: {}
    }
  },
  computed: {
    ...mapState({
      leftmenu: state => state.leftmenu,
      maintoolbar: state => state.maintoolbar,
      user: state => state.user,
      urls: state => state.urls
    })
  },
  watch: {
    urls () {
      this.surls = this.urls.urls
    }
  },
  methods: {
    documentHeight() {
      const doc = document.documentElement
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`)
    }
  },
  mounted() {
    window.addEventListener("resize", this.documentHeight)
    this.documentHeight()
  },
  unmounted() {
    window.removeEventListener("resize", this.documentHeight)
  },
  created() {
    this.$store.commit("leftmenu/enableLeftMenu");
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2cc30e50;*/
  /*height: 100%;*/
}
</style>
