<template>
  <v-card class="color--background-main py-4 px-6" color="background_main" elevation="0" rounded="0">
    <v-row justify="start" v-if="mainpage.title">
      <v-col cols="auto" class="color--corporate-main ms_text-h1 mb-3">
        <span v-if="$vuetify.breakpoint.smAndUp">{{mainpage.title}}</span>
        <span v-else>{{mainpage.title}}</span>
      </v-col>
    </v-row>
    <v-row justify="start" no-gutters>
      <v-col cols="auto">
        <breadcrumbs/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import {mapState} from "vuex"

  export default {
    name: "PageHeader",
    computed: mapState({
      mainpage: state => state.mainpage
    })
  }
</script>

<style scoped>

</style>
