<template>
  <v-container fluid>
    <v-row
      dense
    >
      <v-col
        v-for="(item) in items"
        :key="item"
        cols="12"
        lg="4"
      >
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            height="300px"
            v-bind="props"
            :elevation="isHovering ? 20 : 4"
            class="mx-auto pa-6"
            @click="goToPath(item)"
          >
            <v-img
              :src="item.src"
              contain
              flat
              max-height="180"
            />
            <v-card-title
              primary-title
            />
            <div style="text-align: center">
              <div class="headline">
                {{ item.name }}
              </div>
              <span>
                {{ item.text }}
              </span>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import names from "@/modules/admins/routers/names";

export default {
  name: 'Main',
  data () {
    return {
      items: [
        {
          name: 'Список пользователей ситемы',
          text: '',
          url: '',
          src: 'https://muctr.ru/upload/university/it-resources/Group_44.png',
          route: names.USERS.LIST,
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    })
  },
  methods: {
    goToPath (path) {
      if (path.url !== '') {
        window.open(path.url)
      } else {
        this.$router.push({ name: path.route })
      }
    },
  },
}
</script>

<style scoped>

</style>
