import admin_urls from "@/urls/admin";
import {sendGetRequest, sendPostRequest} from "@/helper/requests";

const sort = function(str) {
  str.sort((a, b) => {

  })
}

const state = {
  accounts: {
    list: [],
    count: 0,
    next: '',
    previous: '',
    detail: null,
    loading: false,
    selector: [],
    connectedAccounts: [],
    selectorCount: 0,
  }
}

const getters = {
  getList: (state) => state.accounts.list,
  getDetail: (state) => state.accounts.detail,
  isLoading: (state) => state.accounts.loading,
  getCount: (state) => state.accounts.count,
  getSelector: (state) => state.accounts.selector,
  getConnectedAccounts: (state) => state.accounts.connectedAccounts,
  getNextUrl: (state) => state.accounts.next,
  getCreateAccounts: (state) => {
    let created = []
    state.accounts.connectedAccounts.forEach((item) => {
      if (!('is_origin' in item)) {
        created.push({
          user: item.value
        })
      }
    })
    return created
  },
  getDeletedAccounts: (state) => {
    let deleted = []
    state.accounts.connectedAccounts.forEach((item) => {
      if (item.is_delete) {
        deleted.push({
          user: item.id
        })
      }
    })
    return deleted
  },
  getOrigin: (state) => {
    return state.accounts.connectedAccounts.find(item => item.isOrigin)
  }
}

const actions = {
  loadAccountsList: ({commit}, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    if (!url) {
      url = admin_urls.ADMIN.USERS.ACCOUNTS.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_LIST', data['results'])
        commit('SET_COUNT', data['count'])
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  editConnectedAccounts: ({commit, getters}, {id, finalizer = () => {}, catcher = () => {}}) => {
    const url = admin_urls.ADMIN.USERS.ACCOUNTS.UPDATE(id)
    sendPostRequest(
      url,
      {
        create: getters.getCreateAccounts,
        delete: getters.getDeletedAccounts,
        origin: getters.getOrigin
      },
      (data, url) => {
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadAccountsSelector: ({commit}, {url, onScroll, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.ACCOUNTS.USER_SELECTOR()
    }
    sendGetRequest(
      url,
      (data, url) => {
        if (onScroll)
          commit('PUSH_SELECTOR', data['results'])
        else
          commit('SET_SELECTOR', data['results'])
        commit('SET_SELECTOR_COUNT', data['count'])
        commit('SET_NEXT', data['next'])
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      },
    )
  },
  backItemToSelector({commit}, item) {
    commit('BACK_ITEM', item)
  },
  addItemToConnectedAccounts({commit}, item) {
    commit('ADD_ITEM_TO_CONNECTED', [item])
    commit('REMOVE_ITEM_FROM_SELECTOR', item)
  },
  addItemsToConnectedAccounts({commit}, items) {
    commit('ADD_ITEM_TO_CONNECTED', [...items.filter((item) => !item.is_origin)])
  },
  clearConnectedAccounts({commit}) {
    commit('CLEAR_CONNECTED_ACCOUNTS')
  },
  clearSelector({commit}) {
    commit('CLEAR_SELECTOR')
  }
}

const mutations = {
  SET_LIST: (state, list) => {
    state.accounts.list = list
  },
  REMOVE_LIST: (state) => {
    state.accounts.list = []
  },
  SET_LOADING: (state) => {
    state.accounts.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.accounts.loading = false
  },
  SET_SELECTOR: (state, selector) => {
    state.accounts.selector = selector
  },
  PUSH_SELECTOR: (state, selector) => {
    state.accounts.selector.push(...selector)
  },
  SET_COUNT: (state, count) => {
    state.accounts.count = count
  },
  SET_SELECTOR_COUNT: (state, count) => {
    state.accounts.selectorCount = count
  },
  ADD_ITEM_TO_CONNECTED: (state, items) => {
    items.map((item) => {
      item.is_delete = false
    })
    state.accounts.connectedAccounts.push(...items)
    state.accounts.connectedAccounts.sort((a, b) => {
      return Number(('is_origin' in b)) - Number(('is_origin' in a))
    })
  },
  REMOVE_ITEM_FROM_SELECTOR: (state, item) => {
    let idx = state.accounts.selector.indexOf(item)
    if (idx !== -1)
      state.accounts.selector.splice(idx, 1)
  },
  BACK_ITEM: (state, item) => {
    if ('is_origin' in item && !item.is_origin) {
      let idx = state.accounts.connectedAccounts.indexOf(item)
      if (idx !== -1)
        state.accounts.connectedAccounts[idx].is_delete = !state.accounts.connectedAccounts[idx].is_delete
      state.accounts.connectedAccounts.sort((a, b) => {
        return Number(('is_origin' in b)) - Number(('is_origin' in a))
      })
    } else {
      state.accounts.selector.push(item)
      let idx = state.accounts.connectedAccounts.indexOf(item)
      if (idx !== -1)
        state.accounts.connectedAccounts.splice(idx, 1)
      state.accounts.selector.sort((a, b) => {
        if (a.fio.toLowerCase() < b.fio.toLowerCase()) {
          return -1;
        }
        if (a.fio.toLowerCase() > b.fio.toLowerCase()) {
          return 1;
        }
        return 0;
      })
    }
  },
  CLEAR_SELECTOR: (state) => {
    state.accounts.selector = []
  },
  CLEAR_CONNECTED_ACCOUNTS: (state) => {
    state.accounts.connectedAccounts = []
  },
  SET_NEXT: (state, url) => {
    state.accounts.next = url
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}


