import { render, staticRenderFns } from "./editPhoneDialog.vue?vue&type=template&id=3a5f8215&scoped=true"
import script from "./editPhoneDialog.vue?vue&type=script&lang=js"
export * from "./editPhoneDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a5f8215",
  null
  
)

export default component.exports