import booker_urls from "@/urls/booker";
import { sendGetRequest } from '@/helper/requests'

const state = {
  booker_logs: {
    list: [],
    profile: null,
    loading: null,
    count: 0,
  }
};

const getters = {
  getLogsList: (state) => state.booker_logs.list,
  getLogsListCount: (state) => state.booker_logs.count,
  isLogsListLoading: (state) => state.booker_logs.loading,
};

const actions = {
  loadList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_LOG_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_LOGS_LIST', data['results'])
        commit('SET_LOGS_LIST_COUNT', data['count'])
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  loadLogsDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_LOG_DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations = {
  SET_LOGS_LIST: (state, list) => {
    state.booker_logs.list = list
  },
  REMOVE_LOGS_LIST: (state) => {
    state.booker_logs.list = []
  },
  SET_LOADING: (state) => {
    state.booker_logs.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.booker_logs.loading = false
  },
  SET_LOGS_LIST_COUNT: (state, count) => {
    state.booker_logs.count = count
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
