const ACCOUNT_HOST = process.env.VUE_APP_API_HOST + String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const ACCOUNTS_INSTANCE = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const ORGANIZATION_INSTANCE = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '');
const ORGANIZATION_HOST = process.env.VUE_APP_API_HOST + String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '');
const EDUCATION_INSTANCE = String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
const EDUCATION_HOST = process.env.VUE_APP_API_HOST + String(process.env.VUE_APP_EDUCATION_PREFIX).replace('/', '');
const SIGNER_INSTANCE = String(process.env.VUE_APP_SIGNER_PREFIX).replace('/', '');
const SIGNER_HOST = process.env.VUE_APP_API_HOST + String(process.env.VUE_APP_SIGNER_PREFIX).replace('/', '');
const REFERENCE_INSTANCE = String(process.env.VUE_APP_REFERENCE_PREFIX).replace('/', '');
const REFERENCE_HOST = process.env.VUE_APP_API_HOST + String(process.env.VUE_APP_REFERENCE_PREFIX).replace('/', '');
export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!#%&*+\-.:<=>?@^_|~])[0-9a-zA-Z!#%&*+\-.:<=>?@^_|~]{12,255}$/;

export {
  ACCOUNT_HOST,
  ACCOUNTS_INSTANCE,
  ORGANIZATION_INSTANCE,
  ORGANIZATION_HOST,
  SIGNER_INSTANCE,
  SIGNER_HOST,
  REFERENCE_INSTANCE,
  REFERENCE_HOST,
  EDUCATION_INSTANCE,
  EDUCATION_HOST
}
