<template>
  <scroll-center-dialog
    v-model="opened"
    :title="title"
    :loading="loading"
    @close="close(false)"
    @apply="apply"
    :applyDisable="!valid"
  >
    <v-container>
      <v-form v-model="valid">
        <v-text-field
          :error="hasError('name')"
          :error-messages="getError('name')"
          label="Название пункта меню"
          outlined
          required
          :rules="[rules.required]"
          v-model="itemData.name"
        ></v-text-field>
        <v-text-field
          :error="hasError('recipient')"
          :error-messages="getError('recipient')"
          @input="deleteError('recipient')"
          label="Почта"
          outlined
          required
          :rules="[rules.required, rules.email]"
          v-model="itemData.recipient"
        ></v-text-field>
        <v-textarea
          textarea
          :error="hasError('msg')"
          :error-messages="getError('msg')"
          label="Текст письма"
          no-resize
          rows="3"
          outlined
          required
          :rules="[rules.required]"
          v-model="itemData.msg"
        ></v-textarea>
        <v-alert type="info" outlined>
          <v-card-text class="text-xs-left py-0">
            Паттерн < who/> заменяется на ФИО и должность
          </v-card-text>
          <v-card-text class="text-xs-left">
            Паттерн < file_name/> заменяется именем подписанного файла
          </v-card-text>
        </v-alert>
      </v-form>
    </v-container>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions} from "vuex";

export default {
  name: "EditMailDialog",
  components: {ScrollCenterDialog},
  mixins: [FormErrorsMixin],
  props: {
    opened: {
      required: true,
      default: false,
      type: Boolean
    },
    itemData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: 'Изменить место отправки',
      loading: false,
      valid: false,
      rules: {
        required: v => !!v || 'Поле обязательно для заполнения.',
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Не верный e-mail.'
        },
      },
    }
  },
  methods: {
    ...mapActions({
      updateMail: 'mail/updateMail'
    }),
    close(isApply) {
      this.$emit('close', isApply)
    },
    apply() {
      this.loading = true
      this.updateMail({
        id: this.itemData.id,
        data: {
          name: this.itemData.name,
          recipient: this.itemData.recipient,
          msg: this.itemData.msg
        },
        url: undefined,
        finalizer: () => {
          this.loading = false
          this.close(true)
        },
        catcher: (error) => {
          this.mapErrors(error)
          this.loading = false
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
