import university_urls from "@/urls/university";
import {sendGetRequest, sendPatchRequest, sendPostRequest} from "@/helper/requests"

const state = {
  university_orders: {
    order_list: [],
    profile: null,
    loading: null,
    count: 0
  }
};

const getters = {
  getOrderList: (state) => state.university_orders.order_list,
  getOrdersCount: (state) => state.university_orders.count,
  isOrdersListLoading: (state) => state.university_orders.loading
};

const actions = {
  loadOrderList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit("SET_LOADING")
    if (!url) {
      url = university_urls.UNIVERSITY.EVENTS_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit("SET_ORDER_LIST", data["results"])
        commit("SET_ORDERS_COUNT", data["count"])
        commit("SET_NOT_LOADING")
        finalizer(data, url)
      },
      (error) => {
        commit("SET_NOT_LOADING")
        catcher(error)
      }
    )
  },
  processingEvents: ({commit}, {url,data={}, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.EVENTS_PROCESSING(id)
    }
    sendPostRequest(
      url,data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  eventsExclude: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.EVENTS_EXCLUDE(id)
    }
    sendPostRequest(
      url,data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadOrderDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.EVENTS_DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadOrderDetailUser: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.EVENTS_USER(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations = {
  SET_ORDER_LIST: (state, order_list) => {
    state.university_orders.order_list = order_list
  },
  REMOVE_ORDER_LIST: (state) => {
    state.university_orders.order_list = []
  },
  SET_LOADING: (state) => {
    state.university_orders.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.university_orders.loading = false
  },
  SET_ORDERS_COUNT: (state, count) => {
    state.university_orders.count = count
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
