<template>
  <scroll-center-dialog
    v-model="opened"
    :loading="save_loading"
    @close="close"
    @apply="save"
    :title="form_title"
    :scrolled="false"
    width="760"
    :applyText="'Записать комментарий'"
    :noPaddingContainer="true"
  >
    <template v-if="loading" >
      <div class="px-3">
        <v-row no-gutters class="text-left mx-2 mt-2">
          <v-col cols="5" md="3" class="font-weight-bold py-1">Номер:</v-col>
          <v-col cols="7" md="9" class="text-right text-md-left py-1">{{ phone.number }}</v-col>
        </v-row>
        <v-row no-gutters class="text-left mx-2">
          <v-col cols="7" md="3" class="font-weight-bold py-1">Разрешены международные звонки:</v-col>
          <v-col cols="5" md="3" class="text-right text-md-left py-1">
            <v-icon
              :color="checkFlag(phone.international_calls_allowed).color"
            >
              {{checkFlag(phone.international_calls_allowed).icon}}
            </v-icon>
          </v-col>
          <v-col cols="7" md="3" class="font-weight-bold py-1">Разрешены междугородние звонки:</v-col>
          <v-col cols="5" md="3" class="text-right text-md-left py-1">
            <v-icon
              :color="checkFlag(phone.long_distance_calls_allowed).color"
            >
              {{checkFlag(phone.long_distance_calls_allowed).icon}}
            </v-icon>
          </v-col>
        </v-row>
        <v-textarea
          class="mt-7 mx-3"
          label="Комментарий"
          rows="4"
          no-resize
          outlined
          v-model="text"
          :error="hasError('text')"
          :errorMessages="getError('text')"
        ></v-textarea>
      </div>
      <comment-list
        :phone-id="phoneId"
        :needReload="need_reload"
        @notNeedReload="notNeedReload"
      >
      </comment-list>
    </template>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions} from "vuex";
import {SemipolarSpinner} from "epic-spinners";
import CommentList from "@/modules/admins/reference/components/commentPhones/CommentList.vue";

export default {
  name: "createCommentDialog",
  props:{
    opened: Boolean,
    phoneId: Number,
  },
  mixins: [FormErrorsMixin],
  components: { ScrollCenterDialog, SemipolarSpinner, CommentList },
  data() {
    return {
      loading: false,
      save_loading: false,
      text: null,
      phone: {},
      form_title: 'Оставить комментарий',
      need_reload: false,
    }
  },
  watch: {},
  computed: {},
  methods: {
    ...mapActions({
      createComment: 'commentPhone/createComment',
      loadPhoneDetail: 'phoneReference/loadPhoneDetail',
    }),
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
    close() {
      this.$emit('close', false)
    },
    save() {
      this.save_loading = true
      let data = {
        text: this.text
      }
      this.createComment({
        phone_id: this.phoneId,
        data: data,
        finalizer: () => {
          this.save_loading = false
          this.close()
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    loadData() {
      this.loadPhoneDetail({
        id: this.phoneId,
        finalizer: (data) => {
          this.phone = data
          this.loading = true
        }
      })
    },
    notNeedReload(){
      this.need_reload = false
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
