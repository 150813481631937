<template>
  <v-dialog persistent v-model="opened" width="500">
    <v-card class="rounded-lg">
      <v-card-title class="super-grey medium-primary">Подтвердите действие</v-card-title>
      <v-divider></v-divider>
      <v-alert type="error" v-if="hasError('detail') || hasError('error')">
        {{ getError('detail') }} {{ getError('error') }}
      </v-alert>
      <v-card-text class="mt-3">
        Вы уверены что хотите зайти под пользователем {{ user_data.lastname }} {{ user_data.firstname }}
        {{ user_data.midname }}?<br>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
          <v-btn @click="apply" color="warning" text>
            Войти под {{ user_data.username }}
          </v-btn>
          <v-btn @click="close" text>
            Отмена
          </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { goToPortalPage } from '@/helper/auth'

export default {
  name: 'LoginAsUserDialog',
  mixins: [ FormErrorsMixin ],
  props: {
    opened: Boolean,
    user_data: Object,
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions({
      loginAsUser: 'auth/ADMIN_LOGIN_AS_USER'
    }),
    close() {
      this.$emit('close', false)
    },
    apply() {
      let url = undefined
      this.loading = true
      this.loginAsUser({
        url: url,
        user: {
          username: this.user_data.username
        },
        finalizer: (data) => {
          this.loading = false
          goToPortalPage()
        },
        catcher: (error) => {
          if (error) {
            this.mapErrors(error)
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
