<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @click:clear="clearSearchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="newDepartment"
          >
            Создать
          </v-btn>
        </v-list>
      </v-menu>
      <div v-else>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="syncCathedra"
            >
              <v-icon>
                watch_later
              </v-icon>
            </v-btn>
          </template>
          <span>Синхронизация факультетов всех кафедр</span>
        </v-tooltip>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="newDepartment"
            >
              <v-icon>
                add
              </v-icon>
            </v-btn>
          </template>
          <span>Добавить кафедру</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getDepartmentList"
      :loading="isLoading"
      :search="search"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :footer-props="{ disablePagination : isLoading, disableItemsPerPage : isLoading, 'items-per-page-options':[25,50,100]}"
    >
      <template v-slot:item.manual="{ item }">
        <v-icon color="teal" v-if="item.manual">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editDepartment(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openDeleteDialog(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          <span>Удалить контакт</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="copy"
      color="#757575"
    >
      Текст скопирован в буфер обмена
    </v-snackbar>
    <ApplyDialog
      v-if="dialog_delete"
      :opened="dialog_delete"
      :delete-dialog="true"
      :get-text="() => `Вы уверены, что хотите удалить?`"
      @close="closeDeleteDialog"
      @apply="applyDelete"
    ></ApplyDialog>
    <CathedraDialog
      v-if="dialog_add_edit"
      :opened="dialog_add_edit"
      :edit_data="docs_data"
      @close="closeEditDialog"
    />
    <syncDialog
      v-if="sync_edit"
      :opened="sync_edit"
      @close="closeEditDialog"
    />
  </v-container>
</template>


<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/admins/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin"
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import CathedraDialog from "@/modules/admins/cathedra/components/cathedraDialog.vue";
import syncDialog from "@/modules/admins/cathedra/components/syncDialog.vue";


export default {
  name: "List",
  components: {
    CathedraDialog,
    ApplyDialog,
    syncDialog
  },
  mixins: [
    PaginatedDataMapperMixin,
    TitledPageMixin,
    FormErrorsMixin
  ],
  watch:{
  },
  data: function () {
    return {
      page_title: "Список кафедр",
      copy: false,
      docs_data: undefined,
      dialog_delete: false,
      dialog_add_edit: false,
      sync_edit: false,
      search: "",
      headers: [
        {
          text: "Код",
          sortable: false,
          value: "code",
          align: "start",
          width: "5%"
        },
        {
          text: "Кафедра",
          sortable: false,
          value: "name",
          align: "left",
          width: "40%"
        },
        {
          text: "Подразделение",
          sortable: false,
          value: "faculty.name",
          align: "left",
          width: "40%"
        },
        {
          text: "Ручное управление",
          sortable: false,
          value: "manual",
          align: "left",
          width: "5%"
        },
        {
          text: "Действия",
          sortable: false,
          value: "actions",
          align: "end",
          width: "10%"
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getDepartmentList: "cathedra/getCathedraList",
      getDepartmentCount: "cathedra/getCathedraListCount",
      isLoading: "cathedra/isCathedraListLoading"
    }),
    names: () => names
  },
  methods: {
    ...mapActions({
      loadDepartmentList: "cathedra/loadCathedraList",
      deleteCathedra: "cathedra/deleteCathedra",
      loadCathedraDetail: "cathedra/loadCathedraDetail"
    }),
    loadData(url = undefined) {
      this.loadDepartmentList({
        url: url,
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    reloadData() {
      this.clearSearchData()
      this.loadData()
    },
    syncCathedra() {
      this.sync_edit = true
    },
    newDepartment() {
      this.docs_data =  undefined
      this.dialog_add_edit = true
    },
    editDepartment(item) {
      this.docs_data = item
      this.dialog_add_edit = true
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
      this.sync_edit = false
    },
    openDeleteDialog(item) {
      this.dialog_delete = true
      this.docs_data = item
    },
    applyDelete(ev) {
      if (ev) {
        this.deleteCathedra({
          id: this.docs_data.id,
          finalizer: () => {
            this.closeDeleteDialog()
          },
          catcher: (val) => {
            this.mapErrors(val)
          }
        })
      }
    },
    closeDeleteDialog() {
      this.loadData()
      this.dialog_delete = false
      this.docs_data =  undefined
    }
  },
  created() {
    this.loadData()
  }
}
</script>
<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0px;
}
</style>
