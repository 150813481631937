import university_urls from "@/urls/university";
import {sendGetRequest, sendPatchRequest, sendPostRequest} from "@/helper/requests"

const state = {
  university_users: {
    user_list: [],
    profile: null,
    loading: null,
    count: 0
  }
};

const getters = {
  getUserList: (state) => state.university_users.user_list,
  getUsersCount: (state) => state.university_users.count,
  isUsersListLoading: (state) => state.university_users.loading
};

const actions = {
  loadUserList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit("SET_LOADING")
    if (!url) {
      url = university_urls.UNIVERSITY.USER_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit("SET_USER_LIST", data["results"])
        commit("SET_USERS_COUNT", data["count"])
        commit("SET_NOT_LOADING")
        finalizer(data, url)
      },
      (error) => {
        commit("SET_NOT_LOADING")
        catcher(error)
      }
    )
  },
  loadUserDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.USER_DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadUserRelated: ({commit}, {id,gets, finalizer = () => {}, catcher = () => {}}) => {
    let url = university_urls.UNIVERSITY.USER_RELATED(id,gets)
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  sendUserMail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.USER_MAIL(id)
    }
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createUserMail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.CREATE_MAIL(id)
    }
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  changeUserAlias: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.USER_ALIAS(id)
    }
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  changeUserIdentify: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.USER_IDENTIFY(id)
    }
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  changeUserProcessing: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.USER_PROCESSING(id)
    }
    sendPostRequest(
      url,data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  changeUserActualize: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.USER_ACTUALIZE(id)
    }
    sendPostRequest(
      url,data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateRelated: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.UPDATE_RELATED(id)
    }
    sendPostRequest(
      url,data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  allowedFieldsRelated: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.ALLOWED_FIELDS_UPDATE_RELATED(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  changeUserNormalize: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = university_urls.UNIVERSITY.USER_NORMALIZE(id)
    }
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  changeUserActivate: ({commit}, {activate = true, id, finalizer = () => {}, catcher = () => {}}) => {
    let url = university_urls.UNIVERSITY.USER_DEACTIVATE(id)
    if(activate)
      url = university_urls.UNIVERSITY.USER_ACTIVATE(id)
    sendPostRequest(
      url,{},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations = {
  SET_USER_LIST: (state, user_list) => {
    state.university_users.user_list = user_list
  },
  REMOVE_USER_LIST: (state) => {
    state.university_users.user_list = []
  },
  SET_LOADING: (state) => {
    state.university_users.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.university_users.loading = false
  },
  SET_USERS_COUNT: (state, count) => {
    state.university_users.count = count
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
