<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @change="searchData"
        @click:clear="searchData"
        @click:append="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isUsersListLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[50]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="guid"
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <div class="pt-3">
              <v-tabs
                v-model="tab"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab  v-for="obj in selector_system"  :key="obj.value+'_k'" :href="'#tab-' + obj.value">
                  {{obj.text}}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="obj in selector_system"
                  :key="obj.value"
                  :value="'tab-' + obj.value"
                >
                  <v-card flat v-if="obj.value !== 'user'">
                    <v-row class="text-left py-2" no-gutters>
                        <v-btn color="primary" outlined block @click="openActualizeDialog(item)">
                         Актуализировать
                        </v-btn>
                    </v-row>

                    <TabPage :system="obj.value"
                             :item="item"
                    ></TabPage>
                  </v-card>
                  <v-card  v-else>
                    <v-row class="text-left py-2" no-gutters>
                      <v-btn color="primary" outlined block @click="openUpdateDialog(item)">
                        Обновить поля
                      </v-btn>
                    </v-row>
                    <vue-json-pretty
                      style="width: 100%"
                      class="text-xs-left"
                      :data="item"
                      show-line
                      show-double-quotes
                      collapsed-on-click-brackets
                      :deep="1"
                    />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-container>
        </td>
      </template>
      <template v-slot:item.is_delete="{ item }">
        <v-icon color="teal" v-if="item.is_delete">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.active_educations="{ item }">
        <v-icon color="teal" v-if="item.active_educations">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.is_employee="{ item }">
        <v-icon color="teal" v-if="item.account?.is_employee">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.account="props">
        <td class="body-2 justify-center text-lg-left">
          <span>{{ props.item.account?.username }} </span>
          <span v-if="props.item.account?.student_identity !== props.item.account?.username ">({{ props.item.account?.student_identity }})</span>
        </td>
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openProcessingDialog(props.item)">
              <v-icon>build</v-icon>
            </v-btn>
          </template>
          Провести первичную нормализацию
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openSendDialog(props)">
              <v-icon>lock_reset</v-icon>
            </v-btn>
          </template>
          Сброс пароля и отправка письма
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openChangeActivate(props,true)">
              <v-icon>check_circle_outline</v-icon>
            </v-btn>
          </template>
          Активировать на AD и Portal
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openChangeActivate(props,false)">
              <v-icon>highlight_off</v-icon>
            </v-btn>
          </template>
          Деактивировать на AD и Portal
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openChangeAlias(props)">
              <v-icon>alternate_email</v-icon>
            </v-btn>
          </template>
          Создать почтовый алиас
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openCreateMailDialog(props)">
              <v-icon>email</v-icon>
            </v-btn>
          </template>
          Создать почту
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openChangeIdentify(props)">
              <v-icon>perm_identity</v-icon>
            </v-btn>
          </template>
          Переименовать в AD
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openNormalizeDialog(props)">
              <v-icon>verified</v-icon>
            </v-btn>
          </template>
          Нормализировать положения на AD
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <apply-dialog
      v-if="opened_sendmail.open"
      :opened="opened_sendmail.open"
      :delete-dialog="false"
      :success="opened_sendmail.data"
      :loading="opened_sendmail.loading"
      :get-text="() => `Вы уверены, что хотите аннулировать действующий пароль и отправить письмо с временным?`"
      @close="closeAllDialog"
      @apply="applySendMail"
    />
    <apply-dialog
      v-if="change_activate.open"
      :opened="change_activate.open"
      :delete-dialog="false"
      :success="change_activate.data"
      :loading="change_activate.loading"
      :get-text= getActivateText
      @close="closeAllDialog"
      @apply="applyChangeActivate"
    />
    <apply-dialog
      v-if="create_student_mail.open"
      :opened="create_student_mail.open"
      :delete-dialog="false"
      :success="create_student_mail.data"
      :loading="create_student_mail.loading"
      :get-text="() => `Вы уверены, что хотите создать почту?`"
      @close="closeAllDialog"
      @apply="applyCreateMail"
    />
    <apply-dialog
      v-if="add_alias.open"
      :opened="add_alias.open"
      :delete-dialog="false"
      :success="add_alias.data"
      :loading="add_alias.loading"
      :get-text="() =>  `Вы уверены, что хотите создать алиас?`"
      @close="closeAllDialog"
      @apply="applyChangeAliase"
    />
    <apply-dialog
      v-if="add_student_identify.open"
      :opened="add_student_identify.open"
      :delete-dialog="false"
      :success="add_student_identify.data"
      :loading="add_student_identify.loading"
      :get-text="() =>  `Вы уверены, что хотите переименовать в AD?`"
      @close="closeAllDialog"
      @apply="applyChangeIdentify"
    />
    <apply-dialog
      v-if="change_normalize.open"
      :opened="change_normalize.open"
      :delete-dialog="false"
      :success="change_normalize.data"
      :loading="change_normalize.loading"
      :get-text="() =>  `Вы уверены, что хотите нормализовать аккаунт?`"
      @close="closeAllDialog"
      @apply="applyChangeNormalize"
    />
    <actualize-dialog
      v-if="change_actualize.open"
      :opened="change_actualize.open"
      :system="String(tab).replace('tab-','')"
      :edit_data="edit_data"
      @close="closeAllDialog"
    ></actualize-dialog>
    <update-related
      v-if="update_related.open"
      :opened="update_related.open"
      :system="String(tab).replace('tab-','')"
      :edit_data="edit_data"
      @close="closeAllDialog"
    ></update-related>
    <primary-processing-dialog
      v-if="opened_processing.open"
      :opened="opened_processing.open"
      :edit_data="edit_data"
      @close="closeAllDialog"
    ></primary-processing-dialog>
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import { mapActions, mapGetters } from "vuex"
import names from "@/modules/university_connector/routers/names"
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import primaryProcessingDialog from "@/modules/university_connector/users/components/primaryProcessingDialog.vue";
import VueJsonPretty from "vue-json-pretty";
import {SemipolarSpinner} from "epic-spinners";
import TabPage from "@/modules/university_connector/users/components/TabPage.vue";
import actualizeDialog from "@/modules/university_connector/users/components/actualizeDialog.vue";
import updateRelated from "@/modules/university_connector/users/components/updateFields.vue";

export default {
  name: "List",
  components: {
    ApplyDialog,
    primaryProcessingDialog,
    actualizeDialog,
    updateRelated,
    VueJsonPretty,
    SemipolarSpinner,
    TabPage
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      tab: null,
      page_title: "Пользователи",
      edit_data: null,
      system: null,
      system_load: null,
      selector_system:[
        {text:'Пользователь', value:'user'},
        {text:'Accounts', value:'accounts'},
        {text:'Eios', value:'education'},
        {text:'AD', value:'adc'},
        {text:'Perco', value:'perco'},
        {text:'Booker', value:'booker'},
      ],
      expanded: [],
      search: {
        parameter: "search"
      },
      errors: {},
      pagination: {
        size_name: "size",
        size: 50
      },
      opened_sendmail: {
        open: false,
        loading: false,
        data: ''
      },
      change_activate: {
        open: false,
        loading: false,
        data: ''
      },
      change_normalize: {
        open: false,
        loading: false,
        data: ''
      },
      change_actualize: {
        open: false,
      },
      update_related: {
        open: false,
      },
      opened_processing: {
        open: false,
        loading: false,
        data: ''
      },
      add_alias: {
        open: false,
        loading: false,
        data: ''
      },
      add_student_identify: {
        open: false,
        loading: false,
        data: ''
      },
      create_student_mail: {
        open: false,
        loading: false,
        data: ''
      },
      headers: [
        {
          text: "Пользователь",
          align: "left",
          sortable: false,
          value: "account",
        },
        {
          text: "Фамилия",
          align: "left",
          sortable: false,
          value: "lastname",
        },
        {
          text: "Имя",
          align: "left",
          sortable: false,
          value: "firstname",
        },
        {
          text: "Отчество",
          align: "left",
          sortable: false,
          value: "midname",
        },
        {
          text: "Дата рождения",
          align: "left",
          sortable: false,
          value: "birth",
        },
        {
          text: "Почта",
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: "Образование",
          align: "left",
          sortable: false,
          value: "active_educations",
        },
        {
          text: "Удалено ф.л.",
          align: "left",
          sortable: false,
          value: "is_delete",
        },
        {
          text: "Работник",
          align: "left",
          sortable: false,
          value: "is_employee",
        },
        {
          text: "Действия",
          align: "right",
          sortable: false,
          value: "actions",
        },
      ]
    }
  },
  watch: {
    expanded(){
      this.tab = 'users'
    }
  },
  computed: {
    ...mapGetters({
      getUsersList: "faces/getUserList",
      getUsersCount: "faces/getUsersCount",
      isUsersListLoading: "faces/isUsersListLoading"
    }),
    names: () => names
  },
  methods: {
    ...mapActions({
      loadUserList: "faces/loadUserList",
      loadUserDetail: "faces/loadUserDetail",
      sendUserMail: "faces/sendUserMail",
      createUserMail: "faces/createUserMail",
      changeUserActivate: "faces/changeUserActivate",
      changeUserAlias: "faces/changeUserAlias",
      changeUserIdentify: "faces/changeUserIdentify",
      changeUserNormalize: "faces/changeUserNormalize",
      loadUserRelated: "faces/loadUserRelated",
    }),
    openProcessingDialog(item) {
      this.opened_processing.open = true
      this.edit_data = item
    },
    getActivateText(){
      let activate = 'деактивировать'
      if (this.edit_data.activate)
        activate = 'активировать'
      return  'Вы уверены, что хотите ' + activate + ' аккаунт?'
    },
    openActualizeDialog(item) {
      this.change_actualize.open = true
      this.edit_data = item
    },
    openUpdateDialog(item) {
      this.update_related.open = true
      this.edit_data = item
    },
    openSendDialog(props) {
      this.opened_sendmail.open = true
      this.opened_sendmail.loading = false
      this.opened_sendmail.data = ''
      this.edit_data = props.item
    },
    openCreateMailDialog(props) {
      this.create_student_mail.open = true
      this.create_student_mail.loading = false
      this.create_student_mail.data = ''
      this.edit_data = props.item
    },
    openNormalizeDialog(props) {
      this.change_normalize.open = true
      this.change_normalize.loading = false
      this.change_normalize.data = ''
      this.edit_data = props.item
    },
    openChangeActivate(props,activate) {
      this.edit_data = props.item
      this.edit_data.activate = activate
      this.change_activate.open = true
      this.change_activate.loading = false
      this.change_activate.data = ''
    },
    openChangeAlias(props) {
      this.add_alias.open = true
      this.add_alias.loading = false
      this.add_alias.data = ''
      this.edit_data = props.item
    },
    openChangeIdentify(props) {
      this.add_student_identify.open = true
      this.add_student_identify.loading = false
      this.add_student_identify.data = ''
      this.edit_data = props.item
    },
    applyCreateMail(ev) {
      if (ev) {
        this.create_student_mail.loading = true
        this.createUserMail({
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.create_student_mail.data = JSON.stringify(json)
            this.create_student_mail.loading = false
          },
          catcher: (error) => {
            this.create_student_mail.loading = false
            this.create_student_mail.data = JSON.stringify(error)
          }
        })
      }
    },
    applyChangeNormalize(ev) {
      if (ev) {
        this.change_normalize.loading = true
        this.changeUserNormalize({
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.change_normalize.data = JSON.stringify(json)
            this.change_normalize.loading = false
          },
          catcher: (error) => {
            this.change_normalize.loading = false
            this.change_normalize.data = JSON.stringify(error)
          }
        })
      }
    },
    applyChangeIdentify(ev) {
      if (ev) {
        this.add_student_identify.loading = true
        this.changeUserIdentify({
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.add_student_identify.data = JSON.stringify(json)
            this.add_student_identify.loading = false
          },
          catcher: (error) => {
            this.add_student_identify.loading = false
            this.add_student_identify.data = JSON.stringify(error)
          }
        })
      }
    },
    applyChangeActivate(ev) {
      if (ev) {
        this.change_activate.loading = true
        this.changeUserActivate({
          activate: this.edit_data.activate,
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.change_activate.data = JSON.stringify(json)
            this.change_activate.loading = false
          },
          catcher: (error) => {
            this.change_activate.loading = false
            this.change_activate.data = JSON.stringify(error)
          }
        })
      }
    },
    applyChangeAliase(ev) {
      if (ev) {
        this.add_alias.loading = true
        this.changeUserAlias({
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.add_alias.data = JSON.stringify(json)
            this.add_alias.loading = false
          },
          catcher: (error) => {
            this.add_alias.loading = false
            this.add_alias.data = JSON.stringify(error)
          }
        })
      }
    },
    applySendMail(ev) {
      if (ev) {
        let url = undefined
        this.opened_sendmail.loading = true
        this.sendUserMail({
          url: url,
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.opened_sendmail.data = JSON.stringify(json)
            this.opened_sendmail.loading = false
          },
          catcher: (error) => {
            this.opened_sendmail.loading = false
            this.opened_sendmail.data = JSON.stringify(error)
          }
        })
      }
    },
    closeAllDialog(){
      this.opened_processing.open = false
      this.change_actualize.open = false
      this.change_actualize.data = ''
      this.update_related.open = false
      this.update_related.data = ''
      this.create_student_mail.open = false
      this.create_student_mail.data = ''
      this.change_normalize.open = false
      this.change_normalize.data = ''
      this.add_alias.open = false
      this.add_alias.data = ''
      this.add_student_identify.open = false
      this.add_student_identify.data = ''
      this.opened_sendmail.open = false
      this.opened_sendmail.data = ''
      this.change_activate.open = false
      this.change_activate.data = ''
      this.edit_data = undefined
      this.loadData(this.getCurrentLoadUrl())
    },

    loadData(url = undefined) {
      this.loadUserList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData(this.getCurrentLoadUrl())
    },
  },
  created () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
