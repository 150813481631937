<template>
  <div class="page-content" style="margin: 0; width: 100%">
    <PageHeader />
    <Main
      v-if="$route.name === names.MAIN"
      :name_list="left_menu_list"
    />
    <router-view v-else />
  </div>
</template>

<script>
import names from '@/modules/booker/routers/names'
import PageHeader from "@/modules/menus/PageHeader";
import Main from '@/modules/booker/Main'
import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
import { mapGetters } from 'vuex'

export default {
  name: "Breadcrumbs",
  components: {
    Main,
    PageHeader
  },
  data() {
    return {
      left_menu_list: [
        {title: 'Перечень работников', icon: 'person', included: true, router: {name:names.USERS.LIST, params:{}}},
        {title: 'Список подразделений', icon: 'dynamic_feed', included: false, router: {name: names.DEPARTMENT.LIST, params: {}}},
        {title: 'Логи операций конектора', icon: 'text_snippet', included: true, router: {name: names.LOGS.LIST, params: {}}},
        {title: 'Должности', icon: 'wysiwyg',
          subs: [
            { title: 'Принятия', icon: 'assignment_turned_in', included: true, router: {name: names.ACCEPTANCES.LIST, params: {}}},
            { title: 'Переводы', icon: 'move_down', included: true, router: {name: names.TRANSFERS.LIST, params: {}}},
            { title: 'Увольнения', icon: 'disabled_by_default', included: true, router: {name: names.DISMISSAL.LIST, params: {}}},
          ]
        },
      ]
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    })
  },
  methods: {
    loadLeftMenu() {
      this.$store.commit('mainpage/changeTitle', 'Работники');
      if (this.isAuthenticated) {
        this.$store.commit('leftmenu/replaceInstances', getInstMenu());
        this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.MAIN));
        this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
        this.$store.commit('leftmenu/show')
      } else {
        this.$store.commit('leftmenu/dissable');
      }
    },
    getLeftMenu() {
      let menu = []
      for (let el in this.left_menu_list){
        menu.push(this.left_menu_list[el])
      }
      return menu
    }
  },
  created() {
    this.loadLeftMenu()
  },
}
</script>

<style scoped>

</style>
