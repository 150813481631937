<template>
  <div class="page-content" style="margin: 0; width: 100%">
    <PageHeader />
    <Main
      v-if="$route.name === names.MAIN"
    />
    <router-view v-else />
  </div>
</template>

<script>
import names from '@/modules/admins/routers/names'
import PageHeader from "@/modules/menus/PageHeader";
import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
import {mapActions, mapGetters} from 'vuex'
import Main from "@/modules/admins/Main";

export default {
  name: 'Breadcrumbs',
  components: {
    Main,
    PageHeader
  },
  data() {
    return {
      left_menu_list: [
        {
          title: 'Пользователи',
          icon: 'people_alt',
          subs: [
            {title: 'Список пользователей системы', icon: 'manage_accounts', included: true, router: {name:names.USERS.LIST, params:{}}},
            {title: 'Список сеансов пользователей', icon: 'preview', included: false, router: {name:names.USERS.SESSION, params:{}}},
            {title: 'Сеансы администраторов', icon: 'admin_panel_settings', included: false, router: {name:names.USERS.SESSION_ADMIN, params:{}}},
            {title: 'Основные аккаунты', icon: 'switch_account', included: false, router: {name:names.ACCOUNTS.LIST, params:{}}},
          ]
        },
        {title: 'Дерево подразделений', icon: 'grading', included: true, router: {name:names.TREE.LIST, params:{}}},
        {title: 'ЭЦП', icon: 'verified',
          subs: [
            { title: 'Файлы', icon: 'insert_drive_file', included: true, router: {name: names.SIGNER_FILES.LIST, params: {}}},
            { title: 'Получатели и лог', icon: 'description', included: true, router: {name: names.SIGNER_LOGS.LIST, params: {}}},
          ]
        },
        {
          title: 'Справочники',
          icon: 'collections_bookmark',
          subs: [
            { title: 'Телефонные коды стран', icon: 'travel_explore', included: true, router: { name: names.COUNTRIES.LIST, params: {}}},
            { title: 'Языки', icon: 'language', included: true, router: { name: names.LANGUAGES.LIST, params: {}}},
          ]
        },
        {
          title: 'Почтовый справочник',
          icon: 'mark_as_unread',
          subs: [
            { title: 'Контакты подразделения', icon: 'account_balance', included: true, router: { name: names.REFERENCE.DEPARTMENT.LIST, params: {}}},
            { title: 'Общие контакты', icon: 'work', included: true, router: { name: names.REFERENCE.COMMON.LIST, params: {}}},
            { title: 'Персональные контакты', icon: 'account_box', included: true, router: { name: names.REFERENCE.PERSONAL.LIST, params: {}}},
            { title: 'Телефонные номера', icon: 'phone', included: true, router: { name: names.REFERENCE.PHONE.LIST, params: {}}},
            { title: 'Выгрузки', icon: 'move_to_inbox', included: true, router: { name: names.FILE.LIST, params: {}}},
          ]
        },
        {
          title: 'Работа с организацией',
          icon: 'maps_home_work',
          subs: [
            { title: 'Факультеты', icon: 'assured_workload', included: true, router: { name: names.FACULTY.LIST, params: {}}},
            { title: 'Кафедры', icon: 'account_balance', included: true, router: { name: names.CATHEDRA.LIST, params: {}}},
            { title: 'Круги организаций', icon: 'public', included: true, router: { name: names.GEO.LIST, params: {}}},
          ]
        },
      ]
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',

    })
  },
  created() {
    this.loadLeftMenu()
    if (!this.accountAccounts) {
      this.loadChoices()
    }
  },
  methods: {
    ...mapActions({
      loadAccountAccounts: 'selectors/loadAccountAccounts'
    }),
    loadChoices () {
      this.loadAccountAccounts({
        url: undefined,
        finalizer: () => {},
      })
    },
    loadLeftMenu() {
      this.$store.commit('mainpage/changeTitle', 'Администрирование');
      if (this.isAuthenticated) {
        this.$store.commit('leftmenu/replaceInstances', getInstMenu());
        this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.MAIN));
        this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
        this.$store.commit('leftmenu/show')
      } else {
        this.$store.commit('leftmenu/dissable');
      }
    },
    getLeftMenu() {
      let menu = []
      for (let el in this.left_menu_list){
        menu.push(this.left_menu_list[el])
      }
      return menu
    }
  },
}
</script>

<style scoped>

</style>
