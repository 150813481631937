<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <List
      v-if="$route.name === names.LOGS.LIST"
    />
    <router-view v-else />
  </div>
</template>

<script>
import names from "@/modules/booker/routers/names";
import List from "@/modules/booker/logs/pages/List";

export default {
  name: "LogsBreadcrumbs",
  components: { List },
  computed: {
    names: () => names,
  }
}
</script>

<style scoped>

</style>
