<template>
  <scroll-center-dialog
    :value="value"
    title="Обновить среднее наименование"
    @close="$emit('close')"
    @apply="$emit('close', text)"
    :apply-disable="!text"
    apply-color="warning"
  >
    <v-card flat class="pt-4">
      <v-alert
        outlined
        type="warning"
      >
        Внимание! Обновление среднего наименования вызовет изменение данных в AD!
      </v-alert>
      <v-text-field
        v-model="text"
        outlined
        label="Наименование"
      />
    </v-card>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";

export default {
  name: "UpdateMidenameDialog",
  props: {
    value: Boolean
  },
  components: {ScrollCenterDialog},
  data() {
    return {
      text: ""
    }
  }
}
</script>

<style scoped>

</style>
