<template>
  <v-row no-gutters>
    <v-col>
      <v-card
        class="pa-4 mb-2 rounded-lg"
        elevation="0"
        color="grey lighten-3"
      >
        <v-card-title class="text-left subtitle-1 px-0 pt-0 pb-2">
          Добавление нового телефона:
        </v-card-title>
        <v-row no-gutters class="pa-0">
          <v-col cols="10" md="11" class="pr-md-1">
            <v-text-field
              class="pa-0"
              v-mask="['+7(###)###-##-##','###']"
              v-model="item.phone"
              outlined
              :error="hasSpecialError('phone')"
              :error-messages="getSpecialError('phone')"
            >
              <template v-slot:label>
                Телефон<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-combobox
              v-model="item.addition"
              class="pa-0"
              chips
              clearable
              outlined
              label="Добавочные"
              :error="hasSpecialError('addition','0')"
              :error-messages="getSpecialError('addition','0')"
              multiple
              hint="Нажмите Enter, чтобы сохранить добавочный номер"
            >
              <template v-slot:selection="{ attrs, item}">
                <v-chip
                  v-bind="attrs"
                  closable
                  @click:close="removeAddition(item)"
                >
                  <strong>{{ item }}</strong>&nbsp;
                </v-chip>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn
              icon
              @click="removePhone(index)"
              class="pr-0"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "Phones",
  props: {
    item: Object,
    index: Number,
    phones: Array,
    specialError: Array,
  },
  components: {},
  mixins: [],
  data() {
    return {

    }
  },
  watch: {},
  computed: {
  },
  methods: {
    // Удаление одного из телефонов
    removePhone(index) {
      this.phones.splice(index, 1)
    },
    removeAddition(item) {
      this.item.addition.splice(this.item.addition.indexOf(item), 1)
    },
    // Функция для проверки наличия специфичных ошибок
    hasSpecialError(field, include_field) {
      let result = this.specialError[this.index]
      if (include_field) {
        if (result)
          if(result[field]) {
            let result_include = result[field]
            if (result_include){
              let key = Object.keys(result_include)[0];
              if (result_include[key])
                return true
            }
          }
      } else {
        if (result)
          if(result[field])
            return true
        return false
      }
    },
    // Функция для отображения специфичных ошибок
    getSpecialError(field, include_field) {
      let result = this.specialError[this.index]
      if (include_field) {
        if(result) {
          let result_include = result[field]
          if (result_include){
            let key = Object.keys(result_include)[0];
            return result_include[key]
          }

        }
      } else {
        if (result)
          return result[field]
        return ''
      }
    }
  },

}
</script>

<style scoped>

</style>
