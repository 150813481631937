import booker_urls from "@/urls/booker";
import {sendGetRequest, sendPatchRequest, sendPostRequest} from "@/helper/requests"

const state = {
  booker_users: {
    user_list: [],
    profile: null,
    loading: null,
    count: 0
  }
};

const getters = {
  getUserList: (state) => state.booker_users.user_list,
  getUsersCount: (state) => state.booker_users.count,
  isUsersListLoading: (state) => state.booker_users.loading
};

const actions = {
  loadUserList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit("SET_LOADING")
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_WORKER_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit("SET_USER_LIST", data["results"])
        commit("SET_USERS_COUNT", data["count"])
        commit("SET_NOT_LOADING")
        finalizer(data, url)
      },
      (error) => {
        commit("SET_NOT_LOADING")
        catcher(error)
      }
    )
  },
  loadUserDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_WORKER_DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  checkCanRegister: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_WORKER_REGISTER(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  doRegisterUser: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_WORKER_REGISTER(id)
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  checkCanRegenerate: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_WORKER_REGENERATE(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  doRegeneratePassword: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_WORKER_REGENERATE(id)
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  normalizeWorker: ({commit}, {id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_WORKER_NORMALIZE_AD(id)
    sendPostRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateAlias: ({commit}, {data, id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_WORKER_UPDATE_ALIAS(id)
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  unlockUser: ({commit}, {data, id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_WORKER_UNLOCK(id)
    sendPatchRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  checkActionDialog: ({commit}, {url, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
    } else {
      sendGetRequest(
        url,
        (data, url) => {
          finalizer(data, url)
        },
        (error, url) => {
          catcher(error, url)
        }
      )
    }
  },
  workerCreateEmail: ({commit}, {id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_WORKER_CREATE_EMAIL(id)
    sendPatchRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  workerNormalizeLogin: ({commit}, {id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_WORKER_NORMALIZE_LOGIN(id)
    sendPatchRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  workerGetNormalizeLogin: ({commit}, {id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_WORKER_NORMALIZE_LOGIN(id)
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  doActionDialog: ({commit}, {url, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
    } else {
      sendPostRequest(
        url,
        data,
        (data, url) => {
          finalizer(data, url)
        },
        (error, url) => {
          catcher(error, url)
        }
      )
    }
  }
};

const mutations = {
  SET_USER_LIST: (state, user_list) => {
    state.booker_users.user_list = user_list
  },
  REMOVE_USER_LIST: (state) => {
    state.booker_users.user_list = []
  },
  SET_LOADING: (state) => {
    state.booker_users.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.booker_users.loading = false
  },
  SET_USERS_COUNT: (state, count) => {
    state.booker_users.count = count
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
