<template>
  <v-container class="fill-height">
    <v-col>
      <v-row
        justify="center"
        align="center"
      >
        <v-card max-width="600px" class="rounded-lg">
          <v-toolbar flat tile rounded="0" width="100%" max-height="60px" color="super-grey" class="px-2 rounded-t-lg">
              <v-spacer/>
              <span class="text-h5">
                Нет доступа
              </span>
            <v-spacer/>
          </v-toolbar>
          <v-card-text>
            <span class="text-h6">
              К сожалению, у вас нет доступа к данному сервису. Вас перенаправит на единый портал <a>portal.muctr.ru</a> через:
            </span>
          </v-card-text>
          <v-card-text>
            <span class="text-h4">
              {{ currentTime }}
            </span>
          </v-card-text>
        </v-card>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { goToPortalPage } from '@/helper/auth'

export default {
  name: 'noAccessPage',
  data () {
    return {
      currentTime: 5,
      timer: null,
    }
  },
  mounted() {
    this.$store.commit('leftmenu/dissable')
    this.startTimer()
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
      goToPortalPage()
    },
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer()
      }
    }
  },
}
</script>

<style scoped>

</style>
