import {sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest} from "@/helper/requests";
import signer from "@/urls/signer";

const state = {
  mailList: {
    list: [],
    count: 0,
    loading: false,
  },
};

const getters= {
  getMailList: (state) => state.mailList.list,
  getMailListCount: (state) => state.mailList.count,
  getMailListLoading: (state) => state.mailList.loading,
};

const actions= {
  loadMailList: ({commit}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = signer.SIGNER.ADMIN.RECIPIENTS.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    commit('SET_LOADING')
    sendGetRequest(
      url,
      (data, url) => {
        let list = new Array(...data)
        commit('SET_NOT_LOADING')
        commit('SET_LIST', list)
        commit('SET_COUNT', list.length)
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      },
    )
  },
  deleteMailList: ({commit}, {id, url, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = signer.SIGNER.ADMIN.RECIPIENTS.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createMail: ({commit}, {url, data, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = signer.SIGNER.ADMIN.RECIPIENTS.CREATE()
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateMail: ({commit}, {id, data, url, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = signer.SIGNER.ADMIN.RECIPIENTS.UPDATE(id)
    }
    sendPatchRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations= {
  SET_LIST: (state, data) => {
    state.mailList.list = data;
  },
  SET_COUNT: (state, data) => {
    state.mailList.count = data;
  },
  SET_LOADING: (state) => {
    state.mailList.loading = true;
  },
  SET_NOT_LOADING: (state) => {
    state.mailList.loading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
