const CONNECTOR_1C = {
  BASE: 'CONNECTOR_1C',
  MAIN: undefined,
  USERS: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  FACULTY: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  GROUP: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  HYSTORIGROUP: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  ORDER: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
}

CONNECTOR_1C.MAIN = `${CONNECTOR_1C.BASE}|Main`

CONNECTOR_1C.USERS.BASE = `${CONNECTOR_1C.BASE}|Users`
CONNECTOR_1C.USERS.LIST = `${CONNECTOR_1C.USERS.BASE}|List`
CONNECTOR_1C.USERS.DETAIL = `${CONNECTOR_1C.USERS.BASE}|Detail`

CONNECTOR_1C.FACULTY.BASE = `${CONNECTOR_1C.BASE}|Faculty`
CONNECTOR_1C.FACULTY.LIST = `${CONNECTOR_1C.FACULTY.BASE}|List`
CONNECTOR_1C.FACULTY.DETAIL = `${CONNECTOR_1C.FACULTY.BASE}|Detail`

CONNECTOR_1C.GROUP.BASE = `${CONNECTOR_1C.GROUP}|Group`
CONNECTOR_1C.GROUP.LIST = `${CONNECTOR_1C.GROUP.BASE}|List`
CONNECTOR_1C.GROUP.DETAIL = `${CONNECTOR_1C.GROUP.BASE}|Detail`

CONNECTOR_1C.HYSTORIGROUP.BASE = `${CONNECTOR_1C.HYSTORIGROUP}|TrueGroup`
CONNECTOR_1C.HYSTORIGROUP.LIST = `${CONNECTOR_1C.HYSTORIGROUP.BASE}|List`
CONNECTOR_1C.HYSTORIGROUP.DETAIL = `${CONNECTOR_1C.HYSTORIGROUP.BASE}|Detail`

CONNECTOR_1C.ORDER.BASE = `${CONNECTOR_1C.BASE}|Order`
CONNECTOR_1C.ORDER.LIST = `${CONNECTOR_1C.ORDER.BASE}|List`
CONNECTOR_1C.ORDER.DETAIL = `${CONNECTOR_1C.ORDER.BASE}|Detail`

export default {
  ...CONNECTOR_1C
}
