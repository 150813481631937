<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      multiple
      flat
    >
      <v-expansion-panel class="rounded-0">
        <v-expansion-panel-header class="text-h6 font-weight-bold">
          История комментариев
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="px-0">
          <v-data-table
            :headers="headers"
            :items="itemsComments"
            :loading="isLoadingComments"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="countComments"
            :footer-props="{'items-per-page-options':[25,50,100]}"
            class="elevation-2 rounded-b-lg rounded-t-0 removeBtnPaddingRight rounded-t-lg"
          >
            <template v-slot:item.text="{ item }">
              <span style="word-break: break-all;"> {{item.text}}</span>
            </template>
            <template v-slot:item.created="{ item }">
              <span v-if="item.created!==null">{{ displayDate(item.created, true) }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip bottom v-if="!item.is_deleted">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="deleteComment(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <ApplyDialog
      v-if="dialogDelete"
      :opened="dialogDelete"
      :get-text="() => `Вы уверены, что хотите удалить?`"
      :delete-dialog="true"
      @close="closeDeleteDialog"
      @apply="closeDeleteDialog"
    ></ApplyDialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {displayDate} from "@/helper";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";

export default {
  name: "EventDetailEventsList",
  props: {
    contactId: Number,
    needReload: Boolean,
  },
  components: {
    ApplyDialog
  },
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      dialogDelete: false,
      deleteObj: null,
      panel: [],
      headers: [
        {text: 'Текст', value: 'text', sortable: false, align: 'start', width: '40%'},
        {text: 'ФИО', value: 'creator.text', sortable: false, align: 'start', width: '20%'},
        {text: 'Дата создания', value: 'created', sortable: false, align: 'center', width: '20%'},
        {text: 'Действия', value: 'action', sortable: false, align: 'right', width: '20%'},
      ],
    }
  },
  watch: {
    needReload() {
      if (this.needReload) {
        this.loadData()
        this.$emit("notNeedReload", !this.needReload)
      }
    }
  },
  computed: {
    ...mapGetters({
      itemsComments: 'commentReference/getCommentList',
      isLoadingComments: 'commentReference/isCommentListLoading',
      countComments: 'commentReference/getCommentListCount'
    })
  },
  methods: {
    ...mapActions({
      loadCommentList: "commentReference/loadCommentList",
      deleteCommentUser: "commentReference/deleteComment"
    }),
    displayDate: displayDate,
    deleteComment(item){
      this.dialogDelete = true
      this.deleteObj = item
    },
    closeDeleteDialog(event){
      if (event) {
        this.deleteCommentUser({
          id: this.deleteObj.id,
          contact_id: this.contactId,
          finalizer: (val) => {
            this.loadData()
          },
          catcher: (val) => {

          },
        })
      }
      this.dialogDelete = false
      this.deleteObj = null
    },
    loadData(url = undefined) {
      this.loadCommentList({
        url: url,
        contact_id: this.contactId,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url))
        },
        finalizer: (data, url) => {
        }
      })
    },
  },
  created() {
    this.loadData()
  }
}

</script>
<style scoped>

</style>
