const ADMIN = {
  BASE: 'Admin',
  MAIN: undefined,
  USERS: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
    SESSION: undefined,
    SESSION_ADMIN: undefined,
  },
  ACCOUNTS: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  LANGUAGES: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  GEO: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  FACULTY: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  FILE: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  CATHEDRA: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  COUNTRIES: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  TREE: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  SIGNER_FILES: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  SIGNER_LOGS: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined,
  },
  REFERENCE: {
    BASE: undefined,
    DEPARTMENT:{
      BASE: undefined,
      LIST: undefined,
    },
    PHONE:{
      BASE: undefined,
      LIST: undefined,
    },
    COMMON:{
      BASE: undefined,
      LIST: undefined,
    },
    PERSONAL:{
      BASE: undefined,
      LIST: undefined,
    }
  },
}

ADMIN.MAIN = `${ADMIN.BASE}|Main`

ADMIN.REFERENCE.BASE = `${ADMIN.BASE}|Reference`
ADMIN.REFERENCE.DEPARTMENT.BASE = `${ADMIN.USERS.BASE}|Department`
ADMIN.REFERENCE.DEPARTMENT.LIST = `${ADMIN.REFERENCE.DEPARTMENT.BASE}|List`

ADMIN.REFERENCE.COMMON.BASE = `${ADMIN.USERS.BASE}|Common`
ADMIN.REFERENCE.COMMON.LIST = `${ADMIN.REFERENCE.COMMON.BASE}|List`

ADMIN.REFERENCE.PHONE.BASE = `${ADMIN.USERS.BASE}|Phone`
ADMIN.REFERENCE.PHONE.LIST = `${ADMIN.REFERENCE.PHONE.BASE}|List`

ADMIN.REFERENCE.PERSONAL.BASE = `${ADMIN.USERS.BASE}|Personal`
ADMIN.REFERENCE.PERSONAL.LIST = `${ADMIN.REFERENCE.PERSONAL.BASE}|List`

ADMIN.USERS.BASE = `${ADMIN.BASE}|Users`
ADMIN.USERS.LIST = `${ADMIN.USERS.BASE}|List`
ADMIN.USERS.DETAIL = `${ADMIN.USERS.BASE}|Detail`
ADMIN.USERS.SESSION = `${ADMIN.USERS.BASE}|Session`
ADMIN.USERS.SESSION_ADMIN = `${ADMIN.USERS.BASE}|SessionAdmin`

ADMIN.ACCOUNTS.BASE = `${ADMIN.BASE}|Accounts`
ADMIN.ACCOUNTS.LIST = `${ADMIN.ACCOUNTS.BASE}|List`
ADMIN.ACCOUNTS.DETAIL = `${ADMIN.ACCOUNTS.BASE}|Detail`

ADMIN.FILE.BASE = `${ADMIN.BASE}|File`
ADMIN.FILE.LIST = `${ADMIN.FILE.BASE}|List`
ADMIN.FILE.DETAIL = `${ADMIN.FILE.BASE}|Detail`

ADMIN.LANGUAGES.BASE = `${ADMIN.BASE}|Languages`
ADMIN.LANGUAGES.LIST = `${ADMIN.LANGUAGES.BASE}|List`
ADMIN.LANGUAGES.DETAIL = `${ADMIN.LANGUAGES.BASE}|Detail`

ADMIN.FACULTY.BASE = `${ADMIN.BASE}|Faculty`
ADMIN.FACULTY.LIST = `${ADMIN.FACULTY.BASE}|List`
ADMIN.FACULTY.DETAIL = `${ADMIN.FACULTY.BASE}|Detail`

ADMIN.GEO.BASE = `${ADMIN.BASE}|Geo`
ADMIN.GEO.LIST = `${ADMIN.GEO.BASE}|List`
ADMIN.GEO.DETAIL = `${ADMIN.GEO.BASE}|Detail`

ADMIN.CATHEDRA.BASE = `${ADMIN.BASE}|Cathedra`
ADMIN.CATHEDRA.LIST = `${ADMIN.CATHEDRA.BASE}|List`
ADMIN.CATHEDRA.DETAIL = `${ADMIN.CATHEDRA.BASE}|Detail`

ADMIN.COUNTRIES.BASE = `${ADMIN.BASE}|Countries`
ADMIN.COUNTRIES.LIST = `${ADMIN.COUNTRIES.BASE}|List`
ADMIN.COUNTRIES.DETAIL = `${ADMIN.COUNTRIES.BASE}|Detail`

ADMIN.TREE.BASE = `${ADMIN.BASE}|Tree`
ADMIN.TREE.LIST = `${ADMIN.TREE.BASE}|List`
ADMIN.TREE.DETAIL = `${ADMIN.TREE.BASE}|Detail`

ADMIN.SIGNER_FILES.BASE = `${ADMIN.BASE}|Signer_files`
ADMIN.SIGNER_FILES.LIST = `${ADMIN.SIGNER_FILES.BASE}|List`
ADMIN.SIGNER_FILES.DETAIL = `${ADMIN.SIGNER_FILES.BASE}|Detail`

ADMIN.SIGNER_LOGS.BASE = `${ADMIN.BASE}|Signer_logs`
ADMIN.SIGNER_LOGS.LIST = `${ADMIN.SIGNER_LOGS.BASE}|List`
ADMIN.SIGNER_LOGS.DETAIL = `${ADMIN.SIGNER_LOGS.BASE}|Detail`

export default {
  ...ADMIN
}
