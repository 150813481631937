import helper from "@/urls/helper"

let getEducationUrlWithParameters = helper.getEducationUrlWithParameters
let getEducationUrlAllowed = helper.getEducationUrlAllowed

export default {
  getEducationUrlWithParameters,
  UNIVERSITY: {
    ALLOWED: () => getEducationUrlAllowed(
      "admin|university|face-list",
      "list"
    ),
    USER_LIST:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|face-list",
        "list",
        {},
        gets
      ),
    USER_DETAIL:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|face-detail",
        "retrieve",
        {},
        gets
      ),
    USER_RELATED:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-get|related|data",
        "get_related_data",
        {id: id},
        gets
      ),
    USER_MAIL:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-reset|password",
        "reset_password",
        {id: id},
        gets
      ),
    USER_ACTIVATE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-activate",
        "activate",
        {id: id},
        gets
      ),
    CREATE_MAIL:
      (id, gets = null) => getEducationUrlWithParameters(
         "admin|university|face-create|email",
        "create_email",
        {id: id},
        gets
      ),
    USER_DEACTIVATE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-deactivate",
        "deactivate",
        {id: id},
        gets
      ),
    USER_ALIAS:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-add|student|alias",
        "add_student_alias",
        {id: id},
        gets
      ),
    USER_IDENTIFY:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-set|student|identify",
        "set_student_identify",
        {id: id},
        gets
      ),
    USER_NORMALIZE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-normalize|ou",
        "normalize_ou",
        {id: id},
        gets
      ),
    USER_PROCESSING:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-primary|processing",
        "primary_processing",
        {id: id},
        gets
      ),
    USER_ACTUALIZE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-actualize|system",
        "actualize_system",
        {id: id},
        gets
      ),
    UPDATE_RELATED:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-update|related",
        "update_related",
        {id: id},
        gets
      ),
    ALLOWED_FIELDS_UPDATE_RELATED:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|face-allowed|update|fields",
        "allowed_fields_for_update_related",
        {id: id},
        gets
      ),
    GROUP_LIST:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|group-list",
        "list",
        {},
        gets
      ),
    GROUP_FIELD:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|history|group-get|updated|fields",
        "get_updated_fields",
        {id: id},
        gets
      ),
    GROUP_FIELD_UPDATE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|history|group-update|related",
        "update_related",
        {id: id},
        gets
      ),
    GROUP_ST_UPDATE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|history|group-link",
        "link",
        {id: id},
        gets
      ),
    GROUP_ST_UNI_UPDATE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|history|group-link|group",
        "link_group",
        {id: id},
        gets
      ),
    GROUP_DETAIL:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|group-detail",
        "retrieve",
        {},
        gets
      ),
    TRUE_GROUP_LIST:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|history|group-list",
        "list",
        {},
        gets
      ),
    TRUE_GROUP_DETAIL:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|history|group-detail",
        "retrieve",
        {},
        gets
      ),
    FACULTY_LIST:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|references|faculty-list",
        "list",
        {},
        gets
      ),
    FACULTY_DETAIL:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|references|faculty-detail",
        "retrieve",
        {},
        gets
      ),
    FACULTY_RESTORE_BOOKER:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|references|faculty-restore|booker",
        "restore_booker",
        {id: id},
        gets
      ),
    FACULTY_RESTORE_AD:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|references|faculty-update|ad",
        "update_ad",
        {id: id},
        gets
      ),
    EVENTS_LIST:
      (gets = null) => getEducationUrlWithParameters(
        "admin|university|events-list",
        "list",
        gets
      ),
    EVENTS_PROCESSING:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|events-primary|processing",
        "primary_processing",
        {id: id},
        gets
      ),
    EVENTS_EXCLUDE:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|events-update|exclude",
        "update_exclude",
        {id: id},
        gets
      ),
    EVENTS_DETAIL:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|events-detail",
        "retrieve",
        {id: id},
        gets
      ),
    EVENTS_USER:
      (id, gets = null) => getEducationUrlWithParameters(
        "admin|university|events-get|student|list",
        "get_student_list",
        {id: id},
        gets
      ),
  }
}
