import admin_urls from "@/urls/admin";
import {sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest, updateFileRequest} from '@/helper/requests'
import {getUserUid} from "@/helper";

const state = {
  admin_users: {
    user_list: [],
    profile: null,
    userWorkerInfo: null,
    userStudentInfo: null,
    loading: null,
    count: 0,
  }
};

const getters = {
  getUserList: (state) => state.admin_users.user_list,
  getUsersCount: (state) => state.admin_users.count,
  isAdmUsersLoading: (state) => state.admin_users.loading,
  getProfile: state => state.admin_users.profile,
  getUserWorkerInfo: state => state.admin_users.userWorkerInfo,
  getUserStudentInfo: state => state.admin_users.userStudentInfo
};

const actions = {
  loadUserList: ({commit}, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    if (!url) {
      url = admin_urls.ADMIN.USERS.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_USER_LIST', data['results'])
        commit('SET_USERS_COUNT', data['count'])
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  loadUserDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_USER_PROFILE', data)
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadUserWorkerInfo: ({commit}, {url, uid, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.USER.WORKER_INFO(uid)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_WORKER_INFO', data)
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadUserStudentInfo: ({commit}, {url, uid, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.USER.STUDENT_INFO(uid)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_STUDENT_INFO', data)
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateUserEmail: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.USER.CHANGE_EMAIL(id)
    }
    sendPutRequest(
      url,
      {
        email: data.email,
        force: data.force
      },
      (data, url) => {
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  activateUser: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.USER.ACTIVATE_USER(id)
    }
    sendPutRequest(
      url,
      {},
      (data) => {
        finalizer(data)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  deactivateUser: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.USER.DEACTIVATE_USER(id)
    }
    sendPutRequest(
      url,
      {},
      (data) => {
        finalizer(data)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  changeUserPassword: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.USER.CHANGE_PASSWORD(id)
    }
    sendPutRequest(
      url,
      {
        new_password: data.new_password,
      },
      (data) => {
        finalizer(data)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  deleteUser: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = admin_urls.ADMIN.USERS.USER.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data) => {
        finalizer(data)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  changeAvatar: ({ commit }, { data,uid, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.CHANGE_AVATAR(uid)
    updateFileRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  createUserLanguage: ({ commit }, { uid, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.LANGUAGES.CREATE(uid)
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  updateUserLanguage: ({ commit }, { uid, id, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.LANGUAGES.UPDATE(uid, id)
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserLanguage: ({ commit }, { uid, id, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.LANGUAGES.DELETE(uid, id)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  createUserPhone: ({ commit }, { uid, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.PHONES.CREATE(uid)
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  updateUserPhone: ({ commit }, { uid, id, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.PHONES.UPDATE(uid, id)
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserPhone: ({ commit }, { uid, id, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.PHONES.DELETE(uid, id)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  getUserAddressDetail: ({ commit }, { uid, id, finalizer = () => {}, catcher = () => {}} ) => {
    const url = admin_urls.ADMIN.USERS.USER.ADDRESS.DETAIL(uid, id)
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserAddress: ({ commit }, { uid, id, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.ADDRESS.DELETE(uid, id)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  createUserAddress: ({ commit }, { uid, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.ADDRESS.CREATE(uid)
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  updateUserAddress: ({ commit }, { uid, id, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.ADDRESS.UPDATE(uid, id)
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  createUserMail: ({ commit }, { uid, data, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.MAIL.CREATE(uid)
    sendPostRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  deleteUserMail: ({ commit }, { uid, id, finalizer = () => {}, catcher = () => {} }) => {
    const url = admin_urls.ADMIN.USERS.USER.MAIL.DELETE(uid, id)
    sendDeleteRequest(
      url, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
  updateUserMail: ({ commit }, { uid, id, data, finalizer = () => {}, catcher = () => {}}) => {
    const url = admin_urls.ADMIN.USERS.USER.MAIL.UPDATE(uid, id)
    sendPutRequest(
      url, data, (data, url) => {
        finalizer(data, url)
      }, (data, url) => {
        catcher(data, url)
      }
    )
  },
};

const mutations = {
  SET_USER_LIST: (state, user_list) => {
    state.admin_users.user_list = user_list
  },
  REMOVE_USER_LIST: (state) => {
    state.admin_users.user_list = []
  },
  SET_LOADING: (state) => {
    state.admin_users.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.admin_users.loading = false
  },
  SET_USERS_COUNT: (state, count) => {
    state.admin_users.count = count
  },
  SET_USER_PROFILE: (state, value) => {
    state.admin_users.profile = value
  },
  SET_WORKER_INFO: (state, value) => {
    state.admin_users.userWorkerInfo = value
  },
  SET_STUDENT_INFO: (state, value) => {
    state.admin_users.userStudentInfo = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
