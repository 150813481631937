<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
<!--      <v-text-field-->
<!--        v-model="search"-->
<!--        append-icon="search"-->
<!--        label="Поиск"-->
<!--        placeholder="Для поиска нажмите Enter или кнопку поиск"-->
<!--        dense-->
<!--        outlined-->
<!--        class="py-3 my-3"-->
<!--        @click:clear="clearSearchData"-->
<!--        clearable-->
<!--        hide-details-->
<!--      ></v-text-field>-->
<!--      <v-spacer></v-spacer>-->
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="newGeo"
          >
            Создать
          </v-btn>
        </v-list>
      </v-menu>
      <div v-else>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="newGeo"
            >
              <v-icon>
                add
              </v-icon>
            </v-btn>
          </template>
          <span>Добавить контакт</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getGeoList"
      :loading="isLoading"
      :search="search"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :footer-props="{ disablePagination : isLoading, disableItemsPerPage : isLoading, 'items-per-page-options':[25,50,100]}"
    >
      <template v-slot:item.is_default="{ item }">
        <v-icon color="teal" v-if="item.is_default">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openDefaultDialog(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :color="item.is_default ?  'green':'red' ">
                verified
              </v-icon>
            </v-btn>
          </template>
          <span>Изменить круг по умолчанию</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editGeo(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openDeleteDialog(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          <span>Удалить контакт</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="copy"
      color="#757575"
    >
      Текст скопирован в буфер обмена
    </v-snackbar>
    <ApplyDialog
      v-if="dialog_delete"
      :opened="dialog_delete"
      :delete-dialog="true"
      :get-text="() => `Вы уверены, что хотите удалить?`"
      @close="closeDeleteDialog"
      @apply="closeDeleteDialog"
    ></ApplyDialog>
    <ApplyDialog
      v-if="dialog_default"
      :opened="dialog_default"
      :delete-dialog="false"
      :get-text="() => `Вы уверены, что хотите сменить статус?`"
      @close="closeDefaultDialog"
      @apply="closeDefaultDialog"
    ></ApplyDialog>
    <GeoDialog
      v-if="dialog_add_edit"
      :opened="dialog_add_edit"
      :edit_data="docs_data"
      @close="closeEditDialog"
    />
  </v-container>
</template>


<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/admins/routers/names";
import TitledPageMixin from '@/mixins/TitledPageMixin'
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import {SemipolarSpinner} from "epic-spinners";
import GeoDialog from "@/modules/admins/geo/components/geoDialog.vue";


export default {
  name: "ListGeo",
  components: {
    GeoDialog,
    ApplyDialog,
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  watch:{
  },
  data: function () {
    return {
      page_title: 'Список геопозиций',
      copy: false,
      expanded: [],
      geo:undefined,
      docs_data: undefined,
      dialog_delete: false,
      dialog_add_edit: false,
      dialog_default: false,
      pagination: {
        size_name: 'size',
      },
      search: '',
      headers: [
        {
          text: 'Организация',
          sortable: false,
          value: 'organization.name',
          align: 'left',
          width: '40%'
        },
        {
          text: 'По умолчанию',
          sortable: false,
          value: 'is_default',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Широта',
          sortable: false,
          value: 'latitude',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Долгота',
          sortable: false,
          value: 'longitude',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Радиус',
          sortable: false,
          value: 'radius',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'end',
          width: '20%'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      getGeoList: 'geo/getGeoList',
      getGeoCount: 'geo/getGeoListCount',
      isLoading: 'geo/isGeoListLoading',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadGeoList: 'geo/loadGeoList',
      deleteGeo: 'geo/deleteGeo',
      loadGeoDetail: 'geo/loadGeoDetail',
      setDefaultGeo: 'geo/setDefaultGeo',
    }),
    loadData(url = undefined) {
      this.loadGeoList({
        url: url,
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)

        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    reloadData() {
      this.clearSearchData()
      this.loadData()
    },
    newGeo() {
      this.docs_data =  undefined
      this.dialog_add_edit = true
    },
    editGeo(item) {
      this.docs_data = item
      this.dialog_add_edit = true
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
    },
    openDeleteDialog(item) {
      this.dialog_delete = true
      this.docs_data = item
    },
    openDefaultDialog(item) {
      this.dialog_default = true
      this.docs_data = item
    },
    closeDefaultDialog(event) {
      if (event) {
        this.setDefaultGeo({
          id: this.docs_data.id,
          finalizer: (val) => {
            this.loadData()
            this.dialog_default = false
            this.docs_data =  undefined
          },
          catcher: (val) => {
            this.dialog_default = false
            this.docs_data =  undefined
          },
        })
      }
      this.dialog_default = false
      this.docs_data =  undefined
    },
    closeDeleteDialog(event) {
      if (event) {
        this.deleteGeo({
          id: this.docs_data.id,
          finalizer: (val) => {
            this.loadData()
            this.dialog_delete = false
            this.docs_data =  undefined
          },
          catcher: (val) => {
            this.dialog_delete = false
            this.docs_data =  undefined
          },
        })
      }
      this.dialog_delete = false
      this.docs_data =  undefined
    },
  },
  created() {
    this.loadData()
  }
}
</script>
<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0px;
}
</style>
