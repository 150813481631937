import {
  ACCOUNT_HOST,
  ACCOUNTS_INSTANCE,
  ORGANIZATION_HOST,
  ORGANIZATION_INSTANCE,
  SIGNER_HOST,
  SIGNER_INSTANCE,
  REFERENCE_INSTANCE,
  REFERENCE_HOST,
  EDUCATION_INSTANCE,
  EDUCATION_HOST
} from '@/helper/consts'
import { getUrlAllowed, getUrlWithParameters } from '@/helper/urls'

export default {
  getAccountsUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
    )
  },

  getAdminUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
    )
  },

  getAdminUrlAllowed(name, method) {
    return getUrlAllowed(ACCOUNTS_INSTANCE, name, method)
  },

  getEducationUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      EDUCATION_HOST, EDUCATION_INSTANCE, name, method, parameters, gets
    )
  },

  getEducationUrlAllowed(name, method) {
    return getUrlAllowed(EDUCATION_INSTANCE, name, method)
  },

  getOrganizationUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameters, gets
    );
  },
  getOrganizationUrlAllowed(name, method) {
    return getUrlAllowed(ORGANIZATION_INSTANCE, name, method)
  },

  getSignerUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      SIGNER_HOST, SIGNER_INSTANCE, name, method, parameters, gets
    );
  },

  getSignerUrlAllowed: function (name, method) {
    return getUrlAllowed(SIGNER_INSTANCE, name, method)
  },

  getReferenceUrlWithParameters: function (name, method, parameters, gets) {
    return getUrlWithParameters(
      REFERENCE_HOST, REFERENCE_INSTANCE, name, method, parameters, gets
    );
  },

  getReferenceUrlAllowed: function (name, method) {
    return getUrlAllowed(REFERENCE_INSTANCE, name, method)
  }
}
