import {sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest, sendPutRequest} from "@/helper/requests";
import reference from "@/urls/reference";

const state = {
  comment: {
    list: [],
    loading: null,
    count: 0,
    detail: [],
  }
};

const getters= {
  getCommentList: (state) => state.comment.list,
  getCommentDetail: state => state.comment.detail,
  getCommentListCount: (state) => state.comment.count,
  isCommentListLoading: (state) => state.comment.loading,
};

const actions= {
  deleteComment: ({commit}, {url,id, phone_id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.COMMENT_PHONE.DELETE(id,phone_id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadCommentList: ({commit}, {url, phone_id, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    if (!url) {
      url = reference.REFERENCE.ADMIN.COMMENT_PHONE.LIST(phone_id)
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_COMMENT_LIST', data['results'])
        commit('SET_COMMENT_COUNT', data['count'])
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  loadCommentDetail: ({commit}, {url,  phone_id, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.COMMENT_PHONE.DETAIL(phone_id, id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_COMMENT_DETAIL', data);
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createComment: ({commit}, {url, phone_id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.COMMENT_PHONE.CREATE(phone_id)
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations= {
  SET_COMMENT_LIST: (state, list) => {
    state.comment.list = list
  },
  SET_COMMENT_DETAIL: (state, value) => {
    state.comment.detail = value
  },
  SET_LOADING: (state) => {
    state.comment.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.comment.loading = false
  },
  SET_COMMENT_COUNT: (state, count) => {
    state.comment.count = count
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
