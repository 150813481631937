<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
<!--      <v-text-field-->
<!--        v-model="search.value"-->
<!--        append-icon="search"-->
<!--        label="Поиск"-->
<!--        placeholder="Для поиска нажмите Enter или кнопку поиск"-->
<!--        dense-->
<!--        outlined-->
<!--        class="py-3 my-3"-->
<!--        @change="searchLoad"-->
<!--        @click:append="searchLoad"-->
<!--        @keydown.enter="searchLoad"-->
<!--        clearable-->
<!--        hide-details-->
<!--      ></v-text-field>-->
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="count"
      item-key="guid"
      :footer-props="{'items-per-page-options':[25,50,100]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :expanded.sync="expanded"
      single-expand
      show-expand
    >
      <template v-slot:item.fio="{item}">
        <span>{{item.face.fio}}</span>
      </template>
      <template v-slot:item.profession="{item}">
        <span>{{item.profession.name}}</span>
      </template>
      <template v-slot:item.department="{item}">
        <span>{{item.department.name}}</span>
      </template>
      <template v-slot:item.created="{item}">
        <span>{{displayDate(item.created)}}</span>
      </template>
      <template v-slot:item.updated="{item}">
        <td class="body-2 justify-center text-sm-center">
          <span v-if="item.updated">{{displayDate(item.updated)}}</span>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.is_execute="{item}">
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="item.is_execute">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.executed="{item}">
        <td class="body-2 justify-center text-sm-center">
          <span v-if="item.executed">{{displayDate(item.executed)}}</span>
          <span v-else>-</span>
        </td>
      </template>
      <template v-slot:item.entered="{item}">
        <td class="body-2 justify-center text-sm-center">
          <span v-if="item.entered">{{displayDate(item.entered)}}</span>
          <span v-else>-</span>
        </td>
      </template>
      <template v-slot:item.organization="{item}">
        <td class="body-2 justify-center text-sm-center">

          <span>{{item.organization.guid}}</span>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openRestart(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                cached
              </v-icon>
            </v-btn>
          </template>
          <span>Перезапуск принятия на должность</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openNormalize(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                auto_fix_normal
              </v-icon>
            </v-btn>
          </template>
          <span>Нормализация логина</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-expand="props">
        <v-btn small icon @click="loadDetail(props)">
          <v-progress-circular v-if="props.item.detail_loading" color="blue" indeterminate></v-progress-circular>
          <v-icon v-else-if="!props.isExpanded">expand_more</v-icon>
          <v-icon v-else>expand_less</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <AcceptanceExpand :item="posDetail"/>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <apply-dialog
        :loading="loadingRestart"
        :opened="openedRestartDialog"
        :get-text="() => 'Перезапуcтить принятие на должность?'"
        @apply="closeRestart"
        @close="closeRestart"
        :error="getError('detail')"
    />
    <normalize-dialog
        v-if="openedNormalize"
        :id="selected"
        :opened="openedNormalize"
        :item="selected"
        @close="closeNormalize"
    />
  </v-container>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {displayDate} from "@/helper";
import AcceptanceExpand from "@/modules/booker/positions/acceptances/components/AcceptanceExpand.vue";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import NormalizeDialog from "@/modules/booker/positions/acceptances/components/normalizeDialog.vue";

export default {
  name: "ListAcceptances",
  components: {NormalizeDialog, AcceptanceExpand, ApplyDialog},
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      page_title: 'Список принятий на должность',
      pagination: {},
      expanded: [],
      selected: null,
      errors: {},
      expandable_data: {},
      loadingRestart: false,
      openedRestartDialog: false,
      openedNormalize: false,
      search: {
        parameter: '',
      },
      headers: [
        {
          text: 'Работник',
          sortable: false,
          value: 'fio',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Должность',
          sortable: false,
          value: 'profession',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Подразделение',
          sortable: false,
          value: 'department',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Cтавка',
          sortable: false,
          value: 'rate',
          align: 'center',
          width: '3%'
        },
        {
          text: 'Создано',
          sortable: false,
          value: 'created',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Обновлено',
          sortable: false,
          value: 'updated',
          align: 'center',
          width: '3%'
        },
        {
          text: 'Выполнено',
          sortable: false,
          value: 'is_execute',
          align: 'center',
          width: '3%'
        },
        {
          text: 'Дата выполнения',
          sortable: false,
          value: 'executed',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Вид занятости',
          sortable: false,
          value: 'kind',
          align: 'left',
          width: '7%'
        },
        {
          text: 'Дата принятия на работу',
          sortable: false,
          value: 'entered',
          align: 'center',
          width: '20%'
        },
        {
          text: 'UID организации',
          sortable: false,
          value: 'organization',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'center',
          width: '5%'
        },
        {
          text: '',
          sortable: false,
          value: 'data-table-expand',
          align: 'right',
          width: '3%'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      list: "acceptances/getList",
      isLoading: "acceptances/getLoading",
      count: "acceptances/getCount",
      posDetail: "acceptances/getDetail"
    })
  },
  methods: {
    ...mapActions({
        loadList: "acceptances/loadList",
        loadPosDetail: "acceptances/loadDetail",
        restartAccepted: "acceptances/restartAccepted"
    }),
    displayDate,
    loadData(url = undefined) {
      this.loadList({
        url: url,
        urlAppend: (url) => {
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    loadDetail(props) {
      if (!props.isExpanded) {
        this.$set(props.item, "detail_loading", true)
        this.loadPosDetail({
          id: props.item.guid,
          finalizer: (data, url) => {
            this.$set(props.item, "detail_loading", false)
            props.expand(!props.isExpanded)
          },
          catcher: (error) => {
            this.$set(props.item, "detail_loading", false)
          }
        })
      } else
        props.expand(!props.isExpanded)
    },
    restartPositionAccepted(item) {
      this.loadingRestart = true
      this.restartAccepted({
        id: item.guid,
        finalizer: () => {
          this.loadingRestart = false
          this.selected = null
          this.openedRestartDialog = false
          this.loadData()
        },
        catcher: (error) => {
          this.loadingRestart = false
          this.mapErrors(error)
        }
      })
    },
    reloadData() {
      this.loadData()
    },
    openNormalize(item) {
      this.openedNormalize = true
      this.selected = item
    },
    closeNormalize() {
      this.openedNormalize = false
      this.selected = null
      this.loadData()
    },
    openRestart(item) {
      this.selected = item
      this.openedRestartDialog = true
    },
    closeRestart(ev) {
      if (ev) {
        this.restartPositionAccepted(this.selected)
      } else {
        this.selected = null
        this.openedRestartDialog = false
        this.errors = {}
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0;
}
</style>
