<template>
  <user-action-form-template
    :process="process"
    :can_process="can_process"
    title="Регенерирование логина пользователя(Нормализция)"
    :open="open"
    @refresh="refresh"
    @exit="exit"
    @action="action"
    :item="item"
    :non_field_errors="this.errors.non"
    @dismiss_error="deleteNonFieldError($event)"
  >
    <template v-slot:action-button-title>
      Регенерация логина
    </template>
<!--    <template v-slot:error-text>-->
<!--      Во время регенерация логина возникли непредвиденные ошибки-->
<!--    </template>-->
    <template v-slot:form>
      <v-form ref="form">
        <v-layout>
          <v-flex>
            Пользователь:
          </v-flex>
        </v-layout>
        <v-layout class="my-2">
          <v-flex class="font-weight-bold">
            {{item.lastname}} {{item.firstname}} {{item.midname}} ({{item.accounts_username}})
          </v-flex>
        </v-layout>
        <v-alert type="info" outlined>
          Если логин пользователя уже был нормализован:
          <v-switch class="my-0 mr-auto" v-model="data.change_fio" persistent-hint label="Повторно нормализовать логин" hide-details ripple></v-switch>
          <v-switch class="my-0 mr-auto" v-model="data.only_send_email" persistent-hint label="Отправить повторное письмо" hide-details ripple></v-switch>
        </v-alert>
        <v-expand-transition>
          <div v-show="!data.only_send_email">
            <v-alert type="warning" outlined>
              После запуска процесса, отменить его действие будет невозможно, аккаунт работника окончательно будет переименован
              на АД и в Портале.
            </v-alert>
            <v-layout>
              <v-flex>
                <v-alert type="info" outlined>
                  Логин будет сгенерирован согласно актуальным правилам формирования логина.
                  <v-btn depressed color="info--text" v-if="!info.generate_detail" @click="info.generate_detail = !info.generate_detail">
                    скрыть <v-icon>expand_less</v-icon>
                  </v-btn>
                  <v-btn depressed color="info--text" v-else @click="info.generate_detail = !info.generate_detail">
                    развернуть <v-icon>expand_more</v-icon>
                  </v-btn>
                <v-expand-transition>
                  <ul v-if="!info.generate_detail">
                    <li>Фамилия транслитерируется и берутся не более 15 первых символов;</li>
                    <li>Если фамилия двойная, то вторая фамилия отбрасывается и берутся не более 15 первых символов;</li>
                    <li>В случае наличия отчества, после транслитерации имени берется первая буква, отделяемая от остальных частей точками;</li>
                    <li>В случае отсутвия отчества, имя используется как последняя часть;</li>
                    <li>Конечная часть логина транслитерируется и берется первый символ, если логин уже занят, то берется 2 символа и более.</li>
                  </ul>
                </v-expand-transition>
                </v-alert>
              </v-flex>
            </v-layout>
            <v-alert type="info" outlined>
              Изменение пароля
              <v-switch class="my-0 mr-auto" v-model="data.is_random_password" label="Сгенерировать случайный пароль" persistent-hint hide-details ripple>Сгенерировать случайный пароль</v-switch>
              <v-switch class="my-0 mr-auto" v-model="data.is_password_change" persistent-hint label="Установить стандартный пароль" hide-details ripple>Установить стандартный пароль</v-switch>
              <v-expand-transition>
                <div v-show="data.is_password_change">
                  Пароль по умолчанию Pwd*12345678
                </div>
              </v-expand-transition>
              <v-switch class="my-0 mr-auto" v-model="data.is_send_email" persistent-hint label="Отправить письмо о нормализации" hide-details ripple>Отправить письмо о нормализации</v-switch>
              <v-expand-transition>
                <div v-show="data.is_send_email">
                  Будет отправлено письмо аналогичное письму о нормализации направляемому в период первичной нормализации.
                </div>
              </v-expand-transition>
            </v-alert>
          </div>
        </v-expand-transition>
        <!--        <v-alert type="info" outline value="true">-->
        <!--          Если логин пользователя уже был нормализован, можно-->
        <!--          <v-switch class="my-0 mr-auto" v-model="data.only_send_email" persistent-hint label="Отправить повторное письмо" hide-details ripple></v-switch>-->
        <!--        </v-alert>-->
      </v-form>
    </template>
    <template v-slot:success>
      <div v-if="!process.result.renamed">
        <p>
          Сводная информация по нормализации пользователя {{item.lastname}} {{item.firstname}} {{item.midname}}!
        </p>
        <table style="border: 1px solid" class="d-inline-block" v-if="!!process.result">
          <tr>
            <td class="px-2">Старый логин</td>
            <td class="px-2">Новый логин</td>
            <td class="px-2">Новый пароль</td>
            <!--          <td class="px-2">Логин постфикс</td>-->
            <!--          <td class="px-2">Пароль постфикс</td>-->
          </tr>
          <tr>
            <td class="px-2">{{ process.result.username.old }}</td>
            <td class="px-2">{{ process.result.username.new }}</td>
            <td class="px-2" v-if="process.result.password && process.result.password.is_password_change">
              {{ process.result.password.ad.password }}
            </td>
            <td class="px-2" v-else>Пароль не устанавливался</td>
            <!--          <td class="px-2" v-if="process.result.postfix_email">{{ process.result.postfix_email }}</td>-->
            <!--          <td class="px-2" v-if="process.result.postfix_password">{{ process.result.postfix_password }}</td>-->
          </tr>
        </table>
        <p v-else>
          Не удалось получить данные.
        </p>
      </div>
      <p v-else>
        Письмо о нормализации логина было повторно отправлено пользователю {{item.lastname}} {{item.firstname}} {{item.midname}}!
      </p>
      <p v-if="process.result.renamed && process.result.password.is_password_change">
        <br>Пользователю при первом входе на рабочей станции или в портале необходимо будет сменить пароль.
        <br>Время действия одноразового пароля не ограничено.
      </p>
    </template>
  </user-action-form-template>
</template>

<script>
import UserActionFormTemplate from "@/modules/booker/users/components/UserActionFormTemplate";
import userActionFormMixin from "@/mixins/userActionFormMixin";
import booker from "@/urls/booker";
import logs from '@/store/modules/booker/logs'

export default {
  name: "UserNormalizeDialog",
  components: {UserActionFormTemplate},
  mixins: [userActionFormMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      info: {
        generate_detail: false,
      },
      data: {
        is_random_password: false,
        is_password_change: false,
        is_send_email: false,
        only_send_email: false,
        change_fio: false,
      },
    }
  },
  watch: {
    'data.only_send_email'(nV, oV){
      if(nV && this.data.change_fio){
        this.data.change_fio = false
      }
    },
    'data.change_fio'(nV, oV){
      if(nV && this.data.only_send_email){
        this.data.only_send_email = false
      }
    },
    'data.is_random_password'(nV, oV) {
      if (nV && this.data.is_password_change) {
        this.data.is_password_change = false
      }
    },
    'data.is_password_change'(nV, oV) {
      if (nV && this.data.is_random_password) {
        this.data.is_random_password = false
      }
    }
  },
  methods: {
    checkSuccess(data){
      return !json.has_error
    },
    cleanData: function () {
      this.data.only_send_email = false;
      this.data.is_password_change = false;
      this.data.is_send_email = false;
      this.data.change_fio = false;
    },
    cleanResult: function () {
      this.process.result = {};
    },
    checkCan(){
      let url = booker.BOOKER.OFFICIAL_WORKER_NORMALIZE(this.item.id)
      this.can_process.loading = true;
      this.checkActionDialog({
        url: url,
        finalizer: (data) => {
          this.can_process.loading = false;
          this.can_process.can = data['can'];
          this.can_process.detail = data['detail'];
          this.afterCan(data);
        },
        catcher: (error) => {
          this.can_process.can = true;
          this.can_process.loading = false;
          this.error = error
        }
      })
    },
    action(){
      let url = booker.BOOKER.OFFICIAL_WORKER_NORMALIZE(this.item.id)
      if(this.$refs['form'].validate()){
        this.errors.non = [];
        this.process.loading = true;
        this.doActionDialog({
          url: url,
          data: this.data,
          finalizer: (data) => {
            this.process.success = true;
            this.process.log = this.getLog(data);
            this.mapResult(data);
            this.process.executed = true;
            this.process.loading = false;
            this.$emit('refresh')
          },
          catcher: (error) => {
            this.process.loading = false;
            this.process.executed = true;
            this.process.success = false;
            this.process.log = error
          }
        })
      }
    }
  },
}
</script>

<style scoped>

</style>
