<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
<!--      <v-text-field-->
<!--        v-model="search.value"-->
<!--        append-icon="search"-->
<!--        label="Поиск"-->
<!--        placeholder="Для поиска нажмите Enter или кнопку поиск"-->
<!--        dense-->
<!--        outlined-->
<!--        class="pa-3 ma-3"-->
<!--        @change="searchData"-->
<!--        @click:append="searchData"-->
<!--        clearable-->
<!--        hide-details-->
<!--      ></v-text-field>-->
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="openEditDialog"
          >
            Создать
          </v-btn>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <div v-else>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="openEditDialog"
            >
              <v-icon>
                add
              </v-icon>
            </v-btn>
          </template>
          <span>Добавить язык</span>
        </v-tooltip>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 mr-3"
              v-bind="attrs"
              v-on="on"
              @click="reloadData"
            >
              <v-icon>
                refresh
              </v-icon>
            </v-btn>
          </template>
          <span>Обновить</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getItems"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getCount"
      :footer-props="{'items-per-page-options':[25,50,100]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
    >
      <template v-slot:item.actions="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openEditDialog(props)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openDeleteDialog(props)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          <span>Удалить язык</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="loadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <languages-dialog
      v-if="opened.edit"
      :opened="opened.edit"
      :edit_data="edit_data"
      @close="closeEditDialog"
    />
    <apply-dialog
      v-if="opened.delete"
      :opened="opened.delete"
      :delete-dialog="true"
      :get-text="() => `Вы уверены, что хотите удалить язык ${delete_data.text}?`"
      @close="closeDeleteDialog"
      @apply="applyDelete"
    />
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {mapActions, mapGetters} from "vuex";
import LanguagesDialog from "@/modules/admins/languages/components/languagesDialog.vue";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";

export default {
  name: "List",
  components: {LanguagesDialog, ApplyDialog},
  mixins: [PaginatedDataMapperMixin, TitledPageMixin],
  data() {
    return {
      page_title: "Список языков",
      opened: {
        edit: false,
        delete: false
      },
      edit_data: null,
      delete_data: null,
      headers: [
        {
          text: "Язык",
          align: "center",
          sortable: false,
          value: "text",
          width: "50%"
        },
        {
          text: "Действия",
          align: "right",
          sortable: false,
          value: "actions",
          width: "50%"
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getItems: "languages/getLanguagesList",
      getCount: "languages/getLanguagesListCount",
      isLoading: "languages/isLanguagesListLoading"
    })
  },
  methods: {
    ...mapActions({
      loadList: "languages/loadList",
      loadDetail: "languages/loadDetail",
      deleteLanguages: "languages/deleteLanguages"
    }),
    loadData(url = undefined) {
      this.loadList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData()
    },
    openEditDialog(props = null) {
      this.opened.edit = true
      this.edit_data = props.item
    },
    closeEditDialog() {
      this.loadData()
      this.opened.edit = false
      this.edit_data = null
    },
    openDeleteDialog(props) {
      this.opened.delete = true
      this.delete_data = props.item
    },
    applyDelete(ev) {
      if (ev) {
        this.deleteLanguages({
          id: this.delete_data.value,
          finalizer: () => {
            this.closeDeleteDialog()
          },
          catcher: (error) => {
            this.mapErrors(error)
          }
        })
      }
    },
    closeDeleteDialog(ev) {
      this.loadData()
      this.opened.delete = false
      this.delete_data = null
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0px;
}
</style>
