import store from "../store";

import {addGetParameters, loadData, setUrlParameters} from "@/helper/requests";

export function makeMediaHostUrl(host, url) {
    return host + url
}

export function getUrl(host, base, urlname, action) {
    let url = store.getters['urls/getPattern'](
        base,
        urlname,
        action
    );
    if (url !== '')
        return host + url;
    else
        return ''

}

export function getUrlAllowed(base, urlname, action) {
    return store.getters['urls/getAllowed'](
        base,
        urlname,
        action
    );
}

export function checkUrlAllowedWithParameters(host, base, urlname, action, pars) {
    let checks = [{
        name: urlname,
        parameters: pars
    }]
    loadData(`${host}/instance/named/url/parametrize/list/allowed/?urls=${JSON.stringify(checks)}`, (data) => {})
}

export function getUrlWithParameters(host, base, urlname, action, parameters = {}, get_parameters = null) {
    let url = setUrlParameters(
        getUrl(
            host,
            base,
            urlname,
            action
        ),
        parameters
    );
    if (get_parameters !== null) {
        return addGetParameters(url, get_parameters);
    }
    return url
}

