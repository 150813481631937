<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @change="searchData"
        @click:clear="searchData"
        @click:append="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isUsersListLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[50]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="guid"
      show-expand
    >
      <template v-slot:item.actions="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openRestoreDialog(props)">
              <v-icon>sync_alt</v-icon>
            </v-btn>
          </template>
          Проверка связи, восстановление или создание на Booker
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openRestoreADDialog(props)">
              <v-icon>compare_arrows</v-icon>
            </v-btn>
          </template>
          Проверка связи, восстановление или создание на AD
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <vue-json-pretty
              style="width: 100%"
              class="text-xs-left"
              :data="item"
              show-line
              show-double-quotes
              collapsed-on-click-brackets
              :deep="1"
            />
          </v-container>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <apply-dialog
      v-if="opened_restore_booker.open"
      :opened="opened_restore_booker.open"
      :delete-dialog="false"
      :success="opened_restore_booker.data"
      :loading="opened_restore_booker.loading"
      :get-text="() =>  `Вы уверены, что хотите восстановить или создать связь на Booker?`"
      @close="closeAllDialog"
      @apply="applyChangeRestoreBooker"
    />
    <apply-dialog
      v-if="opened_restore_ad.open"
      :opened="opened_restore_ad.open"
      :delete-dialog="false"
      :success="opened_restore_ad.data"
      :loading="opened_restore_ad.loading"
      :get-text="() =>  `Вы уверены, что хотите восстановить или создать связь на AD?`"
      @close="closeAllDialog"
      @apply="applyChangeRestoreAD"
    />
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import { mapActions, mapGetters } from "vuex"
import names from "@/modules/university_connector/routers/names"
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import VueJsonPretty from "vue-json-pretty";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";

export default {
  name: "List",
  components: {
    ApplyDialog,
    VueJsonPretty,
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      page_title: "Факультеты",
      expanded: [],
      edit_data: null,
      search: {
        parameter: "search"
      },
      opened_restore_ad: {
        open: false,
        loading: false,
        data: ''
      },
      opened_restore_booker: {
        open: false,
        loading: false,
        data: ''
      },
      errors: {},
      pagination: {
        size_name: "size",
        size: 50
      },

      headers: [
        {
          text: "Пользователь",
          align: "left",
          sortable: false,
          value: "name",
        },

        {
          text: "Действия",
          align: "right",
          sortable: false,
          value: "actions",
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      getUsersList: "faculty_un/getFacultyList",
      getUsersCount: "faculty_un/getFacultysCount",
      isUsersListLoading: "faculty_un/isFacultysListLoading"
    }),
    names: () => names
  },
  methods: {
    ...mapActions({
      loadUserList: "faculty_un/loadFacultyList",
      changeRestoreBooker: "faculty_un/changeRestoreBooker",
      changeRestoreAD: "faculty_un/changeRestoreAD",
    }),
    closeAllDialog(){
      this.opened_restore_booker.open = false
      this.opened_restore_booker.data = ''
      this.opened_restore_ad.open = false
      this.opened_restore_ad.data = ''
      this.edit_data = undefined
      this.loadData(this.getCurrentLoadUrl())
    },
    applyChangeRestoreBooker(ev) {
      if (ev) {
        this.opened_restore_booker.loading = true
        this.changeRestoreBooker({
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.opened_restore_booker.data = JSON.stringify(json)
            this.opened_restore_booker.loading = false
          },
          catcher: (error) => {
            this.opened_restore_booker.loading = false
            this.opened_restore_booker.data = JSON.stringify(error)
          }
        })
      }
    },
    applyChangeRestoreAD(ev) {
      if (ev) {
        this.opened_restore_ad.loading = true
        this.changeRestoreAD({
          id: this.edit_data.guid,
          finalizer: (json) => {
            this.opened_restore_ad.data = JSON.stringify(json)
            this.opened_restore_ad.loading = false
          },
          catcher: (error) => {
            this.opened_restore_ad.loading = false
            this.opened_restore_ad.data = JSON.stringify(error)
          }
        })
      }
    },
    openRestoreADDialog (props) {
      this.opened_restore_ad.open = true
      this.opened_restore_ad.loading = false
      this.opened_restore_ad.data = ''
      this.edit_data = props.item
    },
    openRestoreDialog (props) {
      this.opened_restore_booker.open = true
      this.opened_restore_booker.loading = false
      this.opened_restore_booker.data = ''
      this.edit_data = props.item
    },
    loadData(url = undefined) {
      this.loadUserList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData(this.getCurrentLoadUrl)
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
