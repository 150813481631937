<template>
    <v-dialog persistent v-model="opened" width="500">
        <v-card class="rounded-lg">
            <v-card-title class="super-grey medium-primary">Подтвердите действие</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-5" v-if="!success">
                <span v-html="getText()" v-if="getText !== undefined && opened"></span>
                <slot v-else></slot>
            </v-card-text>
          <v-card-text class="pt-5" v-if="error">
            <v-alert type="error" outlined>{{error}}</v-alert>
          </v-card-text>
            <v-card-text class="pt-5" v-if="success">
               {{success}}
            </v-card-text>
            <v-card-actions>
                <v-flex>
                    <v-btn
                      v-if="!success"
                            @click="closeDialog(true)"
                            :color="deleteDialog ? 'red' : 'warning'"
                            text
                            :loading="loading"
                    >
                        {{ deleteDialog ? 'Удалить' : 'Подтвердить' }}
                    </v-btn>
                    <v-btn
                            @click="closeDialog(false)"
                            text
                            :loading="loading"
                    >
                        Отменить
                    </v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ApplyChangeReadonlyBoolean",
        props: {
            getText: {
              type: Function,
              default: undefined,
            },
            opened: Boolean,
            loading: Boolean,
            deleteDialog: {
              type: Boolean,
              default: false,
            },
            error: {
              type: String,
              default: ''
            },
            success: {
              type: String,
              default: ''
            }
        },
        methods: {
          closeDialog(emit_apply=false){
            if (emit_apply)
              this.$emit('apply', true)
            else
              this.$emit('close', false)
          }
        }
    }
</script>

<style scoped>

</style>
