<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @change="searchData"
        @click:append="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isUsersListLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[50],disablePagination: isUsersListLoading}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :expanded.sync="expanded"
      single-expand
      show-expand
    >
      <template v-slot:item.lastname="props">
        <td class="body-2 justify-center text-lg-left">{{ props.item.lastname }} {{ props.item.firstname }}
          {{ props.item.midname }} <span v-if="props.item.accounts_username">({{props.item.accounts_username}})</span>
        </td>
      </template>
      <template v-slot:item.organization="props">
        <td class="body-2 justify-center text-sm-center">
          <span>{{props.item.organization.name}}</span>
        </td>
      </template>
      <template v-slot:item.has_account="props">
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="props.item.has_account">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.is_active="props">
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="props.item.is_active">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.is_approve="props">
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="props.item.is_approve">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.is_fired="props">
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="!props.item.is_fired">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.is_normalized="props">
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="props.item.is_normalized">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.is_student="props" >
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="props.item.is_student">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openUserRegisterDialog(props)">
              <v-icon>how_to_reg</v-icon>
            </v-btn>
          </template>
          Провести первичную обработку аккаунта
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openUserRegeneratePassword(props)" v-on="on">
              <v-icon>published_with_changes</v-icon>
            </v-btn>
          </template>
          <span>Сбросить пароль</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openUserNormalizeDialog(props)" v-on="on">
              <v-icon>auto_fix_normal</v-icon>
            </v-btn>
          </template>
          <span>Нормализовать логин</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openUserChangeUsernameDialog(props)" v-on="on">
              <v-icon>drive_file_rename_outline</v-icon>
            </v-btn>
          </template>
          <span>Переименовать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openUserActivateDialog(props)" v-on="on">
              <v-icon>check_circle</v-icon>
            </v-btn>
          </template>
          <span>Активировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openUserDeactivateDialog(props)" v-on="on">
              <v-icon>cancel</v-icon>
            </v-btn>
          </template>
          <span>Деактивировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openUserUnlockDialog(props)" v-on="on">
              <v-icon>lock_open</v-icon>
            </v-btn>
          </template>
          <span>Разблокировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="openCreateWorkerEmail(props)" v-on="on">
              <v-icon>alternate_email</v-icon>
            </v-btn>
          </template>
          <span>Создать почтовый адрес Exchange</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-expand="props">
        <v-btn small icon @click="loadDetail(props)">
          <v-progress-circular v-if="props.item.detail_loading" color="blue" indeterminate></v-progress-circular>
          <v-icon v-else-if="!props.isExpanded">expand_more</v-icon>
          <v-icon v-else>expand_less</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <user-expanded :object="expandable_data"></user-expanded>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <user-register-dialog
      :open.sync="forms.register.opened"
      :item.sync="selected"
    />
    <user-regenerate-password
      :open.sync="forms.refresh.opened"
      :item.sync="selected"
    />
    <user-normalize-dialog
      :open.sync="forms.normalize.opened"
      :item.sync="selected"
    />
    <user-change-username-dialog
      :open.sync="forms.rename.opened"
      :item.sync="selected"
    />
    <user-active-dialog
      :open.sync="forms.activate.opened"
      :item.sync="selected"
      :deactivate="false"
      @refresh="$emit('refresh')"
    />
    <user-active-dialog
      :open.sync="forms.deactivate.opened"
      :item.sync="selected"
      :deactivate="true"
      @refresh="$emit('refresh')"
    />
    <apply-dialog
      :loading="forms.unlock.loading"
      :opened="forms.unlock.opened"
      :get-text="() => 'Разблокировать пользователя?'"
      @apply="closeUnlock"
      @close="closeUnlock"
      :error="getError('detail')"
    />
    <apply-dialog
      :loading="forms.workerEmail.loading"
      :opened="forms.workerEmail.opened"
      :get-text="() => 'Создать почтовый адрес Exchange?'"
      @apply="closeCreateWorkerEmail"
      @close="closeCreateWorkerEmail"
      :error="getError('detail')"
    />
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import { mapActions, mapGetters } from "vuex"
import names from "@/modules/booker/routers/names"
import UserExpanded from "@/modules/booker/users/components/UserExpanded";
import UserRegeneratePassword from "@/modules/booker/users/components/UserRegeneratePassword";
import UserNormalizeDialog from "@/modules/booker/users/components/UserNormalizeDialog";
import UserChangeUsernameDialog from "@/modules/booker/users/components/UserChangeUsernameDialog";
import UserActiveDialog from "@/modules/booker/users/components/UserActiveDialog";
import UserRegisterDialog from "@/modules/booker/users/components/UserRegisterDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "List",
  components: {
    ApplyDialog,
    UserActiveDialog,
    UserChangeUsernameDialog,
    UserNormalizeDialog,
    UserRegeneratePassword,
    UserExpanded,
    UserRegisterDialog
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      page_title: "Работники",
      search: {
        parameter: "fio"
      },
      selected: {},
      expanded: [],
      expandable_data: {},
      selectors: {
        position: []
      },
      errors: {},
      pagination: {
        size_name: "size",
        size: 50
      },
      forms: {
        register: {
          opened: false
        },
        refresh: {
          opened: false
        },
        normalize: {
          opened: false
        },
        rename: {
          opened: false
        },
        activate: {
          opened: false
        },
        deactivate: {
          opened: false
        },
        unlock: {
          opened: false,
          loading: false
        },
        workerEmail: {
          opened: false,
          loading: false
        }
      },
      headers: [
        {
          text: "Пользователь",
          align: "left",
          sortable: false,
          value: "lastname",
          width: "35%"
        },
        // {
        //   text: 'Дата рождения',
        //   sortable: false,
        //   align: 'left',
        //   value: 'datebirth',
        //   width: '10%'
        // },
        {
          text: "Организация",
          align: "center",
          sortable: false,
          value: "organization",
          width: "5%"
        },
        {
          text: "Есть AD-а",
          align: "center",
          sortable: false,
          value: "has_account",
          width: "5%"
        },
        {
          text: "Активен P-а",
          align: "center",
          sortable: false,
          value: "is_active",
          width: "5%"
        },
        {
          text: "Подтвержден P-а",
          align: "center",
          sortable: false,
          value: "is_approve",
          width: "5%"
        },
        {
          text: "Работник",
          align: "center",
          sortable: false,
          value: "is_fired",
          width: "5%"
        },
        {
          text: "Был нормализован",
          align: "center",
          sortable: false,
          value: "is_normalized",
          width: "5%"
        },
        {
          text: "Студент",
          align: "center",
          sortable: false,
          value: "is_student",
          width: "5%"
        },
        {
          text: "Действия",
          align: "center",
          sortable: false,
          value: "actions",
          width: "30%"
        },
        {
          text: "",
          value: "data-table-expand"
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getUsersList: "bookerUsers/getUserList",
      getUsersCount: "bookerUsers/getUsersCount",
      isUsersListLoading: "bookerUsers/isUsersListLoading"
    }),
    names: () => names
  },
  methods: {
    ...mapActions({
      loadUserList: "bookerUsers/loadUserList",
      loadUserDetail: "bookerUsers/loadUserDetail",
      bookerUnlockUser: "bookerUsers/unlockUser",
      bookerCreateWorkerEmail: "bookerUsers/workerCreateEmail",
    }),
    loadData(url = undefined) {
      this.loadUserList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData()
    },
    loadDetail(props) {
      let url = undefined
      if (!props.isExpanded) {
        this.$set(props.item, "detail_loading", true)
        this.loadUserDetail({
          url: url,
          id: props.item.id,
          finalizer: (data, url) => {
            this.expandable_data = data
            this.$set(props.item, "detail_loading", false)
            props.expand(!props.isExpanded)
          },
          catcher: (error) => {
            this.$set(props.item, "detail_loading", false)
          }
        })
      } else
        props.expand(!props.isExpanded)
    },
    openUserActivateDialog(props) {
      this.selected = props.item
      this.forms.activate.opened = true
    },
    openUserDeactivateDialog(props) {
      this.selected = props.item
      this.forms.deactivate.opened = true
    },
    openUserUnlockDialog(props) {
      this.selected = props.item
      this.forms.unlock.opened = true
    },
    openUserChangeUsernameDialog(props) {
      this.selected = props.item
      this.forms.rename.opened = true
    },
    openUserNormalizeDialog(props) {
      this.selected = props.item
      this.forms.normalize.opened = true
    },
    openUserRegeneratePassword(props) {
      this.selected = props.item
      this.forms.refresh.opened = true
    },
    openUserRegisterDialog(props) {
      this.selected = props.item
      this.forms.register.opened = true
    },
    openCreateWorkerEmail(props) {
      this.selected = props.item
      this.forms.workerEmail.opened = true
    },
    closeUnlock(ev) {
      if (ev) {
        this.unlockUser(this.selected)
      } else {
        this.selected = {}
        this.forms.unlock.opened = false
        this.errors = {}
      }
    },
    closeCreateWorkerEmail(ev) {
      if (ev) {
        this.createWorkerEmail(this.selected)
      } else {
        this.selected = {}
        this.forms.workerEmail.opened = false
        this.errors = {}
      }
    },
    createWorkerEmail(user) {
      this.forms.workerEmail.loading = true
      this.bookerCreateWorkerEmail({
        id: user.id,
        finalizer: () => {
          this.forms.workerEmail.loading = false
          this.forms.workerEmail.opened = false
          this.selected = {}
          this.loadData()
        },
        catcher: (error) => {
          this.forms.workerEmail.loading = false
          this.selected = {}
          this.mapErrors(error)
        },
      })
    },
    unlockUser(user) {
      this.forms.unlock.loading = true
      this.bookerUnlockUser({
        data: {},
        id: user.id,
        finalizer: () => {
          this.forms.unlock.loading = false
          this.forms.unlock.opened = false
          this.selected = {}
          this.loadData()
        },
        catcher: (error) => {
          this.forms.unlock.loading = false
          this.selected = {}
          this.mapErrors(error)
        },
      })
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
