import {ACCOUNTS_INSTANCE} from "@/helper/consts";
import names from '@/modules/core/routers/names'
import noAccessPage from "@/modules/core/pages/noAccessPage";
// import Breadcrumbs from '@/modules/core/Breadcrumbs'

export default ([
  {
    path: '/no_access',
    name: names.NO_ACCESS_PAGE.BASE,
    component: noAccessPage,
    meta: {
      requiredAuth: true,
      instances: [
        ACCOUNTS_INSTANCE
      ]
    },
    children: []
  }
  // {
  //   path: '/',
  //   name: names.MAIN,
  //   component: Breadcrumbs,
  //   meta: {
  //     breadcrumb: 'Главная страница',
  //     requiredAuth: true,
  //     instances: [
  //       ACCOUNTS_INSTANCE
  //     ]
  //   },
  //   children: [
  //   ]
  // }

])

