const coreName = {
  BASE: 'Core',
  MAIN: undefined,
  NO_ACCESS_PAGE: {
    BASE: undefined,
  }
};
coreName.MAIN = `${coreName.BASE}|Main`
coreName.NO_ACCESS_PAGE.BASE = `${coreName.BASE}|NoAccessPage`

export default {
  ...coreName
}
