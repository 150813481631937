<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="save"
    :title="form_title"
    :scrolled="false"
    :loading="loading"
  >
    <div v-if="!loading" class="px-3">
      <v-autocomplete
        class="pt-4"
        v-model="value.department"
        :items="depart"
        item-value="value"
        item-text="text"
        label="Департамент"
        outlined
        :error="hasError('department')"
        :errorMessages="getError('department')"
      >
      </v-autocomplete>
      <v-autocomplete
        class="pt-4"
        v-model="value.faculty_department"
        :items="faculty"
        item-value="value"
        item-text="text"
        label="Факультет"
        outlined
        :error="hasError('faculty_department')"
        :errorMessages="getError('faculty_department')"
      >
      </v-autocomplete>
      <v-checkbox
        class="pt-4"
        v-model="value.manual"
        label="Управление вручную"
        outlined
        :error="hasError('manual')"
        :errorMessages="getError('manual')">
      </v-checkbox>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "editPersonalDialog",
  props:{
    opened: Boolean,
    edit_data: Object
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, SemipolarSpinner },
  data() {
    return {
      loading:false,
      value: {
        department: null,
        faculty_department: null,
        manual:false
      },
    }
  },
  watch:{

  },
  computed: {
    form_title() {
      if(this.edit_data)
        return 'Редактировать кафедру'
      return 'Добавить кафедру'
    },

    ...mapGetters({
      depart: 'selectors/getDepartments',
      faculty: 'selectors/getFaculty',
      item: 'personalReference/getPersonalDetail'
    }),
  },
  methods: {
    ...mapActions({
      createCathedra: 'cathedra/createCathedra',
      detailCathedra: 'cathedra/loadCathedraDetail',
      updateCathedra: 'cathedra/updateCathedra',
      loadDepartaments : 'selectors/loadDepartments',
      loadFaculty : 'selectors/loadFaculty',
    }),
    close() {
      this.$emit('close', false)
    },
    catchErrors(errors) {

    },
    save() {
      if (this.edit_data) {
        this.updateCathedra({
          id: this.edit_data.id,
          data: this.value,
          finalizer: () => {
            this.close()
          },
          catcher: (errors) => {
            this.mapErrors(errors)
          }
        })
      }
      else {
        this.createCathedra({
          data: this.value,
          finalizer: () => {
            this.close()
          },
          catcher: (errors) => {
            this.mapErrors(errors)
          }
        })
      }
    },
  },
  created() {
    this.loadDepartaments({
      finalizer: (data) => {
      }
    } )
    this.loadFaculty({
      finalizer: (data) => {
      }
    } )
    if(this.edit_data){
      this.loading = true
      this.detailCathedra({
        id: this.edit_data.id,
        finalizer: (data) => {
          this.value.department = data.department
          this.value.faculty_department = data.faculty.id
          this.value.manual = data.manual
          this.loading = false
        }
      })
    }else {
      this.loading = false
    }

  }
}
</script>

<style scoped>

</style>
