import {
  sendPostRequest, sendPatchRequest, sendDeleteRequest, sendGetRequest, sendPutRequest
} from "@/helper/requests";
import admin from "@/urls/reference";
import reference from "@/urls/reference";

const state = {
  file:{
    currentUrl: null,
    items: [],
    loading: false,
    count: 0,
  }
}

const getters = {
  getFileList: state => state.file.items,
  getFileCount: state => state.file.count,
  getFileCurrentUrl: state => state.file.currentUrl,
  isLoadingFile: state => state.file.loading,

}

const actions = {
  loadFileList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = admin.REFERENCE.ADMIN.FILE_UPLOAD.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isLoading){
      commit('SET_FILE_LOADING');
      sendGetRequest(
          url, (data, url) => {
            commit('SET_FILE_LIST', data['results']);
            commit('SET_FILE_COUNT', data['count']);
            commit('SET_FILE_CURRENT_URL', url);
            commit('SET_FILE_LOADING_END');
            finalizer(data, url);
          }, (data, url) => {
            commit('SET_FILE_LOADING_END');
            catcher(data, url);
          }
      )
    }
  },
  uploadFile: ({dispatch, commit, getters}, {gets={},type, finalizer=()=>{}, catcher=()=>{}}) => {
    gets.dump = 'department'
    let url = admin.REFERENCE.ADMIN.FILE_UPLOAD.DEPARTMENT_LOAD(gets)
    if (type === 1){
      gets.dump = 'general'
      url = admin.REFERENCE.ADMIN.FILE_UPLOAD.CONTACTS_LOAD(gets)
    }
    if (type === 2){
      gets.dump = 'personal'
      url = admin.REFERENCE.ADMIN.FILE_UPLOAD.PERSONAL_LOAD(gets)
    }
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url);
      }, (data, url) => {
        catcher(data, url);
      }
    )
  },
  detailFile: ({dispatch, commit, getters}, {id,data, finalizer=()=>{}, catcher=()=>{}}) => {
    let url = admin.REFERENCE.ADMIN.FILE_UPLOAD.DETAIL(id)
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url);
      }, (data, url) => {
        catcher(data, url);
      })
  },
  completeFile: ({dispatch, commit, getters}, {id,finalizer=()=>{}, catcher=()=>{}}) => {
    let url = admin.REFERENCE.ADMIN.FILE_UPLOAD.COMPLETED(id)
    sendGetRequest(
      url, (data, url) => {
        finalizer(data, url);
      }, (data, url) => {
        catcher(data, url);
      })
  },
}

const mutations = {
  SET_FILE_LIST: (state, Acquaintance) => {
    state.file.items = Acquaintance
  },
  SET_FILE_COUNT: (state, value) => {
    state.file.count = value
  },
  SET_FILE_CURRENT_URL: (state, value) => {
    state.file.currentUrl = value
  },
  SET_FILE_LOADING: (state) => {
    state.file.loading = true
  },
  SET_FILE_LOADING_END: (state) => {
    state.file.loading = false
  },
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
