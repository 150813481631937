<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="save"
    :title="'Обновление группы в еиосе'"
    :scrolled="false"
    :loading="loading"
  >
    <div v-if="!loading" class="px-3">
      <v-autocomplete
        v-model="value.fields"
        class="pt-3"
        multiple
        label="Обновляемые поля"
        :items="selector_system"
        outlined
      ></v-autocomplete>
      <v-alert outlined type="error" v-if="hasError('non_field_errors')">
        {{String(getError('non_field_errors'))}}
      </v-alert>
      <v-alert outlined type="error" v-if="hasError('detail')">
        {{String(getError('detail'))}}
      </v-alert>
      <v-alert outlined type="error" v-if="hasError('can_action')">
        {{String(getError('can_action'))}}
      </v-alert>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "actualizeDialog",
  props:{
    opened: Boolean,
    edit_data: Object,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, SemipolarSpinner },
  data() {
    return {
      loading:false,
      selector_system:[
      ],
      value: {
        fields: []
      },
    }
  },

  methods: {
    ...mapActions({
      updateFields: "history_group_un/updateFields",
      loadFieldsGroup: "history_group_un/loadFieldsGroup",
    }),
    close() {
      this.$emit('close', false)
    },

    save() {
      let copyData = Object.assign({}, this.value);
      this.loading = true
        this.updateFields({
          id: this.edit_data.guid,
          data: copyData,
          finalizer: () => {
            this.close()
            this.loading = false
          },
          catcher: (errors) => {
            this.mapErrors(errors)
            this.loading = false
          }
        })
      }
  },
  created() {
    this.loadFieldsGroup({
      id: this.edit_data.guid,
      finalizer: (data, url) => {
        this.selector_system = data
      }
    })
  }
}
</script>

<style scoped>

</style>
