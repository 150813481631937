<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="apply"
    title="Сменить пароль пользователя"
  >
    <v-form v-model="valid">
      <v-card-text class="title">Выберите надежный пароль и не используйте его для других аккаунтов
      </v-card-text>
      <v-text-field
        :rules="[rules.required ,rules.passStr]"
        label="Новый пароль пользователя"
        placeholder="Пароль"
        ref="password"
        required
        outlined
        title="Пароль должен иметь размер не менее 12 символов, содержать хотя бы 1 букву нижнего, верхнего регистров, а так же цифру, может содержать только символы от a до Z, цифры от 0 до 9, а также специальные символы !@#%&*_."
        v-model="value.new_password"
        :append-outer-icon="shownew ? 'visibility_off' : 'visibility'"
        :type="shownew ? 'text' : 'password'"
        @click:append-outer="shownew= !shownew"
        :loading="loading"
      ></v-text-field>
      <v-text-field
        :rules="[rules.required,rules.passStr, rules.identif]"
        label="Повторите пароль"
        placeholder="Пароль"
        ref="password"
        required
        outlined
        title="Пароль должен иметь размер не менее 12 символов, содержать хотя бы 1 букву нижнего, верхнего регистров, а так же цифру, может содержать только символы от a до Z, цифры от 0 до 9, а также специальные символы !@#%&*_."
        v-model="password"
        :append-outer-icon="shownew ? 'visibility_off' : 'visibility'"
        :type="shownew ? 'text' : 'password'"
        @click:append-outer="shownew= !shownew"
        :loading="loading"
      ></v-text-field>
    </v-form>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import { mapActions } from 'vuex'
import { PASSWORD_PATTERN } from '@/helper/consts'
import {getUserUid} from "@/helper";
import {goToLoginPage} from "@/helper/auth";

export default {
  name: "ChangeUserPasswordDialog",
  components: {ScrollCenterDialog},
  props: {
    opened: Boolean,
    user_data: Object,
  },
  data: function () {
    return {
      show: false,
      shownew: false,
      rules: {
        required: value => !!value || 'Пустое поле',
        identif: value => {
          return ((value === this.password) && (value === this.value.new_password)) || 'Пароли не совпадают'
        },
        passStr: value => {
          return PASSWORD_PATTERN.test(value) || 'Пароль должен иметь размер не менее 12 символов, содержать хотя бы 1 букву нижнего, верхнего регистров, а так же цифру, может содержать только символы от a до Z, цифры от 0 до 9, а также специальные символы !#%&*+-.:<=>?@^_|~'
        },
      },
      valid: false,
      password: '',
      value: {
        new_password: '',
      },
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      changeUserPassword: 'adminUsers/changeUserPassword'
    }),
    close() {
      this.$emit('close', false)
    },
    apply() {
      let url = undefined
      this.loading = true
      this.changeUserPassword({
        url: url,
        id: this.user_data.id,
        data: this.value,
        finalizer: (data) => {
          this.loading = false
          if (getUserUid() === this.user_data.uid) {
            goToLoginPage()
          }
          this.$emit('close', true)
        },
        catcher: (error) => {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
