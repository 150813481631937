<template>
 <v-container class="page-content px-5" style="  width: 100%">
   <v-toolbar
       class="removeBtnPaddingRight  rounded-t-lg"
       color="#DADADA"
       flat
   >
     <v-text-field
         v-model="search.value"
         outlined
         dense
         id="StringSearch"
         label="Поиск" hide-details @keydown.enter="searchData">
     </v-text-field>
     <v-spacer></v-spacer>
   </v-toolbar>
   <v-data-table
       style="row-pointer"
       :headers="headers"
       :items="items"
       @click:row="openDetail"
       :loading="isLoading"
       :page.sync="pagination.page"
       :items-per-page.sync="pagination.size"
       :server-items-length="count"
       :footer-props="{'items-per-page-options':[25,50,100]}"
       class="elevation-1 row-pointer"
   >
     <template v-slot:item.created="{item}">
      <span>{{displayDate(item.created,true)}}</span>
     </template>
     <template v-slot:item.changed="{item}">
       <span>{{displayDate(item.changed,true)}}</span>
     </template>
     <template v-slot:item.has_error="{item}">
       <v-icon v-if="item.has_error">done</v-icon>
       <v-icon v-else>close</v-icon>
     </template>
     <template v-slot:no-data>
       <v-btn
           color="primary"
           @click="loadData()"
       >
         Обновить
       </v-btn>
     </template>
   </v-data-table>
 </v-container>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import names from "@/modules/admins/routers/names";

export default {
  name: "List",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      names:names,
      page_title: "Список формирующихся файлов",
      dialog_add_edit: false,
      dialog_delete: false,
      user_data: undefined,
      edit_status: undefined,
      search: {
        parameter: 'search',
      },
      headers: [
        {text: 'ФИО', value: 'user.text', sortable: false, align: 'start', width: '70%'},
        {text: 'Поставленно в очередь', value: 'created', align: 'start', sortable: false, width: '10%'},
        {text: 'Выполнено', value: 'changed', align: 'start', sortable: false, width: '10%'},
        {text: 'Ошибка при формировании', value:"has_error", align: 'start', sortable: false, width: '10%'},
      ],
    }
  },
  watch: {
  },
  computed:{
    ...mapGetters({
      items: 'fileReference/getFileList',
      count: 'fileReference/getFileCount',
      isLoading: 'fileReference/isLoadingFile',
    })
  },
  methods: {
    displayDate:displayDate,
    ...mapActions({
      loadFileList: 'fileReference/loadFileList',
    }),
    openDetail(item){
      this.$router.push({name: names.FILE.DETAIL, params: {id: item.id}})

    },
    loadData(url=undefined){
      this.setLoading();
      this.loadFileList({
        url: url,
        urlAppend: (url) => {
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },


  },
  created() {
    this.loadData()
  }

}

</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
