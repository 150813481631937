<template>
  <scroll-center-dialog
    v-model="opened"
    :title="title"
    :loading="loading"
    :applyDisable="!valid"
    @close="close"
    @apply="apply"
    :apply-text="'Добавить'"
  >
    <v-container>
      <v-list lines="one" max-height="300" style="overflow-y:scroll;">
        <template v-for="(item, index) in list">
        <v-list-item
          :key="item.id"
        >
          <v-list-item-content class="text-wrap  text-left">
            <v-list-item-title v-text="item.text" class="text-wrap"></v-list-item-title>
            <v-list-item-subtitle v-text="displayDate(item.created,true)" class="text-wrap"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="index=== list.length-1">
            <v-btn icon @click="deleteComment()">
              <v-icon color="grey lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
          <v-divider ></v-divider>
        </template>
      </v-list>

      <v-form v-model="valid">
        <v-textarea
          rows="3"
          v-model="data.text"
          label="Новый комментарий к подразделению"
          :error="hasError('text')"
          :error-messages="getError('text')"
          :rules="[rules.required]"
          clearable
          outlined
          @click:clear="errors.fields=[]"
        />
      </v-form>
    </v-container>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import {mapActions} from "vuex";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {displayDate} from "@/helper";

export default {
  name: "commentDialog",
  props:{
    opened: {
      type: Boolean,
      required: true,
    },
    edit_data: {
      type: Object,
      required: false,
    },
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog},
  data() {
    return {
      title: 'Комментарии к подразделению',
      loading: false,
      list:[],
      data: {
        text: '',
      },
      rules: {
        required: v => !!v || 'Поле обязательно для заполнения.',
      },
      valid: false,
    }
  },
  created() {
    this.listComment()
  },
  methods: {
    displayDate:displayDate,
    ...mapActions({
      addCommentTree: 'tree/addCommentTree',
      listCommentTree: 'tree/listCommentTree',
      deleteCommentTree: 'tree/deleteCommentTree',
    }),
    deleteComment(){
      let id =  this.edit_data.id
      if(!id)
        id =  this.edit_data.pk
      this.deleteCommentTree({
        url: undefined,
        organization_id: 1,
        id: id,
        finalizer: (data) => {
           this.listComment()
        },
        catcher: (error) => {
        }
      })
    },
    listComment(){
      let id =  this.edit_data.id
      if(!id)
        id =  this.edit_data.pk
      this.listCommentTree({
        url: undefined,
        organization_id: 1,
        id: id,
        finalizer: (data) => {
          this.list = data.comments.reverse();
        },
        catcher: (error) => {
        }
      })
    },
    close() {
      this.$emit('close')
    },
    apply() {
      this.loading = true
      let url = undefined
      let id =  this.edit_data.id
      if(!id)
        id =  this.edit_data.pk
      this.addCommentTree({
        url: url,
        organization_id: 1,
        data: this.data,
        id: id,
        finalizer: (data) => {
          this.$emit('close')
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
