import selectors from "@/urls/selectors";
import {sendGetRequest} from "@/helper/requests";
import worker from "@/urls/worker";

const state = {
  selectors: {
    country: [],
    phones: [],
    cathedra: [],
    countryCode: [],
    languages: [],
    departments: [],
    faculty: [],
    groups: [],
    territories: [],
    buildings: [],
    rooms: [],
    workers: [],
    reference_departments: [],
    organizations: [],
    st_group_uni : [],
    accountAccounts : null,
    st_group : [],
  },
  choices: {
    contactType: [],
    languageType: [],
  }
}

const getters = {
  accountAccounts: state => state.selectors.accountAccounts,
  getStGroupUni: state => state.selectors.st_group_uni,
  getStGroup: state => state.selectors.st_group,
  getOrganizations: state => state.selectors.organizations,
  getLanguages: state => state.selectors.languages,
  getCountry: state => state.selectors.country,
  getPhones: state => state.selectors.phones,
  getCountryCode: state => state.selectors.countryCode,
  getContactType: state => state.choices.contactType,
  getLanguageType: state => state.choices.languageType,
  getDepartments: state => state.selectors.departments,
  getFaculty: state => state.selectors.faculty,
  getGroups: state => state.selectors.groups,
  getTerritories: state => state.selectors.territories,
  getBuildings: state => state.selectors.buildings,
  getRooms: state => state.selectors.rooms,
  getWorkers: state => state.selectors.workers,
  getReferenceDepartments: state => state.selectors.reference_departments,
}

const mutations = {
  SET_ACCOUNTS: (state, value) => {
    state.selectors.accountAccounts = value
  },
  SET_ST_GROUPS: (state, value) => {
    state.selectors.st_group = value
  },
  SET_ST_GROUP_UNI: (state, value) => {
    state.selectors.st_group_uni = value
  },
  SET_LANGUAGES: (state, value) => {
    state.selectors.languages = value
  },
  SET_PHONES: (state, value) => {
    state.selectors.phones = value
  },
  SET_COUNTRY: (state, value) => {
    state.selectors.country = value
  },
  SET_COUNTRY_CODE: (state, value) => {
    state.selectors.countryCode = value
  },
  SET_CONTACT_TYPE: (state, value) => {
    state.choices.contactType = value
  },
  SET_LANGUAGE_TYPE: (state, value) => {
    state.choices.languageType = value
  },
  SET_DEPARTMENT: (state, value) => {
    state.selectors.departments = value
  },
  SET_FACULTY: (state, value) => {
    state.selectors.faculty = value
  },
  SET_GROUPS: (state, value) => {
    state.selectors.groups = value
  },
  SET_TERRITORIES: (state, value) => {
    state.selectors.territories = value
  },
  SET_BUILDINGS: (state, value) => {
    state.selectors.buildings = value
  },
  SET_ROOMS: (state, value) => {
    state.selectors.rooms = value
  },
  SET_ORGANIZATION: (state, value) => {
    state.selectors.organizations = value
  },
}

const actions = {
  loadAccountAccounts: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.ACCOUNTS.ACCOUNTS()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_ACCOUNTS', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadStGroup: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = selectors.SELECTORS.CHOICES.GROUP()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_ST_GROUPS', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadStGroupUni: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = selectors.SELECTORS.CHOICES.GROUP_UNI()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_ST_GROUP_UNI', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadUserSex: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = selectors.SELECTORS.CHOICES.USERS.SEX()
    sendGetRequest(
      url, finalizer, catcher
    )
  },
  loadUserMarital: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = selectors.SELECTORS.CHOICES.USERS.MARITAL_STATUS()
    sendGetRequest(
      url, finalizer, catcher
    )
  },
  loadCountry: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = selectors.SELECTORS.CONTACTS.COUNTRY()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_COUNTRY', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadPhone: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = selectors.SELECTORS.REFERENCE.PHONE()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_PHONES', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadCountryCode: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.CONTACTS.COUNTRY_CODE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_COUNTRY_CODE', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadLanguages: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.CONTACTS.LANGUAGES()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_LANGUAGES', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadContactType: ({ commit }, { url, finalizer, catcher }) => {
    if (!url) url = selectors.SELECTORS.CHOICES.CONTACT_TYPE()
    sendGetRequest(
      url, (data, url) => {
        commit('SET_CONTACT_TYPE', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadLanguageType: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.CHOICES.LANGUAGE_TYPE()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_LANGUAGE_TYPE', data)
        finalizer(data, url)
      }, catcher
    )
  },
  loadDepartments: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.DEPARTMENTS_LIST(1)
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_DEPARTMENT', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadOrganization: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.ORGANIZATION_LIST()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_ORGANIZATION', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadFaculty: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.FACULTY_LIST(1)
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_FACULTY', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadGroups: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.REFERENCE.GROUPS()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_GROUPS', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadTerritories: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.REFERENCE.TERRITORIES()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_TERRITORIES', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadBuildings: ({ commit }, { gets, finalizer, catcher }) => {
    let url = selectors.SELECTORS.REFERENCE.BUILDINGS(gets)
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_BUILDINGS', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadRooms: ({ commit }, { gets, finalizer, catcher }) => {
    let url = selectors.SELECTORS.REFERENCE.ROOMS(gets)
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_ROOMS', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadReferenceDepartments: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.REFERENCE.DEPARTMENTS()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_REFERENCE_DEPARTMENTS', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  loadWorkers: ({ commit }, { finalizer, catcher }) => {
    let url = selectors.SELECTORS.REFERENCE.WORKERS()
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_WORKERS', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
