<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="save"
    :title="'Нормализация'"
    :scrolled="false"
    :loading="loading"
    :applyable="!result"
  >
    <div v-if="!loading && !result" class="px-3">
      <v-form v-model="valid" ref="form">
        <v-text-field
          v-model="value.new_email"
          label="Новая почта"
          class="mt-3"
          type="email"
          :rules="emailRules"
          clearable
          outlined
        ></v-text-field>
        <v-checkbox
          v-model="value.default_password"
          label="Использовать пароль по умолчанию">
        </v-checkbox>
        <v-checkbox
          v-model="value.sent_notif"
          label="Отправить письмо">
        </v-checkbox>
        <v-alert outlined type="error" v-if="hasError('non_field_errors')">
          {{String(getError('non_field_errors'))}}
        </v-alert>
        <v-alert outlined type="error" v-if="hasError('detail')">
          {{String(getError('detail'))}}
        </v-alert>
      </v-form>
      <v-alert color="info" outlined class="mt-2 text-left">
        При нормализации принудительно происходят следующие действия:
        <ul>
          <li>
            Поиск существующего объекта и восстановление с ним связи. Если объект не найден, то его создают для следующих систем: Active Directory (AD), Accounts (Портал), EIOS-Student (студент в ЭИОС), EIOS-Education (образование в ЭИОС). При наличии объекта его данные обновляются, но только те поля, которые отличаются от информации в системе 1С.
          </li>
          <li>
            Активация аккаунта, назначение ему логина и изменение его статуса в Active Directory, если он не является сотрудником.
          </li>
          <li>
            Активация аккаунта и назначение ему логина на Портале, если он не относится к числу сотрудников.
          </li>
        </ul>
      </v-alert>
    </div>
    <div v-else-if="!loading && result">
      <vue-json-pretty
        style="width: 100%"
        class="text-xs-left"
        :data="result"
        show-line
        show-double-quotes
        collapsed-on-click-brackets
        :deep="1"
      />
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";
import VueJsonPretty from "vue-json-pretty";

export default {
  name: "primaryProcessingDialog",
  props:{
    opened: Boolean,
    edit_data: Object,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, SemipolarSpinner, VueJsonPretty},
  data() {
    return {
      loading:false,
      valid: false,
      result: undefined,
      value: {
        new_email: null,
        default_password: false,
        sent_notif: false,
      },
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail не корректен'
      ]
    }
  },

  methods: {
    ...mapActions({
      changeUserNormalize: "faces/changeUserProcessing",
    }),
    close() {
      this.$emit('close', false)
    },

    save() {
      if (this.$refs.form.validate()){
        let copyData = Object.assign({}, this.value);
        if (!copyData.new_email){
          delete copyData.new_email
        }
        this.loading = true
        this.changeUserNormalize({
          id: this.edit_data.guid,
          data: copyData,
          finalizer: (data) => {
            // this.close()
            this.loading = false
            this.result = data
          },
          catcher: (errors) => {
            this.mapErrors(errors)
            this.loading = false
          }
        })
      }


    },
  },
  created() {
  }
}
</script>

<style scoped>

</style>
