<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <div
      v-if="$route.name === names.REFERENCE.BASE"
    >
      <v-container style="height: 100%">
        <v-card
          flat
          class="mt-4"
        >
          <v-data-iterator
            :items=reference_list
            hide-default-footer
            no-data-text="Нет данных"
          >
            <v-row class="align-center">
              <v-col
                cols="12"
                sm="6"
                lg="4"
                v-for="(item, index) in reference_list"
                :key="`card_${index}`"
              >
                <router-link style="text-decoration: none" :to="{name: item.router.name}">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      min-height="150"
                      :elevation="hover ? 15 : 6"
                      class="ma-3 d-flex transition-ease-in-out"
                      color="#DADADA"
                      style="border-radius: 20px"
                    >
                      <v-container class="my-auto">
                        <v-row class="my-auto">
                          <v-col class="my-auto title">
                            <span style="font-size: 26px">{{ item.title }}</span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-hover>
                </router-link>
              </v-col>
            </v-row>
          </v-data-iterator>
        </v-card>
      </v-container>
    </div>
    <router-view v-else />
  </div>
</template>

<script>

import names from "@/modules/admins/routers/names";

export default {
  name: "Reference",
  components: {},
  data() {
    return {
      reference_list: [
        {title: 'Контакты подразделения', icon: 'account_balance', included: true, router: { name: names.REFERENCE.DEPARTMENT.LIST, params: {}}},
        {title: 'Общие контакты', icon: 'work', included: true, router: { name: names.REFERENCE.COMMON.LIST, params: {}}},
        {title: 'Персональные контакты', icon: 'account_box', included: true, router: { name: names.REFERENCE.PERSONAL.LIST, params: {}}},
        { title: 'Выгрузки', icon: 'move_to_inbox', included: true, router: { name: names.FILE.LIST, params: {}}},
      ]
    }
  },
  computed: {
    names: () => names,
  },
  beforeRouteUpdate(to, from, next){
    next()
    this.$store.commit('mainpage/changeTitle', 'Почтовый справочник');
  },
}
</script>

<style scoped>

</style>
