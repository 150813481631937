<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="count"
      item-key="guid"
      :footer-props="{'items-per-page-options':[25,50,100]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :expanded.sync="expanded"
      single-expand
      show-expand
    >
      <template v-slot:item.fio="{item}">
        <span>{{item.face.fio}}</span>
      </template>
      <template v-slot:item.department="{item}">
        <span>{{item.held.department}}</span>
      </template>
      <template v-slot:item.profession="{item}">
        <span>{{item.held.profession}}</span>
      </template>
      <template v-slot:item.created="{item}">
        <span>{{displayDate(item.created)}}</span>
      </template>
      <template v-slot:item.updated="{item}">
        <td class="body-2 justify-center text-sm-center">
          <span v-if="item.updated">{{displayDate(item.updated)}}</span>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.is_execute="{item}">
        <td class="body-2 justify-center text-sm-center">
          <v-icon v-if="item.is_execute">done</v-icon>
          <v-icon v-else>close</v-icon>
        </td>
      </template>
      <template v-slot:item.executed="{item}">
        <td class="body-2 justify-center text-sm-center">
          <span v-if="item.executed">{{displayDate(item.executed)}}</span>
          <span v-else>-</span>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openRestart(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                cached
              </v-icon>
            </v-btn>
          </template>
          <span>Перезапуск увольнения</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openDeactivate(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                lock
              </v-icon>
            </v-btn>
          </template>
          <span>Деактивация пользователя</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-expand="props">
        <v-btn small icon @click="loadDetail(props)">
          <v-progress-circular v-if="props.item.detail_loading" color="blue" indeterminate></v-progress-circular>
          <v-icon v-else-if="!props.isExpanded">expand_more</v-icon>
          <v-icon v-else>expand_less</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <DismissalExpand :item="posDetail"/>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <apply-dialog
      :loading="loadingRestart"
      :opened="openedRestartDialog"
      :get-text="() => 'Перезапуcтить увольнение с должности?'"
      @apply="closeRestart"
      @close="closeRestart"
      :error="getError('detail')"
    />
    <apply-dialog
      :loading="loadingDeactivate"
      :opened="openedDeactivateDialog"
      :get-text="() => 'Деактивировать пользователя?'"
      @apply="closeDeactivate"
      @close="closeDeactivate"
      :error="getError('detail')"
    />
  </v-container>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import DismissalExpand from "@/modules/booker/positions/dismissal/components/DismissalExpand.vue";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions, mapGetters} from "vuex";
import {displayDate} from "@/helper";

export default {
  name: "ListDismissal",
  components: {DismissalExpand, ApplyDialog},
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      page_title: 'Список увольнений с должности',
      pagination: {},
      expanded: [],
      selected: null,
      errors: {},
      expandable_data: {},
      loadingRestart: false,
      loadingDeactivate: false,
      openedRestartDialog: false,
      openedDeactivateDialog: false,
      search: {
        parameter: '',
      },
      headers: [
        {
          text: 'Работник',
          sortable: false,
          value: 'fio',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Подразделение',
          sortable: false,
          value: 'department',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Должность',
          sortable: false,
          value: 'profession',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Дата увольнения',
          sortable: false,
          value: 'fired',
          align: 'center',
          width: '3%'
        },
        {
          text: 'Дата создания',
          sortable: false,
          value: 'created',
          align: 'center',
          width: '3%'
        },
        {
          text: 'Обновлено',
          sortable: false,
          value: 'updated',
          align: 'center',
          width: '3%'
        },
        {
          text: 'Выполнено',
          sortable: false,
          value: 'is_execute',
          align: 'center',
          width: '3%'
        },
        {
          text: 'Дата выполнения',
          sortable: false,
          value: 'executed',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'center',
          width: '5%'
        },
        {
          text: '',
          sortable: false,
          value: 'data-table-expand',
          align: 'right',
          width: '3%'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      list: "dismissal/getList",
      isLoading: "dismissal/getLoading",
      count: "dismissal/getCount",
      posDetail: "dismissal/getDetail"
    })
  },
  methods: {
    ...mapActions({
      loadList: "dismissal/loadList",
      loadPosDetail: "dismissal/loadDetail",
      restartDismissal: "dismissal/restartDismissal",
      deactivateDismissalAccount: "dismissal/deactivateDismissalAccount"
    }),
    displayDate,
    loadData(url = undefined) {
      this.loadList({
        url: url,
        urlAppend: (url) => {
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    loadDetail(props) {
      if (!props.isExpanded) {
        this.$set(props.item, "detail_loading", true)
        this.loadPosDetail({
          id: props.item.guid,
          finalizer: (data, url) => {
            this.$set(props.item, "detail_loading", false)
            props.expand(!props.isExpanded)
          },
          catcher: (error) => {
            this.$set(props.item, "detail_loading", false)
          }
        })
      } else
        props.expand(!props.isExpanded)
    },
    restartPositionDismissal(item) {
      this.loadingRestart = true
      this.restartDismissal({
        id: item.guid,
        finalizer: () => {
          this.loadingRestart = false
          this.selected = null
          this.openedRestartDialog = false
          this.loadData()
        },
        catcher: (error) => {
          this.loadingRestart = false
          this.mapErrors(error)
        }
      })
    },
    deactivateAccount(item) {
      this.loadingDeactivate = true
      this.deactivateDismissalAccount({
        id: item.guid,
        finalizer: () => {
          this.loadingDeactivate = false
          this.selected = null
          this.openedDeactivateDialog = false
          this.loadData()
        },
        catcher: (error) => {
          this.loadingDeactivate = false
          this.mapErrors(error)
        }
      })
    },
    reloadData() {
      this.loadData()
    },
    openDeactivate(item) {
      this.openedDeactivateDialog = true
      this.selected = item
    },
    closeDeactivate(ev) {
      if (ev) {
        this.deactivateAccount(this.selected)
      } else {
        this.selected = null
        this.openedDeactivateDialog = false
        this.errors = {}
      }
    },
    openRestart(item) {
      this.selected = item
      this.openedRestartDialog = true
    },
    closeRestart(ev) {
      if (ev) {
        this.restartPositionDismissal(this.selected)
      } else {
        this.selected = null
        this.openedRestartDialog = false
        this.errors = {}
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0;
}
</style>
