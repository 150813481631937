<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="apply"
    :title="is_delete ? 'Удалить alias' : 'Добавить alias'"
    :scrolled="false"
    :loading="loading"
    :apply-color="is_delete ? 'red' : 'green'"
    :apply-text="is_delete ? 'Удалить' : 'Добавить'"
    :apply-disable="!(email || is_student_identity)"
  >
    <v-card flat class="pt-4" v-if="is_delete">
      <v-autocomplete
        outlined
        :items="getAdItems"
        :disabled="is_student_identity"
        label="Почта"
        placeholder="Выберите..."
        ref="email"
        required
        v-model="email"
      />
      <v-checkbox
        class="mt-0"
        label="Удалить студенческий идентификатор"
        v-model="is_student_identity"
      />
      <v-alert v-if="hasError('')" outlined type="error">
        {{ getError('') }}
      </v-alert>
    </v-card>
    <v-card flat class="pt-4" v-else>
      <v-text-field
        outlined
        label="Почта"
        v-model="email"
        :disabled="is_student_identity"
      />
      <v-checkbox
        class="mt-0"
        label="Добавить студенческий идентификатор"
        v-model="is_student_identity"
      />
    </v-card>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions} from "vuex";

export default {
  name: "UserUpdateAliasDialog",
  props: {
    value: Boolean,
    opened: Boolean,
    item: Object,
    is_delete: Boolean
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      is_student_identity: false,
      adItems: [],
      email: '',
      loading: false,
      errors: {}
    }
  },
  computed: {
    getAdItems() {
      if (!this.item.full_ad_data.AD.proxyAddresses)
        return []
      return this.item.full_ad_data.AD.proxyAddresses.map((el) => {
        let ar = el.split(':')
        return ar[1]
      })
    }
  },
  methods: {
    ...mapActions({
      updateAlias: 'bookerUsers/updateAlias'
    }),
    close() {
      this.is_student_identity = false
      this.email = ''
      this.$emit('close')
    },
    apply() {
      this.loading = true
      this.updateAlias({
        data: {
          email: String(this.email).replace('@muctr.ru',''),
          is_student_identity: this.is_student_identity,
          remove: this.is_delete
        },
        id: this.item.id,
        finalizer: (data) => {
          this.loading = false
          this.close()
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
