<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="apply"
    :title="'Установка нормализованного логина'"
    :scrolled="false"
    :applyable="false"
    :loading="loading"
  >
    <v-card flat class="pt-4">
      <v-alert outlined type="info" v-if="!is_update_data.can">
        {{is_update_data.detail}}
      </v-alert>
      <v-btn v-else
             outlined
             block color="primary"
             @click.native="apply()">
        установить нормализованный логин
      </v-btn>
    </v-card>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions} from "vuex";

export default {
  name: "UserUpdateAliasDialog",
  props: {
    value: Boolean,
    opened: Boolean,
    item: Object,
    is_delete: Boolean
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      is_update_data:{"can":true,"detail":""},
      loading: false,
      errors: {}
    }
  },
  methods: {
    ...mapActions({
      workerNormalizeLogin: 'bookerUsers/workerNormalizeLogin',
      workerGetNormalizeLogin: 'bookerUsers/workerGetNormalizeLogin'
    }),
    close() {
      this.$emit('close')
    },
    load() {
      this.loading = true
      this.workerGetNormalizeLogin({
        data: {},
        id: this.item.id,
        finalizer: (data) => {
          this.loading = false
          this.close()
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    },
    apply() {
      this.loading = true
      this.workerNormalizeLogin({
        data: {},
        id: this.item.id,
        finalizer: (data) => {
          this.is_update_data = data
          this.loading = false
          this.close()
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    }
  },
  created() {
    this.load()
  }
}
</script>

<style scoped>

</style>
