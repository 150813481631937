<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="save"
    :apply-text="'Синхронизировать'"
    :title="form_title"
    :scrolled="false"
    :loading="loading"
  >
 <v-alert class="mt-2" type="info" outlined>
   Важно! <br>
   После синхронизации все кафедры, не управляемые вручную, будут  обновлены в соответствии с текущей структурой подразделений на момент  проведения синхронизации.
 </v-alert>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "editPersonalDialog",
  props:{
    opened: Boolean,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, SemipolarSpinner },
  data() {
    return {
      loading:false,
      value: {

      },
    }
  },
  watch:{

  },
  computed: {
    form_title() {
      return 'Синхронизация'
    },
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
      syncCathedra: 'cathedra/syncCathedra',
    }),
    close() {
      this.$emit('close', false)
    },
    save() {
      this.syncCathedra({
        finalizer: () => {
          this.close()
        },
        catcher: (errors) => {
          this.mapErrors(errors)
        }
      })
    },
  },
  created() {

  }
}
</script>

<style scoped>

</style>
