<template>
  <scroll-center-dialog
    v-model="opened"
    :title="form_title"
    :loading="loading"
    @close="close"
    @apply="save"
  >
        <v-text-field
          v-model="data.number"
          label="Номер"
          class="pt-3"
          v-mask="'+7(###)###-##-##'"
          :error="hasError('number')"
          :error-messages="getError('number')"
          clearable
          outlined
        ></v-text-field>
        <v-checkbox
          v-model="data.international_calls_allowed"
          label="Разрешены международные звонки"
          :error="hasError('international_calls_allowed')"
          :error-messages="getError('international_calls_allowed')"
          clearable
          outlined
        >
        </v-checkbox>
        <v-checkbox
          v-model="data.long_distance_calls_allowed"
          label="Разрешены междугородние звонки"
          :error="hasError('long_distance_calls_allowed')"
          :error-messages="getError('long_distance_calls_allowed')"
          clearable
          outlined
        >
        </v-checkbox>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "createLanguagesDialog",
  props:{
    opened: Boolean,
    edit: Boolean,
    obj_data: Object,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog},
  data() {
    return {
      loading: false,
      data: {
        name: '',
        international_calls_allowed: false,
        long_distance_calls_allowed: false,
      },
      rules: {
        required: v => !!v || 'Поле обязательно для заполнения.',
      },
    }
  },
  computed: {
    form_title() {
      return !this.obj_data ? 'Добавить номер' : 'Редактировать номер'
    },
  },
  methods: {
    ...mapActions({
      createData: 'phoneReference/createPhone',
      changeData: 'phoneReference/updatePhone',
    }),
    close() {
      this.$emit('close')
    },
    loadDocsData(){
      if(this.obj_data !== undefined){
        this.data = Object.assign({},this.obj_data)
      }
    },
    save(){
      let send = Object.assign({},this.data)
      if (send.id) {
        this.changeData({
          id: send.id,
          data:  send,
          finalizer: (val) => {
            this.$emit('close', send)
          },
          catcher:(val) => {
            this.mapErrors(val)
          },
        })
      }else {
        this.createData({
          data: send,
          finalizer: (val) => {
            this.$emit('close', send)
          },
          catcher:(val) => {
            this.mapErrors(val)
          },
        })
      }
    },
  },
  created() {
    this.loadDocsData()
  }
}
</script>

<style scoped>

</style>
