<template>
  <div class="py-2 my-2">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <span class="title">Дополнительные аккаунты</span>
    </v-toolbar>
    <v-data-table
      :items="getItems"
      :headers="headers"
      hide-default-footer
      class="elevation-2 rounded-b-lg rounded-t-0"
    >
      <template v-slot:item.student_identity="props">
        <span v-if="!!props.item.student_identity">{{props.item.student_identity}}</span>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.is_student="props">
        <v-icon color="teal" v-if="props.item.is_student">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:item.is_employee="props">
        <v-icon color="teal" v-if="props.item.is_employee">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:item.is_graduate="props">
        <v-icon color="teal" v-if="props.item.is_graduate">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:item.is_active="props">
        <v-icon color="teal" v-if="props.item.is_active">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:expanded-item="props">
        <accounts-expand :item="props.item"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "AccountsExpand",
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Пользователь',
          sortable: false,
          value: 'fio',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Логин',
          sortable: false,
          value: 'username',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Студенческий идентификатор',
          sortable: false,
          value: 'student_identity',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Почта',
          sortable: false,
          value: 'email',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Студент',
          sortable: false,
          value: 'is_student',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Работник',
          sortable: false,
          value: 'is_employee',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Выпускник',
          sortable: false,
          value: 'is_graduate',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Активен',
          sortable: false,
          value: 'is_active',
          align: 'center',
          width: '5%'
        },
      ],
    }
  },
  computed: {
    getItems() {
      return this.item.accounts.filter(item => !item.is_origin)
    }
  },
}
</script>

<style scoped>

</style>
