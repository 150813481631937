import booker_urls from "@/urls/booker";
import {sendGetRequest, sendPostRequest} from "@/helper/requests";

const state = {
  dismissal: {
    list: [],
    loading: false,
    count: 0,
    detail: {}
  }
}

const getters = {
  getList: (state) => state.dismissal.list,
  getCount: (state) => state.dismissal.count,
  getLoading: (state) => state.dismissal.loading,
  getDetail: (state) => state.dismissal.detail
}

const actions = {
  loadList({commit}, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) {
    commit('SET_LOADING')
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_DISMISSAL_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_LIST', data['results'])
        commit('SET_COUNT', data['count'])
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  loadDetail({commit}, {id, finalizer = () => {}, catcher = () => {}}) {
    let url = booker_urls.BOOKER.OFFICIAL_DISMISSAL_DETAIL(id)
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_DETAIL', data)
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  restartDismissal({commit}, {id, finalizer = () => {}, catcher = () => {}}) {
    let url = booker_urls.BOOKER.OFFICIAL_DISMISSAL_RESTART(id)
    sendPostRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
  deactivateDismissalAccount({commit}, {id, finalizer = () => {}, catcher = () => {}}) {
    let url = booker_urls.BOOKER.OFFICIAL_DISMISSAL_DEACTIVATE_ACCOUNT(id)
    sendPostRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error) => {
        catcher(error)
      }
    )
  },
}

const mutations = {
  SET_LIST: (state, list) => {
    state.dismissal.list = list
  },
  SET_LOADING: (state) => {
    state.dismissal.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.dismissal.loading = false
  },
  SET_COUNT: (state, count) => {
    state.dismissal.count = count
  },
  SET_DETAIL: (state, data) => {
    state.dismissal.detail = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
