import FormErrorMixin from "@/mixins/FormErrorsMixin";
import moment from "moment";
// import urls from "@/modules/organization/urls";
// import {makeGetRequest, makePostRequest} from "@/helper";
// import VueJsonPretty from 'vue-json-pretty'
// import 'vue-json-pretty/lib/styles.css'
import userActionFormTemplate from "@/modules/booker/users/components/UserActionFormTemplate";
import {mapActions} from "vuex";

export default {
  name: "WorkerActionFormMixin",
  mixins: [FormErrorMixin],
  components: {
    userActionFormTemplate
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      process: {
        loading: false,
        executed: false,
        success: null,
        log: {},
        result: {},
      },
      can_process: {
        loading: false,
        can: false,
        detail: null,
      },
      data: {},
      url: undefined,
      error: undefined,
      rules: {
        required: value => !!value || 'Пустое поле',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Не верный почтовый ящик'
        },
        noMuctr: value => {
          const pattern = /^(?!.*@muctr.ru).*$/
          return pattern.test(value) || 'Почта в домене @muctr.ru не допустима'
        },
      }
    }
  },
  watch: {
    open: function (){
      if(this.open){
        this.checkCan()
      }else{
        setTimeout(()=>{this.can_process.can = false;}, 500)
      }
    }
  },
  methods: {
    ...mapActions({
      checkActionDialog: 'bookerUsers/checkActionDialog',
      doActionDialog: 'bookerUsers/doActionDialog'
    }),
    cleanData: function () {
      this.data = {};
    },
    mapResult: function (result) {
      this.process.result = result;
    },
    cleanResult: function () {
      this.process.result = {};
    },
    clean: function () {
      this.can_process.can = false;
      this.can_process.detail = null;
      this.can_process.loading = false;
      this.process.loading = false;
      this.process.executed = null;
      this.process.success = null;
      this.process.log = null;
      this.cleanData();
      this.cleanResult();
      this.non_field_errors = [];
    },
    refresh: function () {
      this.clean();
      this.checkCan();
    },
    exit: function (){
      if(!this.process.loading){
        this.$emit('update:open', false)
        this.$emit('update:item', {})
        setTimeout(() => {
          this.clean()
        }, 250)
      }
    },
    afterCan(result) {

    },
    checkCan(){
      this.can_process.loading = true;
      this.checkActionDialog({
        url: this.url,
        finalizer: (data) => {
          this.can_process.loading = false;
          this.can_process.can = data['can'];
          this.can_process.detail = data['detail'];
          this.afterCan(data);
        },
        catcher: (error) => {
          this.can_process.loading = false;
          this.error = error
        }
      })
    },
    checkSuccess(data){
      return data.success;
    },
    checkExecuted(data){
      return true;
    },
    getLog(data){
      return data['log']
    },
    action(){
      if(this.$refs['form'].validate()){
        this.errors.non = [];
        this.process.loading = true;
        this.doActionDialog({
          url: this.getUrl,
          data: this.data,
          finalizer: (data) => {
            this.process.success = this.checkSuccess(data);
            this.process.log = this.getLog(data);
            this.mapResult(data);
            this.process.executed = this.checkExecuted(data);
            this.process.loading = false;
            this.$emit('refresh')
          },
          catcher: (error) => {
            this.process.loading = false;
            this.process.executed = true;
            this.process.success = false;
            this.process.log = error
          }
        })
      }
    }
  }
}
