import {checkIsProductionServer} from "@/helper";

const state = {
    // enabled: false,
    mainInstances: [
        {
          title: 'Управление аккаунтом',
          href: 'https://portal.muctr.ru/',
        },
    ],
    secondInstances: [
      {
        name: 'allServices',
        title: 'Все сервисы',
        enabled: false,
        url: 'https://portal.muctr.ru/tools',
      },
      {
        name: 'muctr',
        title: 'Официальный сайт',
        enabled: false,
        url:'https://muctr.ru/'
      },
      {
        name: 'mail',
        title: 'Корпоративная почта',
        enabled: false,
        url:'https://post.muctr.ru/'
      },
      {
        name: 'paymuctr',
        title: 'Оплата услуг университета',
        enabled: false,
        url:'https://pay.muctr.ru/'
      },
      {
        name: 'queue',
        title: 'Очередь на мат. помощь',
        enabled: false,
        url:'https://queue.muctr.ru/'
      },
      {
        name: 'DissBreadcrumbs',
        title: 'Диссоветы',
        url:'https://council.muctr.ru/',
        enabled: false,
      },
    ],
    userActions: [
      {
        title: "Управление аккаунтом",
        url: checkIsProductionServer() ? 'https://portal.muctr.ru/' : 'https://portal-test.muctr.ru/',
      },
    ],

    title: 'Интерфейс администратора',
    userAvatar: null,
    is_page_loading: false,
};

const mutations = {
    changeTitle(state, title) {
        state.title = title
    },
    changeAvatar(state, Avatar) {
        state.userAvatar = Avatar
    },
    setStartLoading(state) {
        state.is_page_loading = true
    },
    setEndRLoading(state) {
        state.is_page_loading = false
    }
};


export default {
    namespaced: true,
    state,
    mutations,
}
