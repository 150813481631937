<template>
  <v-dialog v-model="open" max-width="1000" min-width="400" persistent scrollable>
    <v-card min-width="400" rounded="lg">
      <v-toolbar color="primary" class="elevation-1">
        <v-toolbar-title class="white--text">
          Активация нового пользователя
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="exit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <template v-if="can_approve_process.loading">
        <v-row no-gutters justify="center" align="center" style="height: 30vh">
          <semipolar-spinner
            :animation-duration="2000"
            :size="$vuetify.breakpoint.mdAndUp ? 160 : 60"
            color="#1976D2"
          />
        </v-row>
      </template>
      <template v-else-if="!can_approve_process.loading && can_approve_process.can">
        <v-card-text class="text-xs-left subheading" v-if="!approve_process.executed" style="max-height: calc(100vh - 200px)">
          <v-form ref="form">
            <v-layout>
              <v-flex class="text-h5">
                Активации и предоставления аккаунта пользователю:
              </v-flex>
            </v-layout>
            <v-layout class="my-2">
              <v-flex class="font-weight-bold">
                {{item.lastname}} {{item.firstname}} {{item.midname}}
              </v-flex>
            </v-layout>
            <v-text-field
              :rules="[rules.required, rules.email, rules.noMuctr]"
              :error="hasError('email')"
              :error-messages="getError('email')"
              label="E-mail"
              v-model="data.email"
            >
              <span slot="label">Почта<span class="red--text">*</span>:</span>
            </v-text-field>
            <v-alert type="info" outlined>
              Внешняя почта, необходимая для восстановления пользовательского доступа в систему
            </v-alert>
            <v-switch v-model="data.default_password" label="Установить стандартный пароль"></v-switch>
            <v-slide-y-transition>
              <v-alert v-if="data.default_password" type="warning" outlined>
                Пароль по умолчанию Pwd*12345678
              </v-alert>
            </v-slide-y-transition>
            <v-btn style="width: 100%" class="mx-0" outlined @click="approve_process.extra = !approve_process.extra">
              <span v-if="!approve_process.extra">Показать</span><span v-else>Скрыть </span><span class="ml-1">дополнительные настройки</span>
            </v-btn>
            <v-slide-y-transition>
              <v-card flat v-show="approve_process.extra">
                <v-switch
                  v-model="data.exist_ad" label="Восстановить связь с АД"
                  hint="Данная опция предполагает замену операции создания аккаунта в АД на операцию восстановления связи"
                  :persistent-hint="!data.exist_ad"
                ></v-switch>
                <v-slide-y-transition>
                  <v-alert v-if="data.exist_ad" type="warning" outlined>
                    !!! Только если AD ACCOUNT уже существует<br/>
                    Операция создания аккаунта AD будет заменена на операцию восстановления.
                  </v-alert>
                </v-slide-y-transition>
                <v-switch
                  v-model="data.exist_portal" label="Восстановить связь с порталом"
                  hint="Данная опция предполагает замену операции создания аккаунта в PORTAL ACCOUNTS на операцию восстановления связи"
                  :persistent-hint="!data.exist_portal"
                ></v-switch>
                <v-slide-y-transition>
                  <v-alert v-if="data.exist_portal" type="warning" outlined>
                    !!! Только если PORTAL ACCOUNT уже существует<br/>
                    Операция создания аккаунта Portal будет заменена на операцию восстановления.
                  </v-alert>
                </v-slide-y-transition>
                <v-switch
                  v-model="data.exist_worker" label="Восстановить связь с работником портала"
                  hint="Данная опция предполагает замену операции создания работника в PORTAL ORGANIZATION на операцию восстановления связи"
                  :persistent-hint="!data.exist_worker"
                ></v-switch>
                <v-slide-y-transition>
                  <v-alert v-if="data.exist_worker" type="warning" outlined>
                    !!! Только если ORGANIZATION WORKER уже существует<br/>
                    Операция создания работника WORKER будет заменена на операцию восстановления.
                  </v-alert>
                </v-slide-y-transition>
              </v-card>
            </v-slide-y-transition>
          </v-form>
        </v-card-text>
        <v-card-text v-if="approve_process.executed">
          <v-alert type="warning" outlined class="text-xs-left" v-if="!approve_process.success">
            Во время процесса подтверждения аккаунта произошел сбой.
            <br>Лог операции:<br>
            <div style="max-height: calc(100vh - 300px); overflow-y: scroll;">
              <vue-json-pretty
                style="width: 100%"
                class="text-xs-left"
                :data="approve_process.log"
                show-line
                show-double-quotes
                collapsed-on-click-brackets
                :deep="2"
              ></vue-json-pretty>
            </div>
            <!--            <code style="width: 350px; height: calc(100vh - 300px); overflow-x: scroll; overflow-y: scroll" class="text-xs-left" v-html="approve_process.format_log()"></code>-->
          </v-alert>
          <v-alert type="success" class="text-xs-left" v-else>
            Пользователь успешно подтвержден
            <br>Логин: {{ approve_process.username }}
            <br>Пароль: {{ approve_process.password }}
            <br>На почту {{ approve_process.email }} выслано информационное письмо
            <br>Пользователю при первом входе на рабочей станции или в портале необходимо будет сменить пароль.
            <br>Время действия одноразового пароля не ограничено.
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="!approve_process.executed">
          <v-flex>
            <v-btn @click="approveUser" color="warning" :loading="approve_process.loading" class="mx-1">
              Активировать аккаунт
            </v-btn>
            <v-btn @click="exit" :loading="approve_process.loading" class="mx-1">
              Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </template>
      <v-card-text v-else-if="!error">
        <v-alert class="ma-3" type="warning" outlined>
          Данный аккаунт уже подтвержден. при необходимости повторного предоставления доступа к аккаунту пользователя воспользуйтесь функционалом регенерации.
        </v-alert>
      </v-card-text>
      <v-card-text v-else>
        <v-alert class="ma-3" type="error" outlined>
          При получении данных возникла ошибка. Повторите попытку позднее.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import FormErrorMixin from "@/mixins/FormErrorsMixin";
import { SemipolarSpinner } from "epic-spinners"
import {mapActions} from "vuex";

export default {
  name: "UserRegisterDialog",
  mixins: [FormErrorMixin],
  components: {
    VueJsonPretty,
    SemipolarSpinner
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      approve_process: {
        loading: false,
        executed: false,
        success: null,
        log: {},
        username: null,
        password: null,
        email: null,
        extra: false,
      },
      can_approve_process: {
        loading: false,
        can: false,
      },
      data: {
        email: this.item.email || '',
        default_password: false,
        exist_ad: false,
        exist_portal: false,
        exist_worker: false,
      },
      error: undefined,
      rules: {
        required: value => !!value || 'Пустое поле',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Не верный почтовый ящик'
        },
        noMuctr: value => {
          const pattern = /^(?!.*@muctr.ru).*$/
          return pattern.test(value) || 'Почта в домене @muctr.ru не допустима'
        },
      }
    }
  },
  watch: {
    open: function (){
      if(this.open){
        this.checkCanApprove()
      }else{
        setTimeout(()=>{this.can_approve = false;}, 500)

      }
    }
  },
  methods: {
    ...mapActions({
      checkCanRegister: 'bookerUsers/checkCanRegister',
      doRegisterUser: 'bookerUsers/doRegisterUser'
    }),
    exit: function (){
      if(!this.approve_process.loading){
        this.$emit('update:open', false)
        this.$emit('update:item', {})
        setTimeout(() => {
          this.can_approve_process.can = null;
          this.approve_process.loading = false;
          this.approve_process.executed = null;
          this.approve_process.success = null;
          this.approve_process.log = null;
          this.approve_process.username = null;
          this.approve_process.password = null;
          this.approve_process.email = null;
          this.data.email = this.item.email;
          this.data.default_password = null;
          this.error = undefined;
          this.field_errors = {};
        }, 250)
      }
    },
    checkCanApprove(){
      let url = undefined;
      this.can_approve_process.loading = true;
      this.checkCanRegister({
        url: url,
        id: this.item.id,
        finalizer: (data, url) => {
          this.can_approve_process.loading = false;
          this.can_approve_process.can = data['can'];
        },
        catcher: (error, url) => {
          this.can_approve_process.loading = false;
          this.can_approve_process.can = false;
          this.error = error;
        }
      })
    },
    approveUser(){
      if(this.$refs['form'].validate()){
        let url = undefined;
        this.approve_process.loading = true;
        this.doRegisterUser({
          url: url,
          id: this.item.id,
          data: this.data,
          finalizer: (data, url) => {
            this.approve_process.success = data.success
            this.approve_process.log = data['log']
            this.approve_process.username = data.account_username
            this.approve_process.password = data.password
            this.approve_process.email = data.to_email
            this.approve_process.loading = false;
            this.approve_process.executed = true;
          },
          catcher: (error, url) => {
            this.approve_process.loading = false;
            this.approve_process.executed = true;
            this.approve_process.success = false;
            this.approve_process.log = error
          }
        })
      //   let url = urls.getUrl(
      //     'booker|official-worker-register', 'register'
      //   ).replace('<id>', this.item.id)
      //   makePostRequest(url, this.data).then(resp=>{
      //     // console.logs(resp)
      //     if(resp.ok) {
      //       // console.log('SUCCESS by Status')
      //       return resp.json().then(json => {
      //         this.approve_process.success = json.success
      //         this.approve_process.log = json['log']
      //         this.approve_process.username = json.account_username
      //         this.approve_process.password = json.password
      //         this.approve_process.email = json.to_email
      //         this.approve_process.executed = true;
      //         this.approve_process.loading = false;
      //         // console.log(this.approve_process)
      //         // console.log('SUCCESS')
      //       })
      //     } else {
      //       // console.log('ERROR by Status')
      //       if(resp.statusCode >= 500){
      //         return resp.json().then(json => {
      //           this.approve_process.loading = false;
      //           this.approve_process.executed = true;
      //           this.approve_process.success = false;
      //           this.approve_process.log = json
      //         })
      //       } else {
      //         return resp.json().then(json => {
      //           this.field_errors = json
      //           this.approve_process.executed = false;
      //           this.approve_process.loading = false;
      //         })
      //       }
      //     }
      //   }).catch(resp => {
      //     // console.log('ERROR by catch')
      //     this.approve_process.loading = false;
      //     this.approve_process.executed = true;
      //     this.approve_process.success = false;
      //     this.approve_process.log = 'Пришел полностью пустой ответ.'
      //   })
      //   // console.log(url)
      }
    }
  },
}
</script>

<style scoped>

</style>
