<template>
  <v-container>
    <v-container
      class="text-left pa-0 my-2"
      v-if="!!object && !!object.full_ad_data && !!object.full_ad_data.AD"
    >
      <v-row no-gutters>
        <template v-for="row in rows">
          <template v-for="col in row">
            <v-col :key="`col_${col.field}`" lg="6" cols="12" sm="12">
            <span class="md2">
                {{ col.title }}:
              </span>
              <span class="md4" v-if="col.type === 'default'">
                {{ object.full_ad_data.AD[col.field] }}
              </span>
              <span class="md4" v-else-if="col.type === 'date'">
                {{ displayDate(object.full_ad_data.AD[col.field]) }}
              </span>
              <span class="md4" v-else-if="col.type === 'replace'">
                {{ replaceData(object.full_ad_data.AD[col.field]) }}
              </span>
              <span class="md4" v-if="col.type === 'custom' && col.field === 'organization'">
                {{ object.organization.name }}
              </span>
            </v-col>
          </template>
        </template>
      </v-row>
      <v-btn
        color="primary"
        outlined
        small
        class="my-1"
        @click="openNormalize"
        :loading="loadingNormalize"
      >
        Нормализация положения
      </v-btn>
      <v-btn
        color="primary"
        outlined
        small
        class="ml-1 "
        @click="openLoginNormalize()"
      >
        Установка логина
      </v-btn>
      <v-btn
        color="primary"
        outlined
        small
        class="my-1 mx-1"
        @click="openUpdateAlias(false)"
        :loading="loadingNormalize"
      >
        Добавить alias
      </v-btn>
      <v-btn
        color="primary"
        outlined
        :disabled="!object.full_ad_data.AD.proxyAddresses"
        small
        class="my-1"
        @click="openUpdateAlias(true)"
        :loading="loadingNormalize"
      >
        Удалить alias
      </v-btn>
      <v-spacer/>
      Полные данные аккаунта АД:
      <v-btn outlined small @click="expands.ad = !expands.ad">
        <v-icon v-if="!expands.ad">expand_more</v-icon>
        <v-icon v-else>expand_less</v-icon>
        Раскрыть
      </v-btn>
      Список Alias пользователя:
      <v-btn outlined small @click="expands.alias = !expands.alias">
        <v-icon v-if="!expands.alias">expand_more</v-icon>
        <v-icon v-else>expand_less</v-icon>
        Раскрыть
      </v-btn>
      <v-expand-transition mode="out-in">
        <vue-json-pretty
          v-show="expands.ad"
          style="width: 100%"
          class="text-xs-left"
          :data="object.full_ad_data.AD"
          show-line
          show-double-quotes
          collapsed-on-click-brackets
          :deep="1"
        ></vue-json-pretty>
      </v-expand-transition>
      <v-expand-transition mode="out-in">
        <vue-json-pretty
          v-show="expands.alias"
          style="width: 100%"
          class="text-xs-left"
          :data="getUserAliases"
          show-line
          show-double-quotes
          collapsed-on-click-brackets
          :deep="1"
        ></vue-json-pretty>
      </v-expand-transition>
    </v-container>
    <v-container v-else>
<!--      <v-divider></v-divider>-->
      К сожалению аккаунт для пользователя еще не был создан на АД.
    </v-container>
    <v-container v-if="object.positions.length !== 0">
      <v-divider></v-divider>
      <template class="pt-3" v-for="item in object.positions">
        <v-container :key="`item_${item.department}`">
        <v-row :key="`item_${item.department}`" no-gutters class="text-left">
          <v-col cols="12" sm="6">
            <strong>Подразделение: </strong>{{ item.department }}
          </v-col>
          <v-col cols="12" sm="6">
            <strong>Дата начала работы: </strong>{{ displayDate(item.start_work) }}
          </v-col>
        </v-row>
        <v-row :key="`item_${item.position_name}`" no-gutters class="text-left">
          <v-col cols="12" sm="6">
            <strong>Должность:</strong> {{ item.position_name }}
          </v-col>
          <v-col cols="12" sm="6">
            <strong>Должность по booker:</strong> {{ item.title }}
          </v-col>
        </v-row>
        <v-row :key="`item_${item.type}`" no-gutters class="text-left">
          <v-col cols="12" sm="6">
            <strong>Тип должности:</strong> {{ item.type }}
          </v-col>
          <v-col cols="12" sm="6">
            <strong>Ставка:</strong> {{ item.rate }}
          </v-col>
        </v-row>
        </v-container>
        <v-divider></v-divider>
      </template>
    </v-container>
    <apply-dialog
      v-if="openedNormalize"
      :opened="openedNormalize"
      @close="normalize"
      @apply="normalize"
    >
      <v-alert
        outlined
        type="warning"
      >
        Внимание! Нормализация положения пользователя вызовет изменение данных в AD!
      </v-alert>
    </apply-dialog>
    <user-update-alias-dialog
      :opened="openedUpdateAlias"
      :is_delete="isDeleteAlias"
      :item="object"
      @close="closeAlias"
    />
    <UserLoginNormilaze
      :opened.sync="openedLogin"
      :item.sync="object"
      @close="closeAlias"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import VueJsonPretty from "vue-json-pretty"
import {mapActions} from "vuex";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import UserUpdateAliasDialog from "@/modules/booker/users/components/UserUpdateAliasDialog.vue";
import UserChangeUsernameDialog from "@/modules/booker/users/components/UserChangeUsernameDialog.vue";
import UserLoginNormilaze from "@/modules/booker/users/components/UserLoginNormilaze.vue";

export default {
  name: "UserExpanded",
  components: {
    UserLoginNormilaze,
    UserUpdateAliasDialog,
    ApplyDialog,
    VueJsonPretty
  },
  props: {
    object: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expands: {
        ad: false,
        alias: false,
      },
      isDeleteAlias: false,
      openedUpdateAlias: false,
      openedNormalize: false,
      openedLogin: false,
      loadingNormalize: false,
      rows: [
        [
          {
            title: "Логин АД",
            field: "SamAccountName",
            type: "default"
          },
          {
            title: "Активен",
            field: "Enabled",
            type: "default"
          }
        ],
        [
          {
            title: "Имя объекта АД",
            field: "Name",
            type: "default"
          },
          {
            title: "Отображаемое имя",
            field: "DisplayName",
            type: "default"
          }
        ],
        [
          {
            title: "Подразделение",
            field: "Department",
            type: "default"
          },
          {
            title: "Должность",
            field: "Title",
            type: "default"
          }
        ],
        [
          {
            title: "Создан",
            field: "whenCreated",
            type: "date"
          },
          {
            title: "Изменен",
            field: "whenChanged",
            type: "date"
          }
        ],
        [
          {
            title: "Последний вход",
            field: "LastLogonDate",
            type: "date"
          },
          {
            title: "Путь",
            field: "DistinguishedName",
            type: "replace"
          }
        ],
        [
          {
            title: "Основной адрес",
            field: "UserPrincipalName",
            type: "default"
          },
          {
            title: 'Организация',
            field: 'organization',
            type: 'custom',
          }
        ]
      ]
    }
  },
  computed: {
    getUserAliases() {
      if (!this.object.full_ad_data.AD.proxyAddresses)
        return []
      return this.object.full_ad_data.AD.proxyAddresses.map((el) => {
        let ar = el.split(':')
        return ar[1]
      })
    }
  },
  methods: {
    ...mapActions({
      normalizeWorkerAd: "bookerUsers/normalizeWorker"
    }),
    displayDate: function (date) {
      if (!date) return  ''
      return moment(date).format("DD.MM.YYYY")
    },
    // displayMSDate: function (date) {
    //   if (!date) return  ''
    //   // date = (date.replace('/Date(', '').replace(')/', ''))
    //   // return moment.unix(date.substr(0, 10)).format("DD.MM.YYYY")
    // },
    replaceData (data) {
      return data.replaceAll(",", ", ")
    },
    openNormalize() {
      this.openedNormalize = true
    },
    openLoginNormalize() {
      this.openedLogin = true
    },
    normalize (ev) {
      if (ev) {
        this.loadingNormalize = true
        this.normalizeWorkerAd({
          id: this.object.id,
          finalizer: () => {
            this.loadingNormalize = false
          },
          catcher: () => {
            this.loadingNormalize = false
          }
        })
      }
      this.openedNormalize = false
    },
    openUpdateAlias(isDelete) {
      if (isDelete) {
        this.isDeleteAlias = isDelete
        this.openedUpdateAlias = true
      } else {
        this.openedUpdateAlias = true
      }
    },
    closeAlias() {
      this.isDeleteAlias = false
      this.openedLogin = false
      this.openedUpdateAlias = false
    },
  },
}
</script>

<style scoped>

</style>
