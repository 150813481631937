import {sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest, sendPutRequest} from "@/helper/requests";
import reference from "@/urls/admin";

const state = {
  geo: {
    currentUrl: null,
    list: [],
    loading: null,
    count: 0,
    detail: [],
  }
};

const getters= {
  getGeoList: (state) => state.geo.list,
  getGeoDetail: state => state.geo.detail,
  getGeoCurrentUrl: state => state.geo.currentUrl,
  getGeoListCount: (state) => state.geo.count,
  isGeoListLoading: (state) => state.geo.loading,
};

const actions= {
  loadGeoList: ({commit}, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.GEO.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isLoading || getters.getGeoCurrentUrl !== url) {
      commit('SET_LOADING')
      sendGetRequest(
        url,
        (data, url) => {
          commit('SET_GEO_LIST', data['results'])
          commit('SET_GEO_COUNT', data['count'])
          commit('SET_GEO_CURRENT_URL', url)
          commit('SET_NOT_LOADING')
          finalizer(data, url)
        },
        (error) => {
          commit('SET_NOT_LOADING')
          catcher(error)
        }
      )
    }
  },
  loadGeoDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.GEO.DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_GEO_DETAIL', data);
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createGeo: ({commit}, {url, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.GEO.CREATE()
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  setDefaultGeo: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.GEO.DEFAULT(id)
    }
    sendPostRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateGeo: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.GEO.UPDATE(id)
    }
    sendPutRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deleteGeo: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.GEO.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations= {
  SET_GEO_LIST: (state, list) => {
    state.geo.list = list
  },
  SET_GEO_DETAIL: (state, value) => {
    state.geo.detail = value
  },
  SET_GEO_CURRENT_URL: (state, value) => {
    state.geo.currentUrl = value
  },
  SET_LOADING: (state) => {
    state.geo.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.geo.loading = false
  },
  SET_GEO_COUNT: (state, count) => {
    state.geo.count = count
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
