const BOOKER = {
  BASE: 'Booker',
  MAIN: undefined,
  USERS: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  DEPARTMENT: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  LOGS: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  ACCEPTANCES: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  TRANSFERS: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  },
  DISMISSAL: {
    BASE: undefined,
    LIST: undefined,
    DETAIL: undefined
  }
}

BOOKER.MAIN = `${BOOKER.BASE}|Main`

BOOKER.USERS.BASE = `${BOOKER.BASE}|Users`
BOOKER.USERS.LIST = `${BOOKER.USERS.BASE}|List`
BOOKER.USERS.DETAIL = `${BOOKER.USERS.BASE}|Detail`

BOOKER.DEPARTMENT.BASE = `${BOOKER.BASE}|Department`
BOOKER.DEPARTMENT.LIST = `${BOOKER.DEPARTMENT.BASE}|List`
BOOKER.DEPARTMENT.DETAIL = `${BOOKER.DEPARTMENT.BASE}|Detail`

BOOKER.LOGS.BASE = `${BOOKER.BASE}|Logs`
BOOKER.LOGS.LIST = `${BOOKER.LOGS.BASE}|List`
BOOKER.LOGS.DETAIL = `${BOOKER.LOGS.BASE}|Detail`

BOOKER.ACCEPTANCES.BASE = `${BOOKER.BASE}|Acceptances`
BOOKER.ACCEPTANCES.LIST = `${BOOKER.ACCEPTANCES.BASE}|List`
BOOKER.ACCEPTANCES.DETAIL = `${BOOKER.ACCEPTANCES.BASE}|Detail`

BOOKER.TRANSFERS.BASE = `${BOOKER.BASE}|Transfers`
BOOKER.TRANSFERS.LIST = `${BOOKER.TRANSFERS.BASE}|List`
BOOKER.TRANSFERS.DETAIL = `${BOOKER.TRANSFERS.BASE}|Detail`

BOOKER.DISMISSAL.BASE = `${BOOKER.BASE}|Dismissal`
BOOKER.DISMISSAL.LIST = `${BOOKER.DISMISSAL.BASE}|List`
BOOKER.DISMISSAL.DETAIL = `${BOOKER.DISMISSAL.BASE}|Detail`
export default {
  ...BOOKER
}
