import { ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, SIGNER_INSTANCE, REFERENCE_INSTANCE } from '@/helper/consts'
import Breadcrumbs from '@/modules/admins/Breadcrumbs'
import UsersBreadcrumbs from '@/modules/admins/users/Base.vue'
import AccountsBreadcrumbs from '@/modules/admins/accounts/Base.vue'
import UsersDetail from '@/modules/admins/users/pages/Detail'
import names from '@/modules/admins/routers/names'
import LanguagesBreadcrumbs from "@/modules/admins/languages/Base.vue";
import Tree from "@/modules/admins/tree/Base.vue";
import CountriesBreadcrumbs from "@/modules/admins/countries/Base.vue";
import FacultyBreadcrumbs from "@/modules/admins/faculty/Base.vue";
import GeoBreadcrumbs from "@/modules/admins/geo/Base.vue";
import SignerFilesBreadcrumbs from "@/modules/admins/signer/files/Base.vue";
import SignerLogsBreadcrumbs from "@/modules/admins/signer/logs/Base.vue";
import ReferenceBreadcrumbs from "@/modules/admins/reference/Base.vue";
import ReferenceDepartment  from "@/modules/admins/reference/pages/department/List.vue";
import ReferenceCommon  from "@/modules/admins/reference/pages/common/List.vue";
import ReferencePersonal from "@/modules/admins/reference/pages/personal/List.vue";
import ReferencePhones from "@/modules/admins/reference/pages/phone/List.vue";
import CathedraBreadcrumbs from "@/modules/admins/cathedra/Base.vue";
import FileBreadcrumbs from "@/modules/admins/reference/pages/file/Base.vue";
import FileDetail from "@/modules/admins/reference/pages/file/pages/Detail.vue";
import Session from "@/modules/admins/users/pages/Session.vue";
import SessionAdmin from "@/modules/admins/users/pages/SessionAdmin.vue";
export default ([
  {
    path: '/admin',
    alias: '/',
    redirect: '/admin/users',
    name: names.MAIN,
    component: Breadcrumbs,
    meta: {
      breadcrumb: 'Администрирование',
      requiredAuth: true,
      instances: [
        ACCOUNTS_INSTANCE
      ]
    },
    children: [
      {
        path: 'users',
        name: names.USERS.LIST,
        component: UsersBreadcrumbs,
        meta: {
          breadcrumb: 'Список пользователей',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE
          ]
        },
        children: [
          {
            path: ':id',
            name: names.USERS.DETAIL,
            component: UsersDetail,
            meta: {
              breadcrumb: 'Детальное представление',
              requiredAuth: true,
              instances: [
                ACCOUNTS_INSTANCE
              ]
            }
          }
        ]
      },
      {
        path: 'accounts',
        name: names.ACCOUNTS.LIST,
        component: AccountsBreadcrumbs,
        meta: {
          breadcrumb: 'Список связанных аккаунтов',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE
          ]
        },
        children: [
          // {
          //   path: ':id',
          //   name: names.ACCOUNTS.DETAIL,
          //   component: UsersDetail,
          //   meta: {
          //     breadcrumb: 'Детальное представление',
          //     requiredAuth: true,
          //     instances: [
          //       ACCOUNTS_INSTANCE
          //     ]
          //   }
          // }
        ]
      },
      {
        path: 'session',
        name: names.USERS.SESSION,
        component: Session,
        meta: {
          breadcrumb: 'Текущие сеансы',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        }
      },
      {
        path: 'session-admin',
        name: names.USERS.SESSION_ADMIN,
        component: SessionAdmin,
        meta: {
          breadcrumb: 'Текущие сеансы администраторов',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        }
      },
      {
        path: 'signer_files',
        name: names.SIGNER_FILES.LIST,
        component: SignerFilesBreadcrumbs,
        meta: {
          breadcrumb: 'Файлы ЭЦП',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, SIGNER_INSTANCE
          ]
        }
      },
      {
        path: 'signer_logs',
        name: names.SIGNER_LOGS.LIST,
        component: SignerLogsBreadcrumbs,
        meta: {
          breadcrumb: 'Логи ЭЦП',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, SIGNER_INSTANCE
          ]
        }
      },
      {
        path: 'languages',
        name: names.LANGUAGES.LIST,
        component: LanguagesBreadcrumbs,
        meta: {
          breadcrumb: 'Список языков',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'faculty',
        name: names.FACULTY.LIST,
        component: FacultyBreadcrumbs,
        meta: {
          breadcrumb: 'Список факультетов',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE,ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'geo',
        name: names.GEO.LIST,
        component: GeoBreadcrumbs,
        meta: {
          breadcrumb: 'Список кругов',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE,ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'cathedra',
        name: names.CATHEDRA.LIST,
        component: CathedraBreadcrumbs,
        meta: {
          breadcrumb: 'Список кафедр',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE,ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'countries',
        name: names.COUNTRIES.LIST,
        component: CountriesBreadcrumbs,
        meta: {
          breadcrumb: 'Список стран',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'tree',
        name: names.TREE.LIST,
        component: Tree,
        meta: {
          breadcrumb: 'Дерево подразделений',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE,
            ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'reference',
        name: names.REFERENCE.BASE,
        component: ReferenceBreadcrumbs,
        meta: {
          breadcrumb: 'Почтовый справочник',
          requiredAuth: true,
          instances: [
            REFERENCE_INSTANCE
          ]
        },
        children: [
          {
            path: 'files',
            name: names.FILE.LIST,
            component: FileBreadcrumbs,
            meta: {
              breadcrumb: 'Список файлов',
              requiredAuth: true,
              instances: [
                REFERENCE_INSTANCE
              ]
            },
            children: [
              {
                path: ':id',
                name: names.FILE.DETAIL,
                component: FileDetail,
                meta: {
                  breadcrumb: 'Детальное представление',
                  requiredAuth: true,
                  instances: [
                    REFERENCE_INSTANCE
                  ]
                }
              }
            ]
          },
          {
            path: 'phone',
            name: names.REFERENCE.PHONE.LIST,
            component: ReferencePhones,
            meta: {
              breadcrumb: 'Телефонные номера',
              requiredAuth: true,
              instances: [
                REFERENCE_INSTANCE
              ]
            },
            children: [

            ]
          },
          {
            path: 'department',
            name: names.REFERENCE.DEPARTMENT.LIST,
            component: ReferenceDepartment,
            meta: {
              breadcrumb: 'Контакты подразделения',
              requiredAuth: true,
              instances: [
                REFERENCE_INSTANCE
              ]
            },
            children: [

            ]
          },
          {
            path: 'common',
            name: names.REFERENCE.COMMON.LIST,
            component: ReferenceCommon,
            meta: {
              breadcrumb: 'Общий контакт',
              requiredAuth: true,
              instances: [
                REFERENCE_INSTANCE
              ]
            },
            children: [

            ]
          },
          {
            path: 'personal',
            name: names.REFERENCE.PERSONAL.LIST,
            component: ReferencePersonal,
            meta: {
              breadcrumb: 'Персональный контакт',
              requiredAuth: true,
              instances: [
                REFERENCE_INSTANCE
              ]
            },
            children: [

            ]
          },
        ]
      },
    ]
  }
])
