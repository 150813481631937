<template>
  <v-container class="page-content px-5">
    <v-card>
      <v-toolbar elevation="0" color="#DADADA">
        <v-toolbar-title>
          Перечень всех подписанных документов:
        </v-toolbar-title>
        <v-spacer/>
        <v-menu
          v-if="$vuetify.breakpoint.xsOnly"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }" class="mr-3">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              color="black"
              class="mr-0"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-btn
              depressed
              color="transparent"
              width="100%"
              @click="reloadData"
            >
              Обновить
            </v-btn>
          </v-list>
        </v-menu>
        <div v-else>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                text
                class="pa-0 mr-3"
                v-bind="attrs"
                v-on="on"
                @click="reloadData"
              >
                <v-icon>
                  refresh
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить</span>
          </v-tooltip>
        </div>
      </v-toolbar>
      <v-row dense>
        <v-text-field
          @click:append="searchData"
          @keyup.enter="searchData"
          v-model="search.parameters.fio"
          append-icon="search"
          label="Поиск по ФИО"
          placeholder="Для поиска нажмите Enter или кнопку поиск"
          dense
          outlined
          :class="!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3' : 'px-2 mx-2 my-3'"
          clearable
          hide-details
        />
        <v-text-field
          @click:append="searchData"
          @keyup.enter="searchData"
          v-model="search.parameters.file_name"
          append-icon="search"
          label="Поиск по имени файла"
          placeholder="Для поиска нажмите Enter или кнопку поиск"
          dense
          outlined
          :class="!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3' : 'px-2 mx-2 my-3'"
          clearable
          hide-details
        />
        <v-autocomplete
          @keyup.enter="reloadData"
          v-model="pagination.filters.department"
          :loading="loading"
          item-text="name"
          item-value="uid"
          label="Поиск по подразделению"
          placeholder="Для поиска нажмите Enter"
          no-data-text="Нет данных"
          :items="selectors.departments"
          dense
          outlined
          :class="!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3' : 'px-2 mx-2 my-3'"
          clearable
          hide-details
        />
      </v-row>
      <v-card flat tile>
        <v-data-table
          :headers="headers"
          :items="getList"
          :loading="getLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="getCount"
          :footer-props="{'items-per-page-options':[25,35,100]}"
        >
          <template v-slot:item.actions="props">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="loadFile(props.item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    cloud_download
                  </v-icon>
                </v-btn>
              </template>
              <span>Скачать</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="openDeleteDialog(props.item)"
                  medium
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <delete-dialog
          :opened.sync="opened_delete"
          :loading="delete_loading"
          @close="closeDeleteDialog"
        >
          <template v-slot:card_action>
            <v-card-text class="mt-3">
              Вы точно хотите удалить файл<span v-if="selected && selected.file_name"> '{{ selected.file_name }}'</span>?
            </v-card-text>
          </template>
        </delete-dialog>
        <v-dialog v-model="opened_error" width="400">
          <v-card>
            <v-card-title>
              Лог ошибки
              <v-spacer/>
              <v-btn
                icon
                @click="opened_error=false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-alert type="error" outlined class="ma-3">
                {{ error }}
              </v-alert>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {generateListUidToName} from "@/helper/uidToName";
import DeleteDialog from "@/modules/core/components/DeleteDialog";
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "list",
  mixins: [PaginatedDataMapperMixin, TitledPageMixin],
  components: {DeleteDialog},
  data() {
    return {
      page_title: 'Список файлов ЭЦП',
      headers: [
        {
          text: 'Файл',
          align: 'left',
          sortable: false,
          value: 'file_name',
          width: '40%'
        },
        {
          text: 'Дата и время загрузки',
          align: 'left',
          sortable: false,
          value: 'loaded',
          width: '20%'
        },
        {
          text: 'Загрузивший',
          align: 'left',
          sortable: false,
          value: 'fio',
          width: '30%'
        },
        {
          text: 'Действия',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '10%'
        },
      ],
      pagination: {
        size_name: 'size',
        page_name: 'page',
        filters: {
          department: null,
        },
      },
      search: {
        parameters: {
          fio: null,
          file_name: null,
        },
      },
      selectors: {
        departments: []
      },
      selected: {},
      loading: false,
      delete_loading: false,
      opened_delete: false,
      error: null,
      opened_error: false,
    }
  },
  computed: {
    ...mapGetters({
      getList: 'recipients/getRecipientsList',
      getCount: 'recipients/getRecipientsListCount',
      getLoading: 'recipients/getRecipientsListLoading',
      getDepartments: 'selectors/getDepartments'
    })
  },
  watch: {
    'search.parameters.file_name': function (nVal, oVal) {
      if (this.wait_loader !== null)
        clearTimeout(this.wait_loader)
      this.wait_loader = setTimeout(() => {
        if (this.search.parameters.file_name === '' || this.search.parameters.file_name === null) {
          this.clearSearchData('file_name');
        }
      }, 1000);
    },
    'search.parameters.fio': function (nVal, oVal) {
      if (this.wait_loader !== null)
        clearTimeout(this.wait_loader)
      this.wait_loader = setTimeout(() => {
        if (this.search.parameters.fio === '' || this.search.parameters.fio === null) {
          this.clearSearchData('fio');
        }
      }, 1000);
    },
  },
  methods: {
    ...mapActions({
      loadRecipientsList: 'recipients/loadRecipientsList',
      loadDepartments: 'selectors/loadDepartments',
      deleteRecipients: 'recipients/deleteRecipients',
      upLoadRecipient: 'recipients/upLoadRecipient',
    }),
    loadData(url = undefined) {
      this.loadRecipientsList({
        url: url,
        urlAppend: (url) => {
          return this.addFiltersParameter(this.addSizeParameter(this.addPageParameter(url)));
        },
        finalizer: (data, url) => {
          for (let i in data.results) {
            this.$set(data.results[i], "uid_fio",data.results[i].signature.user)
            this.$set(data.results[i], "fio","-")
          }
          generateListUidToName(data.results,"uid_fio","fio")
          this.setCurrentLoadUrl(url)
        },
        catcher: () => {
        }
      })
    },
    loadFile(item, url = undefined) {
      this.upLoadRecipient({
        id: item.id,
        url: url,
        finalizer: (data) => {
          this.download(data, item.file_name, "application/pdf")
        },
        catcher: (error) => {
          this.error = error
          this.opened_error = true
        }
      })
    },
    download(content, fileName, contentType) {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(content);
      a.download = fileName;
      a.click();
    },
    addSearchParameter(url) {
      return this.addGetParameters(
        url,
        this.search.parameters
      )
    },
    clearSearchData: function (param) {
      this.cleanPaginationPageData();
      switch (param) {
        case 'file_name' :{
          this.search.parameters.file_name = null;
          break
        }
        case 'fio': {
          this.search.parameters.fio = null;
          break
        }
        default: {
          this.search.parameters.file_name = null;
          this.search.parameters.fio = null;
        }
      }
      this.setCurrentLoadUrl(
        this.addSearchParameter(
          this.getCurrentLoadUrl()
        ),
        true
      );
    },
    clearFiltersData() {
      this.cleanPaginationPageData();
      this.pagination.filters.department = null
      this.setCurrentLoadUrl(
        this.addSearchParameter(
          this.getCurrentLoadUrl()
        ),
        true
      );
    },
    loadDepartmentsList() {
      this.loading = true
      this.loadDepartments({
        finalizer: (data) => {
          this.selectors.departments = data
          this.loading = false
        },
        catcher: () => {this.loading = false}
      });
    },
    reloadData() {
      this.clearSearchData()
      this.clearFiltersData()
      this.loadData()
    },
    openDeleteDialog(item) {
      this.selected = item
      this.opened_delete = true
    },
    closeDeleteDialog(isApply) {
      if (isApply) {
        this.delete_loading = true
        this.deleteRecipients({
          id: this.selected.id,
          url: undefined,
          finalizer: (data, url) => {
            this.delete_loading = false
            this.reloadData()
          },
          catcher: (error) => {
            this.delete_loading = false
          },
        });
        this.opened_delete = false
        this.selected = {}
      } else {
        this.opened_delete = false
        this.selected = {}
      }
    }
  },
  created() {
    if (this.getCount === 0) {
      this.loadData()
    }
    if (this.selectors.departments.length === 0) {
      this.loadDepartmentsList()
    }
  }
}
</script>

<style scoped>

</style>
