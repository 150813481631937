<template>
  <v-container class="text-xs-left">
    <v-progress-linear indeterminate v-if="item.detail_loading"></v-progress-linear>
    <v-layout class="my-2" style="max-height: 400px; overflow-y: scroll;">
      <v-flex>
        <v-layout>
          <code v-if="item.has_error" style="width: 100%; float: none;">
            {{ item.error }}
          </code>
        </v-layout>
        <v-layout>
          <vue-json-pretty
            style="width: 100%"
            class="text-xs-left"
            :data="item.json_log"
            show-line
            show-double-quotes
            collapsed-on-click-brackets
            :deep="2"
          ></vue-json-pretty>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";

export default {
  name: "LogsExpanded",
  components: {
    VueJsonPretty
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped>

</style>
