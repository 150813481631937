<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="save"
    :title="'Принудительное обновление полей'"
    :scrolled="false"
    :loading="loading"
  >
    <div v-if="!loading && !result" class="px-3">
      <v-select
        v-model="data.related"
        class="pt-3"
        label="Система"
        :items="related"
        outlined
      ></v-select>
      <v-select
        v-model="data.fields"
        class="pt-3"
        label="Система"
        :items="fields[data.related]"
        outlined
        multiple
      ></v-select>
      <v-alert outlined type="error" v-if="hasError('non_field_errors')">
        {{String(getError('non_field_errors'))}}
      </v-alert>
      <v-alert outlined type="error" v-if="hasError('detail')">
        {{String(getError('detail'))}}
      </v-alert>
    </div>
    <div v-else-if="!loading && result">
      <vue-json-pretty
        style="width: 100%"
        class="text-xs-left"
        :data="result"
        show-line
        show-double-quotes
        collapsed-on-click-brackets
        :deep="2"
      />
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";
import VueJsonPretty from "vue-json-pretty";

export default {
  name: "actualizeDialog",
  props:{
    opened: Boolean,
    edit_data: Object,
    system: String,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, SemipolarSpinner, VueJsonPretty},
  data() {
    return {
      loading:false,
      result: null,
      related: '',
      fields: [],
      data: {
        related: '',
        fields: [],
      },
    }
  },

  methods: {
    ...mapActions({
      updateRelated: "faces/updateRelated",
      allowedFieldsRelated: 'faces/allowedFieldsRelated'
    }),
    close() {
      this.$emit('close', false)
    },

    save() {
      let copyData = Object.assign({}, this.data);
      console.log(this.data)
      this.loading = true
        this.updateRelated({
          id: this.edit_data.guid,
          data: copyData,
          finalizer: (data) => {
            this.result = data
            this.loading = false
          },
          catcher: (errors) => {
            this.mapErrors(errors)
            this.loading = false
          }
        })
      }
  },
  created() {
    this.allowedFieldsRelated({
      id: this.edit_data.guid,
      finalizer: (data) => {
        this.related = data['related']
        this.fields = data['allowed']
      }})
  }
}
</script>

<style scoped>

</style>
