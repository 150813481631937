<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @change="searchData"
        @click:clear="clearSearchData"
        @click:append="searchData"
        @keydown.enter="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-autocomplete
        v-model="pagination.filters.is_origin"
        outlined
        class="mx-2 py-3 my-3"
        dense
        hide-details
        label="Наличие дополнительных аккаунтов"
        :items="selectors.is_origin">
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="loading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="count"
      :disable-pagination="loading"
      disable-sort
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      :footer-props="{'items-per-page-options':[25,50,100], disablePagination: loading}"
      class="elevation-2 rounded-b-lg rounded-t-0"
    >
      <template v-slot:item.data-table-expand="props">
        <v-btn small icon @click="expandItem(props)" :disabled="!props.item.additional">
          <v-progress-circular v-if="props.item.detail_loading" color="blue" indeterminate></v-progress-circular>
          <v-icon color="blue" v-else-if="!props.isExpanded">expand_more</v-icon>
          <v-icon color="green" v-else>expand_less</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.student_identity="props">
        <span color="teal" v-if="!!props.item.student_identity">{{props.item.student_identity}}</span>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.is_student="props">
        <v-icon color="teal" v-if="props.item.is_student">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:item.is_employee="props">
        <v-icon color="teal" v-if="props.item.is_employee">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:item.is_graduate="props">
        <v-icon color="teal" v-if="props.item.is_graduate">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:item.is_active="props">
        <v-icon color="teal" v-if="props.item.is_active">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </template>
      <template v-slot:expanded-item="props">
        <td :colspan="headers.length + 1" style="background: linear-gradient(90deg, #1D71B8 0%, #366091 100%, #366091 100%)">
          <accounts-expand :item="props.item"/>
        </td>
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="openAccountsDialog(props.item)"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <accounts-dialog
      v-if="opened"
      :opened="opened"
      :accounts="selected.accounts"
      :id="selected.id"
      @close="closeAccountsDialog"
    />
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import paginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import titledPageMixin from "@/mixins/TitledPageMixin";
import AccountsDialog from "@/modules/admins/accounts/components/AccountsDialog.vue";
import AccountsExpand from "@/modules/admins/accounts/components/AccountsExpand.vue";

export default {
  name: "List",
  components: {AccountsExpand, AccountsDialog},
  mixins: [paginatedDataMapperMixin, titledPageMixin],
  data() {
    return {
      page_title: 'Список аккаунтов',
      selected: {},
      expanded: [],
      pagination: {
        filters: {
          is_origin: null,
        }
      },
      opened: false,
      selectors: {
        is_origin: [
          {text: "Все", value: null},
          {text: "Да", value: "true"},
          {text: "Нет", value: "false"}
        ],
      },
      search: {
        parameter: 'information',
      },
      headers: [
        {
          text: 'Пользователь',
          sortable: false,
          value: 'fio',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Логин',
          sortable: false,
          value: 'username',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Студенческий идентификатор',
          sortable: false,
          value: 'student_identity',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Почта',
          sortable: false,
          value: 'email',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Студент',
          sortable: false,
          value: 'is_student',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Работник',
          sortable: true,
          value: 'is_employee',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Выпускник',
          sortable: false,
          value: 'is_graduate',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Активен',
          sortable: false,
          value: 'is_active',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'center',
          width: '10%'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      list: "accounts/getList",
      loading: "accounts/isLoading",
      count: "accounts/getCount"
    })
  },
  methods: {
    ...mapActions({
      loadList: "accounts/loadAccountsList"
    }),
    loadData(url = undefined) {
      this.loadList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    expandItem(props) {
      props.expand(!props.isExpanded)
    },
    openAccountsDialog(item) {
      this.selected = item
      this.opened = true
    },
    closeAccountsDialog(ev) {
      this.opened = false
      this.selected = null
      if (ev)
        this.reloadData()
    },
    reloadData() {
      this.search.value = ''
      this.pagination.filters.is_origin = null
      this.loadData()
    },
  },
  created() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
