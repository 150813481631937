<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @click:append="searchDataCommon"
        @keydown.enter="searchDataCommon"
        @click:clear="clearSearchData"
        clearable
        hide-details
      ></v-text-field>
      <v-autocomplete
        v-model="pagination.filters.is_hidden "
        outlined
        class="mx-2 py-3 my-3"
        dense
        hide-details
        label="Скрытые"
        :items="selectors.is_hidden ">
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="newCommon"
          >
            Создать
          </v-btn>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="loadFile"
          >
            Сформировать
          </v-btn>
        </v-list>
      </v-menu>
      <div v-else>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="newCommon"
            >
              <v-icon>
                add
              </v-icon>
            </v-btn>
          </template>
          <span>Добавить контакт</span>
        </v-tooltip>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="reloadData"
            >
              <v-icon>
                refresh
              </v-icon>
            </v-btn>
          </template>
          <span>Обновить</span>
        </v-tooltip>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 mr-3"
              v-bind="attrs"
              v-on="on"
              @click="loadFile"
            >
              <v-icon>
                move_to_inbox
              </v-icon>
            </v-btn>
          </template>
          <span>Сформировать выгрузку</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[25,50,100]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
    >
      <template v-slot:item.email="{ item }">
        <v-tooltip
          v-if="item.email"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="dark-primary mr-1 my-1"
              color="#e5e9f2"
              v-bind="attrs"
              v-on="on"
              @click="copyInClipboard(item.email, 0)"
            >
              <span>{{item.email}}</span>
            </v-chip>
          </template>
          <span>Скопировать почту</span>
        </v-tooltip>
      </template>
      <template v-slot:item.phones="{ item }">
        <v-tooltip
          bottom
          v-for="(number, index) in item.phones"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="dark-primary mr-1 my-1"
              color="#e5e9f2"
              v-bind="attrs"
              v-on="on"
              @click="copyInClipboard(number.phone, index)"
            >
              <span>
                {{number.phone}}
                <span v-if="number.addition.length !== 0">
                  ( Доб:
                  <span v-for="(add, index) in number.addition" :key="index">
                    {{add}}
                    <span v-if="index + 1 !== number.addition.length">
                    ,
                    </span>
                  </span>
                  )
                </span>
              </span>
            </v-chip>
          </template>
          <span>Скопировать телефон</span>
        </v-tooltip>
      </template>
      <template v-slot:item.is_hidden="{ item }">
        <v-icon color="teal" v-if="item.is_hidden">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editCommon(item.id)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openDeleteDialog(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          <span>Удалить контакт</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="copy"
      color="#757575"
    >
      Текст скопирован в буфер обмена
    </v-snackbar>
    <edit-common-dialog
      v-if="opened.add_edit"
      :opened="opened.add_edit"
      :edit="edit_status"
      :common-id="common_id"
      @close="closeEditDialog"
    />
    <delete-common-dialog
      v-if="opened.delete"
      :opened="opened.delete"
      :edit="edit_status"
      :delete_data="delete_data"
      @close="closeDeleteDialog"
    />
  </v-container>
</template>

<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/admins/routers/names";
import DeleteCommonDialog from "@/modules/admins/reference/components/common/deleteCommonDialog.vue";
import EditCommonDialog from "@/modules/admins/reference/components/common/editCommonDialog.vue";
import TitledPageMixin from '@/mixins/TitledPageMixin'

export default {
  name: "List",
  components: {EditCommonDialog, DeleteCommonDialog},
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data: function () {
    return {
      page_title: 'Общие контакты',
      copy: false,
      opened: {
        add_edit: false,
        delete: false,
      },
      edit_status: undefined,
      common_id: undefined,
      delete_data: undefined,
      selectors: {
        is_hidden: [
          {text: "Все", value: null},
          {text: "Да", value: "true"},
          {text: "Нет", value: "false"}
        ],
      },
      pagination: {
        size_name: 'size',
        filters: {
          is_hidden: null,
        }
      },
      search: {
        parameter: 'search',
      },
      headers: [
        {
          text: 'Наименование',
          sortable: false,
          value: 'name',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Группа',
          sortable: false,
          value: 'group',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Почта',
          sortable: false,
          value: 'email',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Телефоны',
          sortable: false,
          value: 'phones',
          align: 'left',
          width: '20%'
        },
        {
          text: 'Скрытый',
          sortable: false,
          value: 'is_hidden',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'center',
          width: '10%'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUsersList: 'commonReference/getCommonList',
      getUsersCount: 'commonReference/getCommonListCount',
      isLoading: 'commonReference/isCommonListLoading'
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadCommonList: 'commonReference/loadCommonList',
      uploadFile: 'fileReference/uploadFile',
    }),
    loadFile(){
      let gets = {
        search: this.search.value,
        is_hidden: this.pagination.filters.is_hidden,
      }
      this.uploadFile({
        gets:gets,
        type: 1,
        finalizer: (data, url) => {
          this.$router.push({name: names.FILE.DETAIL, params: {id: data.unloading}})
        },
        catcher: (error) => {
        }
      })
    },
    copyInClipboard (item, index) {
      let copyText
      if (typeof item === 'object') copyText = item[index]
      else copyText = item
      try {
        navigator.clipboard.writeText(copyText)
        this.copy = true
        setTimeout(() => {
          this.copy = false
        }, "2000")
      } catch (e) {
        // console.log(e)
      }
    },
    loadData(url = undefined) {
      this.loadCommonList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(
            this.addSizeParameter(
              this.addGetParameter(url, 'search', this.search.value)
            )
          )
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    reloadData() {
      this.clearSearchData()
      this.loadData()
    },
    newCommon() {
      this.opened.add_edit = true
      this.edit_status = false
    },
    editCommon(id) {
      this.opened.add_edit = true
      this.edit_status = true
      this.common_id = id
    },
    closeEditDialog() {
      this.loadData()
      this.opened.add_edit = false
      this.common_id = undefined
    },
    openDeleteDialog(item) {
      this.opened.delete = true
      this.delete_data = item
    },
    closeDeleteDialog() {
      this.loadData()
      this.opened.delete = false
      this.delete_data =  undefined
    },
    searchDataCommon() {
      if(this.search.value){
        this.searchData()
      }
    }
  },
  created() {
    this.loadData()
  }
}
</script>
<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0px;
}
</style>
