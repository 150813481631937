<template >
  <v-container
    :fluid="true"
    style="width: 100%"
    class="px-0 pb-4"
  >
    <v-card
      class="rounded-lg text-left"
      flat
    >
      <v-divider/>
      <v-col
        cols="12"
        class="px-0 pt-2 mt-0"
      >
        <v-list
          class="py-0 my-0"
        >
          <v-list-item-subtitle
            class="text-left px-4"
          >
            <span
              style="line-height: 36px"
            >
              Иностранные языки:
            </span>
            <v-btn
              icon
              size="x-small"
              v-show="edit"
            >
              <v-icon
                color="primary"
                @click="opened_form_language = true"
              >
                add
              </v-icon>
            </v-btn>
          </v-list-item-subtitle>
          <v-list-item
            v-for="(item, i) in getProfile.languages"
            :key="i"
            active-color="primary"
            class="text-left"
          >
            <v-icon
              color="blue lighten-2"
              class="pr-4"
            >
              language
            </v-icon>
            <v-list-item-title
              :class="(item.is_native)?'font-weight-black':''"
            >
              {{ item.language.text }}
              <span
                v-if="!item.is_native"
              >
                ({{ item.level}})
              </span>
            </v-list-item-title>
            <v-btn
              icon
              size="x-small"
              v-show="edit"
              @click="selectItem(item,'opened_form_language')"
            >
              <v-icon
                color="primary"
              >
                edit
              </v-icon>
            </v-btn>
            <v-btn
              icon
              size="x-small"
              v-show="edit"
              @click="deleteItem(item,'Language')"
            >
              <v-icon
                color="primary"
              >
                delete
              </v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item
            v-if="getProfile.languages && getProfile.languages.length === 0"
          >
            <v-icon
              class="sub-primary pr-4">language</v-icon>
            <v-list-item-title>
              <span>не указан</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-card-title class="grey lighten-3 justify-center dark-primary">
        Контактная информация
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6">
          <v-list
            density="compact"
          >
            <v-list-item-subtitle>
              <span
                style="line-height: 36px"
                class="text-left pl-4"
              >
                Телефоны:
              </span>
              <v-btn
                icon
                size="x-small"
                v-show="edit"
                @click="opened_form_phone = true"
              >
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </v-list-item-subtitle>
            <v-list-item
              v-for="(item, i) in getProfile.phones"
              :key="i"
              active-color="primary"
            >
              <v-icon
                color="blue lighten-2"
                class="pr-4"
              >
                phone
              </v-icon>
              <v-list-item-title>
                {{ item.code }}{{ item.number }} ({{ item.kind }})
              </v-list-item-title>
              <v-btn
                icon
                size="x-small"
                v-show="edit"
                @click="selectItem(item,'opened_form_phone')"
              >
                <v-icon color="primary">edit</v-icon>
              </v-btn>
              <v-btn
                icon
                size="x-small"
                v-show="edit"
                @click="deleteItem(item,'Phone')"
              >
                <v-icon color="primary">delete</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="getProfile.phones && getProfile.phones.length === 0">
              <v-icon color="blue lighten-2" class="pr-4">phone</v-icon>
              <v-list-item-title>
                <span>не указан</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-list
            density="compact"
          >
            <v-list-item-subtitle>
              <span
                style="line-height: 36px"
                class="text-left pl-4"
              >
                Электронные почты:
              </span>
              <v-btn
                icon
                size="x-small"
                v-show="edit" @click="opened_form_email = true"
              >
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </v-list-item-subtitle>
            <v-list-item
              v-for="(item, i) in getProfile.emails"
              :key="i"
              active-color="primary"
            >
              <v-icon
                color="blue lighten-2"
                class="pr-4"
              >
                email
              </v-icon>
              <v-list-item-title>
                {{ item.email }} ({{ item.kind }})
              </v-list-item-title>
              <v-btn
                icon
                size="x-small"
                v-show="edit && item.id"
                @click="selectItem(item,'opened_form_email')"
              >
                <v-icon
                  color="primary"
                >
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                icon
                size="x-small"
                v-show="edit && item.id"
                @click="deleteItem(item,'Email')"
              >
                <v-icon color="primary">delete</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item
              v-if="getProfile.emails && getProfile.emails.length === 0"
            >
              <v-icon color="blue lighten-2" class="pr-4">email</v-icon>
              <v-list-item-title>
                <span>не указан</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-card-text>
        <v-divider></v-divider>
      </v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="pt-0 mt-0"
        >
          <v-list
            class="py-0 my-0"
          >
            <v-list-item-subtitle>
              <span
                style="line-height: 36px"
                class="text-left pl-4"
              >
                Адреса:
              </span>
              <v-btn
                icon
                size="x-small"
                v-show="edit"
                @click="opened_form_addresses = true"
              >
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </v-list-item-subtitle>
            <v-list-item
              v-for="(item, i) in getProfile.addresses"
              :key="i"
              active-color="primary"
            >
              <v-icon color="blue lighten-2" class="pr-4">home</v-icon>
              <v-list-item-title class="text-wrap">
                <span>{{ item.free }}</span>
              </v-list-item-title>
              <v-btn
                icon
                size="x-small"
                v-show="edit"
                @click="selectItem(item,'opened_form_addresses')"
              >
                <v-icon color="primary">edit</v-icon>
              </v-btn>
              <v-btn
                icon
                size="x-small"
                v-show="edit"
                @click="deleteItem(item,'Address')"
              >
                <v-icon color="primary">delete</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item
              v-if="getProfile.addresses && getProfile.addresses.length === 0"
            >
              <v-icon color="blue lighten-2" class="pr-4">home</v-icon>
              <v-list-item-title>
                <span>не указан</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <edit-detail-language-dialog
        v-if="opened_form_language"
        :opened="opened_form_language"
        :select="select"
        @save="saveForm"
        @close="close"
      />
      <edit-detail-email-dialog
        v-if="opened_form_email"
        :opened="opened_form_email"
        :select="select"
        @save="saveForm"
        @close="close"
      />
      <edit-detail-phone-dialog
        v-if="opened_form_phone"
        :opened="opened_form_phone"
        :select="select"
        @save="saveForm"
        @close="close"
      />
      <edit-detail-address-dialog
        v-if="opened_form_addresses"
        :opened="opened_form_addresses"
        :select="select"
        @save="saveForm"
        @close="close"
      />
      <apply-dialog
        v-if="dialog_delete"
        :opened="dialog_delete"
        :delete-dialog="true"
        :get-text="() => `Вы уверены, что хотите удалить?`"
        @close="closeDeleteDialog"
        @apply="closeDeleteDialog"
      ></apply-dialog>
    </v-card>
  </v-container>

</template>

<script>
import { mapActions, mapGetters } from "vuex"
import EditDetailLanguageDialog from "@/modules/admins/users/components/editDetail/EditDetailLanguageDialog.vue";
import EditDetailEmailDialog from "@/modules/admins/users/components/editDetail/EditDetailEmailDialog.vue";
import EditDetailPhoneDialog from "@/modules/admins/users/components/editDetail/EditDetailPhoneDialog.vue";
import EditDetailAddressDialog from "@/modules/admins/users/components/editDetail/EditDetailAddressDialog.vue";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import UserWorker from "@/modules/admins/users/components/userInformation/UserWorker.vue";

export default {
  name: 'InformationUser',
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  components: {
    UserWorker,
    EditDetailAddressDialog,
    EditDetailPhoneDialog,
    EditDetailEmailDialog,
    EditDetailLanguageDialog,
    ApplyDialog
  },
  data () {
    return {
      opened_form_language: false,
      opened_form_email: false,
      opened_form_phone: false,
      opened_form_addresses: false,
      dialog_delete: false,
      select: null,
      delete_form: ''
    }
  },
  computed: {
    ...mapGetters({
      getProfile: 'adminUsers/getProfile'
    })
  },
  methods: {
    ...mapActions({
      deleteUserLanguage: 'adminUsers/deleteUserLanguage',
      deleteUserEmail: 'adminUsers/deleteUserMail',
      deleteUserPhone: 'adminUsers/deleteUserPhone',
      deleteUserAddress: 'adminUsers/deleteUserAddress',
    }),
    closeDeleteDialog (event) {
      if (event) {
        if (this.select) {
          this['deleteUser' + this.delete_form]({
            uid: this.getProfile.uid,
            id: this.select.id,
            finalizer: (data, url) => {
              this.dialog_delete = false
              this.saveForm()
            },
            catcher: () => {
              this.dialog_delete = false
            }
          })
        }
      } else {
        this.dialog_delete = false
        this.select = null
      }
    },
    saveForm () {
      this.opened_form_language = false
      this.opened_form_email = false
      this.opened_form_phone = false
      this.opened_form_addresses = false
      this.select = null
      this.delete_form = null
      this.$emit('update_profile')
    },
    selectItem (item, form) {
      this[form] = true
      this.select = item
    },
    deleteItem (item, form) {
      this.dialog_delete = true
      this.delete_form = form
      this.select = item
    },
    close () {
      this.opened_form_language = false
      this.opened_form_email = false
      this.opened_form_phone = false
      this.opened_form_addresses = false
      this.select = null
    }
  }
}
</script>

<style scoped>

</style>
