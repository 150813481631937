<template>
  <scroll-center-dialog
    title="Нормализация логина пользователя"
    v-model="opened"
    @close="close"
    @apply="apply"
    :loading="loading"
    apply-text="Подтвердить"
  >
    <v-card flat class="pt-4">
      <v-alert type="warning" outlined>
        Подтвердите действие, нормализовать логин пользователя?
      </v-alert>
      <v-checkbox
          v-model="change_fio"
          label="Сменить фамилию"
      />
      <v-alert v-if="hasError('detail')" outlined>
        {{ getError('detail') }}
      </v-alert>
    </v-card>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions} from "vuex";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "normalizeDialog",
  components: {ScrollCenterDialog},
  mixins: [FormErrorsMixin],
  props: {
    opened: Boolean,
    item: Object
  },
  data() {
    return {
      change_fio: false,
      loading: false,
      errors: {}
    }
  },
  methods: {
    ...mapActions({
      normalize: "acceptances/normalizeLogin"
    }),
    close() {
      this.$emit('close', false)
    },
    apply() {
      this.loading = true
      this.normalize({
        id: this.item.guid,
        data: {
          change_fio: this.change_fio
        },
        finalizer: () => {
          this.$emit('close', true)
          this.loading = false
          this.errors = {}
        },
        catcher: (error) => {
          this.mapErrors(error)
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
