import {sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest, sendPutRequest} from "@/helper/requests";
import reference from "@/urls/reference";

const state = {
  department: {
    currentUrl: null,
    list: [],
    loading: null,
    count: 0,
    detail: [],
  }
};

const getters= {
  getDepartmentList: (state) => state.department.list,
  getDepartmentDetail: state => state.department.detail,
  getDepartmentCurrentUrl: state => state.department.currentUrl,
  getDepartmentListCount: (state) => state.department.count,
  isDepartmentListLoading: (state) => state.department.loading,
};

const actions= {
  loadDepartmentList: ({commit}, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.DEPARTMENT.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isLoading || getters.getDepartmentCurrentUrl !== url) {
      commit('SET_LOADING')
      sendGetRequest(
        url,
        (data, url) => {
          commit('SET_DEPARTMENT_LIST', data['results'])
          commit('SET_DEPARTMENT_COUNT', data['count'])
          commit('SET_DEPARTMENT_CURRENT_URL', url)
          commit('SET_NOT_LOADING')
          finalizer(data, url)
        },
        (error) => {
          commit('SET_NOT_LOADING')
          catcher(error)
        }
      )
    }
  },
  loadDepartmentDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.DEPARTMENT.DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_DEPARTMENT_DETAIL', data);
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createDepartment: ({commit}, {url, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.DEPARTMENT.CREATE()
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateDepartment: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.DEPARTMENT.UPDATE(id)
    }
    sendPutRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deleteDepartment: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.REFERENCE.ADMIN.DEPARTMENT.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations= {
  SET_DEPARTMENT_LIST: (state, list) => {
    state.department.list = list
  },
  SET_DEPARTMENT_DETAIL: (state, value) => {
    state.department.detail = value
  },
  SET_DEPARTMENT_CURRENT_URL: (state, value) => {
    state.department.currentUrl = value
  },
  SET_LOADING: (state) => {
    state.department.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.department.loading = false
  },
  SET_DEPARTMENT_COUNT: (state, count) => {
    state.department.count = count
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
