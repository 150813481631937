import Vue from 'vue';
import Vuex from 'vuex';
import maintoolbar from '@/store/modules/maintoolbar';
import leftmenu from '@/store/modules/leftmenu';
import mainpage from '@/store/modules/mainpage';
import auth from '@/store/modules/auth';
import urls from '@/store/modules/urls';
import adminUsers from '@/store/modules/admin/adminUsers';
import bookerUsers from '@/store/modules/booker/bookerUsers';
import selectors from "@/store/modules/selectors";
import department from '@/store/modules/booker/department';
import logs from '@/store/modules/booker/logs';
import countries from "@/store/modules/admin/countries";
import languages from "@/store/modules/admin/languages";
import tree from "@/store/modules/admin/tree";
import signerLogs from '@/store/modules/admin/signer/signerLogs'
import mail from "@/store/modules/admin/signer/mail";
import recipients from "@/store/modules/admin/signer/recipients";
import departmentsReference from "@/store/modules/admin/reference/departments";
import commonReference from "@/store/modules/admin/reference/common";
import personalReference from "@/store/modules/admin/reference/personal";
import commentReference from "@/store/modules/admin/reference/commentPersonal";
import phoneReference from "@/store/modules/admin/reference/phone";
import commentPhone from "@/store/modules/admin/reference/commentPhone";
import faculty from "@/store/modules/admin/faculty";
import cathedra from "@/store/modules/admin/cathedra";
import session from "@/store/modules/admin/session";
import sessionAdmin from "@/store/modules/admin/sessionAdmin";
import fileReference from "@/store/modules/admin/reference/file";
import acceptances from "@/store/modules/booker/acceptances";
import transfers from "@/store/modules/booker/transfers";
import dismissal from "@/store/modules/booker/dismissal";
import accounts from "@/store/modules/admin/accounts";
import geo from "@/store/modules/admin/geo";
import faces from "@/store/modules/university/faces";
import order from "@/store/modules/university/order";
import faculty_un from "@/store/modules/university/faculty";
import group_un from "@/store/modules/university/group";
import history_group_un from "@/store/modules/university/historygroup";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    leftmenu,
    maintoolbar,
    mainpage,
    auth,
    urls,
    adminUsers,
    selectors,
    bookerUsers,
    department,
    logs,
    faces,
    order,
    faculty_un,
    languages,
    countries,
    recipients,
    signerLogs,
    mail,
    tree,
    session,
    sessionAdmin,
    departmentsReference,
    commonReference,
    personalReference,
    commentReference,
    phoneReference,
    commentPhone,
    faculty,
    geo,
    cathedra,
    fileReference,
    acceptances,
    transfers,
    dismissal,
    accounts,
    group_un,
    history_group_un
  }
})
