<template>
  <scroll-center-dialog
    v-model="opened"
    :loading="save_loading"
    @close="close"
    @apply="save"
    :title="form_title"
    :scrolled="false"
  >
    <div v-if="loading" class="px-3">
      <loading-autocomplete
        v-if="edit === false"
        placeholder="Введите ФИО и выберете из предложенных вариантов"
        label="Работник"
        outlined
        required
        class="mt-4"
        v-model="personal.user"
        search-parameter="fio"
        :load-fios="false"
        :url="worker_selector"
        :error="hasError('user')"
        :errorMessages="getError('user')"
      >
        <template v-slot:no-data>
          <div>
            Для поиска начните набирать ФИО, <br> и либо остановитесь на 1 секунду, либо нажмите Enter
          </div>
        </template>
      </loading-autocomplete>
      <v-text-field
        label="Почта"
        :class="edit? 'mt-4' : 'ma-0'"
        v-model="personal.email"
        :error="hasError('email')"
        :errorMessages="getError('email')"
        clearable
        outlined
      >
          <template v-slot:label>
              Почта<span class="red--text">*</span>
          </template>
      </v-text-field>
      <template
        v-for="(item, i) in personal.phones"
      >
        <ReferencePhones
          :key="i+'phone'"
          :item="item"
          :index="i"
          :phones="personal.phones"
          :special-error.sync="special_error"
        >
        </ReferencePhones>
      </template>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="addNewPhone"
          class="pr-0"
        >
          Добавить телефон
          <v-icon>add</v-icon>
        </v-btn>
      </v-row>
      <v-text-field
        label="Мобильный"
        v-model="personal.mobile"
        v-mask="'+7(###)###-##-##'"
        :error="hasError('mobile')"
        :errorMessages="getError('mobile')"
        clearable
        outlined
      >
          <template v-slot:label>
              Мобильный
          </template>
      </v-text-field>
      <v-autocomplete
        v-model="personal.territory"
        :items="territories"
        item-value="value"
        outlined
        :error="hasError('territory')"
        :errorMessages="getError('territory')"
      >
        <template v-slot:label>
          Территория<span class="red--text">*</span>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="personal.territory"
        v-model="personal.building"
        :items="buildings"
        item-value="value"
        outlined
        :error="hasError('building')"
        :errorMessages="getError('building')"
      >
        <template v-slot:label>
          Строение<span class="red--text">*</span>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-if="personal.building"
        v-model="personal.room"
        :items="rooms"
        item-value="value"
        outlined
        :error="hasError('room')"
        :errorMessages="getError('room')"
      >
        <template v-slot:label>
          Кабинет
        </template>
      </v-autocomplete>
      <v-checkbox
        class="py-0 my-0"
        label="Публичный"
        v-model="personal.is_public"
        :error="hasError('is_public')"
        :errorMessages="getError('is_public')"
      ></v-checkbox>
      <v-checkbox
        class="py-0 my-0"
        label="VIP"
        v-model="personal.is_vip"
        :error="hasError('is_vip')"
        :errorMessages="getError('is_vip')"
      ></v-checkbox>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import loadingAutocomplete from "@/modules/core/components/LoadingAutocomplete.vue";
import selectors from "@/urls/selectors";
import ReferencePhones from "@/modules/admins/reference/components/ReferencePhones.vue";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "editPersonalDialog",
  props:{
    opened: Boolean,
    edit: Boolean,
    personalId: Number,
  },
  mixins: [FormErrorsMixin],
  components: {ReferencePhones, loadingAutocomplete, ScrollCenterDialog, SemipolarSpinner },
  data() {
    return {
      loading: false,
      save_loading: false,
      edit_worker: true,
      user_fio: null,
      personal: {
        id: null,
        user: null,
        email: null,
        mobile: null,
        territory: null,
        building: null,
        room: null,
        is_public: false,
        is_vip: false,
        phones: [
          {phone:'',addition:[]}
        ],
      },
      special_error: {},
      territory: null,
      building: null,
      room: null,
    }
  },
  watch:{
    "personal.territory"(){
      if(this.personal.territory){
        this.loadSelectorBuildings()
      }
      if(this.territory !== this.personal.territory){
        this.territory = this.personal.territory
        this.personal.building = null
        this.personal.room = null
      }
    },
    "personal.building"(){
      if(this.personal.building){
        this.loadSelectorRooms()
      }
      if(this.building !== this.personal.building){
        this.building = this.personal.building
        this.personal.room = null
      }
    },
  },
  computed: {
    form_title() {
      if(!this.edit)
        return 'Добавить контакт'
      else if (this.personal && this.loading)
        return this.user_fio
    },
    worker_selector(){
      return selectors.SELECTORS.REFERENCE.WORKERS()
    },
    ...mapGetters({
      territories: 'selectors/getTerritories',
      buildings: 'selectors/getBuildings',
      rooms: 'selectors/getRooms',
      item: 'personalReference/getPersonalDetail'
    }),
  },
  methods: {
    ...mapActions({
      createPersonal: 'personalReference/createPersonal',
      updatePersonal: 'personalReference/updatePersonal',
      loadTerritories : 'selectors/loadTerritories',
      loadBuildings : 'selectors/loadBuildings',
      loadRooms : 'selectors/loadRooms',
      loadPersonalDetail: 'personalReference/loadPersonalDetail',
      loadPhone: 'selectors/loadPhone',
    }),
    loadSelectors () {
      this.loadPhone({
        url: undefined,
        finalizer: (data) => {
        },
      })
    },
    close() {
      this.$emit('close', false)
    },
    catchErrors(errors) {
      this.save_loading = false;
      if(!errors.phones){
        this.mapErrors(errors)
        this.special_error = {}
      } else {
        this.special_error = errors.phones
      }
    },
    save() {
      this.save_loading = true
      if (this.edit) {
        this.updatePersonal({
          id: this.personalId,
          data: this.prepareData(),
          finalizer: () => {
            this.save_loading = false
            this.close()
          },
          catcher: this.catchErrors
        })
      }
      else {
        this.createPersonal({
          data: this.prepareData(),
          finalizer: () => {
            this.save_loading = false
            this.close()
          },
          catcher: this.catchErrors
        })
      }
    },
    prepareData: function () {
      let data = {}
      for (let el in this.personal) {
        if (el === 'territory' || el === 'building' || 'room'){
          data[el] = this.personal[el]
        } else if (this.personal[el] !== null) {
          data[el] = this.personal[el]
        }
      }
      return data
    },
    // Добавление еще одного телефона
    addNewPhone() {
      this.personal.phones.push({
        phone: null,
        addition: [],
      })
    },
    loadData() {
      if (this.personalId) {
        this.loadPersonalDetail({
          id: this.personalId,
          finalizer: (data) => {
            this.personal.phones = []
            for(let phone of data.phones){
              this.personal.phones.push(
                {
                  "phone": phone.phone_id,
                  "addition": phone.addition
                }
              )
            }
            this.personal.user = data.user.value
            this.user_fio = data.user.text
            this.personal.email = data.email
            this.personal.mobile = data.mobile
            if(data.territory){
              this.personal.territory = data.territory.value
              this.territory = data.territory.value
            }
            if(data.building){
              this.personal.building = data.building.value
              this.building = data.building.value
            }
            if(data.room){
              this.personal.room = data.user.room
              this.room = data.user.room
            }

            this.personal.is_public = data.is_public
            this.personal.is_vip = data.is_vip
            this.edit_worker = false
            this.loading = true
          }
        })
      }
      else {
        this.loading = true;
      }
    },
    loadSelectorTerritories(){
      this.loadTerritories({
        finalizer: (val) => {},
      })
    },
    loadSelectorBuildings(){
      this.loadBuildings({
        gets: { territory: this.personal.territory },
        finalizer: (val) => {},
      })
    },
    loadSelectorRooms(){
      this.loadRooms({
        gets: { building: this.personal.building },
        finalizer: (val) => {},
      })
    },
  },
  created() {
    if (this.territories.length === 0) {
      this.loadSelectorTerritories()
    }
    this.loadData()
    this.loadSelectors()
  }
}
</script>

<style scoped>

</style>
