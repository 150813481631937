import { ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, EDUCATION_INSTANCE } from '@/helper/consts'
import names from "@/modules/university_connector/routers/names";
import Breadcrumbs from "@/modules/university_connector/Breadcrumbs";
import UsersBreadcrumbs from "@/modules/university_connector/users/UsersBreadcrumbs";
import OrdersBreadcrumbs from "@/modules/university_connector/orders/OrdersBreadcrumbs.vue";
import FacultyBreadcrumbs from "@/modules/university_connector/faculty/FacultyBreadcrumbs.vue";
import GroupBreadcrumbs from "@/modules/university_connector/group/GroupBreadcrumbs.vue";
import TrueGroupBreadcrumbs from "@/modules/university_connector/historygroup/HystoriGroupBreadcrumbs.vue";

export default ([
  {
    path: '/university',
    redirect: '/university/users',
    name: names.MAIN,
    component: Breadcrumbs,
    meta: {
      breadcrumb: 'university',
      requiredAuth: true,
      instances: [
        EDUCATION_INSTANCE
      ]
    },
    children: [
      {
        path: 'users',
        name: names.USERS.LIST,
        component: UsersBreadcrumbs,
        meta: {
          breadcrumb: 'Пользователи',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, EDUCATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'groups',
        name: names.GROUP.LIST,
        component: GroupBreadcrumbs,
        meta: {
          breadcrumb: 'Группы',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, EDUCATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'tgroup',
        name: names.HYSTORIGROUP.LIST,
        component: TrueGroupBreadcrumbs,
        meta: {
          breadcrumb: 'Истинные группы',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, EDUCATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'orders',
        name: names.ORDER.LIST,
        component: OrdersBreadcrumbs,
        meta: {
          breadcrumb: 'Приказы',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, EDUCATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'faculty',
        name: names.FACULTY.LIST,
        component: FacultyBreadcrumbs,
        meta: {
          breadcrumb: 'Факультеты',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE, EDUCATION_INSTANCE
          ]
        },
        children: []
      },
    ]
  }
])
