<template>
  <v-list-item
      v-if="item.router"
      :class="{ 'instance_menu__item--active': isCurrent(item)}"
      @click="changeRouter(item)"
      link
      class="instance_menu__item"
  >
    <v-list-item-title class="instance_menu__title text-uppercase ms_text-h4">
      {{item.title}}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "LeftMenuAction",
  props: ["item", "currentInstance"],
  data() {
    return {}
  },
  methods: {
    changeParam: function (item) {
      if (typeof (item) === "object") {
        return {name: item.name, params: item.params, query: item.query};
      } else {
        return {name: item};
      }
    },
    changeRouter: function (item) {
      if(!this.isCurrent(item)) {
        this.$store.commit("leftmenu/selectInstance", item.instance);
        this.$router.push(this.changeParam(item.router))
      } else {
        if (this.$route.name !== item.name) {
          this.$router.push(this.changeParam(item.router))
        }
      }
    },
    isCurrent: function (item) {
      if (this.currentInstance !== undefined && item.name !== undefined)
        return this.currentInstance === item.name;
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.instance_menu__item {
  padding: 16px 24px !important;
  height: 55px;
  border-bottom: 1px solid var(--blue-shades-focus);
  background: var(--background-white);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &:hover {
    background: var(--gray-shades-hover);
  }

  &--active {
    padding: 16px 24px !important;
    height: 55px;
    border-bottom: 1px solid var(--blue-shades-focus);
    background: var(--blue-shades-focus);
  }
}

.instance_menu__title {
  text-align: left;
  color: var(--blue-shades-dark);
}
</style>
