<template>
  <v-dialog persistent scrollable v-model="open" max-width="1000" min-width="400">
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">
          Регенерирование пароля пользователя
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="white" @click="refresh()">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn icon color="white" @click="exit()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <template v-if="can_regenerate_process.loading">
        <v-row no-gutters justify="center" align="center" style="height: 30vh">
          <semipolar-spinner
            :animation-duration="2000"
            :size="$vuetify.breakpoint.mdAndUp ? 160 : 60"
            color="#1976D2"
          />
        </v-row>
      </template>
      <template v-else-if="!can_regenerate_process.loading && can_regenerate_process.can">
        <v-card-text class="text-xs-left subheading" v-if="!regenerate_process.executed" style="max-height: calc(100vh - 200px)">
          <v-form ref="form">
            <v-layout>
              <v-flex>
                Для пользователя:
              </v-flex>
            </v-layout>
            <v-layout class="my-2">
              <v-flex class="font-weight-bold">
                {{item.lastname}} {{item.firstname}} {{item.midname}}
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                будет сгенерирован новый одноразовый пароль, который он должен будет поменять при первом
                успешном входе в систему на своем рабочем компьютере или в системе PORTAL
              </v-flex>
            </v-layout>
            <v-switch
              v-model="data.change_email"
              label="Сменить почту для восстановления пароля"
              hint="При выборе этой опции внешняя почта пользователя будет заменена на указанную почту"
              persistent-hint
            ></v-switch>
            <v-slide-y-transition>
              <v-text-field
                v-if="data.change_email"
                :rules="[rules.required, rules.email, rules.noMuctr]"
                :error="hasError('email')"
                :error-messages="getError('email')"
                label="E-mail для предоставления пароля"
                outlined
                class="my-2"
                v-model="data.email"
              >
                <span slot="label">Почта<span class="red--text">*</span>:</span>
                <template v-slot:help-text></template>
                <!--              <span slot="help-text"></span>-->
              </v-text-field>
            </v-slide-y-transition>
            <!--          <v-alert value="true" type="info" outline>-->
            <!--            Внешняя почта, необходимая для восстановления пользовательского доступа в систему-->
            <!--          </v-alert>-->
            <v-switch
              v-model="data.default_password"
              label="Установить стандартный пароль"
              hint="При выборе этой опции пользователю будет установлен пароль по умолчанию"
              persistent-hint
            ></v-switch>
            <v-slide-y-transition>
              <v-alert v-if="data.default_password" type="warning" outlined>
                Пароль по умолчанию Pwd*12345678
              </v-alert>
            </v-slide-y-transition>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="!regenerate_process.executed">
          <v-flex>
            <v-btn @click="regeneratePassword" color="warning" class="mx-1" :loading="regenerate_process.loading">
              Регенерировать пароль
            </v-btn>
            <v-btn @click="exit()" class="mx-1" :loading="regenerate_process.loading">
              Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
        <v-card-text v-if="regenerate_process.executed">
          <v-alert type="warning" outlined class="text-xs-left" v-if="!regenerate_process.success">
            Во время процесса подтверждения аккаунта произошел сбой.
            <br>Лог операции:<br>
            <div style="max-height: calc(100vh - 300px); overflow-y: scroll;">
              <vue-json-pretty
                style="width: 100%"
                class="text-xs-left"
                :data="regenerate_process.log"
                show-line
                show-double-quotes
                collapsed-on-click-brackets
                :deep="2"
              ></vue-json-pretty>
            </div>
          </v-alert>
          <v-alert type="success" class="text-xs-left" v-else>
            Пароль успешно сгенерирован
            <br>Логин: {{ regenerate_process.username }}
            <br>Пароль: {{ regenerate_process.password }}
            <br>На почту {{ regenerate_process.email }} выслано информационное письмо
            <br>Пользователю при первом входе на рабочей станции или в портале необходимо будет сменить пароль.
            <br>Время действия одноразового пароля не ограничено.
          </v-alert>
        </v-card-text>
      </template>
      <v-card-text v-else-if="!error">
        <v-alert class="ma-3" outlined type="warning">
          К сожалению сгенерировать одноразовый пароль можно только для полностью подтвержденного пользователя.
        </v-alert>
      </v-card-text>
      <v-card-text v-else>
        <v-alert class="ma-3" type="error" outlined>
          При получении данных возникла ошибка. Повторите попытку позднее.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormErrorMixin from "@/mixins/FormErrorsMixin";
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import {SemipolarSpinner} from "epic-spinners";
import {mapActions} from "vuex";

export default {
  name: "UserRegeneratePassword",
  mixins: [FormErrorMixin],
  components: {
    VueJsonPretty,
    SemipolarSpinner
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      regenerate_process: {
        loading: false,
        executed: false,
        success: null,
        log: {},
        username: null,
        password: null,
        email: null,
      },
      error: undefined,
      can_regenerate_process: {
        loading: false,
        can: false,
      },
      data: {
        email: '',
        change_email: false,
        default_password: false,
        // this.item.email || '',
      },
      rules: {
        required: value => !!value || 'Пустое поле',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Не верный почтовый ящик'
        },
        noMuctr: value => {
          const pattern = /^(?!.*@muctr.ru).*$/
          return pattern.test(value) || 'Почта в домене @muctr.ru не допустима'
        },
      }
    }
  },
  watch: {
    open: function (){
      if(this.open){
        this.checkCanRegenerate()
      }else{
        setTimeout(()=>{this.can_regenerate_process.can = false;}, 500)
      }
    }
  },
  methods: {
    ...mapActions({
      checkCanRegeneratePassword: 'bookerUsers/checkCanRegenerate',
      doRegeneratePassword: 'bookerUsers/doRegeneratePassword',
    }),
    clean: function () {
      this.can_regenerate_process.can = false;
      this.can_regenerate_process.loading = false;
      this.regenerate_process.loading = false;
      this.regenerate_process.executed = null;
      this.regenerate_process.success = null;
      this.regenerate_process.log = null;
      this.regenerate_process.username = null;
      this.regenerate_process.password = null;
      this.regenerate_process.email = null;
      this.data.email = this.item.email;
      this.data.default_password = false;
      this.data.change_email = false;
      this.field_errors = {};
    },
    refresh: function () {
      this.clean();
      this.checkCanRegenerate();
    },
    exit: function (){
      if(!this.regenerate_process.loading){
        this.$emit('update:open', false)
        this.$emit('update:item', {})
        setTimeout(() => {
          this.clean()
        }, 250)
      }
    },
    checkCanRegenerate(){
      let url = undefined;
      this.can_regenerate_process.loading = true;
      this.checkCanRegeneratePassword({
        url: url,
        id: this.item.id,
        finalizer: (data) => {
          this.can_regenerate_process.loading = false;
          this.can_regenerate_process.can = data['can'];
        },
        catcher: (error) => {
          this.can_regenerate_process.loading = false;
          this.can_regenerate_process.can = false;
          this.error = error;
        }
      })
    },
    regeneratePassword(){
      if(this.$refs['form'].validate()){
        let url = undefined;
        this.regenerate_process.loading = true;
        this.doRegeneratePassword({
          url: url,
          id: this.item.id,
          data: this.data,
          finalizer: (data) => {
            this.regenerate_process.success = data.success
            this.regenerate_process.log = data['log']
            this.regenerate_process.username = data.account_username
            this.regenerate_process.password = data.password
            this.regenerate_process.email = data.to_email
            this.regenerate_process.executed = true;
            this.regenerate_process.loading = false;
          },
          catcher: (error) => {
            this.regenerate_process.loading = false;
            this.regenerate_process.executed = true;
            this.regenerate_process.success = false;
            this.regenerate_process.log = error
          }
        })
      //   let url = urls.getUrl(
      //     'booker|official-worker-regenerate', 'regenerate'
      //   ).replace('<id>', this.item.id)
      //   makePostRequest(url, this.data).then(resp=>{
      //     // console.logs(resp)
      //     if(resp.ok) {
      //       // console.log('SUCCESS by Status')
      //       return resp.json().then(json => {
      //         this.regenerate_process.success = json.success
      //         this.regenerate_process.log = json['log']
      //         this.regenerate_process.username = json.account_username
      //         this.regenerate_process.password = json.password
      //         this.regenerate_process.email = json.to_email
      //         this.regenerate_process.executed = true;
      //         this.regenerate_process.loading = false;
      //         // console.log(this.regenerate_process)
      //         // console.log('SUCCESS')
      //       })
      //     } else {
      //       // console.log('ERROR by Status')
      //       if(resp.statusCode >= 500){
      //         return resp.json().then(json => {
      //           this.regenerate_process.loading = false;
      //           this.regenerate_process.executed = true;
      //           this.regenerate_process.success = false;
      //           this.regenerate_process.log = json
      //         })
      //       } else {
      //         return resp.json().then(json => {
      //           this.field_errors = json
      //           this.regenerate_process.executed = false;
      //           this.regenerate_process.loading = false;
      //         })
      //       }
      //     }
      //   }).catch(resp => {
      //     // console.log('ERROR by catch')
      //     this.regenerate_process.loading = false;
      //     this.regenerate_process.executed = true;
      //     this.regenerate_process.success = false;
      //     this.regenerate_process.log = 'Пришел полностью пустой ответ.'
      //   })
      //   // console.log(url)
      }
    }
  }
}
</script>

<style scoped>

</style>
