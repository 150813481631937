import store from "@/store";

export function isAliveToken(success = () => {}, error = () => {}) {
  if(store.getters['auth/isCheckExpired']()){
    store.dispatch('auth/CHECK_TOKEN_ALIVE').then(
      () => {
        success()
      }
    ).catch(error)
  } else {
    success()
  }
}

export function goToLoginPage(){
  document.location.href = process.env.VUE_APP_LOGIN_HOST + `?from=${document.location.href}`
}

export function goToPortalPage() {
  document.location.href = process.env.VUE_APP_PORTAL_HOST
}
