<template>
  <user-action-form-template
    :process="process"
    :can_process="can_process"
    :title="!deactivate ? 'Активация аккаунта' : 'Деактивация аккаунта'"
    :open="open"
    @refresh="refresh"
    @exit="exit"
    @action="action"
    :item="item"
    :non_field_errors="this.errors.non"
    :error="error"
    @dismiss_error="deleteNonFieldError($event)"
  >
    <template v-slot:action-button-title>
      <span v-if="!deactivate">Активация</span><span v-else>Деактивация</span> аккаунта
    </template>
    <template v-slot:error-text>
      Во время <span v-if="!deactivate">активация</span><span v-else>деактивация</span> аккаунта возникли непредвиденные оишбки
    </template>
    <template v-slot:form>
      <v-form ref="form">
        <v-layout>
          <v-flex>
            Произвести <span v-if="!deactivate">активацию</span><span v-else>деактивацию</span> аккаунта ад для пользователя:
          </v-flex>
        </v-layout>
        <v-layout class="my-2">
          <v-flex class="font-weight-bold">
            {{item.lastname}} {{item.firstname}} {{item.midname}}
          </v-flex>
        </v-layout>
<!--        <v-layout>-->
<!--          <v-flex>-->
<!--            будет <span v-if="!deactivate">активирован</span><span v-else>деактивирован</span> аккаунт АД.-->
<!--          </v-flex>-->
<!--        </v-layout>-->
      </v-form>
    </template>
    <template v-slot:success="">
      <p>
        Аккаунт пользователя {{item.lastname}} {{item.firstname}} {{item.midname}} успешно <span v-if="!deactivate">активирован</span><span v-else>деактивирован</span>!
      </p>
      <p>
        Пользователю <span v-if="!deactivate">открыта</span><span v-else>закрыта</span> возможность взаимодействия с сервисами.
      </p>
    </template>
  </user-action-form-template>
</template>

<script>
import UserActionFormTemplate from "@/modules/booker/users/components/UserActionFormTemplate";
import userActionFormMixin from "@/mixins/userActionFormMixin";
import booker from "@/urls/booker";

export default {
  name: "UserActiveDialog",
  components: {UserActionFormTemplate},
  mixins: [userActionFormMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true
    },
    deactivate: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data(){
    return {
      info: {
        generate_detail: false,
      },
    }
  },
  methods: {
    checkCan(){
      let url;
      if(!this.deactivate){
        url = booker.BOOKER.OFFICIAL_WORKER_ACTIVATE(this.item.id)
      } else {
        url = booker.BOOKER.OFFICIAL_WORKER_DEACTIVATE(this.item.id)
      }
      this.can_process.loading = true;
      this.checkActionDialog({
        url: url,
        finalizer: (data) => {
          this.can_process.loading = false;
          this.can_process.can = data['can'];
          this.can_process.detail = data['detail'];
          this.afterCan(data);
        },
        catcher: (error) => {
          this.can_process.loading = false;
          this.error = error
        }
      })
    },
    action(){
      let url;
      if(!this.deactivate){
        url = booker.BOOKER.OFFICIAL_WORKER_ACTIVATE(this.item.id)
      } else {
        url = booker.BOOKER.OFFICIAL_WORKER_DEACTIVATE(this.item.id)
      }
      if(this.$refs['form'].validate()){
        this.errors.non = [];
        this.process.loading = true;
        this.doActionDialog({
          url: url,
          data: this.data,
          finalizer: (data) => {
            this.process.success = this.checkSuccess(data);
            this.process.log = this.getLog(data);
            this.mapResult(data);
            this.process.executed = this.checkExecuted(data);
            this.process.loading = false;
            this.$emit('refresh')
          },
          catcher: (error) => {
            this.process.loading = false;
            this.process.executed = true;
            this.process.success = false;
            this.process.log = error
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
