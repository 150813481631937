<template>
  <scroll-center-dialog v-model="opened" @close="close" @apply="checkForm" :title=" (select)?'Изменить телефон':'Добавить телефон'" :scrolled="false">
    <v-card flat class="pt-4">
      <v-autocomplete
        class="py-1"
        :error="hasError('code')"
        :error-messages="getError('code')"
        item-title="text"
        item-value="value"
        :items="accountCountryCode"
        label="Телефонный код страны"
        outlined
        placeholder="Россия (+7)"
        v-model="form.code"
      ></v-autocomplete>
      <v-text-field
        class="py-1"
        :error="hasError('number')"
        :error-messages="getError('number')"
        label="Номер"
        placeholder="9876543210"
        outlined
        v-model="form.number"
      ></v-text-field>
      <v-select
        class="py-1"
        :error="hasError('kind')"
        :error-messages="getError('kind')"
        :items="contactType"
        label="Вид"
        item-title="text"
        item-value="value"
        outlined
        required
        v-model="form.kind"
      ></v-select>
      <v-checkbox
        :error="hasError('is_visible')"
        :error-messages="getError('is_visible')"
        label=" Виден всем пользователям"
        outlined
        v-model="form.is_visible"
      ></v-checkbox>
    </v-card>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from '@/modules/core/components/ScrollCenterDialog'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditDetailPhoneDialog',
  props: {
    value: Boolean,
    select: Object,
    opened: Boolean,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      form: {
        code: null,
        kind: null,
        number: null,
        is_visible: false,
      }
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      contactType: 'selectors/getContactType',
      accountCountryCode: 'selectors/getCountryCode',
      getUserProfile: 'adminUsers/getProfile'
    })
  },
  methods: {
    ...mapActions({
      loadContactType: 'selectors/loadContactType',
      loadAccountCountryCode: 'selectors/loadCountryCode',
      createUserPhone: 'adminUsers/createUserPhone',
      updateUserPhone: 'adminUsers/updateUserPhone'
    }),
    loadChoices () {
      this.loadContactType({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    loadSelectors () {
      this.loadAccountCountryCode({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    save () {
      this.$emit('close')
    },
    checkForm () {
      const finalizer = (val) => {
        this.$emit('save')
      }
      const catcher = (data, url) => {
        this.errors.fields = data
      }
      if (this.select) {
        this.updateUserPhone({
          uid: this.getUserProfile.uid,
          id: this.select.id,
          data: this.form,
          finalizer: finalizer,
          catcher: catcher
        })
      } else {
        this.createUserPhone({
          uid: this.getUserProfile.uid,
          data: this.form,
          finalizer: finalizer,
          catcher: catcher
        })
      }
    },
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.loadChoices()
    this.loadSelectors()
    if (this.select) {
      this.form.code = this.select.country_id
      this.form.number = this.select.number
      this.form.kind = this.select.kind
      this.form.is_visible = this.select.is_visible
    }
  }
}
</script>

<style scoped>

</style>
