import {saveFileRequest, sendDeleteRequest, sendGetRequest} from "@/helper/requests";
import admin from "@/urls/admin";

const state = {
  sessionList: {
    list: [],
    count: 0,
    loading: false,
  }
};

const getters= {
  getSessionList: (state) => state.sessionList.list,
  getSessionListCount: (state) => state.sessionList.count,
  getSessionListLoading: (state) => state.sessionList.loading,
};

const actions= {
  loadSessionList: ({commit}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
    if (!url) {
      url = admin.ADMIN.SESSION.ADMIN()
      if (urlAppend) url = urlAppend(url)
    }
    commit('SET_LOADING')
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_LIST', data['results'])
        commit('SET_COUNT', data['count'])
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      },
    )
  },
};

const mutations= {
  SET_LIST: (state, data) => {
    state.sessionList.list = data;
  },
  SET_COUNT: (state, data) => {
    state.sessionList.count = data;
  },
  SET_LOADING: (state) => {
    state.sessionList.loading = true;
  },
  SET_NOT_LOADING: (state) => {
    state.sessionList.loading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
