import helper from "@/urls/helper"

let getOrganizationUrlWithParameters = helper.getOrganizationUrlWithParameters
let getOrganizationUrlAllowed = helper.getOrganizationUrlAllowed

export default {
  getOrganizationUrlWithParameters,
  BOOKER: {
    ALLOWED: () => getOrganizationUrlAllowed(
      "booker|official-worker-list",
      "list"
    ),
    OFFICIAL_WORKER_LIST:
      (gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-list",
        "list",
        gets
      ),
    OFFICIAL_WORKER_DETAIL:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-detail",
        "retrieve",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_ACTIVATE:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-activate",
        "activate",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_DEACTIVATE:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-deactivate",
        "deactivate",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_CHANGE_USERNAME:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-change_username",
        "change_username",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_NORMALIZE:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-normalize",
        "normalize",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_NORMALIZE_AD:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-normalize|ou",
        "normalize_ou",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_UPDATE_ALIAS:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-update|alias",
        "update_alias",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_REGENERATE:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-regenerate",
        "regenerate",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_REGISTER:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-register",
        "register",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_UNLOCK:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-worker-unlock",
        "unlock",
        {id: id},
        gets
      ),
    OFFICIAL_WORKER_CREATE_EMAIL:
      (id, gets = null) => getOrganizationUrlWithParameters(
        'booker|official-worker-create|email',
        'create_email',
        {id: id},
        gets
    ),
    OFFICIAL_WORKER_NORMALIZE_LOGIN:
      (id, gets = null) => getOrganizationUrlWithParameters(
        'booker|official-worker-set|normalize|login',
        'set_normalize_login',
        {id: id},
        gets
      ),
    OFFICIAL_DEPARTMENT_LIST:
      (gets = null) => getOrganizationUrlWithParameters(
        "booker|official-department-list",
        "list",
        gets
      ),
    OFFICIAL_DEPARTMENT_DETAIL:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-department-detail",
        "retrieve",
        {id: id},
        gets
      ),
    OFFICIAL_DEPARTMENT_NORMALIZE_AD:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-department-normalize|ou",
        "normalize_ou",
        {id: id},
        gets
      ),
    OFFICIAL_DEPARTMENT_GENERATE_ABBR:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-department-generate|abbr",
        "generate_abbr",
        {id: id},
        gets
      ),
    OFFICIAL_DEPARTMENT_UPDATE_MIDNAME:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-department-update|midname",
        "update_midname",
        {id: id},
        gets
      ),
    OFFICIAL_LOG_LIST:
      (gets = null) => getOrganizationUrlWithParameters(
        "booker|official-log-list",
        "list",
        gets
      ),
    OFFICIAL_LOG_DETAIL:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-log-detail",
        "retrieve",
        {id: id},
        gets
      ),
    OFFICIAL_ACCEPTED_LIST:
      (gets = null) => getOrganizationUrlWithParameters(
        "booker|official-accepted-list",
        "list",
        gets
      ),
    OFFICIAL_ACCEPTED_DETAIL:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-accepted-detail",
        "retrieve",
        {
          id: id
        },
        gets
      ),
    OFFICIAL_ACCEPTED_RESTART:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-accepted-restart",
        "restart",
        {
          id: id
        },
        gets
      ),
    OFFICIAL_ACCEPTED_NORMALIZE_LOGIN:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-accepted-normalize|login",
        "normalize_login",
        {
          id: id
        },
        gets
      ),
    OFFICIAL_TRANSFERS_LIST:
      (gets = null) => getOrganizationUrlWithParameters(
        "booker|official-moved-list",
        "list",
        gets
      ),
    OFFICIAL_TRANSFERS_DETAIL:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-moved-detail",
        "retrieve",
        {
          id: id
        },
        gets
      ),
    OFFICIAL_TRANSFERS_RESTART:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-moved-restart",
        "restart",
        {
          id: id
        },
        gets
      ),
    OFFICIAL_DISMISSAL_LIST:
      (gets = null) => getOrganizationUrlWithParameters(
        "booker|official-fired-list",
        "list",
        gets
      ),
    OFFICIAL_DISMISSAL_DETAIL:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-fired-detail",
        "retrieve",
        {
          id: id
        },
        gets
      ),
    OFFICIAL_DISMISSAL_RESTART:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-fired-restart",
        "restart",
        {
          id: id
        },
        gets
      ),
    OFFICIAL_DISMISSAL_DEACTIVATE_ACCOUNT:
      (id, gets = null) => getOrganizationUrlWithParameters(
        "booker|official-fired-deactivate|account",
        "deactivate_account",
        {
          id: id
        },
        gets
      ),
  }
}
