<template>
  <div class="page-content" style="margin: 0; width: 100%">
    <List
      v-if="$route.name === names.GROUP.LIST"
    />
    <router-view v-else />
  </div>
</template>

<script>
import List from "@/modules/university_connector/group/pages/List";
import names from "@/modules/university_connector/routers/names";

export default {
  name: "GroupBreadcrumbs",
  components: { List },
  computed: {
    names: () => names,
  },
}
</script>

<style scoped>

</style>
