import admin_names from '@/modules/admins/routers/names'
import admin from '@/urls/admin'
import booker_names from '@/modules/booker/routers/names'
import university_names from '@/modules/university_connector/routers/names'
import booker from '@/urls/booker'

export function getInstMenu () {
  /**
   * Функция которая возвращает нижнее боковое меню инстанса
   * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
   */
  let menu = []
  if (admin.ADMIN.ALLOWED()) {
    menu.push({
      title: 'Администрирование',
      icon: 'admin_panel_settings',
      router: admin_names.MAIN,
      name: admin_names.MAIN
    })
    menu.push({
      title: 'Booker бухгалтерии',
      icon: 'work',
      router: booker_names.MAIN,
      name: booker_names.MAIN
    })
    menu.push({
      title: 'Университет коннектор',
      icon: 'timeline',
      router: university_names.MAIN,
      name: university_names.MAIN
    })
  }
  return menu
}

export function getInstNameByRouterName (router) {
  /**
   * Функция которая возвращает нижнее боковое меню инстанса
   * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
   */
  return getInstMenu().find(el => {
    return router === el.router
  }).name
}
