<template>
  <v-dialog persistent v-model="opened" width="500">
    <v-card class="rounded-lg">
      <v-card-title class="super-grey medium-primary">Подтвердите действие</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        Вы уверены что хотите удалить пользователя {{ user_data.lastname }} {{ user_data.firstname }}
        {{ user_data.midname }}?
      </v-card-text>
      <v-card-text class="error--text">
        ОТМЕНИТЬ ДЕЙСТВИЕ БУДЕТ НЕ ВОЗМОЖНО
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="apply" color="red" text>
          Удалить {{ user_data.username }}
        </v-btn>
        <v-btn @click="close" text>
          Отмена
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'DeleteUserDialog',
  props: {
    opened: Boolean,
    user_data: Object,
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions({
      deleteUser: 'adminUsers/deleteUser',
    }),
    close () {
      this.$emit('close')
    },
    apply () {
      let url = undefined
      this.loading = true
      this.deleteUser({
        url: url,
        id: this.user_data.id,
        finalizer: (data) => {
          this.loading = false
          this.$emit('close')
        },
        catcher: (error) => {
          if (error.detail) {
            this.mapErrors(error)
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
