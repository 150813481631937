<template>
  <v-container style="height: 100%">
    <v-card
      flat
      class="mt-4"
    >
      <v-data-iterator
        :items=name_list
        hide-default-footer
        no-data-text="Нет данных"
      >
        <v-row align-content="start">
          <v-col
            cols="12"
            sm="6"
            lg="4"
            v-for="(item, index) in name_list"
            :key="`card_${index}`"
          >
            <router-link v-if="!item.subs" style="text-decoration: none" :to="{name: item.router.name}">
              <v-hover v-slot="{ hover }">
                <v-card
                  min-height="150"
                  :elevation="hover ? 15 : 6"
                  class="ma-3 d-flex transition-ease-in-out"
                  color="#DADADA"
                  style="border-radius: 20px"
                >
                  <v-container class="my-auto">
                    <v-row class="my-auto">
                      <v-col class="my-auto title">
                        <span style="font-size: 26px">{{ item.title }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-hover>
            </router-link>
          </v-col>
        </v-row>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "Main",
  mixins: [TitledPageMixin],
  props: ['name_list'],
  data() {
    return {
      page_title: 'Booker бухгалтерии',
    }
  },
}
</script>

<style scoped>

</style>
