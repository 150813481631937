import { ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE } from '@/helper/consts'
import names from "@/modules/booker/routers/names";
import Breadcrumbs from "@/modules/booker/Breadcrumbs";
import UsersBreadcrumbs from "@/modules/booker/users/UsersBreadcrumbs";
import DepartmentBreadcrumbs from "@/modules/booker/department/DepartmentBreadcrumbs";
import LogsBreadcrumbs from "@/modules/booker/logs/LogsBreadcrumbs";
import AcceptancesBreadcrumbs from "@/modules/booker/positions/acceptances/Base.vue";
import TransfersBreadcrumbs from "@/modules/booker/positions/transfers/Base.vue";
import DismissalBreadcrumbs from "@/modules/booker/positions/dismissal/Base.vue";

export default ([
  {
    path: '/booker',
    redirect: '/booker/users',
    name: names.MAIN,
    component: Breadcrumbs,
    meta: {
      breadcrumb: 'booker',
      requiredAuth: true,
      instances: [
        ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
      ]
    },
    children: [
      {
        path: 'users',
        name: names.USERS.LIST,
        component: UsersBreadcrumbs,
        meta: {
          breadcrumb: 'Список пользователей',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'departments',
        name: names.DEPARTMENT.LIST,
        component: DepartmentBreadcrumbs,
        meta: {
          breadcrumb: 'Список подразделений',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'logs',
        name: names.LOGS.LIST,
        component: LogsBreadcrumbs,
        meta: {
          breadcrumb: 'Список логов',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'acceptances',
        name: names.ACCEPTANCES.LIST,
        component: AcceptancesBreadcrumbs,
        meta: {
          breadcrumb: 'Список принятий',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'transfers',
        name: names.TRANSFERS.LIST,
        component: TransfersBreadcrumbs,
        meta: {
          breadcrumb: 'Список переводов',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        },
        children: []
      },
      {
        path: 'dismissal',
        name: names.DISMISSAL.LIST,
        component: DismissalBreadcrumbs,
        meta: {
          breadcrumb: 'Список увольнений',
          requiredAuth: true,
          instances: [
            ACCOUNTS_INSTANCE, ORGANIZATION_INSTANCE
          ]
        },
        children: []
      }
    ]
  }
])
