<template>
  <scroll-center-dialog v-model="opened" @close="close" @apply="checkForm" :title=" (select)?'Изменить почты':'Добавить почту'" :scrolled="false">
    <v-card flat class="pt-4">

      <v-text-field
        class="py-1"
        :error="hasError('email')"
        :error-messages="getError('email')"
        label="Почта"
        ref="number"
        outlined
        required
        v-model="form.email"
      ></v-text-field>

      <v-select
        class="pt-1"
        :error="hasError('kind')"
        :error-messages="getError('kind')"
        :items="contactType"
        label="Вид"
        item-title="text"
        item-value="value"
        outlined
        ref="kind"
        required
        v-model="form.kind"
      ></v-select>
      <v-checkbox
        label=" Виден всем пользователям"
        ref="is_visible"
        v-model="form.is_visible"
      ></v-checkbox>
    </v-card>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from '@/modules/core/components/ScrollCenterDialog'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditDetailEmailDialog',
  props: {
    value: Boolean,
    select: Object,
    opened: Boolean,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data () {
    return {
      open: false,
      selectors: [],
      form: {
        email: null,
        kind: null,
        is_visible: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      contactType: 'selectors/getContactType',
      getProfile: 'adminUsers/getProfile'
    })
  },
  methods: {
    ...mapActions({
      loadContactType: 'selectors/loadContactType',
      createUserMail: 'adminUsers/createUserMail',
      updateUserMail: 'adminUsers/updateUserMail'
    }),
    loadChoices () {
      this.loadContactType({
        url: undefined,
        finalizer: (val) => {},
      })
    },
    save () {
      this.$emit('close')
    },
    checkForm () {
      const finalizer = (val) => {
        this.$emit('save')
      }
      const catcher = (data, url) => {
        this.errors.fields = data
      }
      if (this.select) {
        this.updateUserMail({
          uid: this.getProfile.uid,
          id: this.select.id,
          data: this.form,
          finalizer: finalizer,
          catcher: catcher
        })
      } else {
        this.createUserMail({
          uid: this.getProfile.uid,
          data: this.form,
          finalizer: finalizer,
          catcher: catcher
        })
      }
    },
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.loadChoices()
    if (this.select) {
      this.form.email = this.select.email
      this.form.kind = this.select.kind
      this.form.is_visible = this.select.is_visible
    }
  }
}
</script>

<style scoped>

</style>
