import {sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest, sendPutRequest} from "@/helper/requests";
import reference from "@/urls/admin";

const state = {
  cathedra: {
    currentUrl: null,
    list: [],
    loading: null,
    count: 0,
    detail: [],
  }
};

const getters= {
  getCathedraList: (state) => state.cathedra.list,
  getCathedraDetail: state => state.cathedra.detail,
  getCathedraCurrentUrl: state => state.cathedra.currentUrl,
  getCathedraListCount: (state) => state.cathedra.count,
  isCathedraListLoading: (state) => state.cathedra.loading,
};

const actions= {
  loadCathedraList: ({commit}, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.CATHEDRA.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    if (!getters.isLoading || getters.getCathedraCurrentUrl !== url) {
      commit('SET_LOADING')
      sendGetRequest(
        url,
        (data, url) => {
          commit('SET_CATHEDRA_LIST', data)
          commit('SET_CATHEDRA_CURRENT_URL', url)
          commit('SET_NOT_LOADING')
          finalizer(data, url)
        },
        (error) => {
          commit('SET_NOT_LOADING')
          catcher(error)
        }
      )
    }
  },
  syncCathedra: ({commit}, {url, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.CATHEDRA.SYNC()
    }
    sendPostRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  loadCathedraDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.CATHEDRA.DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_CATHEDRA_DETAIL', data);
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createCathedra: ({commit}, {url, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.CATHEDRA.CREATE()
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateCathedra: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.CATHEDRA.UPDATE(id)
    }
    sendPutRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deleteCathedra: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = reference.ADMIN.CATHEDRA.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations= {
  SET_CATHEDRA_LIST: (state, list) => {
    state.cathedra.list = list
  },
  SET_CATHEDRA_DETAIL: (state, value) => {
    state.cathedra.detail = value
  },
  SET_CATHEDRA_CURRENT_URL: (state, value) => {
    state.cathedra.currentUrl = value
  },
  SET_LOADING: (state) => {
    state.cathedra.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.cathedra.loading = false
  },
  SET_CATHEDRA_COUNT: (state, count) => {
    state.cathedra.count = count
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
