<template>
  <div>
    <v-toolbar color="#DADADA">
      <v-toolbar-title>
        Почтовый перечень:
      </v-toolbar-title>
      <v-spacer/>
        <v-menu
          v-if="$vuetify.breakpoint.xsOnly"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }" class="mr-3">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              color="black"
              class="mr-0"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-btn
              depressed
              color="transparent"
              width="100%"
              @click="openCreateDialog"
            >
              Добавить
            </v-btn>
            <v-btn
              depressed
              color="transparent"
              width="100%"
              @click="reloadData"
            >
              Обновить
            </v-btn>
          </v-list>
        </v-menu>
        <div v-else>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon text
                class="pa-0 ma-0"
                v-bind="attrs"
                v-on="on"
                @click="openCreateDialog"
              >
                <v-icon>
                  add
                </v-icon>
              </v-btn>
            </template>
            <span>Добавить</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                text
                class="pa-0 mr-3"
                v-bind="attrs"
                v-on="on"
                @click="reloadData"
              >
                <v-icon>
                  refresh
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить</span>
          </v-tooltip>
        </div>
    </v-toolbar>
    <v-card flat tile>
      <v-data-table
        :headers="headers"
        :items="getList"
        :loading="getLoading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="getCount"
        :footer-props="{'items-per-page-options':[25,35,100]}"
      >
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="openEditDialog(props.item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-on="on"
                v-bind="attrs"
                @click="openDeleteDialog(props.item)"
                medium
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>

        </template>
      </v-data-table>
      <delete-dialog
        :opened.sync="opened_delete"
        :loading="delete_loading"
        @close="closeDeleteDialog"
      >
        <template v-slot:card_action>
          <v-card-text class="mt-3">
            Вы точно хотите удалить шаблон<span v-if="selected && selected.name"> '{{ selected.name }}'</span>?
          </v-card-text>
        </template>
      </delete-dialog>
      <edit-mail-dialog
        v-if="opened_edit"
        :opened="opened_edit"
        :item-data="selected"
        @close="closeEditDialog"
      />
      <create-mail-dialog
        v-if="opened_create"
        :opened="opened_create"
        @close="closeCreateDialog"
      />
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import DeleteDialog from "@/modules/core/components/DeleteDialog";
import EditMailDialog from "@/modules/admins/signer/logs/components/EditMailDialog";
import CreateMailDialog from "@/modules/admins/signer/logs/components/CreateMailDialog";

export default {
  name: "MailList",
  components: {CreateMailDialog, EditMailDialog, DeleteDialog},
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      headers: [
        {
          text: 'Почта',
          align: 'left',
          sortable: false,
          value: 'recipient',
          width: '10%'
        },
        {
          text: 'Название',
          align: 'left',
          sortable: false,
          value: 'name',
          width: '20%'
        },
        {
          text: 'Текст отправки',
          align: 'left',
          sortable: false,
          value: 'msg',
          width: '60%'
        },
        {
          text: 'Действия',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '10%'
        },
      ],
      pagination: {
        size_name: 'size',
        page_name: 'page',
      },
      opened_delete: false,
      opened_edit: false,
      opened_create: false,
      delete_loading: false,
      selected: {},
    }
  },
  computed: {
    ...mapGetters({
      getList: 'mail/getMailList',
      getCount: 'mail/getMailListCount',
      getLoading: 'mail/getMailListLoading'
    })
  },
  methods: {
    ...mapActions({
      loadMailList: 'mail/loadMailList',
      deleteMailList: 'mail/deleteMailList'
    }),
    loadData(url = undefined) {
      this.loadMailList({
        url: url,
        urlAppend: (url) => {
          return this.addSizeParameter(this.addPageParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: () => {
        }
      })
    },
    reloadData() {
      this.loadData()
    },
    openDeleteDialog(item) {
      this.selected = item
      this.opened_delete = true
    },
    openEditDialog(item) {
      if (item) this.selected = item
      this.opened_edit = true
    },
    openCreateDialog() {
      this.opened_create = true
    },
    closeEditDialog(isApply) {
      this.selected = {}
      this.opened_edit = false
      if (isApply) this.reloadData()
    },
    closeCreateDialog(isApply) {
      this.opened_create = false
      if (isApply) this.reloadData()
    },
    closeDeleteDialog(isApply) {
      if (isApply) {
        this.delete_loading = true
        this.deleteMailList({
          id: this.selected.id,
          url: undefined,
          finalizer: (data, url) => {
            this.delete_loading = false
            this.reloadData()
          },
          catcher: (error) => {
            this.delete_loading = false
          },
        });
        this.opened_delete = false
        this.selected = {}
      } else {
        this.opened_delete = false
        this.selected = {}
      }
    }
  },
  created() {
    if (this.getCount === 0) {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.tableCell {
  overflow-x: hidden;
  word-wrap: break-word;
}
</style>
