<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="save"
    :title="'Первичная нормализация'"
    :scrolled="false"
    :loading="loading"
  >
    <div v-if="!loading" class="px-3">
      <v-select
        readonly
        v-model="value.system"
        class="pt-3"
        label="Система"
        :items="selector_system"
        outlined
      ></v-select>
      <v-alert outlined type="error" v-if="hasError('non_field_errors')">
        {{String(getError('non_field_errors'))}}
      </v-alert>
      <v-alert outlined type="error" v-if="hasError('detail')">
        {{String(getError('detail'))}}
      </v-alert>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "actualizeDialog",
  props:{
    opened: Boolean,
    edit_data: Object,
    system: String,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog, SemipolarSpinner },
  data() {
    return {
      loading:false,
      selector_system:[
        {text:'Accounts', value:'accounts'},
        {text:'AD', value:'adc'},
        {text:'Perco', value:'perco'},
        {text:'Booker', value:'booker'},
      ],
      value: {
        system: ''
      },
    }
  },

  methods: {
    ...mapActions({
      changeUserActualize: "faces/changeUserActualize",
    }),
    close() {
      this.$emit('close', false)
    },

    save() {
        let copyData = Object.assign({}, this.value);
      this.loading = true
        this.changeUserActualize({
          id: this.edit_data.guid,
          data: copyData,
          finalizer: () => {
            this.close()
            this.loading = false
          },
          catcher: (errors) => {
            this.mapErrors(errors)
            this.loading = false
          }
        })
      }
  },
  created() {
    this.value.system = this.system
  }
}
</script>

<style scoped>

</style>
