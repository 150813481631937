import adminUrls from "@/urls/admin";
import {sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest} from "@/helper/requests";

const state = {
  languages: {
    list: [],
    loading: null,
    count: 0,
  }
};
const getters = {
  getLanguagesList: (state) => state.languages.list,
  getLanguagesListCount: (state) => state.languages.count,
  isLanguagesListLoading: (state) => state.languages.loading,
};

const actions = {
  loadList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    if (!url) {
      url = adminUrls.ADMIN.USERS.LANGUAGES.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_LANGUAGES_LIST', data)
        commit('SET_LANGUAGES_LIST_COUNT', data.length)
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  loadDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.USERS.LANGUAGES.DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createLanguages: ({commit}, {url, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.USERS.LANGUAGES.CREATE()
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateLanguages: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.USERS.LANGUAGES.UPDATE(id)
    }
    sendPutRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deleteLanguages: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.USERS.LANGUAGES.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations = {
  SET_LANGUAGES_LIST: (state, list) => {
    state.languages.list = list
  },
  REMOVE_LANGUAGES_LIST: (state) => {
    state.languages.list = []
  },
  SET_LOADING: (state) => {
    state.languages.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.languages.loading = false
  },
  SET_LANGUAGES_LIST_COUNT: (state, count) => {
    state.languages.count = count
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
