<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @change="searchData"
        @click:append="searchData"
        clearable
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getLogsList"
      :loading="isLogsListLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getLogsListCount"
      :footer-props="{'items-per-page-options':[50]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :expanded.sync="expanded"
      single-expand
      show-expand
    >
      <template v-slot:item.code="props">
        <td class="body-2 justify-center text-lg-left">{{props.item.command}}</td>
      </template>
      <template v-slot:item.abbr="props">
        <td class="body-2 justify-center text-sm-center">{{props.item.executor}}</td>
      </template>
      <template v-slot:item.name="props">
        <td class="body-2 justify-center text-sm-center">{{props.item.started}}</td>
      </template>
      <template v-slot:item.department="props">
        <td class="body-2 justify-center text-sm-center"><span v-if="props.item.ended">{{props.item.ended}}</span><v-icon v-else color="info">query_builder</v-icon></td>
      </template>
      <template v-slot:item.errors="props">
        <td class="body-2 justify-center text-sm-center">
          <v-icon color="teal" v-if="props.item.has_error">running_with_errors</v-icon>
          <v-icon v-else>clear</v-icon>
        </td>
      </template>
      <template v-slot:item.data-table-expand="props">
        <v-btn small icon @click="loadDetail(props)">
          <v-progress-circular v-if="props.item.detail_loading" color="blue" indeterminate></v-progress-circular>
          <v-icon v-else-if="!props.isExpanded">expand_more</v-icon>
          <v-icon v-else>expand_less</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <logs-expanded :item="expanded_item"></logs-expanded>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from '@/mixins/PaginatedDataMapperMixin'
import { mapActions, mapGetters } from 'vuex'
import names from '@/modules/booker/routers/names'
import LogsExpanded from "@/modules/booker/logs/components/LogsExpanded";
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "List",
  components: {
    LogsExpanded
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin],
  data() {
    return {
      page_title: 'Логи',
      search: {
        parameter: 'search',
        size: 50,
      },
      expanded: [],
      expanded_item: {},
      pagination: {
        size_name: 'size',
      },
      headers: [
        {
          text: 'Файл команды',
          align: 'left',
          sortable: true,
          value: 'code',
          width: '40%'
        },
        {
          text: 'Исполнитель',
          align: 'center',
          sortable: true,
          value: 'abbr',
          width: '20%'
        },
        {
          text: 'Начало',
          align: 'center',
          sortable: true,
          value: 'name',
          width: '15%'
        },
        {
          text: 'Конец',
          align: 'center',
          sortable: true,
          value: 'department',
          width: '15%'
        },
        {
          text: 'Были ошибки',
          align: 'center',
          sortable: true,
          value: 'errors',
          width: '10%'
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      loadList: 'logs/loadList',
      loadLogsDetail: 'logs/loadLogsDetail'
    }),
    loadData(url = undefined) {
      this.loadList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData()
    },
    loadDetail(props) {
      let url = undefined
      if (!props.isExpanded) {
        this.$set(props.item, 'detail_loading', true)
        this.loadLogsDetail({
          url: url,
          id: props.item.id,
          finalizer: (data, url) => {
            this.expanded_item = data
            this.$set(props.item, 'detail_loading', false)
            props.expand(!props.isExpanded)
          },
          catcher: (error) => {
            this.$set(props.item, 'detail_loading', false)
          }
        })
      } else
        props.expand(!props.isExpanded)
    },
  },
  computed: {
    ...mapGetters({
      getLogsList: 'logs/getLogsList',
      getLogsListCount: 'logs/getLogsListCount',
      isLogsListLoading: 'logs/isLogsListLoading'
    }),
    names: () => names
  },
  created () {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
