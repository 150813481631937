import { render, staticRenderFns } from "./normalizeDialog.vue?vue&type=template&id=153fc90f&scoped=true"
import script from "./normalizeDialog.vue?vue&type=script&lang=js"
export * from "./normalizeDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153fc90f",
  null
  
)

export default component.exports