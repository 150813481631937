<template>
  <v-app-bar :clipped-left="clipped" app color="#1D71B8" elevation="0" fixed height="78" class="top_menu">
    <!-- Иконка навигации для открытия меню -->
    <v-app-bar-nav-icon @click.stop="leftMenuToggle" v-if="leftmenu.enabled" class="ml-2 ml-sm-4">
      <v-icon color="white" size="37">menu</v-icon>
    </v-app-bar-nav-icon>

    <!-- Логотип и заголовок -->
    <v-toolbar-title class="hidden-sm-and-down" style="max-width: 400px; min-width: 350px">
      <router-link class="text-decoration-none white--text" to="/">
        <v-layout row no-gutters class="flex-nowrap">
          <v-avatar size="53">
            <v-img :src="logo" contain alt="logo" aspect-ratio="1"></v-img>
          </v-avatar>
          <v-flex align-self="center" class="text-left text-wrap">
            <span v-if="maintoolbar.title">
              <p class="ms_text-h4 white--text text-uppercase mt-4 mb-0">{{ maintoolbar.title }}</p>
              <p class="ms_text-caption white--text mb-0">РХТУ имени Д.И. Менделеева</p>
            </span>
            <span v-else>
              <p class="ms_text-h4 white--text mb-0">РОССИЙСКИЙ ХИМИКО</p>
              <p class="ms_text-h4 white--text mb-0">ТЕХНОЛОГИЧЕСКИЙ УНИВЕРСИТЕТ</p>
              <p class="ms_text-caption white--text mb-0">имени Д.И. Менделеева</p>
            </span>
          </v-flex>
        </v-layout>
      </router-link>
    </v-toolbar-title>

    <v-spacer/>

    <!-- Элементы навигации в правой части App Bar -->
    <div class="top_menu__button white--text d-flex">
      <div class="d-flex flex-nowrap" v-if="$vuetify.breakpoint.width > 1560">
        <div :key="`top_mainInstances_item_${i}`" v-for="(item, i) in maintoolbar.mainInstances">
          <v-btn @click="dispatchRouter(item)" class="ms_text-h6 text-uppercase white--text px-sm-6" text v-if="item.router">
            {{ item.title }}
          </v-btn>
          <v-btn :href="item.href" text class="ms_text-h6 text-uppercase white--text px-sm-6" target="_blank" v-else>
            {{ item.title }}
          </v-btn>
        </div>
      </div>

      <!-- Меню "Сервисы" -->
      <v-menu offset-y class="text-left">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" class="ms_text-h6 white--text px-sm-6">
            Сервисы <v-icon>arrow_drop_down</v-icon>
          </v-btn>
        </template>
        <v-list class="top_menu__button__list">
          <v-list-item class="top_menu__button__list-item"  v-for="item in maintoolbar.secondInstances" :key="item.title" @click="routerPath(item)">
            <v-list-item-title class="top_menu__button__list-title text-left ms_text-body-1">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Кнопка авторизации или информация о пользователе -->
      <v-menu offset-y class="text-center" v-if="isUserAuthenticated">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" class="ms_text-h6 white--text px-sm-6 mr-1">
            <v-avatar size="32" class="mr-3">
              <v-icon v-if="!maintoolbar.userAvatar" color="white" size="34">account_circle</v-icon>
              <v-img v-else :src="maintoolbar.userAvatar" aspect-ratio="1"></v-img>
            </v-avatar>
            <span v-if="$vuetify.breakpoint.width > 500">{{ user.username }}</span>
            <v-icon>arrow_drop_down</v-icon>
          </v-btn>
        </template>
        <v-list class="top_menu__button__list">
          <template v-if="accountAccounts && accountAccounts.has_additional">
            <v-list-item
              class="top_menu__button__list-item"
              :key="`${i}_accountAccounts`"
              v-for="(item, i) in  accountAccounts.accounts"
              @click="changeUserAccount(item.value)"
            >
              <v-list-item-title class="top_menu__button__list-title text-body-1">
                {{ item.text }} <span v-if="item.is_origin">(основной)</span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="top_menu__button__list-item"  v-for="(item, i) in maintoolbar.userActions" :key="i" @click="routerPath(item)">
            <v-list-item-title class="top_menu__button__list-title ms_text-body-1">{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="top_menu__button__list-item" @click="logout(false, true, false)">
            <v-list-item-title class="top_menu__button__list-title ms_text-body-1">Выйти</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn @click="routerLogin" v-else text class="ms_text-h6 white--text px-sm-6">
        Войти
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import {mapState,mapGetters} from "vuex"
import logo from "@/assets/logo.png";
import NotAvatar from "@/assets/NotAvatar.png";
import {goToLoginPage} from "@/helper/auth";
import names from "@/modules/core/routers/names"
import store from "@/store";

export default {
  name: "TopMenu",
  data() {
    return {
      clipped: true,
      logo,
      names,
      NotAvatar
    };
  },
  computed: {
    ...mapGetters({
      isUserAuthenticated: "auth/isAuthenticated",
      accountAccounts: "selectors/accountAccounts",
    }),
    ...mapState({
      leftmenu: state => state.leftmenu,
      maintoolbar: state => state.maintoolbar,
      user: state => state.auth
    })
  },
  methods: {
    routerLogin(){
      goToLoginPage();
    },
    changeUserAccount(value) {
      store.dispatch('auth/changeAccount', {
        data: { user: value },
        finalizer: () => {
        },
        catcher: () => {
        }
      })
    },
    routerPath(item){
      if (item.router)
        this.$router.push({path: item.router});
      else if (item.url){
        window.open(item.url,"_blank");
      } else{
        this.$router.push({name: item.name});
      }
    },
    dispatchRouter(item) {
      if (item.router)
        this.$router.push({name: item.router});
    },
    leftMenuToggle() {
      this.$store.commit("leftmenu/toggleVisible");
    },
    logout: function (dissable_left=true, reload_page=false, redirect=true) {
      this.$store.commit("urls/clearUrls");
      if (dissable_left)
        this.$store.commit("leftmenu/dissable");
      this.$store.commit("leftmenu/replaceItems", []);
      this.$store.dispatch("auth/AUTH_LOGOUT")
        .then(() => {
          if (redirect)
            document.location.href = process.env.VUE_APP_LOGIN_HOST
          if (reload_page)
            document.location.reload()
        });
    }
  },
  created() {
  }
};
</script>

<style scoped lang="scss">
.top_menu {
  background-image: url('@/assets/bg_top_menu.svg'), linear-gradient(90deg, var(--corporate-main) 0%, var(--blue-shades-dark) 100%);
  background-position: 90% 0, center;
  background-repeat: no-repeat;
}

.v-menu__content {
  box-shadow: none !important;
}

.top_menu__button .v-btn {
  margin: 0 12px;
  border-radius: 2000px;

  &:hover {
    background: var(--back-shades-dark);
  }
}

.top_menu__button__list {
  padding: 0;
  margin-top: 12px;
  border-radius: 15px !important;
  background: var(--background-white);
  box-shadow: 0 6px 20px 0 rgba(38, 54, 73, 0.32);
}

.top_menu__button__list-item {
  border-bottom: 1px solid var(--blue-shades-focus);

  &:last-child {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }

  &:first-child {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  &:hover {
    background: var(--blue-shades-focus);
  }
}

.top_menu__button__list-title {
  padding-left: 24px;
  padding-right: 24px;
  color: var(--corporate-main);
}
</style>
