<template>
  <scroll-center-dialog
    v-model="opened"
    @close="close"
    @apply="apply"
    title="Изменить внешнюю почту пользователя"
    :loading="loading"
  >
    <v-card flat class="pt-4">
      <v-text-field
        :error="hasError('email')"
        :error-messages="getError('email')"
        label="email"
        required
        outlined
        v-model="value.email"
        :rules="[rules.required,rules.email]"
      ></v-text-field>
      <v-checkbox
        v-model="force"
        label="Отправить письмо подтверждения"
      >
      </v-checkbox>
    </v-card>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from '@/modules/core/components/ScrollCenterDialog'
import FormErrorsMixin from '@/mixins/FormErrorsMixin'
import {mapActions} from "vuex";

export default {
  name: 'EditEmailDialog',
  props: {
    opened: Boolean,
    user_data: Object,
  },
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog},
  data() {
    return {
      rules: {
        required: value => !!value || 'Пустое поле',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Не верный почтовый ящик'
        },
      },
      value: {
        email: this.user_data.email || null,
      },
      force: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      updateUserEmail: "adminUsers/updateUserEmail"
    }),
    close() {
      this.$emit('close', false)
    },
    apply() {
      let url = undefined
      this.loading = true
      this.updateUserEmail({
          url: url,
          id: this.user_data.id,
          data: {
            email: this.value.email,
            force: !this.force
          },
          finalizer: (data) => {
            this.$emit('refreshItem')
            this.$emit('close', true)
            this.loading = false
          },
          catcher: (error) => {
            if (error.email) {
              this.mapErrors(error)
            }
            this.loading = false
          }
        }
      )
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
