<template>
  <div>
    <v-toolbar color="#DADADA" elevation="0">
      <v-toolbar-title>
        Перечень всех подписанных документов:
      </v-toolbar-title>
      <v-spacer/>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <div v-else>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              text
              class="pa-0 mr-3"
              v-bind="attrs"
              v-on="on"
              @click="reloadData"
            >
              <v-icon>
                refresh
              </v-icon>
            </v-btn>
          </template>
          <span>Обновить</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-row dense>
      <v-text-field
        v-model="search.parameters.fio"
        append-icon="search"
        label="Поиск по ФИО"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        :class="!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3' : 'px-2 mx-2 my-3'"
        clearable
        @change="searchData"
        @click:append="searchData"
        @keydown.enter="searchData"
        hide-details
      />
      <v-text-field
        v-model="search.parameters.file_name"
        append-icon="search"
        label="Поиск по имени файла"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        :class="!$vuetify.breakpoint.smOnly && !$vuetify.breakpoint.xsOnly? 'pa-1 ma-3' : 'px-2 mx-2 my-3'"
        clearable
        @change="searchData"
        @click:append="searchData"
        @keydown.enter="searchData"
        hide-details
      />
    </v-row>
    <v-card flat tile>
      <v-data-table
        :headers="headers"
        :items="getList"
        :loading="getLoading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="getCount"
        :footer-props="{'items-per-page-options':[25,35,100]}"
      >
        <template v-slot:item.name="props">
          {{props.item.fio}} ({{ props.item.from_email}})
        </template>
        <template v-slot:item.date_download="props">
          {{ props.item.document.loaded}}
        </template>
        <template v-slot:item.file_name="props">
          {{ props.item.document.file_name}}
        </template>
        <template v-slot:item.date_upload="props">
          {{ props.item.date}}
        </template>
        <template v-slot:item.signature="props">
          {{props.item.recipient.name}} ({{ props.item.recipient.recipient }})
        </template>
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="loadFile(props.item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  cloud_download
                </v-icon>
              </v-btn>
            </template>
            <span>Скачать</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="opened_error" width="400">
      <v-card>
        <v-card-title>
          Лог ошибки
          <v-spacer/>
          <v-btn
            icon
            @click="opened_error=false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="error" outlined class="ma-3">
            {{ error }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "LogsList",
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      headers: [
        {
          text: 'Отправитель',
          align: 'left',
          value: 'name',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Дата загрузки',
          align: 'left',
          value: 'date_download',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Файл',
          align: 'left',
          sortable: false,
          value: 'file_name',
          width: '15%'
        },
        {
          text: 'Дата отправки',
          align: 'left',
          value: 'date_upload',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Куда отправлено',
          align: 'left',
          sortable: false,
          value: 'signature',
          width: '25%'
        },
        {
          text: 'Действия',
          align: 'center',
          value: 'actions',
          sortable: false,
          width: '10%'
        },
      ],
      pagination: {
        size_name: 'size',
        page_name: 'page',
      },
      search: {
        parameters: {
          fio: null,
          file_name: null,
        },
      },
      error: null,
      opened_error: false,
    }
  },
  computed: {
    ...mapGetters({
      getList: 'signerLogs/getLogsList',
      getCount: 'signerLogs/getLogsListCount',
      getLoading: 'signerLogs/getLogsListLoading'
    })
  },
  watch: {
    'search.parameters.file_name': function (nVal, oVal) {
      if (this.wait_loader !== null)
        clearTimeout(this.wait_loader)
      this.wait_loader = setTimeout(() => {
        if (this.search.parameters.file_name === '') {
          this.clearSearchData('file_name');
        }
      }, 1000);
    },
    'search.parameters.fio': function (nVal, oVal) {
      if (this.wait_loader !== null)
        clearTimeout(this.wait_loader)
      this.wait_loader = setTimeout(() => {
        if (this.search.parameters.fio === '') {
          this.clearSearchData('fio');
        }
      }, 1000);
    },
  },
  methods: {
    ...mapActions({
      loadLogsList: 'signerLogs/loadLogsList',
      upLoadRecipient: 'recipients/upLoadRecipient'
    }),
    loadData(url = undefined) {
      this.loadLogsList({
        url: url,
        urlAppend: (url) => {
          return this.addSizeParameter(this.addPageParameter(url));
        },
        finalizer: (data, url) => {
          for (let i in data.results) {
            this.$set(data.results[i], "uid_fio",data.results[i].document.signature.user)
            this.$set(data.results[i], "fio","-")
          }
          generateListUidToName(data.results,"uid_fio","fio")
          this.setCurrentLoadUrl(url)
        },
        catcher: () => {
        }
      })
    },
    loadFile(item, url = undefined) {
      this.upLoadRecipient({
        id: item.document.id,
        url: url,
        finalizer: (data) => {
          this.download(data, item.document.file_name, "application/pdf")
        },
        catcher: (error) => {
          this.error = error
          this.opened_error = true
        }
      })
    },
    download(content, fileName, contentType) {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(content);
      a.download = fileName;
      a.click();
    },
    reloadData() {
      this.clearSearchData()
      this.loadData()
    },
    addSearchParameter(url) {
      return this.addGetParameters(
        url,
        this.search.parameters
      )
    },
    clearSearchData: function (param) {
      this.cleanPaginationPageData();
      switch (param) {
        case 'file_name' :{
          this.search.parameters.file_name = null;
          break
        }
        case 'fio': {
          this.search.parameters.fio = null;
          break
        }
        default: {
          this.search.parameters.file_name = null;
          this.search.parameters.fio = null;
        }
      }
      this.setCurrentLoadUrl(
        this.addSearchParameter(
          this.getCurrentLoadUrl()
        ),
        true
      );
    },
  },
  created() {
    if (this.getCount === 0) {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
