<template>
  <scroll-center-dialog
    v-model="opened"
    :title="title(edit_data.name)"
    @close="close"
    @apply="apply"
    :loading="loading"
    :applyDisable="!valid"
  >
    <v-container>
      <v-form v-model="valid">
        <v-text-field
          v-model="data.name"
          label="Название страны"
          :error="hasError('name')"
          :error-messages="getError('name')"
          clearable
          outlined
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="data.code"
          label="Телефонный код страны"
          :error="hasError('code')"
          :error-messages="getError('code')"
          clearable
          outlined
          :rules="[rules.required, rules.maxLength, rules.codePattern]"
        />
      </v-form>
    </v-container>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions} from "vuex";

export default {
  name: "editCountriesDialog",
  mixins: [FormErrorsMixin],
  props:{
    opened: {
      type: Boolean,
      required: true
    },
    edit_data: {
      type: Object,
      default: () => ({
        name: "",
        code: ""
      })
    }
  },
  components: {ScrollCenterDialog},
  data() {
    return {
      title: v => v ? "Редактирование страны" : "Добавить страну",
      loading: false,
      data: {
        name: this.edit_data.name,
        code: this.edit_data.code
      },
      codePattern: new RegExp("^\\+[0-9]+$"),
      rules: {
        required: v => !!v || "Поле обязательно для заполнения.",
        maxLength: v => v.length <= 10 || "Убедитесь, что это значение содержит не более 10 символов.",
        codePattern: v => this.codePattern.test(v) || "Неверная запись кода страны"
      },
      valid: false
    }
  },
  methods: {
    ...mapActions({
      updateCountry: "countries/updateCountry",
      createCountry: "countries/createCountry"
    }),
    close() {
      this.$emit("close")
    },
    apply() {
      if (this.edit_data.name)
        this.save()
      else
        this.create()
    },
    save() {
      let url = undefined
      this.updateCountry({
        url: url,
        id: this.edit_data.id,
        data: this.data,
        finalizer: () => {
          this.loading = false
          this.$emit("close")
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    create() {
      this.loading = true
      let url = undefined
      this.createCountry({
        url: url,
        data: this.data,
        finalizer: () => {
          this.loading = false
          this.$emit("close")
        },
        catcher: (error) => {
          this.loading = false
          this.mapErrors(error)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
