<template>
  <scroll-center-dialog
    v-model="opened"
    @close="closeDialog(false)"
    @apply="applyDialog"
    width="1000"
    :apply-disable="!canApply"
    :loading="loadingApply"
    title="Редактирование связанных аккаунтов"
  >
    <v-container>
      <v-text-field
        v-model="search.value"
        label="Доступные аккаунты"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        :loading="loadingSelector"
        @click:clear="clearSearchData"
        @keydown.enter="searchData"
        hide-details
        clearable
        outlined
      />
    </v-container>
    <v-container>
      <v-container class="bg-grey-lighten-3 px-0">
        <v-row no-gutters>
          <v-col cols="6">
            <v-row no-gutters justify="center">
              Доступные аккаунты
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters justify="center">
              Связанные аккаунты
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="table-border">
        <v-row no-gutters style="max-height: 300px">
          <v-col @scroll="loadOnScroll" cols="6" style="max-height: 300px; overflow-y: auto" v-if="!loadingSelector && selector.length">
            <v-row class="text-left" no-gutters justify="space-between" v-for="item in selector" :key="item.value" align="center">
              <v-col cols="11">
                <v-list-item-title>{{item.fio}}</v-list-item-title>
                <v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="addItemToConnectedAccounts(item)">
                  <v-icon>
                    add
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else-if="!selector.length">
            нет данных
          </v-col>
          <div class="mx-auto my-6" v-else>
            <semipolar-spinner
              class="d-inline-block"
              :animation-duration="2000"
              :size="115"
              color="#1935ff"
            />
          </div>
          <v-col cols="6" style="max-height: 300px; overflow-y: auto">
            <v-row no-gutters class="pt-1 pl-3">
              <span>{{getNowOrigin}}</span>
            </v-row>
            <v-row no-gutters justify="center" v-for="item in connectedAccounts" :key="item.value" align="center">
              <v-row no-gutters align="center" class="ml-2">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-checkbox
                        class="mb-2"
                        hide-details
                        dense
                        v-model="item.isOrigin"
                        :label="item.fio"
                        :disabled="!!getOrigin && !item.isOrigin || item?.is_delete"
                      >
                        <template v-slot:label>
                          <span :class="item?.is_delete ? 'text-decoration-line-through text--primary' : 'text--primary'">{{item.fio}}</span>
                          <span v-if="!('is_origin' in item)" class="text--secondary">&nbsp(новый)</span>
                        </template>
                      </v-checkbox>
                    </span>
                  </template>
                  <span>Сделать основным</span>
                </v-tooltip>
              </v-row>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="backItemToSelector(item)"
                    :color="item?.is_delete ? 'green' : 'red'"
                    :disabled="!!getOrigin && !!item.isOrigin"
                  >
                    <v-icon>
                      {{item?.is_delete ? 'restore_from_trash' : 'delete'}}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{item?.is_delete ? 'Восстановить' : 'Удалить'}}</span>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import paginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "AccountsDialog",
  components: {ScrollCenterDialog, SemipolarSpinner},
  mixins: [paginatedDataMapperMixin],
  data() {
    return {
      loadingSelector: false,
      loadingScroll: false,
      loadingApply: false,
      search: {
        parameter: 'information',
        value: ''
      },
      timeout: null,
      firstConnected: [],
    }
  },
  props: {
    opened: {
      required: true,
      type: Boolean,
      default: false
    },
    id: {
      required: true,
      type: Number
    },
    accounts: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      selector: "accounts/getSelector",
      connectedAccounts: "accounts/getConnectedAccounts",
      getDeletedAccounts: "accounts/getDeletedAccounts",
      getCreateAccounts: "accounts/getCreateAccounts",
      getOrigin: "accounts/getOrigin",
      getNextUrl: "accounts/getNextUrl",
    }),
    canApply() {
      return !!this.getDeletedAccounts.length || !!this.getCreateAccounts.length || !!this.getOrigin
    },
    getNowOrigin() {
      return `${this.accounts.find(item => item.is_origin).fio} (основной)`
    }
  },
  watch: {
    'search.value': function (nVal, oVal) {
      if (this.timeout !== null)
        clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.searchData()
      }, 1000);
    },
  },
  methods: {
    ...mapActions({
      loadSelector: "accounts/loadAccountsSelector",
      addItemToConnectedAccounts: "accounts/addItemToConnectedAccounts",
      addItemsToConnectedAccounts: "accounts/addItemsToConnectedAccounts",
      backItemToSelector: "accounts/backItemToSelector",
      clearConnectedAccounts: "accounts/clearConnectedAccounts",
      editConnectedAccounts: "accounts/editConnectedAccounts",
      clearSelector: "accounts/clearSelector"
    }),
    ...mapMutations({
      setNextUrl: "accounts/SET_NEXT"
    }),
    closeDialog(apply) {
      this.$emit('close', apply)
    },
    applyDialog() {
      this.loadingApply = true
      this.editConnectedAccounts({
        id: this.id,
        finalizer: (data, url) => {
          this.loadingApply = false
          this.closeDialog(true)
        },
        catcher: (error) => {
          this.loadingApply = false
        }
      })
    },
    log(log) {
      console.log('logging: ', log)
    },
    loadOnScroll(event) {
      if (!this.loadingScroll && !!this.getNextUrl && (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 50)) {
        if (this.search.value) {
          this.setNextUrl(
            this.addSearchParameter(
              this.getNextUrl
            )
          )
        }
        this.loadingScroll = true
        this.loadSelector({
          onScroll: true,
          url: this.getNextUrl,
          finalizer: (data, url) => {
            this.loadingScroll = false
          },
          catcher: (data, url) => {
            this.loadingScroll = false
          }
        })
      }
    },
    loadSelectors(url = undefined, search = false) {
      this.loadingSelector = true
      this.loadSelector({
        onScroll: false,
        url,
        finalizer: (data, url) => {
          if (!search)
            this.addItemsToConnectedAccounts(this.accounts)
          this.setCurrentLoadUrl(url)
          this.loadingSelector = false
        },
        catcher: (error) => {
          this.loadingSelector = false
        }
      })
    },
    searchData: function () {
      if (this.search.value !== null && !this.loadingSelector) {
        this.setCurrentLoadUrl(
          this.addSearchParameter(
            this.getCurrentLoadUrl()
          )
        )
        this.loadSelectors(this.getCurrentLoadUrl(), true)
      }
    },
    clearSearchData: function () {
      this.search.value = '';
      if (this.getCurrentLoadUrl() !== null) {
        this.setCurrentLoadUrl(
          this.addSearchParameter(
            this.getCurrentLoadUrl()
          ),
        );
        this.searchData()
      }
    },
  },
  beforeDestroy() {
    this.clearConnectedAccounts()
    this.clearSelector()
  },
  created() {
    this.loadSelectors()
  }
}
</script>

<style scoped lang="scss">
.table-border {
  box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.25);
}
</style>
