<template>
  <v-container :fluid="true" style="width: 100%" class="px-0 pb-0">
    <v-card class="rounded-lg">
      <v-toolbar
        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6 style-color-main-gray text-left px-4 pr-1' : 'text style-color-main-gray text-left px-4 pr-1'"
        dense
        flat
      >
        <span class="dark-primary"> Информация об образовании </span>
        <v-spacer/>
      </v-toolbar>
      <v-expansion-panels
        v-model="expanded_items"
        multiple
        accordion
        flat
        focusable
        class="rounded-b-lg"
      >
        <v-expansion-panel
          :height="toolbarDisplayHeight(education)"
          v-for="(education, index) in userStudent"
          :key="`${index}_education`"
          elevation="0"
          :class="index !== education.length - 1 && !expanded_items.includes(index) ? 'rounded-0 border-b-sm text-left' : 'rounded-0 border-0 text-left'"
        >
          <v-expansion-panel-header>
            <span class="dark-primary"> {{ education.direction }}
              <v-chip :class="$vuetify.breakpoint.xs ? 'mt-1' : ''" style="margin-bottom: 1px">
                {{ education.active ? 'Обучается' : 'Завершил обучение' }}
              </v-chip>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              no-gutters
              :class="($vuetify.breakpoint.mdAndUp && i%2) ? 'bg-grey-lighten-3 rounded-lg' : 'rounded-lg'"
              v-for="(row, i) in data" :key="`row${i}`"
            >
              <v-col
                cols="12"
                :md="(row[col].typeField === 'solo') ? 12 : 6"
                :class="getTableColStyle(col, row, i)"
                v-for="col in Object.keys(row)" :key="`row${i}_${col}`"
              >
                <b>{{ row[col].text }}:</b> {{ education[row[col].field] }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
          <v-spacer/>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserStudent",
  data() {
    return {
      expanded_items: [],
      data: [
        [
          { text: "Год поступления", field: "entry_year" },
          { text: "Форма обучения", field: "form" }
        ],
        [
          { text: "Уровень образования", field: "level" },
          { text: "Группа", field: "education_group" }
        ],
        [
          { text: "Зачетная книжка", field: "record_book_number" },
          { text: "Финансирование", field: "financing" }
        ],
        [
          { text: "Направление подготовки", field: "direction", typeField: "solo" }
        ],
        [
          { text: "Профиль подготовки", field: "profile", typeField: "solo" }
        ],
        [
          { text: "Факультет", field: "faculty", typeField: "solo" }
        ],
        [
          { text: "Кафедра", field: "cathedra", typeField: "solo" }
        ]
      ]
    }
  },
  computed: {
    ...mapGetters({
      userStudent: "adminUsers/getUserStudentInfo"
    })
  },
  methods: {
    getTableColStyle (col, row, i) {
      if (this.$vuetify.breakpoint.smAndDown && (col % 2 || (row[col].typeField === "solo" && i % 2 === 0))) {
        return "pa-3 bg-grey-lighten-3 rounded-lg"
      }
      return "pa-3 rounded-lg pr-md-8"
    },
    toolbarDisplayHeight (education) {
      if (this.$vuetify.breakpoint.width < 380 && (education.direction.length > 30)) {
        return 240
      } else if (this.$vuetify.breakpoint.width < 430 && (education.direction.length > 40)) {
        return 190
      } else if (this.$vuetify.breakpoint.width < 500 && (education.direction.length > 40)) {
        return 160
      } else if (this.$vuetify.breakpoint.xs) {
        return 128
      } else if (this.$vuetify.breakpoint.sm && (education.direction.length > 30)) {
        return 98
      } else if (this.$vuetify.breakpoint.width < 1680 && (education.direction.length > 80)) {
        return 86
      } else {
        return 48
      }
    }
  },
  mounted() {
    let idx = this.userStudent.findIndex(el => el.active)
    if (idx !== -1)
      this.expanded_items.push(idx)
  }
}
</script>

<style scoped>

</style>
