<template>
  <div class="page-content" style="margin: 0; width: 100%">
    <List
      v-show="$route.name === names.ACCOUNTS.LIST"
    />
    <router-view v-if="$route.name !== names.ACCOUNTS.LIST" />
  </div>
</template>

<script>
import List from "@/modules/admins/accounts/pages/List.vue";
import names from "@/modules/admins/routers/names";

export default {
  name: "Base",
  components: {List},
  computed: {
    names: () => names,
  },
}
</script>

<style scoped>

</style>
