<template>
  <user-action-form-template
    :process="process"
    :can_process="can_process"
    title="Смена логина пользователя администратором"
    :open="open"
    @refresh="refresh"
    @exit="exit"
    @action="action"
    :item="item"
    :non_field_errors="this.errors.non"
    @dismiss_error="deleteNonFieldError($event)"
  >
    <template v-slot:action-button-title>
      Смена логина
    </template>
    <template v-slot:error-text>
      Во время смены логина возникли непредвиденные ошибки
    </template>
    <template v-slot:form>
      <v-form ref="form">
        <v-layout>
          <v-flex>
            Пользователь:
          </v-flex>
        </v-layout>
        <v-layout class="my-2">
          <v-flex class="font-weight-bold">
            {{item.lastname}} {{item.firstname}} {{item.midname}}
          </v-flex>
        </v-layout>
        <div>
          <v-layout>
            <v-flex>
              <v-alert type="info" outlined>
                Обратите внимание, что логин меняется только по личному обращению пользователя, и только по причине спорных букв транслитерации.
                Изначально логин пользователя генерируется согласно актуальным правилам формирования логина.
                <v-btn depressed color="info--text" v-if="info.generate_detail" @click="info.generate_detail = !info.generate_detail">
                  скрыть <v-icon>expand_less</v-icon>
                </v-btn>
                <v-btn depressed color="info--text" v-else @click="info.generate_detail = !info.generate_detail">
                  развернуть <v-icon>expand_more</v-icon>
                </v-btn>
                <v-expand-transition>
                  <ul v-if="info.generate_detail">
                    <li>Фамилия транслитерируется и берутся не более 15 первых символо</li>
                    <li>Если фамилия двойная то вторая фамилия отбрасывается и берутся не более 15 первых символов</li>
                    <li>В случае наличия отчества после транслитерации имени берется первая буква, отделяемая от остальных частей точками</li>
                    <li>В случае отсутвия отчества имя используется как последняя часть</li>
                    <li>Конечная часть логина транслитерируется и берется первый символ, если логин уже занят берется 2 символа и более</li>
                  </ul>
                </v-expand-transition>
                <br/>
              </v-alert>

              <v-text-field
                :rules="[rules.required, rules.latin, rules.length]"
                :error="hasError('username')"
                :error-messages="getError('username')"
                label="Новый логин пользователя"
                placeholder="Введите новый логин пользователя"
                v-model="data.username"
                outlined
              >
                <span slot="label">Новый логин<span class="red--text">*</span>:</span>
              </v-text-field>

            </v-flex>
          </v-layout>
          <v-alert type="info" outlined>
            Изменение пароля:
            <v-switch class="my-0 mr-auto" v-model="data.is_password_change" persistent-hint label="Установить стандартный пароль" hide-details ripple>Установить стандартный пароль</v-switch>
            <v-expand-transition>
              <div v-show="data.is_password_change">
                Пароль по умолчанию Pwd*12345678
              </div>
            </v-expand-transition>
          </v-alert>
          <v-alert type="warning" outlined>
            После запуска процесса, отменить его действие будет невозможно, аккаунт работника окончательно будет переименован
            на АД и в Портале и в почте.
          </v-alert>
        </div>
      </v-form>
    </template>
    <template v-slot:success>
      <p v-if="process.result.changed">
        Сводная информация по нормализации пользователя {{item.lastname}} {{item.firstname}} {{item.midname}}!
      </p>
      <table style="border: 1px solid black" v-if="process.result.changed">
        <tr>
          <td class="px-2">Старый логин</td>
          <td class="px-2">Новый логин</td>
          <td class="px-2" v-if="process.result.password && process.result.password.is_password_change">Новый пароль</td>
        </tr>
        <tr>
          <td class="px-2">{{ process.result.username.old }}</td>
          <td class="px-2">{{ process.result.username.new }}</td>
          <td class="px-2" v-if="process.result.password && process.result.password.is_password_change">{{ process.result.password.ad.password }}</td>
        </tr>
      </table>
      <p v-if="process.result.changed && process.result.password.is_password_change">
        <br>Пользователю при первом входе на рабочей станции или в портале необходимо будет сменить пароль.
        <br>Время действия одноразового пароля не ограничено.
      </p>
    </template>
  </user-action-form-template>
</template>

<script>
import userActionFormTemplate from "@/modules/booker/users/components/UserActionFormTemplate";
import userActionFormMixin from "@/mixins/userActionFormMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import booker from "@/urls/booker";

export default {
  name: "UserChangeUsernameDialog",
  components: {userActionFormTemplate},
  mixins: [userActionFormMixin, FormErrorsMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      rules: {
        latin: value => {
          const pattern = /^[a-zA-Z\-]{3,20}\.[a-zA-Z]{1,5}\.[a-zA-Z]{0,5}$/
          return pattern.test(value) || 'Не верный формат логина'
        },
        length: value => {
          return value.length < 20 || 'Слишком длинный'
        },
        ...userActionFormMixin.data().rules,
      },
      info: {
        generate_detail: false,
      },
      url: undefined,
      current_username: '',
      data: {
        username: '',
        is_password_change: true,
      },
    }
  },
  methods: {
    checkSuccess(json){
      return !json.has_error
    },
    checkExecuted(json) {
      return json.changed;
    },
    cleanData: function () {
      this.data.username = '';
      this.data.is_password_change = false;
      this.current_username = '';
    },
    cleanResult: function () {
      this.process.result = {};
    },
    mapResult(result){
      this.process.result = result;
      if(!result.changed)
        this.field_errors = result.detail;
    },
    afterCan(result) {
      this.data.username = result['current_username'];
      this.current_username = result['current_username'];
    },
    checkCan(){
      let url = booker.BOOKER.OFFICIAL_WORKER_CHANGE_USERNAME(this.item.id)
      this.can_process.loading = true;
      this.checkActionDialog({
        url: url,
        finalizer: (data) => {
          this.can_process.loading = false;
          this.can_process.can = data['can'];
          this.can_process.detail = data['detail'];
          this.afterCan(data);
        },
        catcher: (error) => {
          this.can_process.loading = false;
          this.error = error
        }
      })
    },
    action(){
      let url = booker.BOOKER.OFFICIAL_WORKER_CHANGE_USERNAME(this.item.id)
      if(this.$refs['form'].validate()){
        this.errors.non = [];
        this.process.loading = true;
        this.doActionDialog({
          url: url,
          data: this.data,
          finalizer: (data) => {
            this.process.success = this.checkSuccess(data);
            this.process.log = this.getLog(data);
            this.mapResult(data);
            this.process.executed = this.checkExecuted(data);
            this.process.loading = false;
            this.$emit('refresh')
          },
          catcher: (error) => {
            this.process.loading = false;
            this.process.executed = true;
            this.process.success = false;
            this.process.log = error
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
