import helper from '@/urls/helper'

let getOrganizationUrlWithParameters = helper.getOrganizationUrlWithParameters

export default {
  getOrganizationUrlWithParameters,
  WORKER: {
    DEPARTMENT_LIST:
      (organization_id, gets = null) => getOrganizationUrlWithParameters(
        'workers|department|organization|department-list',
        'list',
        {organization_id: organization_id},
        gets
      ),
    DEPARTMENT_ABOLISHED_LIST:
      (organization_id, gets = null) => getOrganizationUrlWithParameters(
        'admin|department|organization|department-abolished-tree',
        'abolished_tree',
        {organization_id: organization_id},
        gets
      ),
    DEPARTMENT_NOTABOLISHED_LIST:
      (organization_id, gets = null) => getOrganizationUrlWithParameters(
        'admin|department|organization|department-tree',
        'tree',
        {organization_id: organization_id},
        gets
      ),
    DEPARTMENT_ADD_COMMENT:
      (organization_id,id, gets = null) => getOrganizationUrlWithParameters(
        'admin|department|organization|department-update-comment',
        'update_comment',
        {organization_id: organization_id, id: id},
        gets
      ),
    DEPARTMENT_LIST_COMMENT:
      (organization_id,id, gets = null) => getOrganizationUrlWithParameters(
        'admin|department|organization|department-comment-history',
        'comment_history',
        {organization_id: organization_id, id: id},
        gets
      ),
    DEPARTMENT_DELETE_COMMENT:
      (organization_id,id, gets = null) => getOrganizationUrlWithParameters(
        'admin|department|organization|department-delete-comment',
        'delete_comment',
        {organization_id: organization_id, id: id},
        gets
      )
  }
}
