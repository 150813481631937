<template>
  <v-container>
    <v-row>
      <v-col cols="3" class="text-left">
        <strong>Работник: </strong>
        <span>{{ item.face.fio }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>C должности: </strong>
        <span>{{ item.from_profession.name }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>На должность: </strong>
        <span>{{ item.to_profession.name }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>С подразделения: </strong>
        <span>{{ item.from_department.name }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>В подразделение: </strong>
        <span>{{ item.to_department.name }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Создано: </strong>
        <span>{{ displayDate(item.created) }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Обновлено: </strong>
        <span v-if="item.updated">{{ displayDate(item.updated) }}</span>
        <span v-else>-</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Выполнено: </strong>
        <v-icon v-if="item.is_execute" color="green">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Дата выполнения: </strong>
        <span v-if="item.executed">{{ displayDate(item.executed) }}</span>
        <span v-else>-</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Удален: </strong>
        <v-icon v-if="item.is_delete" color="green">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Со ставки: </strong>
        <span>{{ item.from_rate }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>На ставку: </strong>
        <span>{{ item.to_rate }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>C какого вида занятости: </strong>
        <span>{{ item.from_kind }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>На какой вид занятости: </strong>
        <span>{{ item.to_kind }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Нормализовано в АД: </strong>
        <v-icon v-if="item.place_at_ad.normalize" color="green">done</v-icon>
        <v-icon v-else color="red">clear</v-icon>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Путь в АД: </strong>
        <span>{{ item.place_at_ad.path }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Подразделение в организации: </strong>
        <span>{{ item.place_at_organization.department }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Должность в организации: </strong>
        <span>{{ item.place_at_organization.position }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Положение в perco: </strong>
        <span v-if="item.place_at_perco">{{ item.place_at_perco }}</span>
        <span v-else>-</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Дата перевода: </strong>
        <span>{{ displayDate(item.date) }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Uid организации: </strong>
        <span>{{ item.organization.guid }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Название организации: </strong>
        <span>{{ item.organization.name }}</span>
      </v-col>
      <v-col cols="3" class="text-left">
        <strong>Guid: </strong>
        <span>{{ item.guid }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {displayDate} from "@/helper";

export default {
  name: "TransfersExpand",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    displayDate
  }
}
</script>

<style scoped>

</style>
