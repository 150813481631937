import helper from "@/urls/helper"

let getAdminUrlWithParameters = helper.getAdminUrlWithParameters
let getOrganizationUrlWithParameters = helper.getOrganizationUrlWithParameters
let getAdminUrlAllowed = helper.getAdminUrlAllowed

export default {
  getAdminUrlWithParameters,
  ADMIN: {
    ALLOWED: () => getAdminUrlAllowed(
      "admin|users|users-list",
      "list"
    ),
    FACULTY: {
      LIST:
        (gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|faculty-list",
          "list",
          {},
          gets
        ),
      DETAIL:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|faculty-detail",
          "retrieve",
          {id: id},
          gets
        ),
      CREATE:
        (gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|faculty-list",
          "create",
          null,
          gets
        ),
      UPDATE:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|faculty-detail",
          "update",
          {id: id},
          gets
        ),
      DELETE:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|faculty-detail",
          "destroy",
          {id: id},
          gets
        )
    },
    GEO: {
      LIST:
        (gets = null) => getOrganizationUrlWithParameters(
          "admin|geo|circle-list",
          "list",
          {},
          gets
        ),
      DETAIL:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|geo|circle-detail",
          "retrieve",
          {id: id},
          gets
        ),
      CREATE:
        (gets = null) => getOrganizationUrlWithParameters(
          "admin|geo|circle-list",
          "create",
          null,
          gets
        ),
      UPDATE:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|geo|circle-detail",
          "update",
          {id: id},
          gets
        ),
      DEFAULT:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|geo|circle-default",
          "set_default",
          {id: id},
          gets
        ),
      DELETE:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|geo|circle-detail",
          "destroy",
          {id: id},
          gets
        )
    },
    CATHEDRA: {
      LIST:
        (gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|cathedra-list",
          "list",
          {},
          gets
        ),
      DETAIL:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|cathedra-detail",
          "retrieve",
          {id: id},
          gets
        ),
      CREATE:
        (gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|cathedra-list",
          "create",
          null,
          gets
        ),
      UPDATE:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|cathedra-detail",
          "update",
          {id: id},
          gets
        ),
      DELETE:
        (id, gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|cathedra-detail",
          "destroy",
          {id: id},
          gets
        ),
      SYNC:
        (gets = null) => getOrganizationUrlWithParameters(
          "admin|department|organization|cathedra-sync",
          "sync",
          {},
          gets
        )
    },
    AUTHENTICATION: {
      LOGIN: {
        AS:
          (gets = null) => getAdminUrlWithParameters(
            "admin|authentication|login|as",
            "post",
            gets
          )
      }
    },
    USERS: {
      LIST:
        (gets = null) => getAdminUrlWithParameters(
          "admin|users|users-list",
          "list",
          gets
        ),
      DETAIL:
        (id, gets = null) => getAdminUrlWithParameters(
          "admin|users|users-detail",
          "retrieve",
          { id: id },
          gets
        ),
      USER: {
        WORKER_INFO:
          (uid, gets = null) => getAdminUrlWithParameters(
            "admin|users|user|worker",
            "get",
            {user_uid: uid}
          ),
        STUDENT_INFO:
          (uid, gets = null) => getAdminUrlWithParameters(
            "admin|users|user|student",
            "get",
            {user_uid: uid}
          ),
        CHANGE_AVATAR:
          (user_uid, gets = null) => getAdminUrlWithParameters(
            "users|user|profile|avatar|change",
            "put",
            { user_uid: user_uid },
            gets
          ),
        CHANGE_PASSWORD:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|users-change_password",
            "change_password",
            {id: id},
            gets
          ),
        CHANGE_EMAIL:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|users-detail",
            "update",
            { id: id },
            gets
          ),
        DELETE:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|users-detail",
            "destroy",
            { id: id },
            gets
          ),
        ACTIVATE_USER:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|users-activate_user",
            "activate",
            {id: id},
            gets
          ),
        DEACTIVATE_USER:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|users-deactivate_user",
            "deactivate",
            {id: id},
            gets
          ),
        LANGUAGES: {
          CREATE:
            (uid, gets = null) => getAdminUrlWithParameters(
              "admin|users|user|languages-list",
              "create",
              {user_uid: uid},
              gets
            ),
          UPDATE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|users|user|languages-detail",
              "update",
              {
                user_uid: uid,
                id: id
              },
              gets
            ),
          DELETE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|users|user|languages-detail",
              "destroy",
              {
                user_uid: uid,
                id: id
              },
              gets
            )
        },
        PHONES: {
          CREATE:
            (uid, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|phones-list",
              "create",
              {user_uid: uid},
              gets
            ),
          UPDATE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|phones-detail",
              "update",
              {
                user_uid: uid,
                id: id
              },
              gets
            ),
          DELETE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|phones-detail",
              "destroy",
              {
                user_uid: uid,
                id: id
              },
              gets
            )
        },
        MAIL: {
          CREATE:
            (uid, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|emails-list",
              "create",
              {user_uid: uid},
              gets
            ),
          UPDATE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|emails-detail",
              "update",
              {
                user_uid: uid,
                id: id
              },
              gets
            ),
          DELETE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|emails-detail",
              "destroy",
              {
                user_uid: uid,
                id: id
              },
              gets
            )
        },
        ADDRESS: {
          DETAIL:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|address-detail",
              "retrieve",
              {
                user_uid: uid,
                id: id
              },
              gets
            ),
          CREATE:
            (uid, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|address-list",
              "create",
              {user_uid: uid},
              gets
            ),
          UPDATE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|address-detail",
              "update",
              {
                user_uid: uid,
                id: id
              },
              gets
            ),
          DELETE:
            (uid, id, gets = null) => getAdminUrlWithParameters(
              "admin|contacts|address-detail",
              "destroy",
              {
                user_uid: uid,
                id: id
              },
              gets
            )
        }
      },
      ACCOUNTS: {
        LIST:
          (gets = null) => getAdminUrlWithParameters(
            'admin|users|origin|users-list',
            'list',
            gets
          ),
        DETAIL: (id, gets = null) => getAdminUrlWithParameters(
          "admin|users|origin|users-detail",
          "retrieve",
          {id: id},
          gets
        ),
        UPDATE:
          (id, gets = null) => getAdminUrlWithParameters(
            'admin|users|origin|users-update-additional',
            'update_additional',
            {id: id},
            gets
          ),
        USER_SELECTOR:
          (gets = null) => getAdminUrlWithParameters(
            'selectors|users|users',
            'get',
            gets
          )
      },
      LANGUAGES: {
        LIST:
          (gets = null) => getAdminUrlWithParameters(
            "admin|users|languages|all-list",
            "list",
            gets
          ),
        CREATE:
          (gets = null) => getAdminUrlWithParameters(
            "admin|users|languages|all-list",
            "create",
            gets
          ),
        DETAIL:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|languages|all-detail",
            "retrieve",
            {id: id},
            gets
          ),
        UPDATE:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|languages|all-detail",
            "update",
            {id: id},
            gets
          ),
        DELETE:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|users|languages|all-detail",
            "destroy",
            {id: id},
            gets
          )
      }
    },
    SESSION: {
      LIST:
        (gets = null) => getAdminUrlWithParameters(
          "admin|authentication|logs|users-list",
          "list",
          gets
        ),
      ADMIN:
        (gets = null) => getAdminUrlWithParameters(
          "admin|authentication|logs|admins-list",
          "list",
          gets
        ),
    },
    CONTACTS: {
      COUNTRY: {
        LIST:
          (gets = null) => getAdminUrlWithParameters(
            "admin|contacts|country-list",
            "list",
            gets
          ),
        CREATE:
          (gets = null) => getAdminUrlWithParameters(
            "admin|contacts|country-list",
            "create",
            gets
          ),
        DETAIL:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|contacts|country-detail",
            "retrieve",
            {id: id},
            gets
          ),
        UPDATE:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|contacts|country-detail",
            "update",
            {id: id},
            gets
          ),
        PARTIAL_UPDATE:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|contacts|country-detail",
            "partial_update",
            {id: id},
            gets
          ),
        DELETE:
          (id, gets = null) => getAdminUrlWithParameters(
            "admin|contacts|country-detail",
            "destroy",
            {id: id},
            gets
          )
      }
    }
  }
}
