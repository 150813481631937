import helper from "./helper";

let getAdminUrlWithParameters = helper.getAdminUrlWithParameters
let getReferenceUrlWithParameters = helper.getReferenceUrlWithParameters
let getOrganizationUrlWithParameters = helper.getOrganizationUrlWithParameters
let getEducationUrlWithParameters = helper.getEducationUrlWithParameters
let getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters
// let getAdminUrlAllowed = helper.getAdminUrlAllowed

export default {
  SELECTORS: {
    ACCOUNTS: {
      ACCOUNTS: (gets = null) => getAccountsUrlWithParameters(
        'selectors|users|additional|accounts',
        'get',
        {},
        gets
      ),
    },
    FACULTY_LIST:
      (organization_id, gets = null) => getOrganizationUrlWithParameters(
        'selectors|department|faculty',
        'get',
        {organization_id: organization_id},
        gets
      ),
    DEPARTMENTS_LIST:
      (organization_id, gets = null) => getOrganizationUrlWithParameters(
        'selectors|department|department',
        'get',
        {organization_id: organization_id},
        gets
      ),
    ORGANIZATION_LIST:
      ( gets = null) => getOrganizationUrlWithParameters(
        'selectors|department|organization',
        'get',
        {},
        gets
      ),
    CONTACTS: {
      COUNTRY:
        (gets = null) => getAdminUrlWithParameters(
        'selectors|contacts|country',
        'get',
        {},
        gets
      ),
      COUNTRY_CODE:
        (gets = null) => getAdminUrlWithParameters(
        'selectors|contacts|country|code',
        'get',
        {},
        gets
      ),
      LANGUAGES:
        (gets = null) => getAdminUrlWithParameters(
        'selectors|users|languages',
        'get',
        {},
        gets
      )
    },
    REFERENCE: {
      PHONE:
        (gets = null) => getReferenceUrlWithParameters(
          'selectors|phones|phones',
          'get',
          {},
          gets
        ),
      GROUPS:
        (gets = null) => getReferenceUrlWithParameters(
          'selectors|additions|group',
          'get',
          {},
          gets
        ),
      TERRITORIES:
        (gets = null) => getReferenceUrlWithParameters(
          'selectors|relations|territories',
          'get',
          {},
          gets
        ),
      BUILDINGS:
        (gets = null) => getReferenceUrlWithParameters(
          'selectors|relations|buildings',
          'get',
          {},
          gets
        ),
      ROOMS:
        (gets = null) => getReferenceUrlWithParameters(
          'selectors|relations|rooms',
          'get',
          {},
          gets
        ),
      DEPARTMENTS:
        (gets = null) => getReferenceUrlWithParameters(
          'selectors|relations|departments',
          'get',
          {},
          gets
        ),
      WORKERS:
        (gets = null) => getReferenceUrlWithParameters(
          'selectors|relations|workers',
          'get',
          {},
          gets
        ),
    },
    CHOICES: {
      GROUP_UNI:
        ( gets = null) => getEducationUrlWithParameters(
          'admin|university|choices|group-list',
          'list',
          {},
          gets
        ),
      GROUP:
        ( gets = null) => getEducationUrlWithParameters(
          'selectors|group|group|with|uid',
          'get',
          {},
          gets
        ),
      CONTACT_TYPE:
        (gets = null) => getAdminUrlWithParameters(
        'choices|contacts|contact',
        'get',
        {},
        gets
      ),
      LANGUAGE_TYPE:
        (gets = null) => getAdminUrlWithParameters(
        'choices|users|language',
        'get',
        {},
        gets
      ),
      USERS: {
        MARITAL_STATUS:
          (gets = null) => getAdminUrlWithParameters(
            'choices|users|marital|status',
            'get',
            {},
            gets
          ),
        SEX:
          (gets = null) => getAdminUrlWithParameters(
            'choices|users|sex',
            'get',
            {},
            gets
          )
      }
    }
  }
}
