<template>
<div>
  <v-data-table
    :loading="user_list.length === 0"
    :items="user_list"
    :search="search"
    :headers="headers"
    :custom-filter="filterOnlyCapsText"
  >
    <template v-slot:top>
      <v-text-field
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="pt-3"
        v-model="search">
      </v-text-field>
    </template>
    <template v-slot:item.is_exclude="{item}">
      <v-icon v-if="item.is_exclude">done</v-icon>
      <v-icon v-else>close</v-icon>
    </template>
    <template v-slot:item.face="{item}">
    {{item.face.lastname}} {{item.face.firstname}} {{item.face.midname}} ({{ displayDate(item.face.birth)}})
    </template>
    <template v-slot:item.actions="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="updateProcessing(item.guid)">
            <v-icon>fact_check</v-icon>
          </v-btn>
        </template>
        Обработать студента
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="updateUser(item.guid)">
            <v-icon>person_remove</v-icon>
          </v-btn>
        </template>
        Исключить студента из обработки приказа
      </v-tooltip>
    </template>
  </v-data-table>
</div>
</template>

<script>
import {displayDate} from "@/helper";

export default {
  name: "ListUserOrder",
  methods: {
    displayDate,
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        JSON.stringify(value).indexOf(search) !== -1
    },
    updateUser(uid){
      this.$emit('updateUser',uid);
    },
    updateProcessing(uid){
      this.$emit('updateProcessing',uid);
    }
  },
  props:{
    user_list: Array,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: "Пользователь",
          align: "left",
          sortable: false,
          value: "face",
        },
        {
          text: "Факультет",
          align: "left",
          sortable: false,
          value: "faculty.name",
        },
        {
          text: "Направление",
          align: "left",
          sortable: false,
          value: "direction.name",
        },
        {
          text: "Профиль",
          align: "left",
          sortable: false,
          value: "profile.name",
        },
        {
          text: "Уровень",
          align: "left",
          sortable: false,
          value: "level",
        },
        {
          text: "Группа",
          align: "left",
          sortable: false,
          value: "group.name",
        },
        {
          text: "Исключен",
          align: "left",
          sortable: false,
          value: "is_exclude",
        },
        {
          text: "Действия",
          align: "right",
          sortable: false,
          value: "actions",
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
