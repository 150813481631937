import Vue from 'vue'
import Router from 'vue-router'
import PageNotFound from '@/modules/core/pages/PageNotFound'
import coreRouter from '@/modules/core/routers/routers'
import adminRouter from '@/modules/admins/routers/routers'
import bookerRouter from '@/modules/booker/routers/routers'
import connectorRouter from '@/modules/university_connector/routers/routers'
import {authRequiredHook, instanceRequiredHook, isAdmin} from '@/router/hooks'

Vue.use(Router)

const baseRoutes = [
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
];
let allRoutes = []

allRoutes = allRoutes.concat(
  baseRoutes,
  coreRouter,
  adminRouter,
  bookerRouter,
  connectorRouter
)

const router = new Router(
  {
    mode: 'history',
    base: process.env.BASE_URL,
    routes: allRoutes,
  }
)
router.beforeEach(authRequiredHook);
router.beforeEach(instanceRequiredHook);
router.beforeEach(isAdmin);

export default router
