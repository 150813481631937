

<template>
  <semipolar-spinner
    v-if="!system_load"
    class="d-inline-block"
    :animation-duration="2000"
    :size="115"
    color="#1935ff"
  />
  <v-card v-else>
    <vue-json-pretty
      style="width: 100%"
      class="text-xs-left"
      :data="system_load"
      show-line
      show-double-quotes
      collapsed-on-click-brackets
      :deep="1"
    />
  </v-card>

</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import {mapActions} from "vuex";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "TabPage",
  components: {
    VueJsonPretty,
    SemipolarSpinner,
  },
  props:{
    system: String,
    item: Object,
  },
  watch: {
    item(){
      this.system_load = null
      this.loadData()
    }
  },
  methods: {
    ...mapActions({
      loadUserRelated: "faces/loadUserRelated",
    }),
    loadData(){
      this.loadUserRelated({
        id:this.item.guid,
        gets: {system: this.system},
        finalizer: (json) => {
          this.system_load = json
        },
        catcher: (error) => {
        }
      })
    }
  },
  created() {
    this.loadData()
  },
  data() {
    return {
      system_load: null,
    }
  },
}
</script>


<style scoped>

</style>
