import booker_urls from "@/urls/booker";
import {sendGetRequest, sendPostRequest} from "@/helper/requests"

const state = {
  booker_department: {
    list: [],
    loading: null,
    count: 0
  }
};

const getters = {
  getDepartmentList: (state) => state.booker_department.list,
  getDepartmentListCount: (state) => state.booker_department.count,
  isDepartmentListLoading: (state) => state.booker_department.loading
};

const actions = {
  loadList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit("SET_LOADING")
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_DEPARTMENT_LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit("SET_DEPARTMENT_LIST", data["results"])
        commit("SET_DEPARTMENT_LIST_COUNT", data["count"])
        commit("SET_NOT_LOADING")
        finalizer(data, url)
      },
      (error) => {
        commit("SET_NOT_LOADING")
        catcher(error)
      }
    )
  },
  loadDepartmentDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = booker_urls.BOOKER.OFFICIAL_DEPARTMENT_DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  normalizeDepartment: ({commit}, {id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_DEPARTMENT_NORMALIZE_AD(id)
    sendPostRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateMidname: ({commit}, {id, data, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_DEPARTMENT_UPDATE_MIDNAME(id)
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  generateAbbr: ({commit}, {id, finalizer = () => {}, catcher = () => {}}) => {
    let url = booker_urls.BOOKER.OFFICIAL_DEPARTMENT_GENERATE_ABBR(id)
    sendPostRequest(
      url,
      {},
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  }
};

const mutations = {
  SET_DEPARTMENT_LIST: (state, list) => {
    state.booker_department.list = list
  },
  REMOVE_DEPARTMENT_LIST: (state) => {
    state.booker_department.list = []
  },
  SET_LOADING: (state) => {
    state.booker_department.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.booker_department.loading = false
  },
  SET_DEPARTMENT_LIST_COUNT: (state, count) => {
    state.booker_department.count = count
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
