<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <v-tooltip
        bottom
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon text
            class="class=pa-3 ma-3"
            v-bind="attrs"
            v-on="on"
            @click="reloadData"
          >
            <v-icon>
              refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isUsersListLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :footer-props="{'items-per-page-options':[50]}"
      class="elevation-2 rounded-b-lg rounded-t-0"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="guid"
      show-expand
    >
      <template v-slot:item.clerical_date="{item}">
        {{ displayDate(item.clerical_date)}}
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="openRestoreDialog(props)">
              <v-icon>account_balance</v-icon>
            </v-btn>
          </template>
          Проведение приказа
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card class="my-2" flat>
            <v-expansion-panels>
              <v-expansion-panel class="my-1">
                <v-expansion-panel-header color="#DADADA">
                  Расширенная информация о приказе
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <vue-json-pretty
                    style="width: 100%"
                    class="text-xs-left"
                    :data="detail"
                    show-line
                    show-double-quotes
                    collapsed-on-click-brackets
                    :deep="1"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="my-1">
                <v-expansion-panel-header color="#DADADA">
                  Список лиц в приказе
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ListUserOrder
                    @updateUser="updateUser"
                    @updateProcessing="updateProcessing"
                    :user_list="user_list"></ListUserOrder>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <apply-dialog
      v-if="opened_restore_order.open"
      :opened="opened_restore_order.open"
      :delete-dialog="false"
      :success="opened_restore_order.data"
      :loading="opened_restore_order.loading"
      :get-text="() =>  (!select_user)?`Вы уверены, что хотите провести приказ?`:`Вы уверены, что хотите провести физ лицо?`"
      @close="closeAllDialog"
      @apply="applyProcessingEvents"
    />
  </v-container>
</template>

<script>
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import { mapActions, mapGetters } from "vuex"
import names from "@/modules/university_connector/routers/names"
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import VueJsonPretty from "vue-json-pretty";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import ListUserOrder from "@/modules/university_connector/orders/components/ListUserOrder.vue";
import {displayDate} from "@/helper";


export default {
  name: "List",
  components: {
    ApplyDialog,
    VueJsonPretty,
    ListUserOrder
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  data() {
    return {
      page_title: "Приказы",
      expanded: [],
      search: {
        parameter: "search"
      },
      detail: {},
      user_list: [],
      select_user: null,
      errors: {},
      pagination: {
        size_name: "size",
        size: 50
      },
      opened_restore_order: {
        open: false,
        loading: false,
        data: ''
      },
      headers: [
        {
          text: "Тип приказа",
          align: "left",
          sortable: false,
          value: "order_type",
        },
        {
          text: "Физ лиц",
          align: "left",
          sortable: false,
          value: "count",
        },
        {
          text: "Номер приказа",
          align: "left",
          sortable: false,
          value: "clerical_number",
        },
        {
          text: "Дата приказа",
          align: "left",
          sortable: false,
          value: "clerical_date",
        },
        {
          text: "Вид события",
          align: "left",
          sortable: false,
          value: "event_kind",
        },
        {
          text: "Действия",
          align: "right",
          sortable: false,
          value: "actions",
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      getUsersList: "order/getOrderList",
      getUsersCount: "order/getOrdersCount",
      isUsersListLoading: "order/isOrdersListLoading"
    }),
    names: () => names
  },
  watch: {
    expanded() {
      if (this.expanded.length > 0) {
        this.detail = {}
        this.user_list = []
        this.loadOrderDetail({
          id:this.expanded[0].guid,
          finalizer: (json) => {
            this.detail = json
          },
          catcher: (error) => {
          }
        })
        this.loadUsers(this.expanded[0].guid)
      }
    }
  },
  methods: {
    displayDate: displayDate,
    ...mapActions({
      loadUserList: "order/loadOrderList",
      loadOrderDetail: "order/loadOrderDetail",
      processingEvents: "order/processingEvents",
      eventsExclude:"order/eventsExclude",
      loadOrderDetailUser: "order/loadOrderDetailUser",
    }),
    loadUsers(guid){
      this.loadOrderDetailUser({
        id:guid,
        finalizer: (json) => {
          this.user_list = json
        },
        catcher: (error) => {
        }
      })
    },
    updateProcessing(event){
      this.select_user = event
      this.opened_restore_order.open = true
      this.opened_restore_order.loading = false
      this.opened_restore_order.data = ''
      this.edit_data = this.expanded[0]
    },
    updateUser(event){
      this.eventsExclude({
        id:this.expanded[0].guid,
        data: {education:event},
        finalizer: (json) => {
          this.loadUsers(this.expanded[0].guid)
        },
        catcher: (error) => {
        }
      })
    },
    applyProcessingEvents(ev) {
      if (ev) {
        let data = {}
        if (this.select_user){
          data = {education:this.select_user}
        }
        this.opened_restore_order.loading = true
        this.processingEvents({
          id: this.edit_data.guid,
          data: data,
          finalizer: (json) => {
            this.opened_restore_order.data = JSON.stringify(json)
            this.opened_restore_order.loading = false
            this.select_user = null
          },
          catcher: (error) => {
            this.opened_restore_order.loading = false
            this.opened_restore_order.data = JSON.stringify(error)
            this.select_user = null
          }
        })
      }
    },
    closeAllDialog(){
      this.opened_restore_order.open = false
      this.opened_restore_order.data = ''
      this.edit_data = undefined
      this.loadData(this.getCurrentLoadUrl())
    },
    openRestoreDialog (props) {
      this.opened_restore_order.open = true
      this.opened_restore_order.loading = false
      this.opened_restore_order.data = ''
      this.edit_data = props.item
    },
    loadData(url = undefined) {
      this.loadUserList({
        url: url,
        urlAppend: (url) => {
          url = this.addPageParameter(url)
          return this.addSizeParameter(url);
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        }
      })
    },
    reloadData() {
      this.loadData(this.getCurrentLoadUrl())
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
