import adminUrls from "@/urls/admin";
import {sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest} from "@/helper/requests";

const state = {
  countries: {
    list: [],
    loading: null,
    count: 0,
  }
};

const getters = {
  getCountriesList: (state) => state.countries.list,
  getCountriesListCount: (state) => state.countries.count,
  isCountriesListLoading: (state) => state.countries.loading,
};

const actions = {
  loadList: ({ commit }, {url, urlAppend, finalizer = () => {}, catcher = () => {}}) => {
    commit('SET_LOADING')
    if (!url) {
      url = adminUrls.ADMIN.CONTACTS.COUNTRY.LIST()
      if (urlAppend) url = urlAppend(url)
    }
    sendGetRequest(
      url,
      (data, url) => {
        commit('SET_COUNTRIES_LIST', data['results'])
        commit('SET_COUNTRIES_LIST_COUNT', data['count'])
        commit('SET_NOT_LOADING')
        finalizer(data, url)
      },
      (error) => {
        commit('SET_NOT_LOADING')
        catcher(error)
      }
    )
  },
  loadDetail: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.CONTACTS.COUNTRY.DETAIL(id)
    }
    sendGetRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  createCountry: ({commit}, {url, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.CONTACTS.COUNTRY.CREATE()
    }
    sendPostRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  updateCountry: ({commit}, {url, id, data, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.CONTACTS.COUNTRY.UPDATE(id)
    }
    sendPutRequest(
      url,
      data,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
  deleteCountry: ({commit}, {url, id, finalizer = () => {}, catcher = () => {}}) => {
    if (!url) {
      url = adminUrls.ADMIN.CONTACTS.COUNTRY.DELETE(id)
    }
    sendDeleteRequest(
      url,
      (data, url) => {
        finalizer(data, url)
      },
      (error, url) => {
        catcher(error, url)
      }
    )
  },
};

const mutations = {
  SET_COUNTRIES_LIST: (state, list) => {
    state.countries.list = list
  },
  REMOVE_COUNTRIES_LIST: (state) => {
    state.countries.list = []
  },
  SET_LOADING: (state) => {
    state.countries.loading = true
  },
  SET_NOT_LOADING: (state) => {
    state.countries.loading = false
  },
  SET_COUNTRIES_LIST_COUNT: (state, count) => {
    state.countries.count = count
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
