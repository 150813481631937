import helper from '@/urls/helper'

let getSignerUrlWithParameters = helper.getSignerUrlWithParameters
let getSignerUrlAllowed = helper.getSignerUrlAllowed

export default {
  getSignerUrlWithParameters,
  SIGNER: {
    ALLOWED: () => getSignerUrlAllowed(
      'admin|documents-list',
      'list'
    ),
    ADMIN:{
      DOCUMENTS: {
        LIST:
          (gets = null) => getSignerUrlWithParameters(
            'admin|documents-list',
            'list',
            {},
            gets
          ),
        DELETE:
          (id, gets = null) => getSignerUrlWithParameters(
            'admin|documents-detail',
            'destroy',
            {id: id},
            gets
          ),
        DETAIL:
          (id, gets = null) => getSignerUrlWithParameters(
            'admin|documents-detail',
            'retrieve',
            {id: id},
            gets
          )
      },
      DISPATCHED_DOCUMENTS: {
        LIST:
          (gets = null) => getSignerUrlWithParameters(
            'admin|dispatched_documents',
            'get',
            {},
            gets
          )
      },
      RECIPIENTS: {
        LIST:
          (gets = null) => getSignerUrlWithParameters(
            'admin|recipient_types-list',
            'list',
            {},
            gets
          ),
        CREATE:
          (gets = null) => getSignerUrlWithParameters(
            'admin|recipient_types-list',
            'create',
            {},
            gets
          ),
        DETAIL:
          (id, gets = null) => getSignerUrlWithParameters(
            'admin|recipient_types-detail',
            'retrieve',
            {id: id},
            gets
          ),
        UPDATE:
          (id, gets = null) => getSignerUrlWithParameters(
            'admin|recipient_types-detail',
            'update',
            {id: id},
            gets
          ),
        DELETE:
          (id, gets = null) => getSignerUrlWithParameters(
            'admin|recipient_types-detail',
            'destroy',
            {id: id},
            gets
          ),
      }
    }
  }

}
