<template>
  <v-container :fluid="true" style="width: 100%" class="px-0 pb-0">
    <v-card class="rounded-lg" v-if="userWorker">
      <v-toolbar
        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6 style-color-main-gray text-left px-4 pr-1' : 'text style-color-main-gray text-left px-4 pr-1'"
        dense
        flat
      >
        <span class="dark-primary"> Личная карточка работника </span>
        <v-spacer/>
      </v-toolbar>
      <v-card-text class="text-left pt-2">
        <v-row
          no-gutters
          :class="($vuetify.breakpoint.mdAndUp && i%2) ? 'bg-grey-lighten-3 rounded-lg' : 'rounded-lg'"
          v-for="(row, i) in data" :key="`row${i}`"
        >
          <v-col
            cols="12" md="6"
            :class="($vuetify.breakpoint.smAndDown && col%2) ? 'pa-3 bg-grey-lighten-3 rounded-lg' : 'pa-3 rounded-lg pr-md-8'"
            v-for="col in Object.keys(row)"
            :key="`row${i}_${col}`"
          >
            <b class="text--primary">{{ row[col].text }}:</b> {{ (row[col].type === 'date') ? displayDate(userWorker[row[col].field]) : userWorker[row[col].field] }}
          </v-col>
        </v-row>
      </v-card-text>
      <section v-if="userWorker">
        <v-card-title class="grey lighten-3 dark-primary justify-center">
          Занимаемые должности
        </v-card-title>
        <v-expansion-panels
          v-model="expanded_items"
          multiple
          accordion
          flat
          focusable
          class="rounded-b-lg"
        >
          <v-expansion-panel
            v-for="(item, index) in userWorker.position_helds"
            :key="index"
            :class="index !== userWorker.position_helds.length - 1 && !expanded_items.includes(index) ? 'rounded-0 ' : 'rounded-0 border-0'"
          >
            <v-expansion-panel-header
              :class="expanded_items.includes(index) ? 'medium-primary' : ''"
            >
              <v-row no-gutters class="align-center">
                <v-col
                  cols="12" md="6"
                  :class="$vuetify.breakpoint.mdAndUp ? 'pr-md-2 text-line-height-1' : 'mb-3 text-line-height-1'"
                >
                  <b>Подразделение:</b>
                  {{ item.department }}
                  <v-icon size="14" class="mb-1 dark-primary" @click.stop="copyInClipboard(item.department)">content_copy</v-icon>
                </v-col>
                <v-col
                  cols="12" md="5"
                  class="text-line-height-1 pl-md-6"
                >
                  <b>Должность:</b>
                  {{ item.title }}
                  <v-icon size="14" class="mb-1 dark-primary" @click.stop="copyInClipboard(item.title)">content_copy</v-icon>
                </v-col>
                <v-col
                  cols="12" md="1"
                  v-if="item.is_fired"
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-3'"
                >
                  <v-chip :color="expanded_items.includes(index) ? 'medium-primary' : ''">Уволен</v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters class="align-center text-left pr-6">
                <v-col cols="12" md="2" class="py-2" v-if="$vuetify.breakpoint.smAndDown">
                  <v-chip><span><b>Ставка:</b> {{ item.rate }}</span></v-chip>
                </v-col>
                <v-col cols="12" md="6" class="py-2">
                  <b>Дата начала работы в организации:</b> {{ displayDate(item.start_work) }}
                </v-col>
                <v-col cols="12" md="4" class="py-2 pl-md-6">
                  <span v-if="!!item.end_work"><b>Дата увольнения:</b> {{ displayDate(item.end_work) }}</span>
                </v-col>
                <v-col cols="12" md="2" class="py-2 text-md-right pr-md-3 pr-xl-5 pr-xxl-16" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-chip><span><b>Ставка:</b> {{ item.rate }}</span></v-chip>
                </v-col>
                <v-col cols="12" class="py-2">
                  <b>Тип должности:</b> {{ item.type }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      expanded_items: [],
      data: [
        [
          { text: "Дата начала трудового стажа", field: "start_seniority", type: "date" },
          { text: "Дата начала трудового стажа в организации", field: "start_seniority_in_organization", type: "date" }
        ],
        [
          { text: "Информация о себе", field: "personal_information" },
          { text: "Награды", field: "awards" }
        ],
        [
          { text: "Предыдущие места работы", field: "previous_jobs" },
          { text: "Ученое звание", field: "academic_ranks" }
        ],
        [
          { text: "Ученая степень", field: "academic_degree" },
          { text: "Образование", field: "education" }
        ]
      ]
    }
  },
  computed: {
    ...mapGetters({
      userWorker: "adminUsers/getUserWorkerInfo",
      getProfile: "adminUsers/getProfile"
    })
  },
  methods: {
    ...mapActions({
      loadUserWorker: "adminUsers/loadUserWorkerInfo"
    }),
    displayDate (date, minutes = false) {
      if (!date) {
        return "Не указано"
      }
      let format = "DD.MM.YYYY"
      if (minutes) { format += " HH:mm" }
      return moment(date).format(format)
    },
    copyInClipboard (text) {
      try {
        navigator.clipboard.writeText(text)
        this.copy = true
        setTimeout(() => {
          this.copy = false
        }, "2000")
      } catch (e) {
      }
    }
  }

}
</script>


<style scoped>

</style>
