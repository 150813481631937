<template>
  <v-row class="pt-3">
    <v-col>
      <v-card
        class="pa-4 mb-2 rounded-lg"
        elevation="0"
        color="grey lighten-3"
      >
        <v-card-title class="text-left subtitle-1 px-0 pt-0 pb-2">

        </v-card-title>
        <v-row no-gutters class="pa-0">
          <v-card-title class="py-0">
            Внесен в персональные контакты:
          </v-card-title>
          <template
            v-for="(phone, i) in item.personal_contact_phones"
          >
            <v-card-text class="py-0 my-0 text-left" :key="i+'_user'">
                  {{ phone.contact.user.text }}
                  <v-btn v-if="!phone.taken" icon @click="$emit('deleteUser', phone)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span v-if="phone.taken">(изъят {{ displayDate(phone.taken, true) }})</span>
            </v-card-text>
          </template>
          <v-card-text class="text-left" v-if="item.personal_contact_phones.length === 0"> Нет</v-card-text>
        </v-row>
        <v-row no-gutters class="pa-0 pt-2">
          <v-card-title class="py-0">
            Внесен в контакты департаментов:
          </v-card-title>
          <template
            v-for="(phone, i) in item.department_contact_phones"
          >
            <v-card-text class="py-0 my-0 text-left" :key="i+'_user'">
                  {{ phone.contact.department.text }}
                  <v-btn v-if="!phone.taken" icon @click="$emit('deleteDepartament', phone)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span v-if="phone.taken">(изъят {{ displayDate(phone.taken, true) }})</span>
            </v-card-text>
          </template>
          <v-card-text class="text-left" v-if="item.department_contact_phones.length === 0"> Нет</v-card-text>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import {displayDate} from "@/helper";

export default {
  name: "Phones",
  methods: {displayDate},
  props: {
    item: Object,
  },
  components: {},
  mixins: [],
  data() {
    return {}
  },


}
</script>

<style scoped>

</style>
