import Vue from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookie from 'vue-cookies'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import '../public/css/main.css'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import DatetimePicker from 'vuetify-datetime-picker'
import VueMask from 'v-mask';
Vue.use(VueCookie);
Vue.use(DatetimePicker);
Vue.use(VueMask);

Vue.config.productionTip = false

Vue.use(VueBreadcrumbs, {
  mixins: [
    {
      methods: {
        getRoute: function f(crumb) {
          return {name: crumb.name, params: this.$route.params};
        }
      }
    }
  ],
  template: `
    <nav class="breadcrumb text-left" v-if="$breadcrumbs.length" xmlns="http://www.w3.org/1999/html">
      <template v-for="(crumb, i) in $breadcrumbs">
        <router-link
          class="breadcrumb-item"
          :to="getRoute(crumb)"
          :id="i+'_breadcrumbs'"
          style="text-decoration: none; color: inherit"
        >
          {{ getBreadcrumb(crumb.meta.breadcrumb) }}
        </router-link>
        <v-icon
          v-if="$breadcrumbs.length - 1 !== i"
          class="breadcrumb-icon my-2 color--corporate-main"
        >
          chevron_right
        </v-icon>
      </template>
    </nav>
  `
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')

function getChromeVersion() {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
}

let chrome = getChromeVersion();
if (chrome && chrome <= 49) {
  window.onload = function () {
    let element = document.getElementById('base-content');
    element.style.paddingTop = '60px';
    element = document.getElementById("main-left-menu");
    element.style.marginTop = '60px';
    element.style.maxHeight = 'calc(100% - 60px)';
  }
}
