<template>
  <v-container class="page-content px-5">
    <v-toolbar
      class="removeBtnPaddingRight rounded-t-lg"
      color="#DADADA"
      elevation="1"
    >
      <v-text-field
        v-model="search.value"
        append-icon="search"
        label="Поиск"
        placeholder="Для поиска нажмите Enter или кнопку поиск"
        dense
        outlined
        class="py-3 my-3"
        @click:append="searchDataDepartment"
        @keydown.enter="searchDataDepartment"
        @click:clear="clearSearchData"
        clearable
        hide-details
      ></v-text-field>
      <v-autocomplete
        v-model="pagination.filters.international_calls_allowed"
        outlined
        class="mx-2 py-3 my-3"
        dense
        hide-details
        label="Международные звонки"
        :items="selectors.type_phone">
      </v-autocomplete>
      <v-autocomplete
        v-model="pagination.filters.long_distance_calls_allowed"
        outlined
        class="mx-2 py-3 my-3"
        dense
        hide-details
        label="Междугородние звонки"
        :items="selectors.type_phone">
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-menu
        v-if="$vuetify.breakpoint.xsOnly"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }" class="mr-3">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            color="black"
            class="mr-0"
          >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="newDepartment"
          >
            Создать
          </v-btn>
          <v-btn
            depressed
            color="transparent"
            width="100%"
            @click="reloadData"
          >
            Обновить
          </v-btn>
        </v-list>
      </v-menu>
      <div v-else>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 ma-0"
              v-bind="attrs"
              v-on="on"
              @click="newDepartment"
            >
              <v-icon>
                add
              </v-icon>
            </v-btn>
          </template>
          <span>Добавить контакт</span>
        </v-tooltip>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon text
              class="pa-0 mr-3"
              v-bind="attrs"
              v-on="on"
              @click="reloadData"
            >
              <v-icon>
                refresh
              </v-icon>
            </v-btn>
          </template>
          <span>Обновить</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getUsersList"
      :loading="isLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.size"
      :server-items-length="getUsersCount"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      class="elevation-2 rounded-b-lg rounded-t-0"
      :footer-props="{ disablePagination : isLoading, disableItemsPerPage : isLoading, 'items-per-page-options':[25,50,100]}"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <semipolar-spinner
            v-if="!phone"
            class="d-inline-block"
            :animation-duration="2000"
            :size="115"
            color="#1935ff"
          />
          <DetailPhone @deleteDepartament="deleteDepartament"
                       @deleteUser="deletePersonal"
                       :item="phone" v-else></DetailPhone>
        </td>
      </template>
      <template v-slot:item.international_calls_allowed="{ item }">
        <v-icon color="teal" v-if="item.international_calls_allowed">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.long_distance_calls_allowed="{ item }">
        <v-icon color="teal" v-if="item.long_distance_calls_allowed">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="createComment(item.id)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                sms
              </v-icon>
            </v-btn>
          </template>
          <span>Оставить комментарий</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editDepartment(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openDeleteDialog(item)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          <span>Удалить контакт</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="reloadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="copy"
      color="#757575"
    >
      Текст скопирован в буфер обмена
    </v-snackbar>
    <editPhoneDialog
      v-if="dialog_add_edit"
      :opened="dialog_add_edit"
      :obj_data="docs_data"
      @close="closeEditDialog"
    ></editPhoneDialog>
    <ApplyDialog
      v-if="dialog_delete"
      :opened="dialog_delete"
      :delete-dialog="true"
      :get-text="() => `Вы уверены, что хотите удалить?`"
      @close="closeDeleteDialog"
      @apply="closeDeleteDialog"
    ></ApplyDialog>
    <create-comment-dialog
      v-if="create_comment"
      :opened="create_comment"
      :phoneId="contact_id"
      @close="closeCreateCommentDialog"
    />
  </v-container>
</template>


<script>

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/admins/routers/names";
import TitledPageMixin from '@/mixins/TitledPageMixin'
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import editPhoneDialog from "@/modules/admins/reference/components/phone/editPhoneDialog.vue";
import CreateCommentDialog from "@/modules/admins/reference/components/commentPhones/createCommentDialog.vue";
import {SemipolarSpinner} from "epic-spinners";
import DetailPhone from "@/modules/admins/reference/components/DetailPhone.vue";

export default {
  name: "List",
  components: {
    CreateCommentDialog,
    ApplyDialog,
    editPhoneDialog,
    SemipolarSpinner,
    DetailPhone
  },
  mixins: [PaginatedDataMapperMixin, TitledPageMixin, FormErrorsMixin],
  watch:{
    expanded(current){
      if(current[0]){
        this.phone = undefined
        this.loadPhoneDetail({
          id: current[0].id,
          finalizer: (data) => {
            this.phone = data
          }
        })

      }
    },
  },
  data: function () {
    return {
      page_title: 'Телефонные номера',
      copy: false,
      expanded: [],
      phone:undefined,
      dialog_add_edit: false,
      edit_status: undefined,
      department_id: undefined,
      docs_data: undefined,
      create_comment: undefined,
      contact_id: undefined,
      dialog_delete: false,
      pagination: {
        filters: {
          international_calls_allowed: null,
          long_distance_calls_allowed: null,
        },
        size_name: 'size',
      },
      selectors: {
        type_phone: [
          {text: "Все", value: null},
          {text: "Да", value: "true"},
          {text: "Нет", value: "false"}
        ],
      },
      search: {
        parameter: 'search',
      },
      headers: [
        {
          text: 'Телефон',
          sortable: false,
          value: 'number',
          align: 'left',
          width: '40%'
        },
        {
          text: 'Разрешены международные звонки',
          sortable: false,
          value: 'international_calls_allowed',
          align: 'center',
          width: '20%'
        },
        {
          text: 'Разрешены междугородние звонки',
          sortable: false,
          value: 'long_distance_calls_allowed',
          align: 'center',
          width: '20%'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'center',
          width: '20%'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUsersList: 'phoneReference/getPhoneList',
      getUsersCount: 'phoneReference/getPhoneListCount',
      isLoading: 'phoneReference/isPhoneListLoading',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadDepartmentList: 'phoneReference/loadPhoneList',
      deletePhone: 'phoneReference/deletePhone',
      deletePhoneDepartment: 'phoneReference/deletePhoneDepartment',
      deletePhonePersonal: 'phoneReference/deletePhonePersonal',
      loadPhoneDetail: 'phoneReference/loadPhoneDetail',
    }),
    deleteDepartament(event){
      this.deletePhoneDepartment({
        id: event.id,
        phone_id: this.expanded[0].id,
        finalizer: () => {
          this.phone = undefined
          this.loadPhoneDetail({
            id: this.expanded[0].id,
            finalizer: (data) => {
              this.phone = data
            }
          })
        },
        catcher: (error) => {

        }
      })
    },
    deletePersonal(event){
      this.deletePhonePersonal({
        id: event.id,
        phone_id: this.expanded[0].id,
        finalizer: () => {
          this.phone = undefined
          this.loadPhoneDetail({
            id: this.expanded[0].id,
            finalizer: (data) => {
              this.phone = data
            }
          })
        },
        catcher: (error) => {

        }
      })
    },
    createComment(id){
      this.create_comment = true
      this.contact_id = id
    },
    closeCreateCommentDialog() {
      this.create_comment = false
      this.contact_id  = undefined
    },
    copyInClipboard (item, index) {
      let copyText
      if (typeof item === 'object') copyText = item[index]
      else copyText = item
      try {
        navigator.clipboard.writeText(copyText)
        this.copy = true
        setTimeout(() => {
          this.copy = false
        }, "2000")
      } catch (e) {
        // console.log(e)
      }
    },
    loadData(url = undefined) {
      this.loadDepartmentList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(
            this.addSizeParameter(
              this.addGetParameter(url, 'search', this.search.value)
            )
          )
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url)
        },
        catcher: (error) => {
          this.mapErrors(error)
        }
      })
    },
    reloadData() {
      this.clearSearchData()
      this.loadData()
    },
    newDepartment() {
      this.docs_data =  undefined
      this.dialog_add_edit = true
    },
    editDepartment(item) {
      this.docs_data = item
      this.dialog_add_edit = true
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
    },
    openDeleteDialog(item) {
      this.dialog_delete = true
      this.docs_data = item
    },
    closeDeleteDialog(event) {
      if (event) {
        this.deletePhone({
          id: this.docs_data.id,
          finalizer: (val) => {
            this.loadData()
            this.dialog_delete = false
            this.docs_data =  undefined
          },
          catcher: (val) => {
            this.mapErrors(val)
            this.dialog_delete = false
            this.docs_data =  undefined
          },
        })
      }else {
        this.dialog_delete = false
        this.docs_data =  undefined
      }
    },
    searchDataDepartment() {
      if(this.search.value){
        this.searchData()
      }
    }
  },
  created() {
    this.loadData()
  }
}
</script>
<style scoped>
.removeBtnPaddingRight >>> .v-toolbar__content {
  padding-right: 0px;
}
</style>
