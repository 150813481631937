<template>
  <v-container class="text-xs-left">
    <v-progress-linear indeterminate v-if="item.detail_loading"></v-progress-linear>
    <v-layout class="title my-2">
      <div class="md3">Наименование: {{item.name}}</div>
      <v-spacer/>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              :loading="loadingNormalize"
              @click="openNormalize"
              class="mx-1"
              outlined
            >
              <v-icon
                color="primary"
              >
                compare_arrows
              </v-icon>
            </v-btn>
          </template>
          <span>Нормализация положения подразделения</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              :loading="loadingGenerate"
              @click="openGenerate"
              class="mx-1"
              outlined
            >
              <v-icon
                color="primary"
              >
                abc
              </v-icon>
            </v-btn>
          </template>
          <span>Сгенерировать аббревиатуру</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              :loading="loadingUpdate"
              @click="openUpdateMidname"
              class="mx-1"
              outlined
            >
              <v-icon
                color="primary"
              >
                sync
              </v-icon>
            </v-btn>
          </template>
          <span>Обновить среднее наименование</span>
        </v-tooltip>
      </div>
    </v-layout>
    <v-divider></v-divider>
    <v-layout class="my-2">
      <v-flex class="md3">Абревиатура:</v-flex>
      <v-flex class="md3">{{item.abbr}}</v-flex>
      <v-flex class="md3">Код:</v-flex>
      <v-flex class="md3">{{item.code}}</v-flex>
    </v-layout>
    <v-layout class="my-2">
      <v-flex class="md3">Организация:</v-flex>
      <v-flex class="md3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{item.organization.name}}</span>
          </template>
          <span>{{item.organization.full_name}}</span>
        </v-tooltip>
      </v-flex>
      <v-flex class="md3">Организация связана:</v-flex>
      <v-flex class="md3">
        <v-icon color="teal" v-if="item.organization.rel_guid">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </v-flex>
    </v-layout>
    <v-layout class="my-2">
      <v-flex class="md3">Подразделение упразднено:</v-flex>
      <v-flex class="md3">
        <v-icon color="teal" v-if="item.abolished">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </v-flex>
      <v-flex class="md3">Подразделение связано:</v-flex>
      <v-flex class="md3">
        <v-icon color="teal" v-if="item.organization_related">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </v-flex>
    </v-layout>
    <v-layout class="my-2">
      <v-flex class="md3">Связано с AD:</v-flex>
      <v-flex class="md3">
        <v-icon color="teal" v-if="item.ms_related">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </v-flex>
      <v-flex class="md3">Связано с Portal:</v-flex>
      <v-flex class="md3">
        <v-icon color="teal" v-if="item.organization_related">done</v-icon>
        <v-icon v-else>clear</v-icon>
      </v-flex>
    </v-layout>
    <v-layout class="my-2">
      <v-flex class="md3">Актуализировано в AD:</v-flex>
      <v-flex class="md3">{{item.ms_related_actualized}}</v-flex>
      <v-flex class="md3">Актуализировано в Portal:</v-flex>
      <v-flex class="md3">{{item.organization_related_actualized}}</v-flex>
    </v-layout>
    <v-layout class="my-2">
      <v-flex class="md3">Создано в конекторе:</v-flex>
      <v-flex class="md3">{{item.created}}</v-flex>
      <v-flex class="md3">Последнее обновление:</v-flex>
      <v-flex class="md3">{{item.synced}}</v-flex>
    </v-layout>
    <v-layout class="my-2">
      <v-flex class="md12">
        <span>Полные данные аккаунта АД: </span>
        <v-btn outlined small @click="expands.ad = !expands.ad">
          <v-icon v-if="!expands.ad">expand_more</v-icon>
          <v-icon v-else>expand_less</v-icon>
          Раскрыть
        </v-btn>
        <v-expand-transition mode="out-in">
          <vue-json-pretty
            v-show="expands.ad"
            style="width: 100%"
            class="text-xs-left"
            :data="item.full_ad_data"
            show-line
            show-double-quotes
            collapsed-on-click-brackets
            :deep="1"
          ></vue-json-pretty>
        </v-expand-transition>
      </v-flex>
    </v-layout>
    <v-layout class="my-2" v-if="error">
      <v-flex class="md12">
        <v-row align="center">
          <span class="red--text">Ошибка обновления среднего значения <code>sync_errors</code></span>
          <v-btn
            icon
            color="red"
            @click="error = null"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-row>
        <vue-json-pretty
          v-show="error"
          style="width: 100%"
          class="text-xs-left"
          :data="error"
          show-line
          show-double-quotes
          collapsed-on-click-brackets
          :deep="1"
        />
      </v-flex>
    </v-layout>
    <update-midename-dialog
      :value="opened.update"
      @close="closeUpdate"
    />
    <apply-dialog
      v-if="opened.normalize"
      :opened="opened.normalize"
      @close="closeNormalizeDepartment"
      @apply="closeNormalizeDepartment"
    >
      <v-alert
        outlined
        type="warning"
      >
        Внимание! Нормализация положения подразделения вызовет изменение данных в AD!
      </v-alert>
    </apply-dialog>
    <apply-dialog
      v-if="opened.generate"
      :opened="opened.generate"
      @close="closeGenerateAbr"
      @apply="closeGenerateAbr"
    >
      <v-alert
        outlined
        type="warning"
      >
        Внимание! Генерация аббревиатуры подразделения вызовет изменение данных в AD!
      </v-alert>
    </apply-dialog>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import VueJsonPretty from "vue-json-pretty";
import UpdateMidenameDialog from "@/modules/booker/department/components/UpdateMidenameDialog.vue";
import ApplyDialog from '@/modules/core/components/ApplyDialog.vue'

export default {
  name: "DepartmentExpanded",
  components: {UpdateMidenameDialog, VueJsonPretty, ApplyDialog},
  props: {
    item: {
      type: Object,
      required: true
    },
    guid: String
  },
  data() {
    return {
      loadingNormalize: false,
      loadingGenerate: false,
      loadingUpdate: false,
      expands: {
        ad: false
      },
      opened: {
        normalize: false,
        generate: false,
        update: false
      },
      error: null
    }
  },
  methods: {
    ...mapActions({
      normalize: "department/normalizeDepartment",
      generate: "department/generateAbbr",
      update: "department/updateMidname"
    }),
    closeNormalizeDepartment(ev) {
      if (ev) {
        this.loadingNormalize = true
        this.normalize({
          id: this.item.guid,
          finalizer: () => {
            this.loadingNormalize = false
          },
          catcher: () => {
            this.loadingNormalize = false
          }
        })
      }
      this.opened.normalize = false
    },
    closeGenerateAbr(ev) {
      if (ev) {
        this.loadingGenerate = true
        this.generate({
          id: this.item.guid,
          finalizer: () => {
            this.loadingGenerate = false
          },
          catcher: () => {
            this.loadingGenerate = false
          }
        })
      }
      this.opened.generate = false
    },
    closeUpdate(arg) {
      if (arg) {
        this.loadingUpdate = true
        this.update({
          id: this.item.guid,
          data: {
            middle_name: arg
          },
          finalizer: (data) => {
            this.loadingUpdate = false
            this.error = {
              api: true,
              perco: true
            }
            if (data.sync_errors.api && data.sync_errors.perco) {
              this.error = data.sync_errors
            }
          },
          catcher: () => {
            this.loadingUpdate = false
          }
        })
      }
      this.opened.update = false
    },
    openUpdateMidname() {
      this.opened.update = true
    },
    openNormalize() {
      this.opened.normalize = true
    },
    openGenerate() {
      this.opened.generate = true
    }
  }
}
</script>

<style scoped>

</style>
