<template>
  <div class="page-content" style="margin: 0px; width: 100%">
 <v-container>
   <v-toolbar
     class="removeBtnPaddingRight rounded-t-lg"
     color="#DADADA"
     elevation="1"
   >
   <v-text-field
     v-model="search"
     label="Поиск в структуре"
     hide-details
     clearable
     :loading="loading"
     outlined
     dense
     clear-icon=" close "
     prepend-inner-icon="search"
   ></v-text-field>
     <v-switch
       hide-details
       outlined
       :disabled ="loading"
       dense
       class="pl-3"
       :label="$vuetify.breakpoint.mdAndUp ? 'Расформированные':''"
       v-model="abolished"
     >
     </v-switch>
   </v-toolbar>
   <v-treeview :items="getItems"
               :search="search"
               hoverable
               :loading="loading"
               item-key="pk"
               class="text-left pt-1 white">
     <template v-slot:label ="{item}">
      <v-list class="py-0 my-0 ">
        <v-list-item class="text-wrap px-0">
          <v-list-item-avatar class="px-0 mx-0" v-show="item.abolished_contains || item.abolished">
            <v-tooltip bottom v-if="item.abolished_contains">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="info"
                >
                  info
                </v-icon>
              </template>
              <span>Содержит расформированые подразделения</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.abolished">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  dangerous
                </v-icon>
              </template>
              <span>Расформировано</span>
            </v-tooltip>
          </v-list-item-avatar>
          <v-list-item-content class="text-wrap">
            <v-list-item-title v-text="item.name" class="text-wrap"></v-list-item-title>
            <v-list-item-subtitle v-text="item.comment" class="text-wrap"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       v-bind="attrs"
                       v-on="on"
                       @click="openEditDialog(item)">
                  <v-icon color="grey lighten-1">speaker_notes</v-icon>
                </v-btn>
              </template>
              <span>Комментарии</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
     </template>
   </v-treeview>
   <comment-dialog
     v-if="opened.edit"
     :opened="opened.edit"
     :edit_data="edit_data"
     @close="closeEditDialog"
   />
 </v-container>
  </div>
</template>

<script>

import names from "@/modules/admins/routers/names";
import {mapActions, mapGetters} from "vuex";
import commentDialog from "@/modules/admins/tree/components/commentDialog.vue";
import worker from "@/urls/worker";

export default {
  name: "Tree",
  components: {commentDialog},
  data() {
    return {
      search:'',
      opened: {
        edit: false,
      },
      loading:false,
      abolished:true,
      edit_data: undefined,
      delete_data: undefined,
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      getItems: 'tree/getTreeList',
    }),
  },
  watch:{
    abolished: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions({
      loadList: 'tree/loadTreeList',
    }),
    openEditDialog(props) {
      this.opened.edit = true
      this.edit_data = props
    },
    closeEditDialog() {
      this.loadData()
      this.opened.edit = false
      this.edit_data = undefined
    },
    loadData(url = undefined) {
      this.loading = true
      if(!this.abolished)
        url =   worker.WORKER.DEPARTMENT_NOTABOLISHED_LIST(1)
      this.loadList({
        url: url,
        organization_id: 1,
        finalizer: (data, url) => {
          this.loading = false
        }
      })
    },
  },
  created() {
    this.loadData()
  },
}
</script>

<style scoped>

</style>
