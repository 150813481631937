import store from '../store'
import { makePostRequest } from '@/helper/requests'

export function generateListUidToName (form, nameUid, nameNew, finalizer = () => {
}) {
  /**
     * Выполняет запрос
     * @param {Object} form - this объекта по которому бедет проводится изменение
     * @param {String} nameUid - Имя поле в котором уид пользователей
     * @return {String} nameNew - добавленное поле в котором уид пользователей заменен на имя
     */

  const users = []
  for (const i in form) {
    users.push(form[i][nameUid])
  }
  const url = store.getters['urls/getPattern'](
    process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', ''),
    'users|user|fio',
    'post'
  )

  makePostRequest(
    process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '') + url, { uids: users }
  )
    .then(resp => resp.json())
    .then(json => {
      for (const i in json) {
        const replaced = form.filter((item) => item[nameUid] === json[i].value)
        if (replaced.length > 0) {
          for (const j in replaced) {
            replaced[j][nameNew] = json[i].text
          }
        }
      }
      finalizer(form)
    })
}

export function generateUidToName (DataUser, Uid, UserName, finalizer = () => {
}) {
  const users = []
  users.push(DataUser[Uid])
  const url = store.getters['urls/getPattern'](
    process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', ''),
    'users|user|fio',
    'post'
  )

  makePostRequest(
    process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '') + url, { uids: users }
  )
    .then(resp => resp.json())
    .then(json => {
      for (const i in json) {
        DataUser[UserName] = json[i].text
      }
      finalizer(DataUser)
    })
}
